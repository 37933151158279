import { Alert, Button, DialogContent, Grid, InputLabel, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import Login from 'shared/api/Acl/Login';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Dots } from 'values/HTMLEntities';
import { areInputsEmpty, generateUserFriendlyErrorMessageForUsernameOrAlias } from 'shared/utils/helperFunctions';
import ToggleVisibilityPasswordField from 'shared/components/ToggleVisibilityPasswordField';

import 'styles/frontshine.css';
/* This component is used for both SIM Billing and Remote Management */
interface Props {
  // Both
  setUsernameAndToken?: (userName: string, token: string) => void;
  // Remote Management
  handleClose?: (event: React.SyntheticEvent) => void;
  setToken?: React.Dispatch<React.SetStateAction<string>>;
  setIsActivationDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoginDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setTab?: (x: string) => void;
  // SIM Billing
  apiContext?: {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  };
}

interface FormValues {
  adminId: string;
  aclPassword: string;
}

const validationSchema = yup.object().shape({
  adminId: yup.string().required('required'),
  aclPassword: yup.string().required('Required')
  // Add password validation
  // Comment out if we do not want to enforce password validation
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
  //   'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number'
  // )
});

const LoginPanel = ({
  setToken,
  setUsernameAndToken,
  setTab,
  setIsActivationDialogOpen,
  setIsLoginDialogOpen,
  apiContext
}: Props) => {
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertContent, setAlertContent] = useState('');

  useEffect(() => {
    setIsSubmitting(apiContext?.loading || false);
    if (apiContext?.errorMessage) {
      setError(true);
      setAlert(true);
      setAlertContent(apiContext?.errorMessage);
    }
  }, [apiContext]);

  const handleLogin = async (values: FormValues) => {
    if (apiContext) {
      apiContext.setLoading(true);
      setError(false);
    }
    setAlert(false);
    setIsSubmitting(true);
    try {
      const { idToken, username } = await Login.getToken(values.adminId, values.aclPassword);
      setError(false);
      if (apiContext === undefined) setIsSubmitting(false);
      if (setUsernameAndToken) {
        // Removed unnecessary await
        setUsernameAndToken(username, idToken);
      }
      if (setToken) {
        setToken(idToken);
      }
      if (apiContext === undefined) {
        setError(false);
        setAlert(true);
        setAlertContent('Login successful!');
      }

      if (setTab) {
        setTab('4');
      }
    } catch (e: any) {
      setIsSubmitting(false);
      setError(true);
      setAlert(true);
      const errorType = e.message.split(':')[0] || e.message;
      switch (errorType) {
        case 'UserNotConfirmedException':
          if (setIsLoginDialogOpen) setIsLoginDialogOpen(false);
          if (setIsActivationDialogOpen) setIsActivationDialogOpen(true);
          break;
        default:
          break;
      }
      // NOTE: We are already handling user not found.

      // Handle user friendly errors for username or alias
      if (errorType === 'InvalidParameterException') {
        setAlertContent(generateUserFriendlyErrorMessageForUsernameOrAlias(e.message));
      } else {
        // The code below gets the string in between the two colons. Example: Error: InvalidParameterException: 2
        // validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular
        // expression pattern: [\p{L}\p{M}\p{S}\p{N}\p{P}]+; Value at 'userName' failed to satisfy constraint: Member
        // must satisfy regular expression pattern: [\p{L}\p{M}\p{S}\p{N}\p{P}]+... will get "2 validation errors
        // detected".
        const actualMessage = e.message.split(': ')[1] || e.message;
        setAlertContent(actualMessage);
      }
    }
  };
  const isMarkdownContent = (content: string) => /\[.*]\(.*\)/.test(content);

  const handleAlertContent = () => {
    if (isMarkdownContent(alertContent)) {
      return <ReactMarkdown remarkPlugins={[remarkGfm]}>{alertContent}</ReactMarkdown>;
    } else {
      return alertContent;
    }
  };

  return (
    <>
      {isSubmitting && apiContext && <Spinner />}
      <Formik
        initialValues={{ adminId: '', aclPassword: '' }}
        onSubmit={handleLogin}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, errors, touched, values, isValid }) => (
          <Form className={'bg-grey'}>
            <DialogContent>
              {alert ? (
                <Alert severity={error ? 'error' : 'success'} sx={styles.alert}>
                  {handleAlertContent()}
                </Alert>
              ) : (
                <></>
              )}
              <div className={'flex items-center justify-center'}>
                <p className={'text-size_10px text-center no-vertical-margin'}></p>
                <img src={'/aiphone_logo_black.png'} style={{ maxWidth: 150 }} alt={'Aiphone logo'} />
                <p className={'text-size_40px text-center no-vertical-margin'}>IXG Cloud</p>
              </div>

              <Grid container direction={'column'} sx={styles.marginTop} spacing={1} className={'space-y-0.5'}>
                <Grid item>
                  <InputLabel>
                    <span className={'font-bolder text-black'}>IXG Cloud Server ID or Email Address</span>
                  </InputLabel>
                  <Field
                    as={TextField}
                    fullWidth
                    name="adminId"
                    margin="dense"
                    placeholder={'IXG Cloud Server ID or Email Address'}
                    sx={styles.inputField}
                    helperText={touched.adminId && errors.adminId}
                    errors={touched.adminId && errors.adminId}
                  />
                </Grid>
                <Grid item className={'mb-2'}>
                  <InputLabel>
                    <span className={'font-bolder text-black'}>Password</span>
                  </InputLabel>
                  <Field
                    as={ToggleVisibilityPasswordField}
                    fullWidth
                    name="aclPassword"
                    placeholder={`${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}`}
                    margin="dense"
                    sx={styles.inputField}
                    helperText={touched.aclPassword && errors.aclPassword}
                    errors={touched.aclPassword && errors.aclPassword}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting || areInputsEmpty(values)}
                className={'w-full mt-4'}
              >
                {isSubmitting && apiContext === undefined ? (
                  <CircularProgress size="25px" sx={{ color: 'white' }} />
                ) : (
                  'Login'
                )}
              </Button>
              <div className={'flex flex-row justify-end'}>
                <a
                  className={'ml-auto mt-1 text-size_14px text-right text-decoration-none james-bond-blue'}
                  href={'https://portal.ixg.aiphone-app.net/account_forget_password.html'}
                  target={'_blank'}
                >
                  Forgot Password?
                </a>
              </div>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </>
  );
};

/** @type {import('@mui/material').SxProps} */
const styles = {
  alert: {
    mb: 2,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px'
  },

  marginTop: {
    mt: 2
  },
  button: {
    backgroundColor: '#d24800',
    color: 'white',
    borderRadius: '5px',
    margin: '5px',
    '&:hover': {
      backgroundColor: '#f85000',
      color: 'white'
    }
  },
  inputField: {
    marginBottom: 1,
    width: '100%',
    padding: 0,
    '& input[type="text" i]': {
      backgroundColor: '#ffffff',
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingRight: '0.75rem',
      paddingLeft: '0.75rem'
    },
    '& input[type="password" i]': {
      backgroundColor: '#ffffff',
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingRight: '0.75rem',
      paddingLeft: '0.75rem'
    },
    '& .MuiInputLabel-root': {
      color: 'grey',
      padding: 0,
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    },
    '&.MuiFormHelperText-root': {
      color: '#d32f2f'
    }
  }
};

export default LoginPanel;
