import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useRef, useState, useEffect } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { PlayCircleFilled, PauseCircle } from '@mui/icons-material';
import { getAllSoundsBySiteId } from 'shared/api/Aws/RemoteManagementApi';

import Skeleton from '@mui/material/Skeleton';
import { filterSoundsByDeviceType } from 'shared/utils/helperFunctions';
export interface IRingtone {
  id: number;
  public_id: string;
  site_public_id: string | null;
  sound_name: string;
  sound_file_name: string;
  s3_location: string;
  last_updated_by: string;
  last_updated_on: string;
  created_by: string;
  created_on: string;
}

interface IRingbackToneProps {
  fieldName: string;
  value: string;
  onChange: (fieldName: string, value: string | number) => void;
  setNeedSave: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'string' | 'number';
  deviceModel: string;
}

const strings = {
  title: 'Ringback Tone',
  description: 'The sound the door station plays when the button is pressed.'
};

const RingbackTone = ({ fieldName, value, onChange, setNeedSave, deviceModel }: IRingbackToneProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [playerCondition, setPlayerCondition] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [ringtoneList, setRingtoneList] = useState<IRingtone[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const site = useSelector((state: RootState) => state.site);
  const users = useSelector((state: RootState) => state.users);

  const getAllSoundsPayload = {
    siteId: site.siteInfo.publicId,
    userId: users.currentUser?.publicId || ''
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (!site.siteInfo.publicId || !users.currentUser?.publicId) return;
      const allSounds = (await getAllSoundsBySiteId(getAllSoundsPayload)) as IRingtone[];
      if (allSounds?.length > 0) {
        const filteredSounds = filterSoundsByDeviceType(allSounds, deviceModel);
        if (filteredSounds) {
          setRingtoneList(filteredSounds);
        }
      }
      const defaultRingtone = allSounds?.find((item) => item.public_id === value);
      setAudioUrl(defaultRingtone?.s3_location || '');
      onChange(fieldName, defaultRingtone?.public_id || '');
      setLoading(false);
    })();
  }, []);

  // update the ringBack tone public id in the form data (string)
  const handleRingbackToneChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    // search the s3 url with the public id
    const selectedRingtone = ringtoneList.find((item) => item.public_id === value);
    setAudioUrl(selectedRingtone?.s3_location || '');
    setNeedSave(true);
    onChange(fieldName, value);
  };

  const handlePlaySound = () => {
    // pause the audio if it is playing
    if (playerCondition) {
      audioRef.current?.pause();
      setPlayerCondition(false);
      return;
    }

    // TODO: if the audio has error, set error message
    if (audioRef.current && audioRef.current.error) return;

    if (audioRef.current) {
      setPlayerCondition(true);
      audioRef.current.play();
    }
  };

  return (
    <Box sx={styles.ringtoneSettingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{strings.title}</Box>
        <Box>{strings.description}</Box>
      </Box>
      <Box sx={styles.ringtoneWrapper}>
        {loading ? (
          <Skeleton animation="wave" variant="rounded" height={60} />
        ) : (
          <FormControl style={styles.formControl}>
            <InputLabel id="ringbackTone">Select ringback tone</InputLabel>
            <Select
              labelId="ringbackToneLabelId"
              id="ringbackToneId"
              value={value}
              label="Select ringback tone"
              onChange={handleRingbackToneChange}
            >
              {ringtoneList.map(({ public_id, sound_name }) => (
                <MenuItem key={public_id} value={public_id}>
                  {sound_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <audio ref={audioRef} src={audioUrl} onEnded={() => setPlayerCondition(false)} />

        {audioUrl.length > 0 && (
          <IconButton onClick={() => handlePlaySound()}>
            {playerCondition ? (
              <PauseCircle color="primary" sx={styles.playIcon} />
            ) : (
              <PlayCircleFilled color="primary" sx={styles.playIcon} />
            )}
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  ringtoneWrapper: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  ringtoneSettingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#e9e9e9',
      color: 'black'
    }
  },
  formControl: {
    width: '410px'
  },
  playIcon: {
    fontSize: '50px'
  },
  controlIcons: {
    fontSize: '30px'
  }
};

export default RingbackTone;
