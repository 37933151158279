import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'store';
import { ErrorType } from 'shared/api/ApiError';
import {
  useAclAuth,
  useFetchACLSites,
  useProcessAclUser,
  useProcessSite,
  useReduxAclUser,
  useReduxSites
} from 'features/SimBilling/Hooks';

export const usePropertyLogic = () => {
  const location = useLocation();
  const siteId = location.pathname.split('/')[3];

  const [userError, setUserError] = useState<boolean>(false);
  const [siteError, setSiteError] = useState<ErrorType | ''>('');

  const { aclToken, aclUserName } = useAclAuth();
  const { user, error } = useReduxAclUser();
  const { fetchSites } = useFetchACLSites();
  const { processUserData } = useProcessAclUser();
  const { resetError, processSiteData } = useProcessSite();
  const { aclSites, errorFetchAclSites, errorProcessAndFetchSitesDetails } = useReduxSites();

  const siteDetails = useSelector((state: RootState) => state.aclSites.sitesDetails[Number(siteId)]);

  useEffect(() => {
    if (aclUserName && aclToken) {
      if (!user) {
        const syncCurrentAclUser = async () => {
          await processUserData(aclUserName, aclToken);
        };
        syncCurrentAclUser();
      }
    } else {
      setUserError(true);
    }
  }, [user, aclUserName, aclToken]);

  useEffect(() => {
    if (error) {
      setUserError(true);
    }
  }, [error]);

  useEffect(() => {
    const fetchData = async () => {
      if (!aclToken) return;

      if (!aclSites) {
        await fetchSites(aclToken);
        return;
      }

      const siteExistsForUser = aclSites.find((site) => site.PropertyID === Number(siteId));
      if (!siteExistsForUser) {
        setSiteError(ErrorType.NOT_FOUND);
        return;
      }

      if (!siteDetails) {
        await processSiteData(aclToken, Number(siteId), siteExistsForUser);
      }
    };

    fetchData();
  }, [aclToken, siteId, aclSites, siteDetails]);

  useEffect(() => {
    if (errorFetchAclSites === undefined || errorFetchAclSites === null) {
      return;
    }
    setSiteError(errorFetchAclSites);
    resetError();
  }, [errorFetchAclSites]);

  useEffect(() => {
    if (errorProcessAndFetchSitesDetails === undefined || errorProcessAndFetchSitesDetails === null) {
      return;
    }
    setSiteError(errorProcessAndFetchSitesDetails);
    resetError();
  }, [errorProcessAndFetchSitesDetails]);

  const subscriptionId = siteDetails ? siteDetails.SubscriptionId : null;

  return {
    user,
    userError,
    siteDetails,
    subscriptionId,
    siteError
  };
};

export default usePropertyLogic;
