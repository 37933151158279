import { getString } from 'shared/utils/LocalizationUtils';

/* Incoming Call tab stub */
export const IncomingCallLabel = () => {
  const incomingCall = getString('Incoming_Call');
  return <span>{incomingCall}</span>;
};

const IncomingCall = () => {
  const incomingCall = getString('Incoming_Call');
  return <span>{incomingCall}</span>;
};

export default IncomingCall;
