import { QuikspecCategory, SystemConstraint } from 'features/QuikSpec/Types/QuikSpecTypes';

/**
 * Two Wire (ChimeCom, ChimeCom 2, LEM) Series QuikSpec Categories and items
 *
 */

// Hidden products are designed not to be displayed.
export const twHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      { deviceName: 'LEM-1' },
      { deviceName: 'LEM-1DL' },
      { deviceName: 'LEM-1DLC' },
      { deviceName: 'LEM-1DLS' },
      { deviceName: 'LE-D' },
      { deviceName: 'LE-DA' },
      { deviceName: 'LS-NVP/C' },
      { deviceName: 'CCS-1A' },
      { deviceName: 'C-ML/A' },
      { deviceName: 'C-123L/A' },
      { deviceName: 'C-123LW' },
      { deviceName: 'SBX-NVP/A' },
      { deviceName: 'DAK-2S' },
      { deviceName: 'RY-AC/A' },
      { deviceName: 'PS-1225UL' },
      { deviceName: 'PT-1210NA' },
      { deviceName: 'SKK-620C' },
      { deviceName: 'DOOR_FLUSH_MOUNT' },
      { deviceName: 'VANDAL' },
      { deviceName: 'NUMBER_OF_MASTERS' },
      { deviceName: 'MASTER_DESK_MOUNT' },
      { deviceName: 'DOOR_RELEASE' },
      { deviceName: 'EXTERNAL_SIGNAL' }
    ]
  }
];

export const twSystemConstraints: SystemConstraint[] = [
  { description: 'Master Stations allowed', max: 2 },
  { description: 'Door Stations allowed', max: 1 }
];
