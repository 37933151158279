import { Card, CardContent, Grid, List, Typography, ListItem } from '@mui/material';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import { useTranslation } from 'react-i18next';

interface IRoleDescriptionsProps {
  roleName: string;
}

const RoleDescriptions = ({ roleName }: IRoleDescriptionsProps) => {
  const { t } = useTranslation(); // Initialize the translation hook

  const description = {
    Admin: {
      name: 'Admin',
      permissions: [
        t('Role_Descriptions.Admin.Permissions.Invite_New_Users'),
        t('Role_Descriptions.Admin.Permissions.Promote_Users'),
        t('Role_Descriptions.Admin.Permissions.Manage_Roles'),
        t('Role_Descriptions.Admin.Permissions.Edit_User_Information'),
        t('Role_Descriptions.Admin.Permissions.Create_Quik_Specs'),
        t('Role_Descriptions.Admin.Permissions.Manage_SIM_Billing'),
        t('Role_Descriptions.Admin.Permissions.Manage_Remote_Sites')
      ]
    },
    Manager: {
      name: 'Manager',
      permissions: [
        t('Role_Descriptions.Manager.Permissions.Invite_Users'),
        t('Role_Descriptions.Manager.Permissions.Manage_Roles'),
        t('Role_Descriptions.Manager.Permissions.Create_Quik_Specs'),
        t('Role_Descriptions.Manager.Permissions.Manage_SIM_Billing_Subscriptions'),
        t('Role_Descriptions.Manager.Permissions.Manage_Remote_Sites')
      ]
    },
    Member: {
      name: 'Member',
      permissions: [
        t('Role_Descriptions.Member.Permissions.Create_Quik_Specs'),
        t('Role_Descriptions.Member.Permissions.Manage_SIM_Billing_Subscriptions'),
        t('Role_Descriptions.Member.Permissions.View_Remote_Sites'),
        t('Role_Descriptions.Member.Permissions.Create_New_Remote_Site'),
        t('Role_Descriptions.Member.Permissions.Edit_Remote_Site_Permission')
      ]
    },
    Associate: {
      name: 'Associate',
      permissions: [
        t('Role_Descriptions.Associate.Permissions.Create_Quik_Specs'),
        t('Role_Descriptions.Associate.Permissions.Edit_Remote_Site_Permission')
      ]
    },
    AiphoneAdmin: {
      name: 'Aiphone Admin',
      permissions: [
        t('Role_Descriptions.Aiphone_Admin.Permissions.Invite_Admins'),
        t('Role_Descriptions.Aiphone_Admin.Permissions.Create_Companies_And_Branches'),
        t('Role_Descriptions.Aiphone_Admin.Permissions.Promote_Users'),
        t('Role_Descriptions.Aiphone_Admin.Permissions.View_And_Edit_SIM_Billing')
      ]
    },
    AiphoneSales: {
      name: 'Aiphone Sales',
      permissions: [
        t('Role_Descriptions.Aiphone_Sales.Permissions.Create_Companies_And_Branches'),
        t('Role_Descriptions.Aiphone_Sales.Permissions.Promote_Users'),
        t('Role_Descriptions.Aiphone_Sales.Permissions.Create_SIM_Billing_And_Remote_Sites')
      ]
    },
    AiphoneTech: {
      name: 'Aiphone Tech',
      permissions: [
        t('Role_Descriptions.Aiphone_Tech.Permissions.Create_Companies_And_Branches'),
        t('Role_Descriptions.Aiphone_Tech.Permissions.Promote_Users'),
        t('Role_Descriptions.Aiphone_Tech.Permissions.View_And_Edit_SIM_Billing')
      ]
    }
  };

  return (
    <Grid item xs={12}>
      {roleName && (
        <Card sx={styles.permissionContainer}>
          <Typography variant="h6" color="initial">
            {t('Role_Descriptions.Permissions_Granted_To', { roleName })}
          </Typography>
          <CardContent>
            <List>
              {description[roleName]?.permissions?.map((desc, index) => (
                <ListItem key={index}>
                  <PersonPinCircleOutlinedIcon />
                  <Typography variant="body2" color="initial" sx={{ paddingX: 2 }}>
                    {desc}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

const styles = {
  permissionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 1,
    paddingX: 1
  }
};

export default RoleDescriptions;
