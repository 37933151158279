import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Slider, Button } from '@mui/material';
import MuiInput from '@mui/material/Input';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTranslation } from 'react-i18next';

type CallTimeoutProps = {
  fieldName: string;
  value: number;
  onChange: (fieldName: string, value: string | number) => void;
  setNeedSave: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'string' | 'number';
};
const MIN_SEC = 10;
const MAX_SEC = 600;
const INFINITE_VALUE = -1;
const DEFAULT_VALUE = 60;

const CallTimeout = ({ fieldName, value, onChange, setNeedSave, type }: CallTimeoutProps) => {
  const { t } = useTranslation();
  const timeoutDialog = t('Timeout_Dialog');
  const timeoutSeconds = t('Timeout_Seconds');
  const title = t('Call_Timeout_Title');
  const description = t('Call_Timeout_Description');
  const [isInfinite, setIsInfinite] = useState(value === INFINITE_VALUE);
  useEffect(() => {
    setIsInfinite(value === INFINITE_VALUE);
  }, [value]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNeedSave(true);
    onChange(fieldName, type === 'number' ? Number(value) : value);
  };
  const handleBlur = () => {
    let finalValue = Number(value);

    // enforce the range between MIN_SEC and MAX_SEC
    if (finalValue < MIN_SEC) {
      finalValue = MIN_SEC;
    } else if (finalValue > MAX_SEC) {
      finalValue = MAX_SEC;
    }

    setNeedSave(true);
    onChange(fieldName, finalValue);
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setNeedSave(true);
    if (typeof newValue === 'number') {
      onChange(fieldName, newValue);
    }
  };
  const handleInfiniteClick = () => {
    setIsInfinite(!isInfinite);
    setNeedSave(true);
    onChange(fieldName, !isInfinite ? INFINITE_VALUE : DEFAULT_VALUE); // Set to 0 or reset to default value
  };

  return (
    <Box sx={styles.callTimeoutSettingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{title}</Box>
        <Box>{description}</Box>
      </Box>
      <Box sx={styles.callTimeoutWrapper}>
        <Grid>
          <Typography id="input-slider" gutterBottom>
            {timeoutDialog} ({timeoutSeconds})
          </Typography>
        </Grid>
        <Grid alignItems="center" sx={styles.sliderWrapper}>
          <Box>
            <AccessTimeIcon />
          </Box>
          <Box sx={{ width: '60%' }}>
            <Slider
              value={isInfinite ? INFINITE_VALUE : value}
              onChange={handleSliderChange}
              aria-labelledby="callTimeout-slider"
              min={MIN_SEC}
              max={MAX_SEC}
              sx={{ paddingX: '10px' }}
              disabled={isInfinite}
            />
          </Box>
          <Box sx={{ paddingLeft: '10px', width: '15%' }}>
            <MuiInput
              value={isInfinite ? INFINITE_VALUE : value}
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              type={type}
              inputProps={{
                step: 1,
                min: MIN_SEC,
                max: MAX_SEC,
                'aria-labelledby': 'callTimeout-slider'
              }}
              disabled={isInfinite}
            />
          </Box>
        </Grid>
        <Button
          variant={isInfinite ? 'contained' : 'outlined'}
          onClick={handleInfiniteClick}
          sx={{ marginTop: '10px' }}
        >
          {isInfinite ? t('Unset_Infinite') : t('Set_Infinite')}
        </Button>
      </Box>
    </Box>
  );
};
const styles = {
  descriptionWrapper: {
    width: '70%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  callTimeoutWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%'
  },
  sliderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  callTimeoutSettingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#e9e9e9', // Background color on hover
      color: 'black' // Text color on hover
    }
  }
};
export default CallTimeout;
