import { Alert, AlertTitle, Box, Button, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import * as Yup from 'yup';
import { AccessoryItem, FeaturedSystems, Item } from 'features/QuikSpec/Types/QuikSpecTypes';
import { updateFormValuesGT } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesGT';
import {
  gtEntranceAndGuard,
  gtOptionalAccessories,
  gtSystemConstraints,
  gtTenantStations,
  gtHidden
} from '../../../Types/GTTypes';
import { acControlKits, acLicenses, acReaderKits, acHidden, acSystemConstraints } from '../../../Types/ACTypes';
import { useState } from 'react';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import QuikspecCategoryGtEntranceGuard from '../../UiParts/QuikspecCategoryGtEntranceGuard';
import QuikspecCategoryAcControllers from '../../UiParts/QuikspecCategoryAcControllers';
import QuikspecCategoryAcLicenses from '../../UiParts/QuikspecCategoryAcLicenses';
import { Form, Formik } from 'formik';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import QuikspecSystemConstraints from '../../UiParts/QuikspecSystemConstraints';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';
import { getString } from 'shared/utils/LocalizationUtils';
import StringUtils from 'shared/utils/StringUtils';

// Function to check if at least one quantity is greater than 0
const atLeastOneQuantityGreaterThanZero = (values: any) => {
  const items = Object.values(values);
  return items.some((item: any) => item.quantity > 0) || atLeastOneAccessoryQuantityGreaterThanZero(values);
};

const atLeastOneAccessoryQuantityGreaterThanZero = (values: any) => {
  const items = Object.values(values);
  const accessories = items.reduce((accumulator: AccessoryItem[], item: any) => {
    const itemAccessories = item.accessories || [];
    return [...accumulator, ...itemAccessories];
  }, []);
  return accessories.some((accessory: AccessoryItem) => accessory.quantity ?? 0 > 0);
};

//Function to check if there are no more than 4 guard stations for a GT system
const atMostFourGuardStations = (values: any) => {
  const items = Object.values(values);
  const guardStationNames = gtEntranceAndGuard[3].items.map((item: Item) => item.deviceName);
  const relevantItems = items.filter((item) => guardStationNames.includes(item.itemName));
  const totalQuantity = relevantItems.reduce((total, item) => total + item.quantity, 0);
  return totalQuantity <= gtEntranceAndGuard[3]?.maxQuantityAllowedPerCategory;
};

const atMostSixteenEntrancePanels = (values: any) => {
  const items = Object.values(values);
  const entrancePanelNames = gtEntranceAndGuard[0].items.map((item: Item) => item.deviceName);
  const relevantItems = items.filter((item: any) => entrancePanelNames.includes(item.itemName));
  const relevantAccessories = relevantItems.reduce((accumulator, item: any) => {
    const accessories = item.accessories || [];
    return [...accumulator, ...accessories];
  }, []);
  const totalQuantity = relevantAccessories.reduce((total: number, accessory) => total + accessory.quantity, 0);
  return totalQuantity <= (gtEntranceAndGuard[0]?.maxQuantityAllowedPerCategory ?? 0);
};

const atLeastOneTenantButton = (values: any) => {
  let result = true;
  const items = Object.values(values);
  const tenantIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_TENANT');
  const videoModuleIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_MODULE_VIDEO_ENTRANCE');
  const videoModuleFlushIndex = items[videoModuleIndex].accessories.findIndex(
    (item) => item.accessoryName === 'Flush Mount'
  );
  const videoModuleSurfaceIndex = items[videoModuleIndex].accessories.findIndex(
    (item) => item.accessoryName === 'Surface Mount'
  );
  const audioModuleIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_MODULE_AUDIO_ENTRANCE');
  const audioModuleFlushIndex = items[audioModuleIndex].accessories.findIndex(
    (item) => item.accessoryName === 'Flush Mount'
  );
  const audioModuleSurfaceIndex = items[audioModuleIndex].accessories.findIndex(
    (item) => item.accessoryName === 'Surface Mount'
  );
  const videoButtonIndex = items.findIndex((item) => item.itemName === 'MODULE_VIDEO_ENTRANCE_KEYPAD');
  const audioButtonIndex = items.findIndex((item) => item.itemName === 'MODULE_AUDIO_ENTRANCE_KEYPAD');
  const numberOfEntrance =
    items[videoModuleIndex].accessories[videoModuleFlushIndex].quantity +
    items[videoModuleIndex].accessories[videoModuleSurfaceIndex].quantity +
    items[audioModuleIndex].accessories[audioModuleFlushIndex].quantity +
    items[audioModuleIndex].accessories[audioModuleSurfaceIndex].quantity;
  if (numberOfEntrance > 0) {
    // When button, quantity is 0
    if (items[videoButtonIndex].quantity + items[audioButtonIndex].quantity <= 0) {
      if (items[tenantIndex].quantity <= 0) {
        result = false;
      }
    }
  }
  return result;
};

const atMostFiveHundredTenantStations = (values: any) => {
  const items = Object.values(values);
  const tenantStations = [gtTenantStations[0], gtTenantStations[1]].flatMap((elt) => elt.items);
  const tenantStationNames = tenantStations.map((item: Item) => item.deviceName);
  const relevantItems = items.filter((item: any) => tenantStationNames.includes(item.itemName));
  const totalQuantity = relevantItems.reduce((total: number, item) => total + item.quantity, 0);
  return totalQuantity <= 500;
};

const validationSchema = Yup.object().shape({
  quikspecName: Yup.string().required('Quikspec Name is required'),
  marketType: Yup.string().required('Market Type is required'),
  items: Yup.array()
    .of(
      Yup.object().shape({
        itemName: Yup.string().required('Item Name is required'),
        quantity: Yup.number().min(0, 'Quantity must be greater than or equal to 0').required('Quantity is required')
      })
    )
    .test(
      'atLeastOneQuantity',
      'At least one item with or without accessories must have quantity greater than 0',
      atLeastOneQuantityGreaterThanZero
    )
    .test('atMostFourGuardStations', 'There can only be at most 4 Guard stations', atMostFourGuardStations)
    .test('atMostSixteenEntrancePanels', 'There can only be at most 16 Entrance Panels', atMostSixteenEntrancePanels)
    .test('atLeastOneTenantButton', 'There is no tenant called from Module Entrance Panels', atLeastOneTenantButton)
    .test(
      'atMostFiveHundredTenantStations',
      'There can only be at most 500 Tenant Stations',
      atMostFiveHundredTenantStations
    )
});

const QuikspecGT = () => {
  const navigate = useNavigate();
  const [acOpen, setACOpen] = useState(false);
  const location = useLocation();
  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = FeaturedSystems.findIndex((system) => system.siteName === siteName);
  const systemName = FeaturedSystems[siteIndex].seriesName;
  const systemforC2 = FeaturedSystems[siteIndex].systemforC2;
  const gtAlertError = getString('Error');
  const additionalAccessControlOptions = getString('Additional_AccessControl_Options_Dialog');
  const addAcSeries = getString('Button_Add_Ac_Series_To_Gt_Spec');
  const goBack = getString('Need_To_Go_Back_Dialog');
  const returnToGt = StringUtils.format(getString('Button_Return_To_System_Page'), 'GT');

  const categories = [
    ...gtEntranceAndGuard,
    ...gtTenantStations,
    ...gtOptionalAccessories,
    ...gtHidden,
    ...acControlKits,
    ...acLicenses,
    ...acReaderKits,
    ...acHidden
  ];
  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      //filter out items with quantity set to zero in order to submit quikspec
      values = updateFormValuesGT(values);

      //determine if form is in edit mode
      const editing = stateFormValues != undefined;
      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors({ submit: 'Something went wrong. Please try again or contact customer support.' });
    }
  };

  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              {/* Render QuikspecCategoryTabs for each category */}
              {!acOpen && (
                <Box>
                  <QuikspecSystemConstraints systemConstraints={gtSystemConstraints} />
                  <QuikspecCategoryGtEntranceGuard categories={gtEntranceAndGuard} />
                  <QuikspecCategoryTabs categories={gtTenantStations} />
                  <QuikspecCategoryTabs categories={gtOptionalAccessories} />
                  <Box align="center">
                    <Typography variant="h4" align="center">
                      {additionalAccessControlOptions}
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={() => setACOpen(true)}>
                      {addAcSeries}
                    </Button>
                  </Box>
                </Box>
              )}
              {acOpen && (
                <Box>
                  <QuikspecSystemConstraints systemConstraints={acSystemConstraints} />
                  <QuikspecCategoryAcControllers categories={acControlKits} />
                  <QuikspecCategoryAcLicenses categories={acLicenses} />
                  <QuikspecCategoryTabs categories={acReaderKits} />
                  <Box align="center">
                    <Typography variant="h4" align="center">
                      {goBack}
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={() => setACOpen(false)}>
                      {returnToGt}
                    </Button>
                  </Box>
                </Box>
              )}
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>{gtAlertError}</AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton editing={state.formValues != undefined} errors={errors} submitting={isSubmitting} />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecGT;
