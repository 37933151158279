import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';

export const updateFormValuesKB = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesKB(values);
  updateWiresKB(values);
  updateNotesKB(values);
  return values;
};

const updateItemQuantityWithAccessoriesKB = (values: FormValues) => {
  const items = Object.values(values.items);

  // Every two master stations needs a power supply
  const PS2420ULIndex = items.findIndex((item) => item.itemName === 'PS-2420UL');
  const totalMasterStations = items
    .filter((item) => item.category === 'Master Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  items[PS2420ULIndex].quantity = Math.ceil(totalMasterStations / 2);
};

const updateWiresKB = (values: FormValues) => {
  values.wires.push(
    {
      system: 'Door to Master Station',
      wire: '871802',
      description: "2 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: "650'",
      jacketNomOD: 'PVC .190"',
      capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
    },
    {
      system: 'Power to Master Station',
      wire: '871802',
      description: "2 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: 'N/A',
      jacketNomOD: 'PVC .190"',
      capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
    }
  );

  if (
    values.items
      .filter((item) => item.itemName === 'KB-3HRD')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'KB-3SD')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'Between Master Stations',
      wire: '871802',
      description: "2 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: "330'",
      jacketNomOD: 'PVC .190"',
      capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
    });
  }
};

const updateNotesKB = (values: FormValues) => {
  if (
    values.items
      .filter((item) => item.itemName === 'IER-2')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push('Chime from the door is heard through the IER-2. No communication is available with IER-2.');
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-ES')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The RY-ES provides a momentary contact when the door calls in. Any external device with its own power supply may be connected to this contact. (Not supplied by Aiphone.)'
    );
  }
};
