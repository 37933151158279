/*
 * Check if user exist in C2, if not create a new user.
 * Set the C2ContactId and C2CompanyId in local storage.
 * */
import { createContact, findContact } from 'shared/api/Aws/C2Api';
import { IC2User } from '../components/Registration';

export const checkAndSetupC2Contact = async (payload: IC2User) => {
  let contactFromC2 = await findContact({ email: payload.email });
  if (!contactFromC2?.data?.contactId) {
    const createContactInC2Payload = {
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      personPhone: payload.phoneNumber,
      address: payload.address,
      address2: payload.address2,
      city: payload.city,
      state: payload.state,
      zip: payload.zipCode,
      country: payload.country,
      companyName: payload.companyName
    };
    contactFromC2 = await createContact(createContactInC2Payload);
  }

  if (!contactFromC2) {
    throw new Error('Error while creating contact in C2');
  }

  const { companyCountry, contactId, companyId } = contactFromC2.data;

  saveC2DataToStorage(contactId, companyId, companyCountry);
};

export const getAndSetC2Contact = async (email: string) => {
  const contactFromC2 = await findContact({ email });

  if (!contactFromC2) {
    throw new Error('User not found in C2');
  }

  const { companyCountry, contactId, companyId } = contactFromC2.data;

  saveC2DataToStorage(contactId, companyId, companyCountry);
};

const saveC2DataToStorage = (c2ContactId: string, c2CompanyId: string, c2CompanyCountry: number) => {
  localStorage.setItem('C2ContactId', c2ContactId);
  localStorage.setItem('C2CompanyId', c2CompanyId);
  localStorage.setItem('C2CompanyCountry', `${c2CompanyCountry}`);
};
