import { Card } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridFilterItem, GridRowId, GridRowParams } from '@mui/x-data-grid';
import { usePermission } from 'context/PermissionContext';
import { UUID } from 'crypto';
import { PermissionsContextType } from 'permissions/utils';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteTenantMutation, useLazyGetTenantListWithSiteIdQuery } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import SettingsIcon from '@mui/icons-material/Settings';
import { RootState } from 'store';

interface ITenantsDataGridProps {
  searchParams: URLSearchParams;
  filterModel: {
    items: GridFilterItem[];
  };
  setFilterModel: (model: { items: GridFilterItem[] }) => void;
  setIsEditTenantDialogOpen: (value: boolean) => void;
  setSelectedTenantId: (value: string) => void;
}

const TenantsDataGrid = ({
  searchParams,
  filterModel,
  setFilterModel,
  setIsEditTenantDialogOpen,
  setSelectedTenantId
}: ITenantsDataGridProps) => {
  const [rows, setRows] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const tenantsList = useSelector((state: RootState) => state.tenants.TenantList);
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const navigate = useNavigate();
  const siteId = useParams().id;
  const [deleteTenant] = useDeleteTenantMutation();
  const [fetchTenants] = useLazyGetTenantListWithSiteIdQuery();
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);

  const { isAllowedTo } = usePermission();
  const canUnitView = isAllowedTo('unit:view', sitePublicId, PermissionsContextType.SITE);
  const canTenantEdit = isAllowedTo('tenant:edit', sitePublicId, PermissionsContextType.SITE);
  const canTenantDelete = isAllowedTo('tenant:delete', sitePublicId, PermissionsContextType.SITE);

  useEffect(() => {
    if (searchParams.get('unitNumber')) {
      const unitNumber = searchParams.get('unitNumber');
      setFilterModel({
        items: [
          {
            field: 'unitNumber',
            operator: 'equals',
            value: unitNumber
          }
        ]
      });
    }
  }, [searchParams]);

  const handleViewUnit = useCallback(
    (unitPublicId: UUID) => () => {
      navigate(`../units/${unitPublicId}/unitinformation`, { relative: 'path' });
    },
    []
  );

  useEffect(() => {
    setLoading(true);
    generateRows();
  }, [tenantsList, unitList, buildingList]);

  const handleEditTenant = useCallback(
    (id: GridRowId) => async () => {
      await setSelectedTenantId(id as string);
      setIsEditTenantDialogOpen(true);
    },
    []
  );

  const handleDeleteTenant = useCallback(
    (id: GridRowId) => async () => {
      setLoading(true);
      try {
        await deleteTenant(id as string);
        setSuccess('Tenant deleted successfully');
        fetchTenants({ sitePublicId: siteId, qty: 500, page: 0 });
      } catch (err) {
        setError('Failed to delete tenant');
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const generateRows = () => {
    setRows(
      Object.entries(tenantsList)
        .map(([key, tenant]) => {
          const unit = unitList[tenant.unitPublicId];
          if (!unit) {
            return null; // or handle this case as appropriate
          }
          const building = buildingList[unit.buildingPublicId];
          if (!building) {
            return null; // or handle this case as appropriate
          }

          return {
            id: key,
            buildingNumber: building.buildingNumber,
            unitPublicId: unit.publicId,
            unitNumber: unit.unitNumber,
            firstName: tenant.firstName,
            lastName: tenant.lastName,
            email: tenant.email,
            phoneNumber: tenant.phoneNumber,
            isPrimaryTenant: tenant.isPrimaryTenant ? 'Yes' : 'No'
          };
        })
        .filter((row) => row !== null)
    );
    setLoading(false);
  };

  const columns = [
    { field: 'buildingNumber', headerName: 'Building Number', width: 150 },
    { field: 'unitNumber', headerName: 'Unit Number', width: 150 },
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'isPrimaryTenant', headerName: 'Primary Tenant', width: 150 },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) =>
        [
          canUnitView && (
            <GridActionsCellItem
              label="View Unit"
              onClick={handleViewUnit(params.row.unitPublicId)}
              showInMenu={true}
            />
          ),
          canTenantEdit && (
            <GridActionsCellItem label="Edit Tenant" onClick={handleEditTenant(params.id)} showInMenu={true} />
          ),
          canTenantDelete && (
            <GridActionsCellItem label="Delete Tenant" onClick={handleDeleteTenant(params.id)} showInMenu={true} />
          )
        ].filter(Boolean)
    }
  ];

  return (
    <>
      <Card sx={styles.tenantsDatagridWrapper}>
        <DataGrid
          rows={rows}
          columns={columns}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => setFilterModel(newModel)}
          loading={loading}
          slots={{
            moreActionsIcon: SettingsIcon
          }}
        />
      </Card>
      <SnackbarAlert
        type={error ? 'error' : 'success'}
        time={5000}
        text={error || success || ''}
        isOpen={!!error || !!success}
        onClose={() => {
          setError(null);
          setSuccess(null);
        }}
      />
    </>
  );
};

const styles = {
  tenantsDatagridWrapper: {
    display: 'flex',
    height: '90%',
    width: '100%'
  },
  deviceIcon: {
    display: 'inline-block',
    marginRight: '5px'
  }
};

export default TenantsDataGrid;
