import { useState } from 'react';
import { Box, Tabs, Tab, Container } from '@mui/material';
import SiteUsersTab from './Tabs/SiteUsersTab';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useTranslation } from 'react-i18next';

const SiteUsers = () => {
  const { t } = useTranslation();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e: React.SyntheticEvent, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const { isAllowedTo } = usePermission();
  const canViewUsers =
    isAllowedTo('user:view', sitePublicId, PermissionsContextType.SITE) ||
    isAllowedTo('user:view', null, PermissionsContextType.GLOBAL);

  return (
    <Container maxWidth="lg">
      <Box>
        <Tabs value={currentTabIndex} onChange={handleTabChange} aria-label="">
          {canViewUsers && <Tab label={t('Site_Administrator')} />}
        </Tabs>
      </Box>
      <Box>{currentTabIndex === 0 && canViewUsers && <SiteUsersTab />}</Box>
    </Container>
  );
};

export default SiteUsers;
