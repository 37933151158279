import { getString } from 'shared/utils/LocalizationUtils';

/* Syslog Settings tab stub */
export const SyslogSettingsLabel = () => {
  const syslogSettingsLabel = getString('Syslog_Settings');
  return <span>{syslogSettingsLabel}</span>;
};

const SyslogSettings = () => {
  const syslogSettingsLabel = getString('Syslog_Settings');
  return <span>{syslogSettingsLabel}</span>;
};

export default SyslogSettings;
