import ReactGA from 'react-ga4';

const sendEvent = (category: string, action: string, label = '', value?: number) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

const trackButtonClick = (category: string, label: string) => {
  ReactGA.event({
    category: category,
    action: 'Click',
    label: label
  });
};

const trackPaginationClick = (category: string, action: string, label: string) => {
  ReactGA.event({
    category,
    action,
    label
  });
};

const trackTabClick = (category: string, tabLabel: string) => {
  sendEvent(category, 'Tab Click', tabLabel);
};

export const analyticsService = {
  sendEvent,
  trackPaginationClick,
  trackButtonClick,
  trackTabClick
};
