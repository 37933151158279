import { Box, Grid } from '@mui/material';
import DeviceCard from './DeviceCard';
interface ISelectedDeviceSettingsProps {
  devices: string[];
  handleRemoveCallDestinationDevice: (devicePublicId: string) => void;
}

const SelectedDevice = ({ devices, handleRemoveCallDestinationDevice }: ISelectedDeviceSettingsProps) => {
  // Map through the devices and display the device card
  const displayDeviceCard = () => {
    return devices.map((device, index) => {
      if (!device) return null;
      return (
        <Grid item key={index}>
          <DeviceCard
            key={index}
            devicePublicId={device}
            handleRemoveCallDestinationDevice={handleRemoveCallDestinationDevice}
          />
        </Grid>
      );
    });
  };

  return (
    <>
      <Box sx={styles.selectedDeviceWrapper}>
        <Grid container spacing={2} justifyContent="center">
          {displayDeviceCard()}
        </Grid>
      </Box>
    </>
  );
};

const styles = {
  selectedDeviceWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default SelectedDevice;
