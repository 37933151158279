import { useEffect, useState } from 'react';
import { Button, Box, Grid, Typography } from '@mui/material';
import { SubscriptionPlan } from 'features/SimBilling/Types/SimBillingTypes';
import SubscriptionDetailsInfo from './SubscriptionDetailsInfo';

interface SubscriptionPlansProps {
  handleSubscribe: (sub?: SubscriptionPlan) => void;
  currentPriceId: string | null;
  subscriptionPlans: SubscriptionPlan[] | undefined;
  isPastDue: boolean | null;
  subscriptionInactive: boolean;
  isSubscriptionCreatedByUser?: boolean | undefined;
}

const SUBSCRIPTION_END_DATE = 18;

const isFocusedPlan = (amount: number, price: string, current: string | null) => {
  if (price === current) {
    return true;
  }
  if (amount === 2 && current === '') {
    return true;
  }
  return false;
};

const SubscriptionPlans = ({
  handleSubscribe,
  currentPriceId,
  subscriptionPlans,
  isPastDue,
  subscriptionInactive,
  isSubscriptionCreatedByUser
}: SubscriptionPlansProps) => {
  const [isSubscriptionDisabled, setIsSubscriptionDisabled] = useState<boolean>(false);

  useEffect(() => {
    const checkSubscriptionAvailability = () => {
      const currentDate = new Date();
      const currentDay = currentDate.getUTCDate();
      if (currentDay === SUBSCRIPTION_END_DATE) {
        setIsSubscriptionDisabled(true);
      } else {
        setIsSubscriptionDisabled(false);
      }
    };

    const interval = setInterval(checkSubscriptionAvailability, 60000);
    checkSubscriptionAvailability();

    return () => clearInterval(interval);
  }, []);

  if (subscriptionPlans === undefined) {
    return null;
  }

  const overlayStyle = {
    position: 'absolute',
    top: 30,
    left: 0,
    right: 0,
    bottom: 0,
    height: '260px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textShadow: '0px 2px 4px rgba(0,0,0,0.5)',
    padding: '20px',
    boxSizing: 'border-box',
    zIndex: 2
  };

  const sortedSubscription = [...subscriptionPlans].sort((a, b) => a.price - b.price);

  return (
    <Box position="relative" sx={{ width: '100%', height: '100%' }}>
      {isPastDue && <Box sx={overlayStyle}></Box>}
      <Box sx={styles.componentContainer}>
        <Grid container spacing={2} sx={styles.tableContainer}>
          {sortedSubscription.map((sub) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={sub.priceID} sx={{ display: 'flex' }}>
                <Box
                  key={sub.priceID}
                  sx={
                    isFocusedPlan(sub.dataAmount, sub.priceID, currentPriceId)
                      ? styles.featuredPlanCard
                      : styles.planCard
                  }
                >
                  <Box
                    sx={
                      isFocusedPlan(sub.dataAmount, sub.priceID, currentPriceId) ? styles.featuredTitle : styles.title
                    }
                  >
                    {isFocusedPlan(sub.dataAmount, sub.priceID, currentPriceId) ? (
                      <Typography variant="h4" color="#FFFFFF">
                        {sub.productName}
                      </Typography>
                    ) : (
                      <Typography variant="h5" color="#FFFFFF">
                        {sub.productName}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={styles.planContent}>
                    <Typography variant="h5" color="initial" display="inline">
                      ${sub.price} {sub.currency.toUpperCase()}
                    </Typography>
                    {sub.price !== 0 ? (
                      <Typography variant="caption" color="GrayText">
                        / month
                      </Typography>
                    ) : null}
                    <Box sx={styles.itemDescription}>{sub.description}</Box>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubscribe(sub)}
                        disabled={
                          currentPriceId === sub.priceID ||
                          subscriptionInactive ||
                          isSubscriptionDisabled ||
                          isSubscriptionCreatedByUser === false
                        }
                        sx={styles.button}
                      >
                        {currentPriceId === sub.priceID ? 'Current Plan' : 'Subscribe'}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <SubscriptionDetailsInfo />
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  button: {
    width: '150px'
  },
  componentContainer: {
    margin: '0 auto',
    maxWidth: '1100px'
  },
  tableContainer: {
    justifyContent: 'center',
    alignItems: 'stretch',
    textAlign: 'center',
    padding: '2rem',
    display: 'flex',
    flexWrap: 'wrap'
  },
  planCard: {
    borderRadius: '2%',
    border: '2px solid #003366',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1
  },
  featuredPlanCard: {
    borderRadius: '2%',
    border: '2px solid #0071CE',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1
  },
  planContent: {
    padding: '20px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  featuredTitle: {
    border: '3px solid #0071CE',
    borderRadius: '2% 2% 0 0',
    backgroundColor: '#0071CE',
    padding: '1rem',
    textTransform: 'uppercase'
  },
  title: {
    padding: '1rem',
    border: '3px solid #003366',
    borderRadius: '2% 2% 0 0',
    backgroundColor: '#003366',
    textTransform: 'uppercase',
    color: 'white'
  },
  itemDescription: {
    marginBottom: 1,
    minHeight: '50px'
  }
};

export default SubscriptionPlans;
