import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  useGetAssignableRolesQuery,
  useGetBranchesWithCompanyPublicIdQuery,
  useInviteBranchUserMutation
} from '../../../services/aiphoneCloud';
import RoleDescriptions from '../RoleDescriptions';
import CompanySelect from '../shared/components/CompanySelect';
import { ICompanyList } from './EditRegisteredUserDialog';
import BranchSelect from '../shared/components/BranchSelect';
import { RootState } from 'store';
import { CloudRole, UserInfo } from 'shared/utils/UserUtils';
import { ContextType } from 'store/slices/usersSlice';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';

interface IInviteUserDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setSuccessMessage: (successMessage: string) => void;
  setIsError: (isError: boolean) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  companies: ICompanyList;
  adminBranchName: string;
  adminBranchId: string | undefined;
  adminCompanyName: string;
}

interface FormValues {
  email: string;
  company: {
    publicId: string;
    name: string;
    state: string;
    postalCode: string;
  };
  branch: string;
  role: string;
}

const InviteUserDialog = ({
  isOpen,
  setIsOpen,
  setErrorMessage,
  setSuccessMessage,
  setIsError,
  setIsSuccess,
  companies,
  adminBranchName,
  adminBranchId,
  adminCompanyName
}: IInviteUserDialogProps) => {
  const { t } = useTranslation();

  const [company, setCompany] = React.useState<string>('');
  const [branch, setBranch] = React.useState<string>('');
  const [role, setRole] = React.useState<string>('');
  const [selectedBranchPublicId, setSelectedBranchPublicId] = React.useState<string>('');
  const [inviteUser, { isLoading: isInviteLoading }] = useInviteBranchUserMutation();
  const usersCurrentBranch = useSelector((state: RootState) => state.branches.currentBranch);

  const { isAllowedTo } = usePermission();
  const user = useSelector((state: RootState) => state.users.currentUser);

  const canEditBranchGlobally = isAllowedTo('branch:edit', null, PermissionsContextType.GLOBAL);
  const { data: branches, isFetching: isFetchingBranches } = useGetBranchesWithCompanyPublicIdQuery(company, {
    skip: !company || !canEditBranchGlobally
  });

  const contextPublicId = selectedBranchPublicId || branch;

  const { data: roles = [], isFetching: isFetchingRoles } = useGetAssignableRolesQuery(
    {
      contextPublicId: contextPublicId,
      contextType: ContextType.BRANCH
    },
    { skip: !contextPublicId }
  );

  const hasManagerRole = new UserInfo().isBranchManager();

  const buildInitialFormValues = (): FormValues => {
    return {
      company: {
        publicId: canEditBranchGlobally ? adminBranchId || '' : usersCurrentBranch?.company.publicId || '',
        name: canEditBranchGlobally ? adminCompanyName || '' : usersCurrentBranch?.company?.name || '',
        state: '',
        postalCode: ''
      },
      branch: adminBranchId || '',
      role: '',
      email: ''
    };
  };

  const resetStateValues = (): void => {
    setCompany('');
    setBranch('');
    setRole('');
  };

  const handleCloseClick = (): void => {
    setIsOpen(false);
    resetStateValues();
  };

  useEffect(() => {
    if (!canEditBranchGlobally && usersCurrentBranch) {
      setCompany(usersCurrentBranch.company.publicId);
      setSelectedBranchPublicId(adminBranchId);
      setBranch(adminBranchId);
    } else if (canEditBranchGlobally) {
      setCompany('');
      setSelectedBranchPublicId('');
      setBranch('');
    }
  }, [adminBranchId, canEditBranchGlobally, usersCurrentBranch]);

  const handleBranchChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    formikProps: FormikProps<FormValues>
  ): void => {
    const selectedBranchPublicId = event.target.value as string;

    formikProps.setFieldValue('branch', selectedBranchPublicId);
    setBranch(selectedBranchPublicId);
    setSelectedBranchPublicId(selectedBranchPublicId);
    setRole('');
  };

  const handleCompanyChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    value: { publicId: string } | null,
    formikProps: FormikProps<FormValues>
  ): Promise<void> => {
    if (!value) {
      await formikProps.setFieldValue('company', { publicId: '', name: '', state: '', postalCode: '' });
      await formikProps.setFieldValue('branch', '');
      await formikProps.setFieldValue('role', '');
      setCompany('');
      setBranch('');
      setRole('');
    } else {
      await formikProps.setFieldValue('company', value);
      await formikProps.setFieldValue('branch', '');
      await formikProps.setFieldValue('role', '');
      setCompany(value.publicId);
      setBranch('');
      setRole('');
    }
  };

  const handleInviteUser = (values: FormValues): void => {
    const invitedBranch =
      adminBranchName || (branches && branches[values.branch] ? branches[values.branch].branchName : 'Unknown Branch');

    const formattedEmail = values.email.toLowerCase();
    const invitedTo = `${values.company.name} - ${invitedBranch}`;

    const inviteUserPayload = {
      companyPublicId: values.company.publicId,
      branchPublicId: values.branch,
      rolePublicId: role,
      email: formattedEmail,
      invitedBy: user?.firstName,
      invitedTo: invitedTo,
      languageId: 2,
      countryId: 2
    };

    inviteUser(inviteUserPayload)
      .unwrap()
      .then((response) => {
        if (response.error) {
          setIsError(true);
          setErrorMessage(response.error.message);
          return;
        }
        setIsSuccess(true);
        setSuccessMessage(t('User_Invited_Successfully'));
        setIsOpen(false);
        setRole('');
      })
      .catch((error) => {
        const errorData = JSON.parse(error.data || '{}');
        const errorCode = errorData?.errorCode;
        if (errorCode === '23505') {
          setErrorMessage(t('User_Already_Has_Role'));
          setIsError(true);
          setIsOpen(false);
          setRole('');
        } else {
          setErrorMessage(t('Error_Inviting_User'));
          setIsError(true);
        }
      });
  };

  const validateSchema = yup.object().shape({
    email: yup.string().email(t('Email_Invalid')).required(t('Email_Required')),
    company: yup.object().required(t('Company_Required')),
    branch: yup.string().required(t('Branch_Required')),
    role: yup.string().required(t('Role_Required'))
  });

  return (
    <Dialog open={isOpen} onClose={handleCloseClick} aria-labelledby="" maxWidth="sm" fullWidth>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{t('Invite_User')}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={handleCloseClick}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik
        initialValues={buildInitialFormValues()}
        validationSchema={validateSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleInviteUser(values);
          setSubmitting(false);
        }}
      >
        {(props) => {
          const { errors, touched } = props;
          return (
            <Form>
              <DialogContent>
                <Box>
                  <Typography variant="body1" color="initial">
                    {t('Invite_User_Form_Info')}
                  </Typography>
                </Box>
                <Box>
                  <Box sx={styles.my2}>
                    <Typography variant="h6" color="initial">
                      {t('User_Information')}
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="email-label" shrink={!!props.values.email}>
                          {t('Email')}
                        </InputLabel>
                        <Field
                          name="email"
                          id="email"
                          as={TextField}
                          variant="outlined"
                          size="small"
                          value={props.values.email}
                          onChange={props.handleChange}
                          error={errors.email && touched.email}
                          helperText={errors.email && touched.email ? errors.email : ''}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Box sx={styles.my2}>
                    <Typography variant="h6" color="initial">
                      {t('Branch_Information')}
                    </Typography>
                  </Box>

                  <Grid container spacing={2}>
                    {canEditBranchGlobally && (
                      <CompanySelect
                        companies={companies}
                        handleChange={async (event, value) => handleCompanyChange(event, value, props)}
                        value={props.values.company?.publicId || ''}
                        label={t('Select_Company')}
                      />
                    )}
                    {!canEditBranchGlobally && (
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          sx={styles.inputField}
                          label={t('Company_Name')}
                          size="small"
                          name="company"
                          value={props.values.company.name}
                          disabled
                        />
                      </Grid>
                    )}
                    {canEditBranchGlobally && (
                      <BranchSelect
                        isBranchesFetching={isFetchingBranches}
                        isCompanySelected={!!company}
                        branches={branches}
                        handleChange={(event) => handleBranchChange(event, props)}
                        label={t('Select_Branch')}
                      />
                    )}
                    {adminCompanyName.length !== 0 && !canEditBranchGlobally && (
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          sx={styles.inputField}
                          label={t('Branch')}
                          size="small"
                          name="branch"
                          value={adminBranchId}
                          inputProps={{ value: adminBranchName }}
                          disabled
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <FormControl
                        sx={styles.inputField}
                        size="small"
                        fullWidth
                        helperText={touched.role && errors.role ? errors.role : ''}
                        error={touched.role && !!errors.role}
                        disabled={!branch && !selectedBranchPublicId}
                      >
                        <InputLabel id="role-label">{'Select a role'}</InputLabel>
                        <Field
                          name="role"
                          id="role"
                          as={Select}
                          labelId="role-label"
                          label={t('Select_Role')}
                          onChange={(event) => {
                            props.handleChange(event);
                            setRole(event.target.value as string);
                          }}
                        >
                          <MenuItem value="">
                            <em>Select a role</em>
                          </MenuItem>
                          {!isFetchingRoles && roles.length > 0 ? (
                            hasManagerRole ? (
                              roles
                                .filter((role) => role.companyTypeContextId !== 5 && role.roleName !== CloudRole.ADMIN)
                                .map((role) => (
                                  <MenuItem key={role.publicId} value={role.publicId}>
                                    {role.roleName}
                                  </MenuItem>
                                ))
                            ) : (
                              roles
                                .filter(
                                  (role) =>
                                    role.companyTypeContextId !== 5 &&
                                    role.roleName !== 'Property Admin' &&
                                    role.roleName !== 'Property Manager' &&
                                    role.roleName !== 'Property Member' &&
                                    role.roleName !== 'Property Associate'
                                )
                                .map((role) => (
                                  <MenuItem key={role.publicId} value={role.publicId}>
                                    {role.roleName}
                                  </MenuItem>
                                ))
                            )
                          ) : (
                            <MenuItem disabled>No roles available</MenuItem>
                          )}
                        </Field>
                        <ErrorMessage name="role">{(msg) => <FormHelperText error>{msg}</FormHelperText>}</ErrorMessage>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <RoleDescriptions roleName={roles.find((id) => id.publicId === role)?.roleName} />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseClick} variant="contained">
                  {t('Cancel')}
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isInviteLoading}
                  disabled={props.isSubmitting}
                >
                  {t('Invite_User')}
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const styles = {
  mt2: {
    marginTop: 2
  },
  my2: {
    marginY: 2
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray'
      },
      '&:hover fieldset': {
        '& .enabled': {
          borderColor: '#003366'
        }
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  }
};

export default InviteUserDialog;
