import { useState } from 'react';
import UserQuikspecsDataGrid from './UserQuikspecsDataGrid';
import { Container, Paper, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import BranchQuikspecsDataGrid from './BranchQuikspecsDataGrid';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const SavedSpecs = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const getBranches = useSelector((state: RootState) => state.users.currentUser?.permissions?.branch) || {};
  const branchList = Object.keys(getBranches).map((branchId) => {
    return {
      branchId,
      branchName: getBranches[branchId]?.branchName
    };
  });

  const userBelongsToBranch = branchList.length > 0;
  return (
    <Container maxWidth="xl">
      <Paper sx={styles.usersPanel}>
        <TabContext value={currentTab.toString()}>
          <TabList onChange={handleTabChange}>
            <Tab label="My Specs" value="0" />
            {branchList.map((branch, index) => (
              <Tab key={branch.branchId} label={branch.branchName} value={String(index + 1)} />
            ))}
          </TabList>
          <TabPanel value="0">
            <UserQuikspecsDataGrid />
          </TabPanel>
          {userBelongsToBranch &&
            branchList.map((branch, index) => (
              <TabPanel key={branch.branchId} value={String(index + 1)}>
                <BranchQuikspecsDataGrid branchPublicId={branch.branchId.toString()} />
              </TabPanel>
            ))}
        </TabContext>
      </Paper>
    </Container>
  );
};

const styles = {
  devButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px'
  },
  usersPanel: {
    mt: 2
  },
  usersButtonContainer: {
    display: 'flex',
    float: 'right',
    padding: '20px'
  }
};

export default SavedSpecs;
