import { useState, useEffect } from 'react';

const useAclAuth = () => {
  const [aclToken, setAclToken] = useState(localStorage.getItem('acltoken'));
  const [aclUserName, setAclUserName] = useState(localStorage.getItem('aclUserName'));

  useEffect(() => {
    const handleStorageChange = () => {
      setAclToken(localStorage.getItem('acltoken'));
    };

    window.addEventListener('storage', handleStorageChange);

    const intervalId = setInterval(() => {
      const currentToken = localStorage.getItem('acltoken');
      const currentAclUserName = localStorage.getItem('aclUserName');
      if (currentToken !== aclToken) {
        setAclToken(currentToken);
      }
      if (currentAclUserName !== aclUserName) {
        setAclUserName(currentAclUserName);
      }
    }, 1000);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(intervalId);
    };
  }, [aclToken]);

  return { aclToken, aclUserName };
};

export default useAclAuth;
