import { useSelector } from 'react-redux';
import { RootState, selectAclSite } from 'store';
import { useAppDispatch } from 'store/hooks';

const useReduxSites = () => {
  const dispatch = useAppDispatch();
  const aclSites = useSelector((state: RootState) => state.aclSites.sites);
  const loadingFetchAclSites = useSelector((state: RootState) => state.aclSites.loading.fetchAclSites);
  const errorFetchAclSites = useSelector((state: RootState) => state.aclSites.error.fetchAclSites);
  const selectedSite = useSelector((state: RootState) => state.aclSites.selectedSite);
  const sitesDetails = useSelector((state: RootState) => state.aclSites.sitesDetails);
  const loadingProcessAndFetchSitesDetails = useSelector(
    (state: RootState) => state.aclSites.loading.processAndFetchSitesDetails
  );
  const errorProcessAndFetchSitesDetails = useSelector(
    (state: RootState) => state.aclSites.error.processAndFetchSitesDetails
  );

  if (!selectedSite) {
    const siteId = location.pathname.split('/')[3];
    dispatch(selectAclSite(Number(siteId)));
  }

  return {
    aclSites,
    loadingFetchAclSites,
    errorFetchAclSites,
    selectedSite,
    sitesDetails,
    loadingProcessAndFetchSitesDetails,
    errorProcessAndFetchSitesDetails
  };
};

export default useReduxSites;
