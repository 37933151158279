import { createContext, ReactNode, useState } from 'react';

interface AuthProviderProps {
  children?: ReactNode;
}

interface IAuthContext {
  username: string;
  password: string;
  errorMessage: string;
  sessionUserAttributes: any;
  session: string;
  setUsername: (newState: string) => void;
  setPassword: (newState: string) => void;
  setErrorMessage: (newState: string) => void;
  setSessionUserAttributes: (newState: any) => void;
  setSession: (newState: any) => void;
}

const initialValue = {
  username: '',
  password: '',
  errorMessage: '',
  sessionUserAttributes: {},
  session: '',
  setUsername: () => undefined,
  setPassword: () => undefined,
  setErrorMessage: () => undefined,
  setSessionUserAttributes: () => undefined,
  setSession: () => undefined
};

const AuthContext = createContext<IAuthContext>(initialValue);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [username, setUsername] = useState(initialValue.username);
  const [password, setPassword] = useState(initialValue.password);
  const [errorMessage, setErrorMessage] = useState(initialValue.errorMessage);
  const [sessionUserAttributes, setSessionUserAttributes] = useState(initialValue.sessionUserAttributes);
  const [session, setSession] = useState(initialValue.session);

  return (
    // Allows for protected routes
    <AuthContext.Provider
      value={{
        username,
        setUsername,
        password,
        setPassword,
        errorMessage,
        setErrorMessage,
        sessionUserAttributes,
        setSessionUserAttributes,
        session,
        setSession
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
