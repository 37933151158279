import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ResetInstructions = () => {
  const { t } = useTranslation();

  return (
    <Grid sx={styles.resetContainer}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {t('Gateway_Reset_Instructions.Step1')}
      </Typography>
      <Typography>
        1. {t('Gateway_Reset_Instructions.Step2')}
        <br />
        2. {t('Gateway_Reset_Instructions.Step3')}
        <br />
        3. {t('Gateway_Reset_Instructions.Step4')}
        <br />
        4. {t('Gateway_Reset_Instructions.Step5')}
        <br />
      </Typography>
    </Grid>
  );
};

const styles = {
  resetContainer: {
    padding: '20px',
    margin: '30px',
    border: '1px solid #c7c7c7',
    borderRadius: '5px'
  }
};

export default ResetInstructions;
