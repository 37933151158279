import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { aiphoneCloudApi } from 'services/aiphoneCloud';
import { UUID } from 'crypto';
import defaultIXMV7 from 'data/Device/DefaultValues/Default_IX-MV7';

export interface IDevice {
  deviceType: any;
  publicId: string;
  sitePublicId: string;
  unitPublicId?: string;
  buildingPublicId?: string;
  basicInfo: IDeviceBasicInfo;
  networkSettings?: IDeviceNetworkSettings;
  gatewaySettings?: IDeviceGatewaySettings;
  systemInfo?: IDeviceSystemInfo;
  callSettings?: IDeviceCallSettings;
  deviceSettings?: IDeviceDeviceSettings;
  pagingSettings?: IDevicePagingSettings;
  syslogSettings?: IDeviceSyslogSettings;
  contactInputList?: IDeviceContactInput[];
  functionSettings?: IDeviceFunctionSettings;
  transferSettings?: ITransferSettings;
  contactOutputList?: IContactOutput[];
  entrancePanelSettings?: IEntrancePanelSettings;
  isUpdated?: boolean;
  lastSyncedBy: string | null;
  lastSyncedOn: string | null;
  lastUpdatedBy: string | null;
  lastUpdatedOn: string | null;
}

export interface IDeviceWithAddressBook {
  publicId: string;
  sitePublicId: string;
  unitPublicId?: string;
  basicInfo: IDeviceBasicInfo;
  networkSettings?: IDeviceNetworkSettings;
  systemInfo: IDeviceSystemInfo;
  callSettings: IDeviceCallSettings;
  deviceSettings?: IDeviceDeviceSettings;
  pagingSettings?: IDevicePagingSettings;
  syslogSettings?: IDeviceSyslogSettings;
  contactInputList?: IDeviceContactInput[];
  functionSettings: IDeviceFunctionSettings;
  transferSettings?: ITransferSettings;
  contactOutputList?: IContactOutput[];
  isUpdated?: boolean;
}

export interface IDeviceBasicInfo {
  firmwareVersion?: any;
  adminId: string;
  adminPass: string;
  memoId: string;
  memoPass: string;
  adminPassConfirm?: string;
  dateTime: string;
  daylightSavings: boolean;
  deviceModel: number;
  devicePublicId: string;
  deviceType: number;
  macAddress: string;
  onvifId?: string;
  onvifPass?: string;
  onvifPassConfirm?: string;
  rtspId?: string;
  rtspPass?: string;
  rtspPassConfirm?: string;
  serialNumber?: string;
  stationLanguage: number;
  stationLanguageVer2: number;
  stationName: string;
  stationNumber: string;
  stationPronunciationName: string;
  timezone: number;
  timezoneVer2: number;
  userId: string;
  userPass: string;
  userPassConfirm?: string;
  stationLocation?: string;
  systemExtendMode?: boolean;
}

export interface IDeviceNetworkSettings {
  audioBufferMaximumPackets: number;
  audioBufferStartingPackets: number;
  audioEncoder: number;
  audioEncoder1RtpEndPort: number;
  audioEncoder1RtpStartPort: number;
  audioEncoder2RtpEndPort: number;
  audioEncoder2RtpStartPort: number;
  audioTosValue: string;
  devicePublicId: string;
  hostName: string;
  ipAddressMethod: number;
  ipV4Address: string;
  ipV4DefaultGateway: string;
  ipV4MulticastAddress: string;
  ipV4PrimaryDns: string;
  ipV4PrimarySipAddress: string;
  ipV4SecondaryDns: string;
  ipV4SecondarySipAddress: string;
  ipV4TertiarySipAddress: string;
  ipV6Address: string;
  ipV6DefaultGateway: string;
  ipV6MulticastAddress: string;
  ipV6PrimaryDns: string;
  ipV6PrimarySipAddress: string;
  ipV6SecondaryDns: string;
  ipV6SecondarySipAddress: string;
  ipV6TertiarySipAddress: string;
  ipVersion: number;
  masterStationVideoBitrate: number;
  masterStationVideoDistribution: boolean;
  masterStationVideoFramerate: number;
  masterStationVideoH264Profile: number;
  masterStationVideoIPictureInterval: number;
  ntpPrimaryServerIpV4Address: string;
  ntpPrimaryServerIpV6Address: string;
  ntpPrimaryServerPort: number;
  ntpSecondaryServerIpV4Address: string;
  ntpSecondaryServerIpV6Address: string;
  ntpSecondaryServerPort: number;
  ntpSettings: boolean;
  ntpSyncInterval: number;
  rtpIdleNoticeTimer: number;
  rtpSendInterval: number;
  sipHealthCheckTimer: number;
  sipInboundDtmfTimeout: number;
  sipPrimaryPass: string;
  sipPrimaryPort: number;
  sipPrimaryUser: string;
  sipRegistrationInterval: number;
  sipSecondaryPass: string;
  sipSecondaryPort: number;
  sipSecondaryUser: string;
  sipServerType: number;
  sipSignallingPort: number;
  sipTertiaryPass: string;
  sipTertiaryPort: number;
  sipTertiaryUser: string;
  sipTosValue: string;
  sipUserAgent: string;
  subnetMask: string;
  videoDualStream?: boolean;
  videoEncoder1Bitrate?: number;
  videoEncoder1Framerate?: number;
  videoEncoder1IPictureInterval?: number;
  videoEncoder1RtpEndPort?: number;
  videoEncoder1RtpStartPort: number;
  videoEncoder2IPictureInterval?: number;
  videoEncoder2Method?: number;
  videoEncoder2Resolution?: number;
  videoEncoder2RtpEndPort: number;
  videoEncoder2RtpStartPort: number;
  videoEncoder2Framerate?: number;
  videoEncoder2H264Quality?: number;
  videoEncoder2Bitrate?: number;
  videoEncoder2JpegQuality?: number;
  videoTosValue: string;
  vlanId: number;
  vlanPriority: number;
  vlanSettings: boolean;
}

export interface IDeviceGatewaySettings {
  enablePin?: boolean;
  gatewayNumber?: number;
  gatewayIvrTimeout?: number;
  gatewayIvrLanguage?: any;
  communicationMethod?: number;
  gatewayMaxTalkPaths?: number;
  gatewaySerialNumber?: string;
  gatewayChangePriority?: number;
  gatewayGuardButtonList?: any[];
  contactOutput1UnlockKey?: string;
  contactOutput2UnlockKey?: string;
}

export interface IAddressBookEntry {
  targetDevicePublicId?: string;
  targetUnitPublicId?: string;
  associatedNetworkCameraPublicId?: string;
  ringtoneSetting?: IRingtoneSettings;
  unlockSetting?: IUnlockSettings;
}

export interface INetworkCameraListEntry {
  networkCameraPublicId: string;
}

export interface ICallGroupInfoEntry {
  siteCallGroupPublicId: string;
  protocolAudio: number;
  protocolVideo: number;
}

export interface ICustomSoundEntry {
  customSoundPublicId: string;
  operation: number;
}

export interface IDeviceSystemInfo {
  devicePublicId: string;
  nodeLocation: string;
  addressBook?: IAddressBookEntry[];
  associatedNetworkCameraList?: INetworkCameraListEntry[];
  callGroupInfo?: ICallGroupInfoEntry[];
  customSounds?: ICustomSoundEntry[];
}
export interface IContactGroupList {
  [key: string]: IContactGroupObj;
}

export interface IContactGroupObj {
  publicId: string;
  targetList: IContactGroupEntry[];
}

export interface IContactGroupEntry {
  targetDevicePublicId?: string;
  targetUnitPublicId?: string;
  protocol: number;
}

export interface ICallTimerChangeEntry {
  siteCallGroupPublicId: string;
  timerChangePriority: number;
  timerChangeNumber: number;
}

export interface ICallTimerChange {
  callTimerChange: ICallTimerChangeEntry[];
}

export interface ICallScheduleEntry {
  scheduleNumber: number;
  startTime: string;
  endTime: string;
  destinationSiteCallGroupPublicId: string;
  callPriority: number;
}

export interface IIndividualCallScheduleEntry extends ICallScheduleEntry {
  scheduleDate: string;
}

export interface ICallSchedule {
  sunday: ICallScheduleEntry[];
  monday: ICallScheduleEntry[];
  tuesday: ICallScheduleEntry[];
  wednesday: ICallScheduleEntry[];
  thursday: ICallScheduleEntry[];
  friday: ICallScheduleEntry[];
  saturday: ICallScheduleEntry[];
  individual: IIndividualCallScheduleEntry[];
}

export interface IRingtoneSettings {
  targetDevicePublicId?: string | null;
  callButtonNormalSound?: string | null;
  callButtonNormalCustomSound?: string | null;
  callButtonPrioritySound?: string | null;
  callButtonPriorityCustomSound?: string | null;
  callButtonUrgentSound?: string | null;
  callButtonUrgentCustomSound?: string | null;
  optionInputNormalSound?: string | null;
  optionInputNormalCustomSound?: string | null;
  optionInputPrioritySound?: string | null;
  optionInputPriorityCustomSound?: string | null;
  optionInputUrgentSound?: string | null;
  optionInputUrgentCustomSound?: string | null;
}

export interface IDeviceCallSettings {
  devicePublicId: string;
  callButtonFunction?: number;
  callMode?: number;
  outgoingCallTimeout: number;
  incomingCallRingtone?: string;
  incomingCallTimeout: number;
  incomingCallRingtoneCount?: number;
  callButtonIncomingCallRingtoneCount: number;
  contactInputIncomingCallRingtoneCount: number;
  siteCallGroup?: string;
  callTimerChangeTime?: number;
  callBusyToneSettings: string;
  callErrorTone?: string;
  callResume?: boolean;
  autoAnswer: boolean;
  ipCallPriority: number;
  contactGroupList: IContactGroupList;
  contactGroup?: IContactGroupEntry[];
  callTimerChange?: ICallTimerChange;
  callSchedule?: ICallSchedule;
  ringtoneSetting?: UUID;
  ringtoneSettingsList: IRingtoneSettings[];
}
export interface IUnlockObj {
  devicePublicId: UUID;
  deviceTarget: number;
}
export interface IUnlockOperationGroupObj {
  publicId: UUID;
  deviceList: IUnlockObj[];
}
export interface IUnlockOperationGroupList {
  [key: string]: IUnlockOperationGroupObj;
}
export interface IDeviceDeviceSettings {
  statusLed?: boolean;
  pushToTalk?: boolean;
  callTimeout?: number;
  onHoldSound?: UUID;
  pagingSound?: UUID;
  privacyMode?: boolean;
  timeoutSound?: UUID;
  displayKeypad?: boolean;
  devicePublicId?: UUID;
  monitorTimeout?: number;
  autoAnswerSound?: UUID;
  deviceSpeedDial?: IDeviceSpeedDial[];
  doorReleaseSound?: string;
  pinJackInputType?: number;
  displayBrightness?: number;
  incomingCallSound?: UUID;
  volumeNotification?: number;
  scanMonitorInterval?: number;
  timeoutWarningSound?: UUID;
  picturePicturePrimary?: number;
  volumeExternalSpeaker?: number;
  volumeIncomingHandset?: number;
  volumeIncomingSpeaker?: number;
  volumeOutgoingHandset?: number;
  volumeOutgoingSpeaker?: number;
  volumeRingtone?: number;
  volumeOperation?: boolean;
  outputToExternalSpeaker?: number;
  volumeExternalMicrophone?: number;
  ipPhoneCallRate?: number;
  optionContactOutputKey?: string;
  picturePictureDisplayRatio?: number;
  automaticSupervisionSettings?: IAutomaticSupervisionSettings;
  volumeSpeaker?: number;
  volumeMonitoringMicrophone?: number;
  volumeIncomingPage?: number;
  unlockOperationGroupList?: IUnlockOperationGroupList;
}

export interface IUnlockCardGroup {
  roomList: Array<string>;
  cardGroupList: Array<string>;
}

export interface ICallRestrictionSchedule {
  friday: Array<string>;
  monday: Array<string>;
  sunday: Array<string>;
  tuesday: Array<string>;
  saturday: Array<string>;
  thursday: Array<string>;
  wednesday: Array<string>;
}
export interface IEntrancePanelSettings {
  shockSensor?: number | undefined;
  facilityCode?: number | undefined;
  displayKeypad?: boolean | undefined;
  devicePublicId?: string | undefined;
  callRestriction?: boolean | undefined;
  pinCode4bit8bit?: string | undefined;
  unlockCardGroup?: IUnlockCardGroup | undefined;
  unlockCardFormat?: number | undefined;
  vigiKTypeSetting?: number | undefined;
  displayFullKeypad?: boolean | undefined;
  displayNameSearch?: boolean | undefined;
  displayQrCodeScan?: boolean | undefined;
  pinCodeOutputMode?: number | undefined;
  displayQuickSelection?: boolean | undefined;
  displayAutoEndCall?: boolean | undefined;
  displayHearingMark?: boolean | undefined;
  displayVideoSource?: number | undefined;
  shockSensorSetting?: boolean | undefined;
  displayBuildSetting?: boolean | undefined;
  displayFullkeyImage?: boolean | undefined;
  displayMotionSensor?: boolean | undefined;
  displayTenkeyUnlock?: boolean | undefined;
  pinCode26bitWiegand?: number | undefined;
  displayWelcomeScreen?: boolean | undefined;
  acUnlockDetectionTime?: number | undefined;
  displayLanguageSwitch?: boolean | undefined;
  displayNameSearchList?: string[] | undefined;
  displayOperationGuide?: boolean | undefined;
  displayQuickThumbnail?: boolean | undefined;
  displayUnlockCodeList?: ICodeListEntry[] | undefined;
  displayScreenSelection?: number | undefined;
  unlockCardFacilityCode?: number | undefined;
  accessControlLogSetting?: boolean | undefined;
  callRestrictionSchedule?: ICallRestrictionSchedule | undefined;
  quickSelectionGroupList?: string[] | undefined;
  shockSensorWarningSound?: string | undefined;
  accessControlCoordination?: boolean | undefined;
  unlockCardScheduleInfoList?: string[] | undefined;
  displayCallRestrictionImage?: false | undefined;
  displayWelcomeScreenSettings?: object | undefined;
  displayFullkeyImageSettingList?: string[] | undefined;
  vigiKRoomFirstLastNameManagementSetting?: number | undefined;
}

export interface ICodeListEntry {
  devicePublicId: string;
  unitPublicId: string;
  accessCode: string;
}

export interface IDeviceSpeedDial {
  publicId: UUID;
  buttonName: string;
  buttonNumber: number;
  targetDevices: UUID[];
  speedDialFunction: number;
  optionRelaySecurity: boolean;
  disableButtonSettings: boolean;
}

export interface IAutomaticSupervisionSetting {
  startTime: string;
  daySetting: number;
  periodSetting: number;
}

export interface IAutomaticSupervisionSettings {
  line: IAutomaticSupervisionSetting;
  error: IAutomaticSupervisionSetting;
}

export interface IDeviceTypes {
  [key: string]: string[];
}

export interface IDevicePagingSettings {
  allPage: IPagingStationLists;
  pagingEnabled: boolean;
  pagingTimeout: number;
  devicePublicId: UUID;
  pagingStartDelay: number;
  pagingUrgentRejectCalls: boolean;
}

export interface IPagingStationLists {
  select: UUID[];
  unicast: UUID[];
}

export interface IDeviceSyslogSettings {
  syslogPort: number;
  devicePublicId: UUID;
  ipV4SyslogAddress: string;
  ipV6SyslogAddress: string;
}

export interface IDeviceContactInput {
  api1: string;
  api2: string;
  publicId: UUID;
  ringtone: UUID;
  inputName: string;
  inputNumber: number;
  pagingGroup: IPagingGroup;
  callSchedule: IContactInputCallScheduleContainer;
  detectionTime: number;
  inputFunction: number;
  inputPolarity: number;
  devicePublicId: UUID;
  callPriority: number;
  incomingCallTimeout: number;
  outgoingCallTimeout: number;
  siteCallGroupPublicId: UUID;
  deviceContactGroupPublicId: UUID;
}

export interface IPagingGroup {
  ringCount: number;
  pagingType: number;
  pagingPriority: number;
  soundSettingPublicId: UUID;
}

export interface IContactInputCallScheduleContainer {
  friday: IContactInputCallSchedule[];
  monday: IContactInputCallSchedule[];
  sunday: IContactInputCallSchedule[];
  tuesday: IContactInputCallSchedule[];
  saturday: IContactInputCallSchedule[];
  thursday: IContactInputCallSchedule[];
  wednesday: IContactInputCallSchedule[];
  individual: IContactInputIndividualCallSchedule[];
}

interface IContactInputCallSchedule {
  scheduleNumber: number;
  startTime: string;
  endTime: string;
  siteCallGroupPublicId: UUID;
  callGroupPriority: number;
}

export interface IContactInputIndividualCallSchedule extends IContactInputCallSchedule {
  scheduleDate: string;
}

export interface IDeviceFunctionSettings {
  sifEvent?: ISifEvent[];
  smtpAuth?: false;
  smtpEvent?: ISmtpEvent[];
  smtpAuthId?: string;
  cgiSettings?: boolean;
  displayMode?: number;
  eapPassword?: string;
  eapUsername?: string;
  sifSettings?: boolean;
  smtpAuthMode?: number;
  chimeSchedule?: IChimeScheduleContainer;
  displayImage1?: string;
  displayImage2?: string;
  displayLayout?: number;
  devicePublicId?: UUID;
  displayModeTop?: number;
  sipUriSettings?: boolean;
  smtpEncryption?: number;
  smtpPortNumber?: number;
  smtpServerName?: string;
  ieee802Security?: boolean;
  displayBaseColor?: number;
  recordingAutoEnd?: number;
  smtpAuthPassword?: string;
  displayImageSpace?: string;
  eapSecurityMethod?: number;
  recordingAddition?: boolean;
  recordingOverwrite?: boolean;
  unlockSettingsList?: IUnlockSettings[];
  recordingAllowManual?: boolean;
  recordingFileDivision?: number;
  recordingInputPriority?: number;
  smtpSenderEmailAddress?: string;
  displayButtonNumberName?: string;
  displayButtonSelectName?: string;
  receptionModeCallReject?: boolean;
  sifTransmissionInterval?: number;
  automaticRecordingTriggers?: IAutomaticRecordingTriggers;
  displayButtonListSelectName?: string;
  smtpDestinationEmailAddress1?: string;
  smtpDestinationEmailAddress2?: string;
  smtpDestinationEmailAddress3?: string;
  displayButtonGeneralInformationName?: string;
  networkCameraEventNotificationRingtoneCount?: number;
  smtpEventSendScheduledLogEmailAddress1SendTime?: string;
  smtpEventSendScheduledLogEmailAddress2SendTime?: string;
  smtpEventSendScheduledLogEmailAddress3SendTime?: string;
  smtpEventSendScheduledLogEmailAddress1SendInterval?: number;
  smtpEventSendScheduledLogEmailAddress2SendInterval?: number;
  smtpEventSendScheduledLogEmailAddress3SendInterval?: number;
  sifCommunicationDestinationList?: sifCommunicationDestinations[];
}

export interface sifCommunicationDestinations {
  sslEnabled: boolean;
  programType: string;
  connectionType: number;
  destinationPort: number;
  sifDestinationNumber: number;
  targetDevicePublicId: string | null;
}

export interface ISifEvent {
  eventType: number;
  sifDestination1: boolean;
  sifDestination2: boolean;
  sifDestination3: boolean;
  sifDestination4: boolean;
  sifDestination5: boolean;
  sifDestination6: boolean;
  sifDestination7: boolean;
  sifDestination8: boolean;
  sifDestination9: boolean;
  sifDestination10: boolean;
  sifDestination11: boolean;
  sifDestination12: boolean;
  sifDestination13: boolean;
  sifDestination14: boolean;
  sifDestination15: boolean;
  sifDestination16: boolean;
}

export interface ISmtpEvent {
  eventType: number;
  emailSubject: string;
  enableEmail1: boolean;
  enableEmail2: boolean;
  enableEmail3: boolean;
}

export interface IChimeScheduleContainer {
  friday: IChimeSchedule[];
  monday: IChimeSchedule[];
  sunday: IChimeSchedule[];
  tuesday: IChimeSchedule[];
  saturday: IChimeSchedule[];
  thursday: IChimeSchedule[];
  wednesday: IChimeSchedule[];
  individual: IChimeIndividualSchedule[];
}

export interface IChimeSchedule {
  scheduleNumber: number;
  startTime: string;
  soundType: number;
  customSoundPublicId: UUID;
}

export interface IUnlockSettings {
  targetDevicePublicId?: string;
  deviceTarget: number;
}

export interface IChimeIndividualSchedule extends IChimeSchedule {
  scheduleDate: string;
}

export interface IAutomaticRecordingTriggers {
  callStart: boolean;
  monitoring: boolean;
  incomingCall: boolean;
  outgoingCall: boolean;
  scheduledStart: boolean;
  callStartDoorStation: boolean;
  monitorStationDoorStation: boolean;
}

export interface ITransferSettings {
  transferDelay: boolean;
  devicePublicId: UUID;
  transferAbsent: boolean;
  transferUserLock: boolean;
  transferDelayTime: number;
  transferScheduleLink: boolean;
  transferScheduleVer3: ITransferScheduleVer3;
  deviceTransferDelayDestinationList: UUID[];
  delayDestinationUnitPublicId: UUID;
  transferDelayRetransferDestination: UUID;
  deviceTransferAbsentDestinationList: UUID[];
  absentDestinationUnitPublicId: UUID;
  transferAbsentRetransferDestination: UUID;
}

export interface ITransferScheduleVer3 {
  sunday: ITransferSchedule[];
  monday: ITransferSchedule[];
  tuesday: ITransferSchedule[];
  wednesday: ITransferSchedule[];
  thursday: ITransferSchedule[];
  friday: ITransferSchedule[];
  saturday: ITransferSchedule[];
  individual: ITransferScheduleIndividual[];
}

export interface ITransferSchedule {
  transferNumber: number;
  startTime: string;
  endTime: string;
  destination1PublicId: UUID;
  destination2PublicId: UUID;
  destination3PublicId: UUID;
  destination4PublicId: UUID;
  destination5PublicId: UUID;
  destination6PublicId: UUID;
  destination7PublicId: UUID;
  destination8PublicId: UUID;
  destination9PublicId: UUID;
  destination10PublicId: UUID;
  retransferDestinationPublicId: UUID;
}

export interface ITransferScheduleIndividual extends ITransferSchedule {
  scheduleDate: string;
}

export interface IContactOutput {
  sound: string;
  timer: number;
  control: IContactOutputControl;
  publicId: UUID;
  soundEnd: UUID;
  unlockKey: string;
  outputName: string;
  soundStart: UUID;
  optionRelay: boolean;
  callSchedule: IContactOutputCallScheduleContainer;
  outputNumber: number;
  devicePublicId: UUID;
  outputFunction: number;
  sifEventSourceDevicePublicId?: string;
  previousSelectedDeviceId?: string | null;
}

export interface IContactOutputControl {
  [key: string]: boolean;
}

export interface IContactOutputCallScheduleContainer {
  friday: IContactOutputCallSchedule[];
  monday: IContactOutputCallSchedule[];
  sunday: IContactOutputCallSchedule[];
  tuesday: IContactOutputCallSchedule[];
  saturday: IContactOutputCallSchedule[];
  thursday: IContactOutputCallSchedule[];
  wednesday: IContactOutputCallSchedule[];
  individual: IContactOutputIndividualCallSchedule[];
}

export interface IContactOutputCallSchedule {
  scheduleNumber: number;
  startTime: string;
  endTime: string;
}

export interface IContactOutputIndividualCallSchedule extends IContactOutputCallSchedule {
  scheduleDate: string;
}

export interface IUpdateSelectedDeviceSettings {
  deviceSettings: IDeviceDeviceSettings;
  publicId: string;
}

export interface DeviceList {
  [key: string]: IDevice;
}

export interface DeviceDefaults {
  [key: number]: IDevice;
}

export interface DevicesState {
  DeviceList: DeviceList;
  DeviceListByType: IDeviceTypes;
  SelectedDevice: string | null;
  getDevicesNetworkSettingsStatus: 'loading' | 'succeeded' | 'failed' | 'initial';
}

export const deviceDefaults: DeviceDefaults = {
  4: defaultIXMV7
};

interface DeviceTypeModelMapping {
  [key: number]: {
    fallback: string;
    models?: { [modelKey: number]: string };
  };
}

export const getDeviceTypeModelMapping = (deviceType: number, deviceModel: number): string | undefined => {
  const deviceMapping = deviceTypeMapping[deviceType];

  if (!deviceMapping) {
    return undefined;
  }

  if (deviceMapping.models && deviceMapping.models[deviceModel]) {
    return deviceMapping.models[deviceModel];
  }
  return deviceMapping.fallback;
};

export const deviceTypeMapping: DeviceTypeModelMapping = {
  4: { fallback: 'MasterStation' },
  5: { fallback: 'SubMasterStation' },
  6: { fallback: 'IOAdaptor' },
  8: {
    fallback: 'VideoDoorStation',
    models: { 8: 'EmergencyStation', 9: 'EmergencyStation' }
  },
  9: {
    fallback: 'AudioDoorStation',
    models: { 2: 'EmergencyStation', 3: 'EmergencyStation' }
  },
  10: { fallback: 'AudioDoorStation' },
  11: { fallback: 'VideoDoorStation' },
  14: { fallback: 'TenantStation' },
  15: { fallback: 'EntranceStation' },
  16: { fallback: 'GuardStation' },
  17: { fallback: 'LiftControl' },
  18: { fallback: 'GatewayAdaptor' },
  20: { fallback: 'VideoDoorStation' }
};
const initialState: DevicesState = {
  DeviceList: {},
  DeviceListByType: {},
  SelectedDevice: null,
  getDevicesNetworkSettingsStatus: 'initial'
};

function getUpdatedDeviceGroups(devices: IDevice[]): IDeviceTypes {
  const updatedDeviceGroups: IDeviceTypes = {
    MasterStation: [],
    SubMasterStation: [],
    EntranceStation: [],
    TenantStation: [],
    GuardStation: [],
    VideoDoorStation: [],
    AudioDoorStation: [],
    EmergencyStation: [],
    GatewayAdaptor: [],
    LiftControl: [],
    IOAdaptor: []
  };

  devices.forEach((device: IDevice) => {
    const { deviceType, deviceModel } = device.basicInfo;
    const group = getDeviceTypeModelMapping(deviceType, deviceModel);
    if (group) {
      try {
        updatedDeviceGroups[group].push(device.publicId);
      } catch (e) {
        console.log(`Device type ${deviceType} not found in deviceTypeMapping`);
      }
    }
  });

  return updatedDeviceGroups;
}

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setLastSyncedOn: (state, action: PayloadAction<string>) => {
      state.DeviceList[action.payload].lastSyncedOn = new Date().toISOString();
    },
    setSelectedDevice: (state, action: PayloadAction<string>) => {
      state.SelectedDevice = action.payload;
    },
    setDevicesBasicInfoList: (state, action) => {
      state.DeviceList = action.payload;
      state.DeviceListByType = getUpdatedDeviceGroups(action.payload);
    },
    setDeviceNetworkSettings: (state, action) => {
      const publicId = action.payload.publicId;
      state.DeviceList[publicId].networkSettings = action.payload.networkSettings;
    },
    updateSelectedDevice: (state, action) => {
      state.DeviceList[action.payload.device.publicId] = action.payload.device;
    },
    setDeviceBasicInfo: (state, action) => {
      state.DeviceList[action.payload.publicId].basicInfo = action.payload.basicInfo;
    },
    setDeviceCallSettings: (state, action) => {
      state.DeviceList[action.payload.device.publicId].callSettings = action.payload.device.callSettings;
    },
    setDeviceDeviceSettings: (state, action) => {
      state.DeviceList[action.payload.device.publicId].deviceSettings = action.payload.device.deviceSettings;
    },
    setDeviceUnitPublicId: (state, action) => {
      state.DeviceList[action.payload.devicePublicId].unitPublicId = action.payload.unitPublicId;
    },
    updateContactOutputs: (state, action: PayloadAction<IContactOutput[]>) => {
      action.payload.forEach((newOutput) => {
        const device = state.DeviceList[newOutput.devicePublicId];
        if (device && device.contactOutputList) {
          device.contactOutputList.forEach(() => {
            if (device.contactOutputList) {
              const index = device.contactOutputList.findIndex((output) => output.publicId === newOutput.publicId);
              if (index !== -1) {
                device.contactOutputList[index] = newOutput;
              } else {
                device.contactOutputList.push(newOutput);
              }
            }
          });
        }
        state.DeviceList[newOutput.devicePublicId] = device;
      });
    },
    updateContactInputs: (state, action: PayloadAction<IDeviceContactInput[]>) => {
      action.payload.forEach((newInput) => {
        const device = state.DeviceList[newInput.devicePublicId];
        if (device && device.contactInputList) {
          device.contactInputList.forEach(() => {
            if (device.contactInputList) {
              const index = device.contactInputList.findIndex((input) => input.publicId === newInput.publicId);
              if (index !== -1) {
                device.contactInputList[index] = newInput;
              } else {
                device.contactInputList.push(newInput);
              }
            }
          });
        }
        state.DeviceList[newInput.devicePublicId] = device;
      });
    },
    clearDevices: (state) => {
      state.DeviceList = {};
      state.DeviceListByType = {};
    },
    clearSelectedDevice: (state) => {
      state.SelectedDevice = null;
    },
    removeDevice: (state, action) => {
      delete state.DeviceList[action.payload];
      state.DeviceListByType = getUpdatedDeviceGroups(Object.values(state.DeviceList));
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(aiphoneCloudApi.endpoints.getDeviceListWithSitePublicId.matchFulfilled, (state, { payload }) => {
      if (payload.deviceList) {
        state.DeviceList = payload.deviceList;
        state.DeviceListByType = getUpdatedDeviceGroups(Object.values(state.DeviceList));
      } else {
        state.DeviceList = {};
        state.DeviceListByType = {};
      }
    });
  }
});

export const selectDevices = (state: RootState) => state.devices.DeviceList;

export const selectDeviceTypes = (state: RootState) => state.devices.DeviceListByType;

export const getSelectedDevice = (state: RootState) => {
  return state.devices.DeviceList[state.devices.SelectedDevice as string];
};

export const getDeviceWithPublicId = (state: RootState, publicId: string) => {
  return state.devices.DeviceList[publicId];
};

export const {
  setLastSyncedOn,
  setSelectedDevice,
  setDevicesBasicInfoList,
  setDeviceNetworkSettings,
  setDeviceBasicInfo,
  setDeviceCallSettings,
  setDeviceDeviceSettings,
  setDeviceUnitPublicId,
  updateContactOutputs,
  updateContactInputs,
  clearDevices,
  clearSelectedDevice,
  updateSelectedDevice,
  removeDevice
} = devicesSlice.actions;

export const devicesReducer = devicesSlice.reducer;
