import React from 'react';

type SuccessMessageProps = {
  title: string;
  message: string;
  iconComponent?: React.ReactNode;
  button?: React.ReactNode;
};

const InfoScreen: React.FC<SuccessMessageProps> = ({ title, message, iconComponent, button }) => {
  return (
    <div style={styles.infoScreenWrapper}>
      <div style={styles.titleWrapper}>
        {iconComponent && iconComponent}
        <span style={styles.title}>{title}</span>
      </div>
      <div style={styles.description}>{message}</div>
      {button && button}
    </div>
  );
};

export default InfoScreen;

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  infoScreenWrapper: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center'
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: '30px'
  },
  description: {
    fontSize: '20px',
    textAlign: 'center' as const,
    marginTop: '20px'
  }
};
