import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, resetfetchSubPlansDataError } from 'store';
import { useAppDispatch } from 'store/hooks';
import { fetchSubscriptionPlansData } from 'features/SimBilling/Stores/subscriptionPlansSlice';

const useFetchSubscriptionPlans = (countryCode: string | null) => {
  const dispatch = useAppDispatch();
  const subscriptionPlans = useSelector((state: RootState) => state.subscriptionPlans.plans);
  const subscriptionPlansError = useSelector((state: RootState) => state.subscriptionPlans.error);
  const subscriptionPlansLoading = useSelector((state: RootState) => state.subscriptionPlans.loading);

  const resetfetchSubPlansError = () => {
    dispatch(resetfetchSubPlansDataError());
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!subscriptionPlans) {
        if (countryCode) {
          await dispatch(fetchSubscriptionPlansData({ countryCode: countryCode }));
        }
      }
    };

    fetchData();
  }, [dispatch, subscriptionPlans, countryCode]);

  return { subscriptionPlans, subscriptionPlansError, subscriptionPlansLoading, resetfetchSubPlansError };
};

export default useFetchSubscriptionPlans;
