import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { UUID } from 'crypto';

interface IDeviceCard {
  devicePublicId: UUID | string;
  handleRemoveCallDestinationDevice: (devicePublicId: string) => void;
}

const DeviceCard = ({ devicePublicId, handleRemoveCallDestinationDevice }: IDeviceCard) => {
  const device = useSelector((state: RootState) => state.devices.DeviceList[devicePublicId]);
  const imageUrl = `https://aiphone-images.s3.us-west-2.amazonaws.com/icons/${getDeviceModelNumberFromModelType(
    device?.basicInfo?.deviceModel,
    device?.basicInfo?.deviceType
  )}.png`;

  const [isDeleting, setIsDeleting] = useState(false);
  const handleDeleteDevice = async (devicePublicId: string) => {
    setIsDeleting(true);
    await handleRemoveCallDestinationDevice(devicePublicId);
    setIsDeleting(false);
  };

  return (
    <Card sx={styles.cardWrapper}>
      <Grid sx={styles.topBarWrapper}>
        <Grid sx={styles.stationNameWrapper}>
          <Typography variant="h5">{device?.basicInfo?.stationName}</Typography>
        </Grid>

        <Grid sx={styles.loadingWrapper}>
          <LoadingButton
            loading={isDeleting}
            size="small"
            onClick={() => {
              handleDeleteDevice(devicePublicId);
            }}
            sx={styles.loadingButton}
          >
            <CancelOutlinedIcon />
          </LoadingButton>
        </Grid>
      </Grid>

      <CardContent>
        <Grid sx={styles.deviceWrapper} container alignItems="center">
          <Grid sx={styles.deviceInfoWrapper}>
            <Typography variant="body2" color="text.secondary">
              <strong>Model Number: </strong>
              {getDeviceModelNumberFromModelType(device?.basicInfo?.deviceModel, device?.basicInfo?.deviceType)}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              <strong>Station Number: </strong>
              {device?.basicInfo?.stationNumber}
            </Typography>
          </Grid>
          <Grid sx={styles.deviceImageWrapper}>
            <Box component="img" src={imageUrl} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const styles = {
  cardWrapper: {
    width: 330,
    height: 150,
    margin: '10px'
  },
  topBarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  stationNameWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '15px'
  },
  deviceWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  deviceInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  deviceImageWrapper: {
    MarginLeft: '40px',
    p: 2
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loadingButton: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    minWidth: 0,
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  }
};
export default DeviceCard;
