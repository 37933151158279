import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Grid, TextField } from '@mui/material';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { LoadingButton } from '@mui/lab';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import StringUtils from 'shared/utils/StringUtils';

import * as yup from 'yup';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import { useTranslation } from 'react-i18next';

interface PhoneSettingsFormValues {
  contactOutput1UnlockKey: string;
  contactOutput2UnlockKey: string;
}

export const PhoneSettingsLabel = () => {
  const { t } = useTranslation();
  return <span>{t('Advanced_Settings_Tab_Phone_Settings')}</span>;
};

const PhoneSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedDevice = useSelector(getSelectedDevice);

  const fieldErrorMaxLen = t('Field_Error_Max_Len');
  const contactOutput1UnlockKeyTitle = t('Advanced_Settings_Contact_Output_1_Unlock_Key_Title');
  const contactOutput2UnlockKeyTitle = t('Advanced_Settings_Contact_Output_2_Unlock_Key_Title');
  const contactOutputUnlockKeyDescription = t('Advanced_Settings_Contact_Output_Unlock_Key_Description');
  const unauthorizedUser = t('Advanced_Settings_Unauthorized_User');
  const updateDeviceError = t('Advanced_Settings_Error_Update_Device');
  const successUpdateDevice = t('Advanced_Settings_Success_Update_Device');
  const resetButtonText = t('Button_Reset');
  const saveChangesButtonText = t('Button_Save_Changes');

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();

  const validationSchema = yup.object().shape({
    contactOutput1UnlockKey: yup
      .string()
      .matches(/^\d*$/, t('Only_Numeric_Values_Allowed'))
      .max(20, StringUtils.format(fieldErrorMaxLen, contactOutput1UnlockKeyTitle, 20)),
    contactOutput2UnlockKey: yup
      .string()
      .matches(/^\d*$/, t('Only_Numeric_Values_Allowed'))
      .max(20, StringUtils.format(fieldErrorMaxLen, contactOutput2UnlockKeyTitle, 20))
  });

  const onSubmit = async (values: PhoneSettingsFormValues, actions: FormikHelpers<PhoneSettingsFormValues>) => {
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        gatewaySettings: values
      }
    };

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.gatewaySettings = {
      ...newDevice.gatewaySettings,
      ...values
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }
        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(unauthorizedUser);
        } else {
          setErrorMessage(updateDeviceError);
        }
      });
  };

  const initialValues: PhoneSettingsFormValues = {
    contactOutput1UnlockKey: selectedDevice.gatewaySettings?.contactOutput1UnlockKey || '',
    contactOutput2UnlockKey: selectedDevice.gatewaySettings?.contactOutput2UnlockKey || ''
  };

  return (
    <>
      <Box sx={containerStyle.mainWrapper}>
        <SnackbarAlert
          type="error"
          time={10000}
          text={`${errorMessage}`}
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <SnackbarAlert
          type="success"
          time={3000}
          text={successUpdateDevice}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
        />
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ dirty, touched, errors, isSubmitting }) => (
            <Form style={containerStyle.form}>
              <Box sx={containerStyle.controlPanelWrapper}>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="reset"
                  disabled={!dirty || isSubmitting || isUpdating}
                >
                  {resetButtonText}
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="submit"
                  disabled={!dirty || isSubmitting || isUpdating}
                >
                  {saveChangesButtonText}
                </LoadingButton>
              </Box>
              <Card sx={containerStyle.settingsWrapper}>
                <Box sx={containerStyle.gridContainer}>
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{contactOutput1UnlockKeyTitle}</Box>
                      <Box>{contactOutputUnlockKeyDescription}</Box>
                    </Grid>
                    <Grid item xs={6} lg={4} justifyContent="flex-end">
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={TextField}
                          label={contactOutput1UnlockKeyTitle}
                          name="contactOutput1UnlockKey"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.contactOutput1UnlockKey && errors.contactOutput1UnlockKey}
                          error={touched.contactOutput1UnlockKey && errors.contactOutput1UnlockKey}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{contactOutput2UnlockKeyTitle}</Box>
                      <Box>{contactOutputUnlockKeyDescription}</Box>
                    </Grid>
                    <Grid item xs={6} lg={4} justifyContent="flex-end">
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={TextField}
                          label={contactOutput2UnlockKeyTitle}
                          name="contactOutput2UnlockKey"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.contactOutput2UnlockKey && errors.contactOutput2UnlockKey}
                          error={touched.contactOutput2UnlockKey && errors.contactOutput2UnlockKey}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default PhoneSettings;
