/**
 * Input function have different drop down menu based on the device type
 * IX-EA, IX-DV, IX-DVM, IX-SSA, IX-SS-2G, IX-RS, IXG-DM7 ['No Function' , 'Call' ]
 * IX-MV7, IXG-MK, IXG-2C7, IXW-MA [No Function' ]
 */

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { getString } from 'shared/utils/LocalizationUtils';
import { RootState } from 'store';
import { IContactOutput, IDeviceContactInput, getDeviceTypeModelMapping } from 'store/slices/devicesSlice';

interface IInputFunctionSettingProps {
  selectedRelay: IDeviceContactInput;
  setNeedsSave: (needsSave: boolean) => void;
  newRelaySettings: Array<IContactOutput | IDeviceContactInput>;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
  inputFunction: number;
  setInputFunction: (inputFunction: number) => void;
}

const strings = {
  title: 'Input Function',
  description: 'Choose a function for this input.',
  ioAdapter:
    'This feature will be available in a future release. If this feature is important to you, please send a brief email to.',
  email: (
    <a href="mailto:cloudadmin@aiphone.com" target="_blank">
      Cloudadmin@aiphone.com
    </a>
  )
};
/**
 * @param enumList
 * @param deviceType
 * @returns list of input function options based on device type
 */
const generateInputFunctionOptions = (enumList: EnumList, deviceType: number, deviceModel: number) => {
  const doorStationDevices = [5, 8, 9, 10, 11, 12, 20];
  const doorStationOptions = [1, 2];

  const entranceStationDevices = [15];
  const entranceStationOptions = [1, 2];

  const masterStationDevices = [4];
  const masterStationOptions = [1];

  const guardStationDevices = [16];
  const guardStationOptions = [1];

  const tenantStationDevices = [14];
  const tenantStationOptions = [1];
  const isSelectedDeviceIOAdaptorType = () => {
    return getDeviceTypeModelMapping(deviceType, deviceModel) === 'IOAdaptor';
  };

  let options = [];

  switch (true) {
    case doorStationDevices.includes(deviceType):
      options = Object.entries(enumList.contactInputFunction).filter(([key]) =>
        doorStationOptions.includes(parseInt(key))
      );
      break;

    case entranceStationDevices.includes(deviceType):
      options = Object.entries(enumList.contactInputFunction).filter(([key]) =>
        entranceStationOptions.includes(parseInt(key))
      );
      break;

    case masterStationDevices.includes(deviceType):
      options = Object.entries(enumList.contactInputFunction).filter(([key]) =>
        masterStationOptions.includes(parseInt(key))
      );
      break;

    case guardStationDevices.includes(deviceType):
      options = Object.entries(enumList.contactInputFunction).filter(([key]) =>
        guardStationOptions.includes(parseInt(key))
      );
      break;

    case tenantStationDevices.includes(deviceType):
      options = Object.entries(enumList.contactInputFunction).filter(([key]) =>
        tenantStationOptions.includes(parseInt(key))
      );
      break;

    case isSelectedDeviceIOAdaptorType():
      options = Object.entries(enumList.contactInputFunction);
      return options.map(([key, option]) => (
        <MenuItem key={key} value={parseInt(key)} disabled={option.value !== 'No Function'}>
          {option.value}
        </MenuItem>
      ));

    default:
      options = Object.entries(enumList.contactInputFunction);
      break;
  }
  return options.map(([key, option]) => (
    <MenuItem key={key} value={parseInt(key)}>
      {option.value}
    </MenuItem>
  ));
};

const InputFunctionSetting = ({
  selectedRelay,
  setNeedsSave,
  newRelaySettings,
  setNewRelaySettings,
  inputFunction,
  setInputFunction
}: IInputFunctionSettingProps) => {
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const { deviceType, deviceModel } = deviceList[selectedRelay.devicePublicId].basicInfo;
  const inputFunctionText = getString('Input_Function');

  useEffect(() => {
    setInputFunction(selectedRelay.inputFunction);
  }, [selectedRelay]);

  useEffect(() => {
    // Find the relay in newRelaySettings that matches the selectedRelay's publicId
    const matchingRelay = newRelaySettings.find((relay) => relay.publicId === selectedRelay.publicId);
    if (
      matchingRelay &&
      'inputFunction' in matchingRelay &&
      matchingRelay.inputFunction !== selectedRelay.inputFunction
    ) {
      // If a matching relay is found and its inputFunction is different from the selectedRelay's inputFunction,
      // update the inputFunction state with the new inputFunction from newRelaySettings
      setInputFunction(matchingRelay.inputFunction);
    } else {
      // If no matching relay is found or the functions are the same, use the selectedRelay's inputFunction
      setInputFunction(selectedRelay.inputFunction);
    }
  }, [newRelaySettings, selectedRelay]);

  const handleChange = (event: SelectChangeEvent<number>) => {
    const inputFunction = event.target.value as number;
    if (selectedRelay.inputFunction !== inputFunction) {
      setNeedsSave(true);
      setNewRelaySettings((prev) => {
        const newSettingsArray = prev;
        const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);
        const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
        if (index !== -1) {
          if (currentRelaySettings) newSettingsArray[index] = { ...currentRelaySettings, inputFunction };
        } else {
          newSettingsArray.push({ ...selectedRelay, inputFunction });
        }
        return newSettingsArray;
      });
    } else {
      setNeedsSave(false);
      setNewRelaySettings((prev) => {
        return prev.filter((relay) => relay.publicId !== selectedRelay.publicId);
      });
    }
    setInputFunction(event.target.value as number);
  };

  const enumList: EnumList = fetchLocalEnumList();

  return (
    <Box sx={styles.settingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{strings.title}</Box>
        <Box sx={styles.description}> {strings.description}</Box>
      </Box>
      {deviceType == 6 && (
        <Box sx={styles.ioAdapter}>
          {strings.ioAdapter}
          {strings.email}
        </Box>
      )}
      <Box sx={styles.selectWrapper}>
        <FormControl sx={styles.selectField}>
          <InputLabel id="input-function-label">{inputFunctionText}</InputLabel>
          <Select
            labelId="input-function-label"
            id="input-function"
            value={inputFunction}
            label="Input Function"
            onChange={handleChange}
          >
            {generateInputFunctionOptions(enumList, deviceType, deviceModel)}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  selectWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  ioAdapter: {
    fontSize: '1rem',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  email: {},
  selectField: {
    width: '60%'
  }
};

export default InputFunctionSetting;
