import { createSlice } from '@reduxjs/toolkit';
import { aiphoneCloudApi } from 'services/aiphoneCloud';
import { RootState } from 'store';

export interface Building {
  buildingName: string;
  buildingSequentialNumber: number;
  buildingNumber: string;
  publicId: string;
  sitePublicId: string;
  createdBy: string;
  createdOn: string;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
}

interface BuildingState {
  BuildingList: BuildingList;
  SelectedBuilding: Building | null | undefined;
  Loading: boolean;
}

export interface BuildingList {
  [key: string]: Building;
}

const initialState: BuildingState = {
  BuildingList: {},
  SelectedBuilding: null,
  Loading: false
};

const buildingsSlice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {
    updateBuildings: () => {
      //TODO: Implement the updateBuildings reducer
    },
    addBuilding: () => {
      //TODO: Implement the addBuilding reducer
    },
    deleteBuilding: () => {
      //TODO: Implement the deleteBuilding reducer
    },
    resetBuildingsState: (state) => {
      state.BuildingList = {};
      state.SelectedBuilding = null;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(aiphoneCloudApi.endpoints.getBuildings.matchFulfilled, (state, { payload }) => {
      state.BuildingList = {};
      const newBuildingList: BuildingList = payload;
      state.BuildingList = newBuildingList;
    });
  }
});

export const selectBuildings = (state: RootState) => state.buildings.BuildingList;

export const { updateBuildings, deleteBuilding, addBuilding, resetBuildingsState } = buildingsSlice.actions;
export const buildingsReducer = buildingsSlice.reducer;
