/**
 * Paging has 3 tabs (All Page, Building Page, Option Input Page)
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from '../DeviceTabs';

const Paging = () => {
  const PagingTabs: DeviceTabType[] = [
    DeviceTabType.Paging_AllPage,
    DeviceTabType.Paging_BuildingPage,
    DeviceTabType.Paging_OptionInputPage
  ];

  return <TabsContainer tabTypes={PagingTabs} />;
};

export default Paging;
