import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';

interface RadioButtonProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton = ({ label, value, checked, onChange }: RadioButtonProps) => {
  return <FormControlLabel control={<Radio checked={checked} onChange={onChange} value={value} />} label={label} />;
};

export default RadioButton;
