import { Grid, Typography } from '@mui/material';
import { Field, useField } from 'formik';
import { useEffect, useState } from 'react';
import PasswordField from 'shared/components/PasswordField';
import { useTranslation } from 'react-i18next';
import useValidationSchemas from 'features/Auth/validationSchemas';

// Password values for formik component
export const initialValues = {
  password: '',
  passwordConfirm: ''
};

/**
 * Create password form for registration
 *
 * @param props Formik helper function setValidation and state setter setPasswordVerified
 * @returns
 */
export const CreatePassword = (props: any) => {
  const [, passwordMeta] = useField('password');
  const [, passwordConfirmMeta] = useField('passwordConfirm');
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasMinChar, setHasMinChar] = useState(false);
  const [hasMatchingPassword, setHasMatchingPassword] = useState(false);
  const { createPWDValidation } = useValidationSchemas();
  const { t } = useTranslation(); // Added useTranslation hook

  // Texts for password criteria
  const oneLower = t('PasswordCriteria_OneLowerCaseLetter');
  const oneUpper = t('PasswordCriteria_OneUpperCaseLetter');
  const oneNumber = t('PasswordCriteria_OneNumber');
  const oneSpecial = t('PasswordCriteria_OneSpecialCharacter');
  const atLeastEight = t('PasswordCriteria_EightCharacters');
  const mustMatch = t('PasswordCriteria_PasswordMatch');

  // Sets validation on component load
  useEffect(() => {
    props.setValidation(createPWDValidation);
  }, []);

  // Password Match requirement is always satisfied at last
  useEffect(() => {
    updatePasswordVerificationStatus();
  }, [hasMatchingPassword]);

  function updatePasswordVerificationStatus() {
    // Once the password has met the minimum requirements the "Sign Up" button will no longer be disabled
    if (hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar && hasMinChar && hasMatchingPassword) {
      props.setPasswordVerified(true);
    } else {
      props.setPasswordVerified(false);
    }
  }

  /**
   * Checks password for:
   * - One lowercase character
   * - One uppercase character
   * - One number
   * - One special character
   * - At least 8 characters
   * - Both password fields match
   *
   * @param event OnKeyUp event object
   */
  function handleValidPassword(event: React.KeyboardEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    const targetID = target.id;

    if (targetID === 'password') {
      const passwordCheck = document.getElementById('passwordConfirm') as HTMLInputElement | null;

      if (passwordCheck !== null) {
        target.value.match(/[a-z]/g) ? setHasLowerCase(true) : setHasLowerCase(false);

        target.value.match(/[A-Z]/g) ? setHasUpperCase(true) : setHasUpperCase(false);

        target.value.match(/[0-9]/g) ? setHasNumber(true) : setHasNumber(false);

        target.value.match(/[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/g)
          ? setHasSpecialChar(true)
          : setHasSpecialChar(false);

        target.value.length >= 8 ? setHasMinChar(true) : setHasMinChar(false);

        target.value === passwordCheck.value ? setHasMatchingPassword(true) : setHasMatchingPassword(false);
      }
    }
  }

  /**
   * Confirms passwords match
   *
   * @param event OnKeyUp event object
   */
  function handlePasswordMatch(event: React.KeyboardEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    const passwordCheck = document.getElementById('password') as HTMLInputElement | null;

    if (passwordCheck !== null) {
      target.value === passwordCheck.value ? setHasMatchingPassword(true) : setHasMatchingPassword(false);
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Field
            name="password"
            id="password"
            as={PasswordField}
            sx={styles.inputField}
            autoComplete="new-password"
            label={t('Password_EnterNew')} // Translated text
            helperText={passwordMeta.touched && passwordMeta.error}
            error={Boolean(passwordMeta.touched && passwordMeta.error)}
            onKeyUp={handleValidPassword}
          />
          <Field
            name="passwordConfirm"
            id="passwordConfirm"
            as={PasswordField}
            sx={styles.inputField}
            autoComplete="new-password"
            label={t('Password_ConfirmNew')} // Translated text
            helperText={passwordConfirmMeta.touched && passwordConfirmMeta.error}
            error={Boolean(passwordConfirmMeta.touched && passwordConfirmMeta.error)}
            onKeyUp={handlePasswordMatch}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={0}>
        <Grid item xs={6}>
          <Typography id="lowerCase" sx={hasLowerCase ? styles.valid : styles.invalid}>
            &#x2022; {oneLower}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography id="upperCase" sx={hasUpperCase ? styles.valid : styles.invalid}>
            &#x2022; {oneUpper}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography id="number" sx={hasNumber ? styles.valid : styles.invalid}>
            &#x2022; {oneNumber}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography id="specialChar" sx={hasSpecialChar ? styles.valid : styles.invalid}>
            &#x2022; {oneSpecial}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography id="minLength" sx={hasMinChar ? styles.valid : styles.invalid}>
            &#x2022; {atLeastEight}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography id="match" sx={hasMatchingPassword ? styles.valid : styles.invalid}>
            &#x2022; {mustMatch}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  inputField: {
    marginY: 2,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '& .MuiInputLabel-root': {
      color: 'red'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    },
    '.MuiFormHelperText-root': {
      color: '#d32f2f'
    }
  },
  invalid: {
    color: '#d32f2f'
  },
  valid: {
    color: '#00BB31'
  }
};
