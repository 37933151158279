import React from 'react';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

/**
 * This interface defines the properties used for configuring the StationNameNumberCell component.
 *
 * @property {string} title - The main title text to be displayed within the StationNameNumberCell.
 *
 * @property {string} subtitle - The subtitle text to be displayed within the StationNameNumberCell.
 *
 * @property {BoxProps} [boxProps] - Optional properties for customizing the Box component wrapped around the StationNameNumberCell.
 *
 * @property {TypographyProps} [titleTypographyProps] - Optional properties for customizing the Typography component for the title.
 *
 * @property {TypographyProps} [subtitleTypographyProps] - Optional properties for customizing the Typography component for the subtitle.
 */
export interface StationNameNumberCellProps {
  title: string;
  subtitle: string;
  boxProps?: BoxProps;
  titleTypographyProps?: TypographyProps;
  subtitleTypographyProps?: TypographyProps;
}

/**
 * StationNameNumberCell is a functional React component that displays a title and a subtitle
 * within a flexible box layout. The component allows customization of the box and
 * typography properties.
 *
 * @param {object} props - The properties object.
 * @param {string} props.title - The title text to display.
 * @param {string} props.subtitle - The subtitle text to display.
 * @param {object} [props.boxProps] - Additional properties for the container Box component.
 * @param {object} [props.subtitleTypographyProps] - Additional properties for the subtitle Typography component.
 * @param {object} [props.titleTypographyProps] - Additional properties for the title Typography component.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const StationNameNumberCell: React.FC<StationNameNumberCellProps> = ({
  title,
  subtitle,
  boxProps,
  subtitleTypographyProps,
  titleTypographyProps
}: StationNameNumberCellProps): React.ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
        padding: 2
      }}
      {...boxProps}
    >
      <Typography
        fontSize={'14px'}
        variant="body1"
        sx={{
          fontWeight: 500,
          color: 'black',
          textAlign: 'left',
          marginBottom: '0px',
          lineHeight: '24px',
          ...titleTypographyProps?.sx
        }}
        {...titleTypographyProps}
      >
        {title}
      </Typography>
      <Typography
        fontSize={'14px'}
        variant="body2"
        sx={{
          fontWeight: 400,
          lineHeight: '20.02px',
          color: 'grey',
          textAlign: 'left',
          marginTop: '0px',
          ...subtitleTypographyProps?.sx
        }}
        {...subtitleTypographyProps}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default StationNameNumberCell;
