import React, { useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import theme from 'data/theme';
import ProductCard from 'features/QuikSpec/Components/UiParts/ProductCard';
import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';
import useQuantityChange from '../../Hooks/useQuantityChange';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import RadioButton from './QuikspecRadioButton';

interface Props {
  categories: QuikspecCategory[];
}

const QuikspecCategoryNhxPatient = ({ categories }: Props) => {
  const defaultItemsPerPage = 4;
  const [page] = React.useState(1);
  const [selectedTab] = useState(0);
  const [selectedRadioItem] = useState<string>('');
  const { directChangeQuantity, directGetQuantity } = useQuantityChange(selectedRadioItem, false, 1);

  // Control for Bathroom Pull Cords
  const [excludeBathroomPullCord, setExcludeBathroomPullCord] = useState<boolean>();
  useEffect(() => {
    if (excludeBathroomPullCord === undefined) {
      if (directGetQuantity('EXCLUDE_BATHROOM_PULL_CORD') > 0) {
        setExcludeBathroomPullCord(true);
      } else {
        setExcludeBathroomPullCord(false);
      }
    } else {
      if (excludeBathroomPullCord) {
        directChangeQuantity('EXCLUDE_BATHROOM_PULL_CORD', '1');
      } else {
        directChangeQuantity('EXCLUDE_BATHROOM_PULL_CORD', '0');
      }
    }
  }, [excludeBathroomPullCord]);

  // Control for Dual Bulb Corridor Lamps
  const [excludeCorridorLamp, setExcludeCorridorLamp] = useState<boolean>();
  useEffect(() => {
    if (excludeCorridorLamp === undefined) {
      if (directGetQuantity('EXCLUDE_CORRIDOR_LAMP') > 0) {
        setExcludeCorridorLamp(true);
      } else {
        setExcludeCorridorLamp(false);
      }
    } else {
      if (excludeCorridorLamp) {
        directChangeQuantity('EXCLUDE_CORRIDOR_LAMP', '1');
      } else {
        directChangeQuantity('EXCLUDE_CORRIDOR_LAMP', '0');
      }
    }
  }, [excludeCorridorLamp]);

  return (
    <Box sx={styles.mainStationsBox}>
      <Grid container justifyContent="center" flexDirection={'column'} sx={styles.mainStationsTabGrid}>
        <Tabs value={selectedTab} variant="fullWidth" TabIndicatorProps={{ style: { display: 'none' } }}>
          {categories.map((product) => (
            <Tab key={product.id} label={product.title} sx={styles.mainStationsTab} />
          ))}
        </Tabs>

        <Grid container justifyContent="center">
          <Grid item xs={12} sx={styles.categoryDescription}>
            <Typography variant="h6" component={'h6'} sx={styles.descriptionContainer}>
              {categories[selectedTab].description}
            </Typography>
          </Grid>
          {categories[selectedTab].items
            .slice(
              (page - 1) * (categories[selectedTab].itemsPerPage || defaultItemsPerPage),
              page * (categories[selectedTab].itemsPerPage || defaultItemsPerPage)
            )
            .map((item, index) => (
              <ProductCard item={item} key={index} index={index} />
            ))}
          <Grid>
            <Grid container paddingLeft={2}>
              <Grid item paddingTop={1} xs={1}>
                <img src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/NHR-7A.jpg`} height={80} alt="NHR-7/A" />
              </Grid>
              <Grid item paddingTop={1} paddingRight={1} xs={7}>
                Bathroom Pull Cords (NHR-7/A) required for
              </Grid>
              <Grid item xs={4}>
                <Grid item>
                  <RadioButton
                    value={'Required'}
                    label={'Patient stations only'}
                    checked={false === excludeBathroomPullCord}
                    onChange={() => setExcludeBathroomPullCord(false)}
                  />
                </Grid>
                <Grid item>
                  <RadioButton
                    value={'Not Required'}
                    label={'None required'}
                    checked={true === excludeBathroomPullCord}
                    onChange={() => setExcludeBathroomPullCord(true)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container paddingLeft={2}>
              <Grid item paddingTop={1} xs={1}>
                <img src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/NHR-4AA.jpg`} height={80} alt="NHR-4A/A" />
              </Grid>
              <Grid item paddingTop={1} paddingRight={1} xs={7}>
                Dual Bulb Corridor Lamps (NHR-4A/A) required for
              </Grid>
              <Grid item xs={4}>
                <Grid item>
                  <RadioButton
                    value={'Required'}
                    label={'Patient stations only'}
                    checked={false === excludeCorridorLamp}
                    onChange={() => setExcludeCorridorLamp(false)}
                  />
                </Grid>
                <Grid item>
                  <RadioButton
                    value={'Not Required'}
                    label={'None required'}
                    checked={true === excludeCorridorLamp}
                    onChange={() => setExcludeCorridorLamp(true)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainStationsBox: {
    my: 4,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  mainStationsTabGrid: {
    my: 2,
    width: '95%',
    mx: 'auto'
  },
  mainStationsTab: {
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: '0',
    '&:first-of-type': {
      borderTopLeftRadius: '7px',
      borderBottomLeftRadius: '7px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '7px',
      borderBottomRightRadius: '7px'
    },
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f0f0f0',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#d0d0d0',
      opacity: 1
    }
  },
  categoryDescription: {
    my: 2,
    py: 2
  },
  descriptionContainer: {
    color: '#2c3e50',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 500
  }
};

export default QuikspecCategoryNhxPatient;
