import React from 'react';
import { Box, Typography, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import CallGroup from './CallGroup';
import ContactInput from './ContactInput';
import CallPriority from './CallPriority';

interface IOptionType {
  value: string;
  label: string;
}

interface ICallPriorityOptionType {
  value: number;
  label: string;
}
interface IDestinationProps {
  isUpdating: boolean;
  needSave: boolean;
  callDropDownList: {
    contactGroupList: IOptionType[];
    callGroupList: IOptionType[];
    callPriorityList: ICallPriorityOptionType[];
  };
  setNeedSave: (value: boolean) => void;
  formData: {
    contactInputValue: string;
    callGroupValue: string;
    callPriorityValue: number;
  };
  onChange: (fieldName: string, value: number | string) => void;
  onSave: () => void;
}

const ControlPanel = ({
  isUpdating,
  needSave,
  setNeedSave,
  formData,
  onChange,
  callDropDownList,
  onSave
}: IDestinationProps) => {
  const handleUpdateCallDestination = () => {
    onSave();
  };

  return (
    <>
      <Box sx={styles.controlPanelWrapper}>
        <Card sx={styles.leftPanelWrapper}>
          <Box sx={styles.callGroupWrapper}>
            <ContactInput
              setNeedSave={setNeedSave}
              fieldName="contactInputValue"
              value={formData.contactInputValue}
              contactGroupList={callDropDownList.contactGroupList}
              onChange={onChange}
            />
          </Box>
          <Typography>will call</Typography>
          <PhoneForwardedOutlinedIcon />
          <Box sx={styles.callGroupWrapper}>
            <CallGroup
              setNeedSave={setNeedSave}
              fieldName="callGroupValue"
              value={formData.callGroupValue}
              callGroupList={callDropDownList.callGroupList}
              onChange={onChange}
            />
          </Box>
          <Typography>with priority</Typography>
          <Box sx={styles.callGroupWrapper}>
            <CallPriority
              setNeedSave={setNeedSave}
              fieldName="callPriorityValue"
              value={formData.callPriorityValue}
              callPriorityList={callDropDownList.callPriorityList}
              onChange={onChange}
            />
          </Box>
        </Card>
        <Box sx={styles.saveChangesWrapper}>
          <LoadingButton
            variant="outlined"
            loading={isUpdating}
            disabled={isUpdating || !needSave}
            onClick={handleUpdateCallDestination}
          >
            Save changes
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  controlPanelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100px'
  },
  leftPanelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '80%',
    overflow: 'auto',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#e9e9e9', // Background color on hover
      color: 'black' // Text color on hover
    }
  },
  callGroupWrapper: {
    width: '25%',
    minWidth: '150px',
    height: '60px',
    margin: '10px'
  },
  saveChangesWrapper: {
    display: 'flex',
    flexDirection: 'flex-end'
  }
};

export default ControlPanel;
