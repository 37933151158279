import { Card, Box, Checkbox, FormControlLabel, List, CardHeader, DialogContent } from '@mui/material';
import { IUnit } from 'store/slices/unitsSlice';
import theme from 'data/theme';
import Tooltip from '@mui/material/Tooltip';
import UnitAddressBook from './UnitAddressBook';
import { ICallDirectoryList } from '../entranceDisplay/EntranceDisplay';
import { UUID } from 'crypto';

interface IUnitAddressBookListProps {
  unitAddressBookList: IUnit[];
  callDirectoryList: ICallDirectoryList[];
  isSelectAll: boolean;
  onSelectUnit: (id: string) => void;
  toggleSelectAll: () => void;
  removeUnitFromCallDirectory: (id: UUID) => void;
}

const UnitAddressBookList = ({
  unitAddressBookList,
  callDirectoryList,
  onSelectUnit,
  toggleSelectAll,
  removeUnitFromCallDirectory
}: IUnitAddressBookListProps) => {
  const displayUnit = (arr: IUnit[]) => {
    const callDirectoryIds = callDirectoryList?.map((unit) => unit.unitPublicId);

    // remove the unit with no unit number or undefined
    arr = arr.filter((unit) => unit && unit.unitNumber);

    // sort the unit by unit number in ascending order
    const sortedArr = arr.sort((a, b) => Number(a.unitNumber) - Number(b.unitNumber));
    return sortedArr?.map((unit: IUnit) => {
      return (
        <UnitAddressBook
          key={unit.publicId}
          unit={unit}
          isSelected={callDirectoryIds?.includes(unit.publicId)}
          handleSelect={onSelectUnit}
          removeUnitFromCallDirectory={removeUnitFromCallDirectory}
        />
      );
    });
  };

  if (!unitAddressBookList?.length) {
    return (
      <Card style={styles.addedDeviceWrapper}>
        <Box style={styles.noDevicesWrapper}>No items in Address book</Box>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title="Units in Address Book"
        titleTypographyProps={{
          align: 'center',
          sx: {
            width: '100%',
            justifyContent: 'center'
          }
        }}
      />

      <Box sx={{ backgroundColor: theme.palette.neutral.medium, paddingX: '10px' }}>
        {unitAddressBookList?.length > 0 && (
          <Tooltip title="Select a unit to include in the call directory" arrow>
            <FormControlLabel
              sx={{ paddingLeft: '45px' }}
              control={
                <Checkbox
                  size="small"
                  checked={callDirectoryList.length === unitAddressBookList.length}
                  onChange={toggleSelectAll}
                />
              }
              label="Select All"
            />
          </Tooltip>
        )}
        <DialogContent sx={styles.scrollableWrapper}>
          <List sx={styles.newDevicesWrapper}>
            <Box>{displayUnit(unitAddressBookList)}</Box>
          </List>
        </DialogContent>
      </Box>
    </Card>
  );
};

const styles = {
  addedDeviceWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center'
  },
  noDevicesWrapper: {
    marginTop: '50px'
  },
  newDevicesWrapper: {
    marginBottom: '25px',
    overflowY: 'auto'
  },
  multiselectWrapper: {
    display: 'flex',
    paddingLeft: '20px',
    backgroundColor: theme.palette.neutral.light,
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    height: '50px'
  },
  newDevicesTitle: {
    marginLeft: '15px'
  },
  scrollableWrapper: {
    height: '600px',
    overflow: 'auto'
  }
};

export default UnitAddressBookList;
