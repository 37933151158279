import React, { useContext, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { forgotPassword } from 'shared/api/Aws/authApi';
import { useNavigate } from 'react-router-dom';
import useValidationSchemas from 'features/Auth/validationSchemas';
import { isApiError } from 'shared/api/ApiError';
import { getCognitoErrorMessage } from '../../utils/cognitoOperations';
import SnackbarAlert from '../../../../shared/components/SnackbarAlert';
import { AuthContext } from 'context/AuthContext';
import { useTranslation } from 'react-i18next'; // Import useTranslation for i18n

const RequestReset: React.FC = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const navigate = useNavigate();
  const { setUsername } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { resetPWDValidation } = useValidationSchemas();

  const handleRequestReset = async (values: { email: string }) => {
    const formattedEmail = values.email.toLowerCase();
    try {
      const forgotPasswordPayload = {
        userData: {
          email: formattedEmail
        }
      };
      setUsername(formattedEmail);
      await forgotPassword(forgotPasswordPayload);
      setSuccessMessage(t('Password_Reset_LinkSent')); // Using translation for messages
      setTimeout(() => navigate('/auth/login'), 4000);
    } catch (error: unknown) {
      if (isApiError(error)) {
        const errorMessages = getCognitoErrorMessage(error.code);
        setErrorMessage(errorMessages);

        if ('InvalidParameterException' === error.code) {
          setErrorMessage(t('EmailNotVerified'));
          setTimeout(() => {
            navigate('/auth/confirm-account');
          }, 5000);
        }
      } else {
        setErrorMessage(t('Password_Reset_Error'));
      }
    }
  };

  return (
    <>
      <SnackbarAlert
        type="error"
        time={10000}
        text={errorMessage}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
      <SnackbarAlert
        type="success"
        time={10000}
        text={successMessage}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage('')}
      />
      <Formik initialValues={{ email: '' }} validationSchema={resetPWDValidation} onSubmit={handleRequestReset}>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="email"
                  as={TextField}
                  sx={styles.inputField}
                  label={t('Email_Address')} // Translated label
                  helperText={touched.email && errors.email}
                  error={touched.email && !!errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  type="submit"
                  sx={styles.submitButton}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  variant="aiphoneOrange"
                  loadingIndicator={<CircularProgress size="20px" color="white" />}
                >
                  {t('Reset_Password')}
                </LoadingButton>
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={styles.forgotPasswordButton}
                  onClick={() => navigate('/auth/login')}
                  disabled={isSubmitting}
                >
                  {t('Return_To_Login')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  submitButton: {
    fontSize: '1.5rem',
    fontWeight: '700',
    borderRadius: '0.25 rem',
    width: '100%'
  },
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '.MuiFormHelperText-root': {
      color: '#d32f2f'
    }
  },
  forgotPasswordButton: {
    float: 'right',
    color: '#0071CE',
    fontFamily: "'Roboto Condensed', sans-serif",
    fontWeight: 'bold'
  }
};

export default RequestReset;
