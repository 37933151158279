import React from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';

interface IOptionType {
  value: number;
  label: string;
}

type RingCountProps = {
  fieldName: string;
  value: number;
  onChange: (fieldName: string, value: string | number) => void;
  setNeedSave: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'string' | 'number';
};

const strings = {
  title: 'Ringback Tone Count',
  description: 'The number of times the Ringback Tone plays when the call button is pressed.'
};

const RingCount = ({ fieldName, value, onChange, setNeedSave, type }: RingCountProps) => {
  const dropdownOptions: IOptionType[] = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
    { value: -1, label: 'Infinite' }
  ];

  const handleRingCountChange = (event: React.SyntheticEvent<Element, Event>, option: IOptionType | null) => {
    if (option !== null) {
      setNeedSave(true);
      if (type === 'number' && typeof option.value === 'number') {
        onChange(fieldName, option.value);
      }
    }
  };

  return (
    <Box sx={styles.ringCountSettingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{strings.title}</Box>
        <Box sx={styles.description}>{strings.description}</Box>
      </Box>
      <Box sx={styles.ringCountWrapper}>
        <Autocomplete
          value={dropdownOptions.find((option) => option.value === value) || null}
          defaultValue={dropdownOptions[0]}
          options={dropdownOptions}
          getOptionLabel={(option) => option.label}
          sx={{ width: '100%' }}
          onChange={handleRingCountChange}
          renderInput={(params) => <TextField {...params} label="Ring Count" />}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      </Box>
    </Box>
  );
};
const styles = {
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  ringCountWrapper: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  ringCountSettingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#e9e9e9', // Background color on hover
      color: 'black' // Text color on hover
    }
  },
  formControl: {
    width: '275px'
  },
  playIcon: {
    fontSize: '50px'
  },
  controlIcons: {
    fontSize: '30px'
  },
  iconButtonsWrapper: {}
};
export default RingCount;
