/**
 * FeedbackFAB.tsx
 * @description This file contains the FeedbackFAB component.
 * Addresses: https://aiphone-corporation.atlassian.net/browse/RM-426
 */
import FeedbackIcon from '@mui/icons-material/Feedback';
import React from 'react';
import 'styles/frontshine.css';
import { Tooltip } from '@mui/material';
import { BaseFAB } from './BaseFAB';

//  This implementation includes the Tooltip that says "Submit Feedback" when hovering over the FAB.

/**
 * @function FeedbackFAB
 * @desc A floating action button that opens a feedback form.
 * @returns {JSX.Element} The FeedbackFAB component.
 */
export const FeedbackFAB = (): JSX.Element => {
  return (
    <BaseFAB
      color="primary"
      variant={'extended'}
      aria-label={'Feedback'}
      onClick={() => {
        window.open('https://forms.office.com/r/sCQGkqNFxG', '_blank');
      }}
    >
      <Tooltip placement={'top'} title={'Submit Feedback'}>
        <FeedbackIcon className={'text-white'} />
      </Tooltip>
    </BaseFAB>
  );
};
