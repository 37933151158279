import { FormValues } from '../Types/QuikspecFormTypes';

export const updateFormValues = (values: FormValues) => {
  const description = values.quikspecName; //Job Name,Quikspec Name,Description are synonymous
  // Set & update FormValues to match some C2 fields

  // Save values on UI before updating
  values.itemsOnUI = JSON.parse(JSON.stringify(values.items));

  const items = Object.values(values.items);

  // Update quantities of main products by accumulating accessories
  items.map((item: any) => {
    if (item.accessories && item.accessories.length > 0) {
      const accessoryQuantities = item.accessories.map((accessory: any) => accessory.quantity);
      const totalAccessoryQuantity = accessoryQuantities.reduce((accumulator: number, currentValue: number) => {
        return accumulator + currentValue;
      }, 0);
      item.quantity = totalAccessoryQuantity;
    }
    return item;
  });

  // Update quantities of accessories themselves
  items.map((item: any) => {
    if (item.accessories && item.accessories.length > 0) {
      item.accessories.map((accessory: any) => {
        const itemIndex = items.findIndex((item) => item.itemName === accessory.modelName);
        if (itemIndex >= 0) {
          items[itemIndex].quantity += accessory.quantity;
        }
      });
    }
    return item;
  });
  values = {
    ...values,
    description,
    lastUpdateDate: formatDate(new Date()),
    userEmail: values.userEmail
  };
  //return values in order to reflect change outside of function
  return values;
};

function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero based
  const day = ('0' + date.getDate()).slice(-2);
  const hour = ('0' + date.getHours()).slice(-2);
  const minute = ('0' + date.getMinutes()).slice(-2);
  const second = ('0' + date.getSeconds()).slice(-2);

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}
