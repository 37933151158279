import { Box, /*Button,*/ Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { QRCodeSVG } from 'qrcode.react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

const AppsQr = () => {
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const unitPublicId = useParams().unitid || '';
  const selectedUnit = unitList[unitPublicId];
  const unitQRCode = selectedUnit?.unitAwsRoomAuthCode || 'XXXXXXXXXXXXXXXXXXXXXXXX'; // placeholder QR code
  const selectedUnitType = selectedUnit?.unitType || '';
  const { t } = useTranslation();

  const [isQrDialogOpen, setIsQrDialogOpen] = useState(false);

  const handleClose = () => {
    setIsQrDialogOpen(false);
  };

  const QRDialog = () => (
    <Dialog open={isQrDialogOpen} onClose={handleClose} style={styles.qrCodeModal} fullWidth maxWidth="md">
      <DialogTitle style={styles.qrCodeModal}>Unit QR Code</DialogTitle>
      <span style={styles.qrCodeDisplay}>
        <QRCodeSVG value={unitQRCode} size={300} level="H" />
      </span>
      <Typography variant="caption" textAlign="center">
        {t('AppsQr.scanMessage')}
      </Typography>
    </Dialog>
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={styles.qrCodeWrapper} onClick={() => setIsQrDialogOpen(true)}>
            <QRCodeSVG value={unitQRCode} style={styles.qrCodeWrapper} level="H" />
          </Box>
          <Box sx={styles.qrCodeTextWrapper}>
            <Typography variant="caption" textAlign="center">
              {t('AppsQr.codeMessage')}
              <br />
              {selectedUnitType === 4 ? t('AppsQr.tenantApp') : t('AppsQr.appUser')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <QRDialog />
    </>
  );
};

const styles = {
  qrCodeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
  },
  qrCodeModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    AlignHorizontalCenter: 'center',
    margin: '10px'
  },
  qrCodeTextWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    padding: '10px'
  },
  qrCodeTitle: {
    display: 'flex',
    justifyContent: 'center'
  },
  qrCodeDisplay: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px'
  }
};

export default AppsQr;
