import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';
import { updateItemQuantityWithAccessoriesAC } from './QuikspecUpdateFormValuesAC';
import { updateItemQuantityWithAccessoriesIX } from './QuikspecUpdateFormValuesIX';

export const updateFormValuesIXIXG = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesIX(values);
  updateItemQuantityWithAccessoriesAC(values);
  updateItemQuantityWithAccessoriesIXIXG(values);
  updateWiresIXIXG(values);
  updateNotesIXIXG(values);
  return values;
};

const updateItemQuantityWithAccessoriesIXIXG = (values: FormValues) => {
  const items = Object.values(values.items);

  // Gateway adapter and app lifetime license
  const IXGWTGWIndex = items.findIndex((item) => item.itemName === 'IXGW-TGW');
  const IXGAPPIndex = items.findIndex((item) => item.itemName === 'IXG-APP');
  const lifetimeLicenseIndex = items.findIndex((item) => item.itemName === 'LIFETIME_LICENSE');

  // When app is needed, gateway adapter is required
  if (items[IXGAPPIndex].quantity > 0 && items[IXGWTGWIndex].quantity === 0) {
    items[IXGWTGWIndex].quantity = 1;
  }

  // When lifetime license is not needed, IXG-APP is not spec-ed in
  if (items[lifetimeLicenseIndex].quantity === 0) {
    items[IXGAPPIndex].quantity = 0;
  }

  items[lifetimeLicenseIndex].quantity = 0;
};

const updateNotesIXIXG = (values: FormValues) => {
  values.notes.push(
    'Network devices required to connect an IX | IXG system are not included in this specification and must be purchased separately. Not available from Aiphone.'
  );
  values.notes.push(
    'The IX | IXG stations are PoE powered only. Please ensure all devices are connected to PoE switches or injectors. For additional information contact your Network Administrator or Aiphone Technical Support at (800) 692-0200.'
  );
  values.notes.push(
    'Door strikes and their power sources are not included in this quote and must be purchased separately. Not available from Aiphone.'
  );
  values.notes.push(
    "An amplifier or self amplified speaker can be connected to the 600 ohm output on the back of an IX-EA, IX-DV, IX-DVF, IX-DVF-4, IX-DVF-P, IX-DVF-HW, IX-DVF-RA, IX-DVF-2RA, IX-SSA-RA, IX-SSA-2RA, IX-SSA to increase outgoing volume. Communication from the door station to the master will still be transmitted from the door station's microphone. The IX-DVM and IX-SS-2G do not have the hardware to support this feature."
  );

  if (
    values.items
      .filter((item) => item.itemName === 'IXGW-LC')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'An IXGW-LC has been added to the parts list. This adaptor is a requirement when elevator/lift control is required.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IXGW-TGW')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'An IXGW-TGW has been added to the parts list. This adaptor is a requirement when utilizing the IXG Mobile Application.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IXGW-LC-RY20')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push('An IXGW-LC-RY20 has been added to the parts list. This adaptor allows for secure door release.');
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IX-SOFT')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The IX-SOFT is delivered via a USB dongle that must remain plugged into the computer using the software. No replacements will be made available if lost.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IX-SOFT')
      ?.reduce((total: number, item) => total + item.quantity, 0) >= 2
  ) {
    values.notes.push(
      'The IX-SOFT is also available in bundles with 3, 5, or 10 licensed USB dongles at a discounted price. Please choose the appropriate sized bundle(s) based on your requirements. Model numbers are IX-SOFT-3, IX-SOFT-5, and IX-SOFT-10.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IX-RS-B')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'IX-RS-W')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      "Ceiling speaker's 8 ohm direct input connected to the IX-RS provides approximately 500mW of output. For large rooms and/or noisy environments, a self amplified speaker may be preferred. Contact Technical Support for additional assistance."
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IX-DVF-L')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'A PS-2420UL has been added to the specification for each IX-DVF-L. The IX-DVF-L requires 24V DC power from a separate PS-2420UL power supply for T-coil functionality.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IXW-MA')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'An IXW-MA has been added to the parts list. This adaptor is a requirement when utilizing remote door release relays.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IXW-MAA')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'An IXW-MAA has been added to the parts list. This adaptor is a requirement when utilizing remote door release relays.'
    );
  }
};

const updateWiresIXIXG = (values: FormValues) => {
  values.wires.push(
    {
      system: 'IX | IXG Units Network Drop',
      wire: 'CAT-5e/6',
      description: 'UTP4 non-shielded Category 5e/6 cable (Not Available from Aiphone)',
      maxDistance: 'Per Spec',
      jacketNomOD: 'Per Spec',
      capacitanceLoopRes: 'Per Spec'
    },
    {
      system: 'Contact to Strike',
      wire: '821802',
      description: '2 Cond.,18AWG, Shielded, Available in: 1000’',
      maxDistance: 'N/A',
      jacketNomOD: 'PVC .185"',
      capacitanceLoopRes: '55 pf/ft 6.45 Ohms'
    }
  );
};
