import { getString } from 'shared/utils/LocalizationUtils';

/* Building Page tab stub */
export const BuildingPageLabel = () => {
  const buildingPageLabel = getString('Building_Page');
  return <span>{buildingPageLabel}</span>;
};

const BuildingPage = () => {
  const buildingPageLabel = getString('Building_Page');
  return <span>{buildingPageLabel}</span>;
};

export default BuildingPage;
