import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { RootState } from 'store';

interface Props {
  category: string;
}

const AddDevicesTabContent = ({ category }: Props) => {
  const devices = useSelector((state: RootState) => state.devices);
  const categoryDevices = devices.DeviceListByType[category];

  // Getting rid of duplicates based on the same MAC Address
  const uniqueCategoryDevices = categoryDevices.filter(
    (deviceId, index, self) =>
      self.findIndex(
        (id) => devices.DeviceList[id].basicInfo.macAddress === devices.DeviceList[deviceId].basicInfo.macAddress
      ) === index
  );
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {uniqueCategoryDevices.map((deviceId) => {
          const device = devices.DeviceList[deviceId];
          const modelNumber = getDeviceModelNumberFromModelType(
            device.basicInfo.deviceModel,
            device.basicInfo.deviceType
          );
          const imageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${modelNumber}.png`;

          return (
            <Grid item xs={4} key={device.publicId}>
              <Card sx={{ position: 'relative' }} key={device.publicId}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: 'clamp(16px, 5vw, 24px)' }}>
                    {device.basicInfo.macAddress}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Model Number: </strong>
                        <br />
                        {modelNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={styles.centerContent}>
                        <img src={imageUrl} alt={device.basicInfo.macAddress} />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
};

export default AddDevicesTabContent;
