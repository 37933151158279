import { useState } from 'react';
import { Box } from '@mui/material';
import AppHeader from '../../layouts/AppHeader';
import SideBar from '../../layouts/SideBar';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SimBillingSideBar from 'layouts/SimBillingSideBar';

function Dashboard() {
  const location = useLocation();

  let renderSidebar = true; // Default initialization
  let SidebarToRender = SideBar; // Default initialization

  const isDevicePage = /^\/site\/[a-zA-Z0-9-]+\/devices\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]+)?$/.test(location.pathname);
  const isUnitPage = /^\/site\/[a-zA-Z0-9-]+\/units\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]+)?$/.test(location.pathname);
  const isUnitSubPage = /^\/site\/[a-zA-Z0-9-]+\/units\/[a-zA-Z0-9-]+\/(qr&accesscodes)$/.test(location.pathname);
  const isSimBillingPage = /^\/simbilling\/sites/.test(location.pathname);
  const isWizardPage = /^\/site\/[a-zA-Z0-9-]+\/wizard\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]+)?$/.test(location.pathname);
  const isSitePage = /^\/site\/[a-zA-Z0-9-]+\/?$/.test(location.pathname);
  const isSiteSubPage =
    /^\/site\/[a-zA-Z0-9-]+\/(siteinfo|answering-stations|door-stations|entrance-stations|gateway-adaptors|io-adaptors|lift-control-adaptors|tenants|users|units|apps|adaptors|settings|maps|sync|wizard)$/.test(
      location.pathname
    );

  if (isDevicePage || isUnitPage || isUnitSubPage || isSiteSubPage || isWizardPage || isSitePage) {
    renderSidebar = false;
  } else if (isSimBillingPage) {
    SidebarToRender = SimBillingSideBar;
    renderSidebar = true;
  }

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  if (document.getElementById('wholeSections') !== null) {
    document.getElementById('wholeSections').scrollTop = 0;
  }

  return (
    <>
      <AppHeader collapsed={collapsed} setCollapsed={setCollapsed} toggled={toggled} setToggled={setToggled} />
      <Box id="wholeSections" sx={styles.container}>
        {renderSidebar ? (
          <>
            <SidebarToRender collapsed={collapsed} toggled={toggled} />{' '}
            <Box component={'main'} sx={styles.mainSection}>
              <Outlet />
            </Box>
          </>
        ) : (
          <Outlet />
        )}
      </Box>
    </>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  container: {
    display: 'flex',
    bgcolor: 'neutral.light',
    height: 'calc(100% - 64px)',
    overflow: 'auto'
  },
  mainSection: {
    p: 4,
    width: '100%',
    height: '100%'
  }
};

export default Dashboard;
