import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface ITenantsControlPanelProps {
  setIsOpen: (value: boolean) => void;
  resetFilters: () => void;
  showResetButton?: boolean;
}

const TenantsControlPanel = ({ setIsOpen, resetFilters, showResetButton }: ITenantsControlPanelProps) => {
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const { isAllowedTo } = usePermission();
  const canTenantCreate = isAllowedTo('tenant:create', sitePublicId, PermissionsContextType.SITE);

  const strings = {
    title: 'Tenants',
    description:
      'Add, edit, and delete tenants for your site. The primary tenant designation determines the phone number used for telephone entry and the name displayed in the IXG-DM7 directory.'
  };

  const handleAdd = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Box style={styles.tenantsControlPanelWrapper}>
        <Box sx={styles.tenantsDescriptionWrapper}>
          <Box sx={styles.tenantsTitle}>{strings.title}</Box>
          <Box sx={styles.tenantsDescription}> {strings.description}</Box>
        </Box>
        <Box style={styles.tenantsButtonsWrapper}>
          <Box sx={styles.tenantsButton}>
            {showResetButton && (
              <Button variant="contained" color="error" onClick={resetFilters}>
                Remove Filters
              </Button>
            )}
          </Box>
          {canTenantCreate && (
            <Box sx={styles.tenantsButton}>
              <LoadingButton variant="contained" onClick={handleAdd}>
                Add a tenant
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

const styles = {
  tenantsControlPanelWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tenantsDescriptionWrapper: {
    width: '100%'
  },
  tenantsTitle: {
    fontSize: '25px',
    fontWeight: 'bold'
  },
  tenantsDescription: {},
  tenantsButtonsWrapper: {
    display: 'flex',
    width: '50%',
    justifyContent: 'end'
  },
  tenantsButton: {
    marginLeft: '20px'
  }
};

export default TenantsControlPanel;
