import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';

export const updateFormValuesNHX = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesNHX(values);
  updateWiresNHX(values);
  updateNotesNHX(values);
  return values;
};

const updateItemQuantityWithAccessoriesNHX = (values: FormValues) => {
  const items = Object.values(values.items);

  const NHX80XIndex = items.findIndex((item) => item.itemName === 'NHX-80X');
  const NHX30GIndex = items.findIndex((item) => item.itemName === 'NHX-30G');
  const NHR30KIndex = items.findIndex((item) => item.itemName === 'NHR-30K');
  const NHR8CIndex = items.findIndex((item) => item.itemName === 'NHR-8C');
  const NHR7AIndex = items.findIndex((item) => item.itemName === 'NHR-7A');
  const NHR4AAIndex = items.findIndex((item) => item.itemName === 'NHR-4A/A');
  const PS2420ULIndex = items.findIndex((item) => item.itemName === 'PS-2420UL');
  const bathroomIndex = items.findIndex((item) => item.itemName === 'EXCLUDE_BATHROOM_PULL_CORD');
  const corridorIndex = items.findIndex((item) => item.itemName === 'EXCLUDE_CORRIDOR_LAMP');
  const totalNH1SA = items
    .filter((item) => item.itemName === 'NH-1SA/A')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalNH2SA = items
    .filter((item) => item.itemName === 'NH-2SA/A')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalCommonAreaCallStations = items
    .filter((item) => item.itemName === 'NHR-3TS')
    .reduce((total: number, item) => total + item.quantity, 0);

  // Central Control Unit is always required
  items[NHX80XIndex].quantity = 1;

  // If total patient stations and common area call stations are more than 50, add-on is required
  if (totalNH1SA + totalNH2SA + totalCommonAreaCallStations > 50) {
    items[NHX30GIndex].quantity = 1;
    items[NHR30KIndex].quantity = 1;
  }

  // Bedside call cords
  items[NHR8CIndex].quantity = totalNH1SA + totalNH2SA * 2;

  // Bathroom pull cords
  if (items[bathroomIndex].quantity === 0) {
    items[NHR7AIndex].quantity = totalNH1SA + totalNH2SA + totalCommonAreaCallStations * 2;
  }

  // Dual LED corridor lamps
  if (items[corridorIndex].quantity === 0) {
    items[NHR4AAIndex].quantity = totalNH1SA + totalNH2SA;
  }

  // Power supply is always 2
  items[PS2420ULIndex].quantity = 2;

  items[bathroomIndex].quantity = 0;
  items[corridorIndex].quantity = 0;
};

const updateWiresNHX = (values: FormValues) => {
  values.wires.push(
    {
      system: 'CCU to Master',
      wire: '86221025A',
      description: "5 Pair, 22 AWG, Shielded, Low-cap, Available in: 250'",
      maxDistance: "65'",
      jacketNomOD: 'PVC .304"',
      capacitanceLoopRes: '12.5 pf/ft, 16.14 Ohms'
    },
    {
      system: 'Trunk Line',
      wire: '86220810',
      description: "4 Pair, 22 AWG, Shielded, Low-cap, Available in: 1000'",
      maxDistance: "330'",
      jacketNomOD: 'PVC .235"',
      capacitanceLoopRes: '12.5 pf/ft, 16.14 Ohms'
    },
    {
      system: 'Subs to Trunk Line',
      wire: '86220810',
      description: "4 Pair, 22 AWG, Shielded, Low-cap, Available in: 1000'",
      maxDistance: "330'",
      jacketNomOD: 'PVC .235"',
      capacitanceLoopRes: '12.5 pf/ft, 16.14 Ohms'
    }
  );

  if (
    values.items
      .filter((item) => item.itemName === 'NHR-SP')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'NHR-SP to NHX-80X',
      wire: '86220810',
      description: "4 Pair, 22 AWG, Shielded, Low-cap, Available in: 1000'",
      maxDistance: "65'",
      jacketNomOD: 'PVC .235"',
      capacitanceLoopRes: '12.5 pf/ft, 16.14 Ohms'
    });
  }

  if (
    values.items
      .filter((item) => item.itemName === 'NHR-7A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'NHR-4A/A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'NHR-7A or NHR-4A/A to Sub',
      wire: '822203',
      description: "3 Cond., 22AWG, Shielded, Available in: 500'",
      maxDistance: 'N/A',
      jacketNomOD: 'PVC .250"',
      capacitanceLoopRes: '48.2 pf/ft, 16.60 Ohms'
    });
  }

  values.wires.push({
    system: 'PS-2420UL to NHX-80X',
    wire: 'Any',
    description: 'Standard 2 Conductor, 18AWG',
    maxDistance: "16'",
    jacketNomOD: 'N/A',
    capacitanceLoopRes: 'N/A'
  });
};

const updateNotesNHX = (values: FormValues) => {
  if (
    values.items
      .filter((item) => item.itemName === 'NHR-7A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      "One NHR-7A has been included in your equipment list for each requested station in the system. You may include up to five NHR-7A's for each station."
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'NHR-4A/A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'One NHR-4A/A has been included in your equipment list for each requested station in the system. If not all stations require an NHR-4A/A, subtract that number from the total.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'NHR-3TS')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      "Two NHR-7A's have been included in your equipment list for each NHR-3TS in the system. You may include up to 10 NHR-7A's for each NHR-3TS (5 per bulb)."
    );
  }
};
