import React, { useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Login from 'shared/api/Acl/Login';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { areInputsEmpty, debounce } from 'shared/utils/helperFunctions';
import 'styles/frontshine.css';
import { Dots } from '../../../../../../values/HTMLEntities';
import { IXGPortalLoginFormStyles } from '../../../../../../styles/form-input';
import { checkemail } from '../../../../../../shared/api/Acl/IxgAclApi';

interface Props {
  setIsActivationDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRegisterDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const validationSchema = Yup.object({
  id: Yup.string()
    .max(22, 'ID must not exceed 22 characters')
    .matches(/^[a-zA-Z0-9]*$/, 'ID should not contain special characters')
    .required('Administrator ID is required.'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[A-Z]/, 'Password must include at least one uppercase letter')
    .matches(/[a-z]/, 'Password must include at least one lowercase letter')
    .matches(/\d/, 'Password must include at least one number')
    .required('Password is required.'),
  confirmPW: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required.'),
  email: Yup.string().email('Invalid email address').required('Email Address is required.')
});

const CreatePanel = ({ setIsActivationDialogOpen, setIsRegisterDialogOpen }: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState('');
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);

  const handleSignup = async (values: { id: string; password: string; email: string }) => {
    try {
      setIsSubmitting(true);

      // Double-check if the email is already in the system
      const emailResult = await checkemail(values.email);
      const resultJson: boolean = emailResult['Data']['IsDuplicated'];
      if (resultJson) {
        setEmailError('Email already exists');
        setIsSubmitting(false);
        return;
      }

      const result = await Login.signup(values.id, values.password, values.email);
      setResult(result);
      if (setIsRegisterDialogOpen) {
        setIsRegisterDialogOpen(false);
      }
      if (setIsActivationDialogOpen) {
        setIsActivationDialogOpen(true);
      }

      setIsSubmitting(false);
    } catch (e: any) {
      setIsSubmitting(false);
      setResult(e.toString());
    }
  };

  const handleClickShowPassword = (kind: 'normal' | 'confirm') => {
    const inputRef = kind === 'normal' ? passwordInputRef : confirmPasswordInputRef;
    const setShow = kind === 'normal' ? setShowPassword : setShowConfirmPassword;
    const show = kind === 'normal' ? showPassword : showConfirmPassword;

    if (inputRef.current) {
      const cursorPosition = inputRef.current.selectionStart;
      setShow(!show);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const checkEmailAndSetError = debounce(
    async (email: string, setFieldError: (field: string, message: string | undefined) => void) => {
      setIsCheckingEmail(true);
      if (email && email.length > 0) {
        const result = await checkemail(email);
        const resultJson: boolean = result['Data']['IsDuplicated'];
        if (resultJson) {
          setEmailError('Email already exists');
          setFieldError('email', 'Email already exists');
        } else {
          setEmailError(undefined);
          setFieldError('email', undefined);
        }
      }
      setIsCheckingEmail(false);
    },
    500 // 500ms debounce makes it feel more responsive
  );

  return (
    <Formik
      initialValues={{ id: '', password: '', confirmPW: '', email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSignup(values)}
    >
      {({ handleChange, handleBlur, values, errors, setFieldError, touched, isValid }) => (
        <Form className={'bg-grey'}>
          <Box className={'mt_20px bg-grey'}>
            <div className={'flex items-center justify-center bg-grey'}>
              <img src={'/aiphone_logo_black.png'} style={{ maxWidth: 150 }} alt={'Aiphone logo'} />
              <p className={'text-size_40px text-center no-vertical-margin'}>IXG Cloud</p>
            </div>
            <DialogTitle className={'text-center'}>Create a New Administrator ID</DialogTitle>
            <DialogContent className={'bg-grey'}>
              <Grid container direction={'column'} spacing={1} className={'space-y-0.5'}>
                <Grid item>
                  <InputLabel>
                    <span className={'font-bolder text-black'}>Administrator ID</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={values.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Administrator ID'}
                    id="id"
                    margin="dense"
                    sx={IXGPortalLoginFormStyles.inputField}
                    error={touched.id && Boolean(errors.id)}
                    helperText={touched.id && errors.id}
                    required
                  />
                </Grid>
                <Grid item>
                  <InputLabel>
                    <span className={'font-bolder text-black'}>Email Address</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    onBlurCapture={() => {
                      checkEmailAndSetError(values.email, setFieldError);
                    }}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Email Address'}
                    sx={IXGPortalLoginFormStyles.inputField}
                    id="email"
                    type="email"
                    error={emailError !== undefined}
                    helperText={emailError}
                    required
                    InputProps={{
                      endAdornment: isCheckingEmail ? (
                        <InputAdornment position={'end'}>
                          <CircularProgress size="25px" />
                        </InputAdornment>
                      ) : undefined
                    }}
                  />
                </Grid>
                <Grid item>
                  <InputLabel>
                    <span className={'font-bolder text-black'}>Password</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    inputRef={passwordInputRef}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={IXGPortalLoginFormStyles.inputField}
                    placeholder={`${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}`}
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={'end'}>
                          <IconButton
                            aria-label={'toggle password visibility'}
                            onClick={() => handleClickShowPassword('normal')}
                            onMouseDown={handleMouseDownPassword}
                            edge={'end'}
                            tabIndex={-1}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    required
                  />
                  <Typography variant="caption" color="textSecondary">
                    Password must be at least 8 characters long and include:
                    <ul>
                      <li>At least one uppercase letter</li>
                      <li>At least one lowercase letter</li>
                      <li>At least one number</li>
                    </ul>
                  </Typography>
                </Grid>
                <Grid item>
                  <InputLabel>
                    <span className={'font-bolder text-black'}>Confirm Password</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    inputRef={confirmPasswordInputRef}
                    value={values.confirmPW}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={IXGPortalLoginFormStyles.inputField}
                    placeholder={`${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}`}
                    id="confirmPW"
                    type={showConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={'end'}>
                          <IconButton
                            aria-label={'toggle password visibility'}
                            onClick={() => handleClickShowPassword('confirm')}
                            onMouseDown={handleMouseDownPassword}
                            edge={'end'}
                            tabIndex={-1}
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={touched.confirmPW && Boolean(errors.confirmPW)}
                    helperText={touched.confirmPW && errors.confirmPW}
                    required
                  />
                </Grid>
              </Grid>

              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting || areInputsEmpty(values) || emailError !== undefined}
                className={'w-full mt-4'}
              >
                {isSubmitting ? <CircularProgress size="25px" sx={{ color: 'white' }} /> : 'Create'}
              </Button>
              {
                // Display result message
                result.length > 0 && (
                  <Alert className={'mt-4'} severity={result.toLowerCase() === 'success' ? 'success' : 'error'}>
                    {result}
                  </Alert>
                )
              }
            </DialogContent>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CreatePanel;
