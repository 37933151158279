import React, { useEffect, useState } from 'react';
import { Box, Grid, Pagination, Tab, Tabs, Typography, Radio, FormControlLabel } from '@mui/material';
import theme from 'data/theme';
import ProductCardRadioButton from 'features/QuikSpec/Components/UiParts/ProductCardRadioButton';
import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';
import useQuantityChange from '../../Hooks/useQuantityChange';
import ProductCard from './ProductCard';
import { FormValues } from '../../Types/QuikspecFormTypes';
interface Props {
  categories: QuikspecCategory[];
  initialValues?: FormValues;
}

const QuikspecCategoryTabsRadioButtons = ({ categories, initialValues }: Props) => {
  const defaultItemsPerPage = 4;
  const [page, setPage] = React.useState(1);
  const [selectedTab, setSelectedTab] = useState(0);
  const initialProduct = initialValues?.items?.find((item) => {
    if (item.itemName === 'JOW-2D' && item.quantity > 0) {
      return true;
    }
    return false;
  })
    ? initialValues.items.find(
        (i) => i.itemName === 'JO-DVF' && i.accessories?.some((accessory) => accessory.quantity > 0)
      )
    : initialValues?.items?.find((item) => {
        return (
          (item.itemName === 'JV-DV' && item.quantity > 0) ||
          (item.itemName === 'JV-DVF' && item.accessories?.some((accessory) => accessory.quantity > 0)) ||
          (item.itemName === 'JO-DA' && item.quantity > 0) ||
          (item.itemName === 'JO-DV' && item.quantity > 0) ||
          (item.itemName === 'JO-DVF' && item.accessories?.some((accessory) => accessory.quantity > 0))
        );
      });
  const [selectedProduct, setSelectedProduct] = useState<string>(
    initialProduct ? initialProduct.itemName : categories[0].items[0].deviceName
  );
  const { handleQuantityChange } = useQuantityChange(selectedProduct, false, 1);

  useEffect(() => {
    if (initialValues && initialValues.items) {
      const initialProduct = initialValues.items.find((item) => {
        if (item.itemName === 'JOW-2D' && item.quantity > 0) {
          return true;
        }
        return false;
      })
        ? initialValues.items.find(
            (i) => i.itemName === 'JO-DVF' && i.accessories?.some((accessory) => accessory.quantity > 0)
          )
        : initialValues.items.find((item) => {
            return (
              (item.itemName === 'JV-DV' && item.quantity > 0) ||
              (item.itemName === 'JV-DVF' && item.accessories?.some((accessory) => accessory.quantity > 0)) ||
              (item.itemName === 'JO-DA' && item.quantity > 0) ||
              (item.itemName === 'JO-DV' && item.quantity > 0) ||
              (item.itemName === 'JO-DVF' && item.accessories?.some((accessory) => accessory.quantity > 0))
            );
          });

      if (initialProduct) {
        setSelectedProduct(initialProduct.itemName);
      }
    }
  }, [initialValues]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(1);
    setSelectedTab(newValue);
  };

  const handleRadioChange = (event: any) => {
    selectedProduct && handleQuantityChange('0');
    // Special case for JO-DVF since it comes with Accessories
    if (selectedProduct === 'JO-DVF') {
      handleQuantityChange('0', 'Surface Mount');
      handleQuantityChange('0', 'Flush Mount');
    }

    // Special case for JV-DVF since it comes with Accessories
    if (selectedProduct === 'JV-DVF') {
      handleQuantityChange('0', 'Surface Mount');
      handleQuantityChange('0', 'Flush Mount');
    }

    setSelectedProduct(event.target.value);
  };

  useEffect(() => {
    handleQuantityChange('1');
  }, [selectedProduct]);
  return (
    <Box sx={styles.mainStationsBox}>
      <Grid container justifyContent="center" flexDirection={'column'} sx={styles.mainStationsTabGrid}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {categories.map((product) => (
            <Tab key={product.id} label={product.title} sx={styles.mainStationsTab} />
          ))}
        </Tabs>

        <Grid container justifyContent="center">
          <Grid item xs={12} sx={styles.categoryDescription}>
            <Typography variant="h6" component={'h6'} sx={styles.descriptionContainer}>
              {categories[selectedTab].description}
            </Typography>
          </Grid>
          {categories[selectedTab].items
            .slice(
              (page - 1) * (categories[selectedTab].itemsPerPage || defaultItemsPerPage),
              page * (categories[selectedTab].itemsPerPage || defaultItemsPerPage)
            )
            .map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Radio
                    sx={styles.sampleform}
                    checked={selectedProduct === item.deviceName}
                    onChange={handleRadioChange}
                    value={item.deviceName}
                    name="product-selection"
                  />
                }
                label={
                  item.countWithAccessories ? (
                    <ProductCard item={item} index={index} isSelected={selectedProduct === item.deviceName} />
                  ) : (
                    <ProductCardRadioButton
                      item={item}
                      index={index}
                      isSelected={selectedProduct === item.deviceName}
                    />
                  )
                }
              />
            ))}
        </Grid>
        <Grid container justifyContent={'center'}>
          <Pagination
            count={Math.ceil(
              categories[selectedTab].items.length / (categories[selectedTab].itemsPerPage || defaultItemsPerPage)
            )}
            page={page}
            onChange={handleChange}
            color="secondary"
            sx={styles.pagination}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainStationsBox: {
    my: 4,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  mainStationsTabGrid: {
    my: 2,
    width: '95%',
    mx: 'auto'
  },
  mainStationsTab: {
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: '0',
    '&:first-of-type': {
      borderTopLeftRadius: '7px',
      borderBottomLeftRadius: '7px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '7px',
      borderBottomRightRadius: '7px'
    },
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f0f0f0',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#d0d0d0',
      opacity: 1
    }
  },
  categoryDescription: {
    my: 2,
    py: 2
  },
  descriptionContainer: {
    color: '#2c3e50',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 500
  },
  sampleform: {
    display: 'none'
  },
  pagination: {
    justifyContent: 'center',
    position: 'relative'
  }
};

export default QuikspecCategoryTabsRadioButtons;
