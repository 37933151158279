export enum Status {
  Online = 'Online',
  Synced = 'Synced',
  StandBy = 'Stand by',
  Success = 'Success',
  UpToDate = 'Up to date',
  Busy = 'Busy',
  Syncing = 'Syncing...',
  Waiting = 'Preparing',
  Checking = 'Checking',
  NeedsSync = 'Needs sync',
  NeedsAssociation = 'Device IP address incorrect',
  NeedsIPAddress = 'Needs IP address',
  NeedsUnit = 'Needs unit',
  NeedsStationNumber = 'Needs station number',
  NeedsAddressBook = 'Needs address book',
  NeedsCallList = 'Needs call list',
  NeedsUpdate = 'Needs update',
  Updating = 'Updating',
  Error = 'Error',
  Unknown = 'Unknown',
  Offline = 'Offline'
}

export const getColorFromStatus = (status: Status) => {
  switch (status) {
    case Status.Online:
    case Status.Synced:
    case Status.StandBy:
    case Status.Success:
    case Status.UpToDate:
      return 'success';

    case Status.Busy:
    case Status.NeedsSync:
    case Status.NeedsAssociation:
    case Status.NeedsIPAddress:
    case Status.NeedsUnit:
    case Status.NeedsStationNumber:
    case Status.NeedsAddressBook:
    case Status.NeedsCallList:
    case Status.NeedsUpdate:
      return 'warning';

    case Status.Syncing:
    case Status.Updating:
    case Status.Waiting:
    case Status.Checking:
      return 'info';

    case Status.Offline:
    case Status.Unknown:
    case Status.Error:
    default:
      return 'error';
  }
};

export const getColorFromComplete = (boolean) => {
  return boolean ? 'success' : 'error';
};

export const getIconColorFromComplete = (boolean) => {
  return boolean ? true : false;
};
