import { IDevice } from 'store/slices/devicesSlice';
import { getFirmwareModelNameFromDeviceType } from 'shared/utils/helperFunctions';
import { Status } from 'shared/utils/Status';
import { LatestFirmwareDownload } from 'features/RemoteManagement/Types';

const firmwareUrl = 'https://081gx2j0nf.execute-api.us-east-1.amazonaws.com/prod/firmware/download/';
const latestFirmwareUrl = 'https://081gx2j0nf.execute-api.us-east-1.amazonaws.com/prod/firmware/latest';

/** Type definition for the latest firmware list */
type LatestFirmwareList = {
  [modelNumber: string]: {
    standard: { version: number };
    enhanced?: { version: number };
    dev1?: { version: number };
  };
};

/** Check if  the firmware is standard or enhanced */
export const checkFirmwareSeries = (firmwareVersion: string) => {
  if (!firmwareVersion) {
    return 'unknown';
  }
  const threshold = 0.79;
  const decimalPart = firmwareVersion.split('.')[1];
  const decimalValue = parseFloat('0.' + decimalPart);
  return decimalValue < threshold ? 'standard' : 'enhanced';
};

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/** check whether the device needs firmware update */
export const checkCurrentFirmwareStatus = (device: IDevice, latestFirmwareList: LatestFirmwareList) => {
  // check the current version number against the latest version number
  const modelNumber = getFirmwareModelNameFromDeviceType(device.basicInfo.deviceType);

  if (modelNumber === null) {
    return;
  }
  const firmwareVersionString = device?.basicInfo?.firmwareVersion;
  if (!firmwareVersionString) {
    return;
  }
  const currentFirmwareVersionNumber = parseInt(firmwareVersionString.replace('.', ''), 10);
  const latestEnhancedFirmwareVersion = latestFirmwareList[modelNumber]?.enhanced?.version
    ? latestFirmwareList[modelNumber]?.enhanced?.version
    : 0;
  const latestStandardFirmwareVersion = latestFirmwareList[modelNumber]?.standard?.version
    ? latestFirmwareList[modelNumber]?.standard?.version
    : 0;
  if (checkFirmwareSeries(device.basicInfo.firmwareVersion) === 'standard') {
    return currentFirmwareVersionNumber >= latestStandardFirmwareVersion ? Status.UpToDate : Status.NeedsUpdate;
  } else {
    return currentFirmwareVersionNumber >= latestEnhancedFirmwareVersion ? Status.UpToDate : Status.NeedsUpdate;
  }
};

/** This is the same JSON format for the latest.json that retrieve from the s3 bucket */
export const firmwareUpdateLog = {
  'IX-BA': {
    standard: {
      name: 'IX-BA_V720.bin',
      version: 720,
      updateLog: ['Fixed SSL Security Algorithm', 'Security updates', 'Released: 09-27-23']
    },
    'IX-DA': {
      standard: {
        name: 'IX-DA_V720.bin',
        version: 720,
        updateLog: ['Fixed SSL Security Algorithm', 'Security updates', 'Released: 09-27-23']
      }
    },
    'IX-DV': {
      standard: {
        name: 'IX-DV_V720.bin',
        version: 720,
        updateLog: ['Fixed SSL Security Algorithm', 'Security updates', 'Released: 09-27-23']
      },
      enhanced: {
        name: 'IX-DV_V794.bin',
        version: 794,
        updateLog: [
          'Added relay control and event handling to the ONVIF library',
          'Added compatibility with Mitel MiVoice Business 9.1',
          'Added auto answer feature from an incoming SIP call',
          'Fixed SSL Security Algorithm'
        ]
      }
    },
    'IX-DVM': {
      standard: {
        name: 'IX-DVM_V720.bin',
        version: 720,
        updateLog: ['Fixed SSL Security Algorithm', 'Released: 09-27-23']
      },
      enhanced: {
        name: 'IX-DVM_V794.bin',
        version: 794,
        updateLog: [
          'Added relay control and event handling to the ONVIF library',
          'Added compatibility with Mitel MiVoice Business 9.1',
          'Added auto answer feature from an incoming SIP call',
          'Fixed SSL Security Algorithm'
        ]
      }
    },
    'IX-EA': {
      standard: {
        name: 'IX-EA_V720.bin',
        version: 720,
        updateLog: ['Fixed SSL Security Algorithm', 'Security updates', 'Released: 09-27-23']
      },
      enhanced: {
        name: 'IX-EA_V794.bin',
        version: 794,
        updateLog: [
          'Added relay control and event handling to the ONVIF library',
          'Added compatibility with Mitel MiVoice Business 9.1',
          'Added auto answer feature from an incoming SIP call',
          'Fixed SSL Security Algorithm'
        ]
      }
    },
    'IX-MV7': {
      standard: {
        name: 'IX-MV7_V720.bin',
        version: 720,
        updateLog: ['Fixed SSL Security Algorithm', 'Security updates', 'Released: 09-27-23']
      }
    },
    'IX-RS': {
      standard: {
        name: 'IX-RS_V720.bin',
        version: 720,
        updateLog: ['Fixed SSL Security Algorithm', 'Security updates', 'Released: 09-27-23']
      },
      enhanced: {
        name: 'IX-RS_V794.bin',
        version: 794,
        updateLog: [
          'Added relay control and event handling to the ONVIF library',
          'Added compatibility with Mitel MiVoice Business 9.1',
          'Added auto answer feature from an incoming SIP call',
          'Fixed SSL Security Algorithm'
        ]
      }
    },
    'IX-SS-2G': {
      standard: {
        name: 'IX-SS-2G_V720.bin',
        version: 720,
        updateLog: ['Fixed SSL Security Algorithm', 'Security updates', 'Released: 09-27-23']
      },
      enhanced: {
        name: 'IX-SS-2G_V794.bin',
        version: 794,
        updateLog: [
          'Added relay control and event handling to the ONVIF library',
          'Added compatibility with Mitel MiVoice Business 9.1',
          'Added auto answer feature from an incoming SIP call',
          'Fixed SSL Security Algorithm'
        ]
      }
    },
    'IX-SSA': {
      standard: {
        name: 'IX-SSA_V720.bin',
        version: 720,
        updateLog: ['Fixed SSL Security Algorithm', 'Security updates', 'Released: 09-27-23']
      },
      enhanced: {
        name: 'IX-SSA_V794.bin',
        version: 794,
        updateLog: [
          'Added relay control and event handling to the ONVIF library',
          'Added compatibility with Mitel MiVoice Business 9.1',
          'Added auto answer feature from an incoming SIP call',
          'Fixed SSL Security Algorithm'
        ]
      }
    },
    'IXG-2C7': {
      standard: {
        name: 'IXG-2C7_V300.bin',
        version: 300,
        updateLog: [
          'Ver. 2.02: Minor bug fixes and improvements',
          'Released: 03-21-21',
          'Security update for the mobile app registration QR code.',
          'Requires IXGW-GW to be on firmware V300 or higher for mobile app functionality',
          'Allows compatibility with IXG Support Tool 5.0.1.1',
          'Released 04/01/2024'
        ]
      }
    },
    'IXG-DM7': {
      standard: {
        name: 'IXG-DM7_V300.bin',
        version: 300,
        updateLog: [
          'Fixed video and audio problems when connecting to IXGW-LC',
          'Allows compatibility with IXG Support Tool 5.0.1.1',
          'Released: 04-01-24'
        ]
      }
    },
    'IXG-MK': {
      standard: {
        name: 'IXG-MK_V300.bin',
        version: 300,
        updateLog: [
          'Fixed incorrect display about DST (Daylight Saving Time)',
          'Allows compatibility with IXG Support Tool 5.0.1.1',
          'Released: 4-01-2024'
        ],
        warningLog: {
          description:
            'Warning: Updating the IXG-MK from firmware version 1.** to version 3.00 will cause the station to reset to factory defaults.',
          solution:
            'To preserve the existing settings, refer to the IXG Support Tool Setting Manual (Administrator Mode) and follow the steps below to back up and restore the settings.',
          link: 'https://www.aiphone.com/wp-content/uploads/2020/03/IXG-Support-Tool-Setting-Manual-Administrator-Mode-Ver.5.0.0.0-EN.pdf',
          steps: [
            'Follow the steps on page 139 (2.3 Download Settings) to back up the station’s settings.',
            'Upgrade the firmware on the IXG-MK by following the instructions on pages 470-471 (Firmware Update)',
            'Re-associate the station and its settings (2.2 Association Settings, page 137) and upload the settings to the stations (2.4 Upload Settings, page 141.'
          ]
        }
      }
    },
    'IXGW-GW': {
      standard: {
        name: 'IXGW-GW_V300.bin',
        version: 300,
        updateLog: [
          'Improved stability when applying settings',
          'Fixed a problem with the Mobile App Configuration on IXG-2C7',
          'Improved stability when connecting to Mobile Apps',
          'Security update for mobile app registration QR code',
          'Allows compatibility with IXG Support Tool 5.0.1.1',
          'Released: 04-01-2024'
        ]
      },
      enhanced: {
        name: 'IXGW-GW_V381_003_dev.bin',
        version: 381,
        updateLog: ''
      }
    },
    'IXGW-LC': {
      standard: {
        name: 'IXGW-LC_V300.bin',
        version: 300,
        updateLog: [
          'Minor bug fixes and improvements.',
          'Allows compatibility with IXG Support Tool 5.0.1.1',
          'Released: 04-01-2024'
        ]
      }
    },
    'IXW-MA': {
      standard: {
        name: 'IXW-MA_V925.bin',
        version: 925,
        updateLog: [
          'TLS 1.0 and TLS 1.1 are disabled (TLS 1.2 is still active). Minor bug fixes.',
          'Security updates by disabling SSL weak hash algorithms and minor bug fixes.'
        ]
      }
    }
  }
};
/**
 * @function getLatestFirmwareDownloadUrl
 * @description Gets a string URL to the latest firmware version for a device type. Open this string url in
 * another tab to download the latest device firmware
 * @param deviceType
 */
export const getLatestFirmwareDownloadUrl = async (deviceType: string): Promise<LatestFirmwareDownload | null> => {
  const firmwareVersionCheck = await fetch(latestFirmwareUrl);

  const latestVersion = await firmwareVersionCheck.json();

  const versions = latestVersion[deviceType];
  // Check if there is a key for 'standard'. If so, then return the value for the key
  let result = '';
  let versionNumber = 0;
  let name = '';
  let updateLog = '';
  if (versions && versions.standard) {
    result = `${firmwareUrl}${versions.standard.name}`;
    versionNumber = versions.standard.version;
    name = versions.standard.name;
    updateLog = versions.standard.updateLog;
  } else if (versions && versions.enhanced) {
    result = `${firmwareUrl}${versions.enhanced.name}`;
    versionNumber = versions.enhanced.version;
    name = versions.enhanced.name;
    updateLog = versions.enhanced.updateLog;
  } else if (versions && versions.dev1) {
    result = `${firmwareUrl}${versions.dev1.name}`;
    versionNumber = versions.dev1.version;
    name = versions.dev1.name;
    updateLog = versions.dev1.updateLog;
  }

  if (result.length > 0) {
    // Send a fetch to that url of result to get the url we really want
    const response = await fetch(result);
    // Open in new tab
    // It is the responsibility of the calling function to open in a new window
    const downloadUrl = await response.text();
    return {
      downloadUrl,
      versionNumber,
      updateLog,
      name
    };
  }

  return null;
};
