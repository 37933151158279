import { IDeviceTypes } from 'store/slices/devicesSlice';

interface Unit {
  unitNumber: string;
  unitName: string;
  unitType: number;
  publicId?: string;
  devicePublicIds: string[];
}

const useConfigureWizardMultiUnits = (devices: IDeviceTypes, buildingPublicId: string) => {
  const newUnits: Unit[] = [];
  let unitCount = 1;

  ['GuardStation'].forEach((stationType) => {
    for (let i = 0; i < devices[stationType].length; i += 8) {
      const newUnit: Unit = {
        unitNumber: `${unitCount}`.padStart(4, '0'),
        unitName: 'Guard' + `${unitCount}`.padStart(4, '0'),
        unitType: 1,
        devicePublicIds: devices[stationType].slice(i, i + 8).map((deviceId) => deviceId)
      };

      newUnits.push(newUnit);
      unitCount++;
    }
  });

  ['EntranceStation'].forEach((stationType) => {
    for (let i = 0; i < devices[stationType].length; i += 10) {
      const newUnit: Unit = {
        unitNumber: `${unitCount}`.padStart(4, '0'),
        unitName: 'Entrance' + `${unitCount}`.padStart(4, '0'),
        unitType: 2,
        devicePublicIds: devices[stationType].slice(i, i + 10).map((deviceId) => deviceId)
      };

      newUnits.push(newUnit);
      unitCount++;
    }
  });

  ['EmergencyStation'].forEach((stationType) => {
    for (let i = 0; i < devices[stationType].length; i += 10) {
      const newUnit: Unit = {
        unitNumber: `${unitCount}`.padStart(4, '0'),
        unitName: 'OutsideArea' + `${unitCount}`.padStart(4, '0'),
        unitType: 6,
        devicePublicIds: devices[stationType].slice(i, i + 10).map((deviceId) => deviceId)
      };

      newUnits.push(newUnit);
      unitCount++;
    }
  });

  const params = {
    newUnitsArray: newUnits,
    buildingPublicId: buildingPublicId
  };

  return params;
};

export default useConfigureWizardMultiUnits;
