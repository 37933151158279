import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';

export const updateFormValuesAC = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesAC(values);
  return values;
};

export const updateItemQuantityWithAccessoriesAC = (values: FormValues) => {
  const items = Object.values(values.items);

  // Update quantities of Access Controllers
  const numberOfDoorIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_READERS_AC');
  const numberOfDoorTroveIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_READERS_AC_TROVE');
  const ACS2DRCIndex = items.findIndex((item) => item.itemName === 'ACS-2DR-C');
  const ACS2DRIndex = items.findIndex((item) => item.itemName === 'ACS-2DR');
  const AC2DEIndex = items.findIndex((item) => item.itemName === 'AC-2DE');
  const ACPS1250Index = items.findIndex((item) => item.itemName === 'AC-PS1250');
  const ACCA1Index = items.findIndex((item) => item.itemName === 'AC-CA1');
  if (numberOfDoorIndex >= 0 && items[numberOfDoorIndex].quantity > 0) {
    items[ACS2DRCIndex].quantity = Math.ceil(items[numberOfDoorIndex].quantity / 8);
    items[AC2DEIndex].quantity = Math.ceil(items[numberOfDoorIndex].quantity / 2) - items[ACS2DRCIndex].quantity;
    items[ACPS1250Index].quantity = items[ACS2DRCIndex].quantity;
    items[numberOfDoorIndex].quantity = 0;
  }
  if (numberOfDoorTroveIndex >= 0 && items[numberOfDoorTroveIndex].quantity > 0) {
    items[ACS2DRIndex].quantity = Math.ceil(items[numberOfDoorTroveIndex].quantity / 8);
    items[AC2DEIndex].quantity = Math.ceil(items[numberOfDoorTroveIndex].quantity / 2) - items[ACS2DRIndex].quantity;
    items[ACCA1Index].quantity = items[ACS2DRIndex].quantity;
    items[numberOfDoorTroveIndex].quantity = 0;
  }

  // Update quantities of Elevator Controllers
  const numberOfFloorIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_FLOORS');
  const ACSELVIndex = items.findIndex((item) => item.itemName === 'ACS-ELV');
  const ACIOEIndex = items.findIndex((item) => item.itemName === 'AC-IOE');
  const ACCIndex = items.findIndex((item) => item.itemName === 'AC-C');
  if (numberOfFloorIndex >= 0 && items[numberOfFloorIndex].quantity > 0) {
    items[ACSELVIndex].quantity = Math.ceil(items[numberOfFloorIndex].quantity / 64);
    items[ACIOEIndex].quantity = Math.ceil(items[numberOfFloorIndex].quantity / 8) - items[ACSELVIndex].quantity;
    items[ACPS1250Index].quantity += items[ACSELVIndex].quantity;
    let floors = items[numberOfFloorIndex].quantity;
    while (floors > 0) {
      if (floors > 56) {
        items[ACCIndex].quantity += 2;
      } else if (floors > 24) {
        items[ACCIndex].quantity += 1;
      }
      floors = floors - 64;
    }
    items[numberOfFloorIndex].quantity = 0;
  }

  // Update quantities of I/O Controllers
  const numberOfInputIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_INPUTS');
  const numberOfOutputIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_OUTPUTS');
  const ACSIOIndex = items.findIndex((item) => item.itemName === 'ACS-IO');
  if (numberOfInputIndex >= 0 && numberOfOutputIndex >= 0) {
    const ioMax = Math.max(items[numberOfInputIndex].quantity, items[numberOfOutputIndex].quantity);
    if (ioMax > 0) {
      items[ACSIOIndex].quantity = Math.ceil(ioMax / 64);
      items[ACIOEIndex].quantity += Math.ceil(ioMax / 8) - items[ACSIOIndex].quantity;
      items[ACPS1250Index].quantity += items[ACSIOIndex].quantity;
      items[ACCIndex].quantity += Math.ceil(ioMax / 32) - items[ACSIOIndex].quantity;
    }
    items[numberOfInputIndex].quantity = 0;
    items[numberOfOutputIndex].quantity = 0;
  }

  // Cards, fobs, and tags are spec-ed based on only accessories
  const ACPCIndex = items.findIndex((item) => item.itemName === 'AC-PC');
  const ACPFIndex = items.findIndex((item) => item.itemName === 'AC-PF');
  const ACPTIndex = items.findIndex((item) => item.itemName === 'AC-PT');
  items[ACPCIndex].quantity = 0;
  items[ACPFIndex].quantity = 0;
  items[ACPTIndex].quantity = 0;
};
