import { useState, useEffect } from 'react';
import { ApiError, ErrorType } from 'shared/api/ApiError';
import { useStripeFunctions } from 'shared/api/Aws/awsApi';

export interface Price {
  id: string;
}

export interface Item {
  price: Price;
}

export interface ItemCollection {
  data: Item[];
}

export enum SubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  PAUSED = 'paused'
}

export interface SubscriptionDetails {
  current_period_end: number;
  status: SubscriptionStatus;
  items: ItemCollection;
}
const useSubscriptionDetail = (subscriptionId: string | null) => {
  const [currentPriceId, setCurrentPriceId] = useState<string | null>(null);
  const [subscriptionDetail, setSubscriptionDetail] = useState<SubscriptionDetails | null>(null);
  const [subscriptionDetailError, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        if (subscriptionId) {
          const params = { subscriptionId, action: 'getSubscriptionDetails' };
          const subscriptionDetails = await useStripeFunctions(params);

          if (!subscriptionDetails?.data) {
            setError(ErrorType.SERVER);
            return;
          }
          const items = subscriptionDetails.data.items;
          const priceId = items.data.length ? items.data[0].price.id : null;

          setCurrentPriceId(priceId);
          setSubscriptionDetail(subscriptionDetails.data);
        }
      } catch (error) {
        if (error instanceof ApiError) {
          setError(error.type);
        }
        setError(ErrorType.UNEXPECTED);
      }
    })();
  }, [subscriptionId]);

  return { currentPriceId, subscriptionDetail, subscriptionDetailError };
};

export default useSubscriptionDetail;
