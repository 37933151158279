import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleError } from 'features/SimBilling/Utils/SimBillingUtils';
import { ErrorType } from 'shared/api/ApiError';
import { useStripeFunctions } from 'shared/api/Aws/awsApi';
import { SubscriptionPlan, initialSubscriptionPlansState } from 'features/SimBilling/Types/SimBillingTypes';

export const fetchSubscriptionPlansData = createAsyncThunk<
  SubscriptionPlan[],
  { countryCode: string },
  { rejectValue: { errorMessage: ErrorType } }
>('subscriptionPlans/fetchData', async (params, { rejectWithValue }) => {
  try {
    const functionParams = { action: 'getSubscriptionPlans', countryCode: params.countryCode };
    const response = await useStripeFunctions(functionParams);
    if (!response?.data) {
      return rejectWithValue({ errorMessage: ErrorType.SERVER });
    }
    const plansData: SubscriptionPlan[] = response.data.map((plan: any) => ({
      priceID: plan.id,
      price: plan.unitAmount / 100,
      productID: plan.product,
      productName: plan.name,
      currency: plan.currency,
      dataAmount: Number.isNaN(parseFloat(plan.dataAmount)) ? 0 : parseFloat(plan.dataAmount),
      description: plan.description,
      featured: false
    }));
    return plansData;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

const subscriptionPlansSlice = createSlice({
  name: 'subscriptionPlans',
  initialState: initialSubscriptionPlansState,
  reducers: {
    resetfetchSubPlansDataError: (state) => {
      state.error = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSubscriptionPlansData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubscriptionPlansData.fulfilled, (state, action) => {
        state.loading = false;
        state.plans = action.payload;
      })
      .addCase(fetchSubscriptionPlansData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.errorMessage || action.error.message;
      });
  }
});

export const { resetfetchSubPlansDataError } = subscriptionPlansSlice.actions;
export const subscriptionPlansReducer = subscriptionPlansSlice.reducer;
