/**
 * only apply to typescript code
 * This is to create typed versions of the 'useDispatch' and
 * 'useSelector' hook for usage in your application
 *
 * This allows you to import them into any component file that needs
 * to use the Hooks, and avoid potential cicrular import
 * dependency issues.
 */
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from './index';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
