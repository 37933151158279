import { getString } from 'shared/utils/LocalizationUtils';

/* IXG Support Tool tab stub */
export const IXGSupportToolLabel = () => {
  const ixgSupportTool = getString('IXG_Support_Tool');
  return <span>{ixgSupportTool}</span>;
};

const IXGSupportTool = IXGSupportToolLabel;

export default IXGSupportTool;
