import React, { useEffect, useState } from 'react';
import { Button, Container, Paper, Tab, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DeviceTabContent from 'features/RemoteManagement/Components/DeviceTabContent';
import AddDeviceDialog from 'features/RemoteManagement/Components/AddDeviceDialog';
import { useGetDeviceListWithSitePublicIdQuery } from 'services/aiphoneCloud';

const AnsweringStations = () => {
  const [value, setValue] = useState('0');
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);
  const [shouldFetchDevices, setShouldFetchDevices] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const { refetch } = useGetDeviceListWithSitePublicIdQuery({
    qty: '-1',
    page: 0,
    sitePublicId
  });

  useEffect(() => {
    if (shouldFetchDevices) {
      refetch();
      setShouldFetchDevices(false);
    }
  }, [shouldFetchDevices, refetch]);

  const deviceFilters = {
    '0': 'Video Master Station',
    '1': 'Sub Master Station',
    '2': 'Tenant Station',
    '3': 'Video Guard Station'
  };

  const deviceTabs = [
    { deviceType: 'MasterStation', label: `Master Stations (${deviceListByType.MasterStation?.length || 0})` },
    {
      deviceType: 'SubMasterStation',
      label: `Sub Master Stations (${deviceListByType.SubMasterStation?.length || 0})`
    },
    { deviceType: 'TenantStation', label: `Tenant Stations (${deviceListByType.TenantStation?.length || 0})` },
    { deviceType: 'GuardStation', label: `Guard Stations (${deviceListByType.GuardStation?.length || 0})` }
  ];

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Paper elevation={1}>
          <TabContext value={value}>
            <Box sx={styles.addDeviceButton}>
              <Button variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
                Add Device
              </Button>
            </Box>
            <AddDeviceDialog
              isOpen={isDialogOpen}
              setIsOpen={setIsDialogOpen}
              description="Add a new device to your site"
              deviceTypeFilter={deviceFilters[value]}
              setShouldFetchDevices={setShouldFetchDevices}
              isUnitRequired={true}
            />
            <TabList onChange={handleTabChange}>
              {deviceTabs.map((tab, index) => (
                <Tab label={tab.label} value={index.toString()} key={tab.deviceType} />
              ))}
            </TabList>
            {deviceTabs.map((tab, index) => (
              <TabPanel value={index.toString()} key={index}>
                <DeviceTabContent deviceList={deviceListByType[tab.deviceType]} />
              </TabPanel>
            ))}
          </TabContext>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  addDeviceButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 2
  }
};

export default AnsweringStations;
