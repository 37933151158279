import { Box, Button } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useGetBranchesQuery } from 'services/aiphoneCloud';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBranchDialog from '../UiParts/AddBranchDialog';
import Add from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import EditBranchDialog from '../UiParts/EditBranchDialog';
import DeleteBranchDialog from '../UiParts/DeleteBranchDialog';
import { useTranslation } from 'react-i18next';
import { EnumList, IStateValue, fetchEnumList } from 'shared/utils/EnumUtils';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';

type BranchRecord = {
  publicId: string;
  company: {
    name: string;
    stateId: number;
    publicId: string;
    postalCode: string;
  };
  address: string;
  address2: string;
  address3: string;
  city: string;
  branchName: string;
  c2CompanyName: string;
  aiphoneCloudAccountNumber: string;
  phoneNumber: string;
  stateId: number;
  postalCode: string;
  isDutyFree: boolean;
  sitePublicId: string[];
  createdBy: string;
  lastUpdatedBy: string;
  createdOn: string;
  lastUpdatedOn: string;
};

////////////////////////////////////////////////////////////////////////////
// Moved to separate component
////////////////////////////////////////////////////////////////////////////
interface BranchesToolBarProps {
  setIsCreateBranchDialogOpen: (value: boolean) => void;
  setIsDeleteBranchDialogOpen: (value: boolean) => void;
  canCreateBranch: boolean;
  canDeleteBranch: boolean;
  selectionModel: GridRowSelectionModel;
  isFetching: boolean;
}

const BranchesToolBar = (props: BranchesToolBarProps) => {
  const { t } = useTranslation();

  return (
    <GridToolbarContainer>
      <Box sx={styles.w100}>
        {props.canCreateBranch && (
          <Button
            onClick={() => props.setIsCreateBranchDialogOpen(true)}
            disabled={props.isFetching}
            startIcon={<Add />}
          >
            {t('Add_Branch')}
          </Button>
        )}
        {props.canDeleteBranch && (
          <Button
            onClick={() => {
              props.setIsDeleteBranchDialogOpen(true);
            }}
            disabled={props.isFetching || props.selectionModel.length !== 1}
            startIcon={<DeleteForeverIcon />}
          >
            {t('Delete_Branch')} {/* Localization */}
          </Button>
        )}
      </Box>
    </GridToolbarContainer>
  );
};

const BranchesDataGrid = () => {
  const defaultPageSize = 50;
  const { t } = useTranslation();

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCreateBranchDialogOpen, setIsCreateBranchDialogOpen] = useState(false);
  const [isEditBranchDialogOpen, setIsEditBranchDialogOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [isDeleteBranchDialogOpen, setIsDeleteBranchDialogOpen] = useState(false);
  const [branchList, setBranchList] = useState<{ [key: string]: BranchRecord }>({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [rowCount, setRowCount] = useState(0);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = useState(true);

  const { isAllowedTo } = usePermission();

  const canCreateBranch = isAllowedTo('branch:create', null, PermissionsContextType.GLOBAL);
  const canEditBranch = isAllowedTo('branch:edit', null, PermissionsContextType.GLOBAL);
  const canDeleteBranch = isAllowedTo('branch:delete', null, PermissionsContextType.GLOBAL);
  const canViewBranch = isAllowedTo('branch:view', null, PermissionsContextType.GLOBAL);

  const { data, isFetching, error, refetch } = useGetBranchesQuery({
    qty: pageSize.toString(),
    page: page.toString()
  });
  const branchData: { [key: string]: BranchRecord } = data ? data.branchList : {};

  useEffect(() => {
    if ((data || error) && isSuccess) {
      refetch();
    }
  }, [page, pageSize, isSuccess]);

  useEffect(() => {
    if (data?.branchList) {
      setBranchList(branchData);
      setRowCount(data.totalBranches);
    } else if (branchData) {
      setBranchList({});
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const errorData = JSON.parse((error as { data: string }).data);
      const errorCode = errorData.errorCode;
      if (errorCode === 'A0001') {
        setErrorMessage(t('Unauthorized_to_fetch_branches'));
      } else {
        setErrorMessage(t('Failed_to_fetch_branches'));
      }
      setIsError(true);
    }
  }, [error]);

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  const computeRows = (): GridRowsProp => {
    return Object.entries(branchList).map(([key, branch]) => ({
      id: key,
      companyName: branch.company.name,
      branchName: branch.branchName,
      address: branch.address,
      address2: branch.address2,
      city: branch.city,
      state: enumList.state[branch.stateId].value,
      stateId: branch.stateId,
      zipCode: branch.postalCode,
      country: enumList.country[(enumList.state[branch.stateId] as IStateValue).countryId].value,
      phoneNumber: branch.phoneNumber
    }));
  };

  // We need to memoize this due to the reference to the useTranslation hook object, "t".
  const columns: GridColDef[] = React.useMemo(() => {
    const columns: GridColDef[] = [
      { field: 'companyName', headerName: t('Company_Name'), flex: 1 },
      { field: 'branchName', headerName: t('Branch_Name'), flex: 1 },
      { field: 'address', headerName: t('Address'), flex: 1 },
      { field: 'address2', headerName: t('Address_2'), flex: 1 },
      { field: 'city', headerName: t('City'), flex: 1 },
      { field: 'state', headerName: t('State'), flex: 1 },
      { field: 'zipCode', headerName: t('Zip_Code'), flex: 1 },
      { field: 'country', headerName: t('Country'), flex: 1 },
      { field: 'phoneNumber', headerName: t('Phone_Number'), flex: 1 }
    ];

    if (canEditBranch) {
      columns.push({
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SettingsIcon />}
            label={t('Edit')} // Localization
            key={params.row.id}
            onClick={() => {
              setIsEditBranchDialogOpen(true);
              setSelectedBranch(params.row.id);
            }}
          />
        ]
      });
    }

    return columns;
  }, [t]);

  const rows: GridRowsProp = !isFetching && !fetchingEnums ? computeRows() : [];

  return (
    <>
      {canViewBranch && (
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[25, 50, 100]}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          slots={{
            moreActionsIcon: SettingsIcon,
            toolbar: () => (
              <BranchesToolBar
                setIsCreateBranchDialogOpen={setIsCreateBranchDialogOpen}
                setIsDeleteBranchDialogOpen={setIsDeleteBranchDialogOpen}
                canCreateBranch={canCreateBranch}
                canDeleteBranch={canDeleteBranch}
                selectionModel={selectionModel}
                isFetching={isFetching}
              />
            )
          }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          rowCount={rowCount}
          paginationMode="server"
          pagination
          autoHeight
        />
      )}
      <AddBranchDialog
        isOpen={isCreateBranchDialogOpen}
        setIsOpen={setIsCreateBranchDialogOpen}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <EditBranchDialog
        isOpen={isEditBranchDialogOpen}
        setIsOpen={setIsEditBranchDialogOpen}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
        selectedBranch={selectedBranch}
      />
      <DeleteBranchDialog
        isOpen={isDeleteBranchDialogOpen}
        setIsOpen={setIsDeleteBranchDialogOpen}
        selectionModel={selectionModel}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
};

const styles = {
  w100: {
    width: '100%'
  }
};

export default BranchesDataGrid;
