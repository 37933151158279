import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';

/**
 * IXG Series QuikSpec Categories and items
 *
 */
export const ixgStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Entrance Stations',
    description:
      'The IXG-DM7-HIDA Entrance Station can selectively call a Unit/Tenant in the system. A Unit/Tenant can consist of one or more stations and/or mobile apps assigned to an apartment, commercial space, or concierge/guard area. An IXG System can have up to 9,999 units or stations.',
    items: [
      {
        deviceName: 'IXG-DM7-HIDA',
        accessories: [
          { accessoryName: 'Flush Mount', deviceName: 'IXG-DM7-BOX' },
          { accessoryName: 'Surface Mount', deviceName: 'SBX-IXGDM7' }
        ],
        countWithAccessories: true
      }
    ]
  },
  {
    id: 2,
    title: 'Tenant Stations',
    description:
      'When visitors call, they can be answered with the IXG-2C7 Video Tenant Station. By adding a security layer of video identification and communication to each apartment or suite, these sleek stations will appeal to building owners and tenants alike.',
    items: [
      {
        deviceName: 'IXG-2C7',
        accessories: [{ accessoryName: 'Flush Mount', deviceName: 'IXG-FMK-2C7' }, { accessoryName: 'Surface Mount' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IXG-2C7-L',
        accessories: [{ accessoryName: 'Flush Mount', deviceName: 'IXG-FMK-2C7' }, { accessoryName: 'Surface Mount' }],
        countWithAccessories: true
      }
    ]
  },
  {
    id: 3,
    title: 'Guard Stations',
    description:
      'The IXG-MK is an IP video guard station with a 7" LCD touchscreen for the IXG Series multi-tenant system. The IXG-MK station can answer calls from an entrance station, tenant station, or individual door station.',
    items: [{ deviceName: 'IXG-MK' }]
  }
];

export const ixgMobile: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Multi-Purpose Gateway Adaptor',
    description:
      'IXGW-TGW enables you to add the Cellular Connection, Mobile App, Telephone Entry, and Remote Management features to your system. The Cellular Connection can add the internet connection to IXG System where a wired internet is not prepared. The IXG Mobile Application can be used to receive video calls from any stations within the unit it resides in. For residents who prefer traditinal phone calls, IXGW-TGW can dial into those phones. The Remote Management provides you a cloud portal where you can change the configurations of IX/IXG stations online.',
    items: [
      {
        deviceName: 'IXGW-TGW'
      }
    ]
  },
  {
    id: 2,
    title: 'Mobile App Lifetime License Card',
    description:
      'The IXG Mobile App can receive calls from intercom stations, unlock doors, and monitor doors. To activate the app, the app subscription or Lifetime License Card is required. Lifetime License Card is provided with a license code on a physical card. By entering the code on IXG Portal, the system can activate IXG Mobile App up to the selected quantity of IXG-APP.',
    items: [{ deviceName: 'IXG-APP' }]
  }
];

export const ixgDoorStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Audio/Video Door Stations',
    description:
      'Audio/Video Door Stations provide two-way audio communication, video, and door release relay outputs.',
    items: [{ deviceName: 'IX-DV' }, { deviceName: 'IX-DVM' }, { deviceName: 'IX-EA' }, { deviceName: 'IX-DVF' }]
  },
  {
    id: 2,
    title: 'Audio Only Door Stations',
    description: 'Audio Only Door Stations provide two-way audio communication and door release relay outputs.',
    items: [{ deviceName: 'IX-SS-2G' }, { deviceName: 'IX-SSA' }]
  }
];

export const ixgMasterStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Master Stations',
    description:
      'Master stations are where incoming calls are received, door release can be activated, other master stations can be called, and pages can be initiated. In typical applications, at least one master station is used to communicate to door and sub stations, and multiple master stations can be used for internal communication, system partitioning, and call transfers.',
    items: [
      { deviceName: 'IX-MV7-B' },
      { deviceName: 'IX-MV7-W' },
      { deviceName: 'IX-MV7-HB' },
      { deviceName: 'IX-MV7-HB-L' },
      { deviceName: 'IX-MV7-HW-JP' }
    ]
  },
  {
    id: 2,
    title: 'Sub-Master Stations',
    description:
      'Sub master stations have similar basic features when compared to a master station, such as 2-way communication and the ability to activate door release, but is limited in other advanced features. When choosing the addition of a sub master station, ensure the station is appropriate for the application.',
    items: [{ deviceName: 'IX-RS-B' }, { deviceName: 'IX-RS-W' }]
  }
];

export const ixgAdaptors: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Network Adaptors',
    description:
      'All network adaptors listed below can be added to your system design. The IPW-1VC and IPW-POE8 can be added without IXG or IX Series stations.',
    items: [
      { deviceName: 'IXW-MA' },
      { deviceName: 'IXW-MAA' },
      { deviceName: 'IPW-1VC' },
      { deviceName: 'IPW-10VC' },
      { deviceName: 'IPW-POE8' },
      { deviceName: 'PS-POE' },
      { deviceName: 'IXW-PBXA' },
      { deviceName: 'IXW-HWCBP' }
    ]
  },
  {
    id: 2,
    title: 'Elevator/Lift Control Adapters',
    description:
      'All network adaptors listed below can be added to your system design. The IPW-1VC and IPW-POE8 can be added without IXG or IX Series stations.',
    items: [{ deviceName: 'IXGW-LC' }, { deviceName: 'IXGW-LC-RY20' }]
  },
  {
    id: 3,
    title: 'Speakers and Amplifiers',
    description:
      'Aiphone offers amplifiers and speakers that can be used with IX series stations for paging capabilities.',
    items: [{ deviceName: 'MH-15W' }, { deviceName: 'MD35M' }, { deviceName: 'MD60M' }, { deviceName: 'MD120M' }]
  }
];

// Hidden products are designed not to be displayed.
export const ixgHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      {
        deviceName: 'SBX-IXDV30'
      },
      {
        deviceName: 'IX-MB'
      },
      {
        deviceName: 'SBX-IDVF'
      },
      {
        deviceName: 'SBX-IDVFRA'
      },
      {
        deviceName: 'SBX-IXGDM7'
      },
      {
        deviceName: 'SBX-2G/A'
      },
      {
        deviceName: 'SBX-NVP/A'
      },
      {
        deviceName: 'PS-2420UL'
      },
      {
        deviceName: 'IXG-DM7-BOX'
      },
      {
        deviceName: 'IXG-FMK-2C7'
      },
      {
        deviceName: 'TW-MKL'
      }
    ]
  }
];
