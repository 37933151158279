import { Box, Button, Card, Dialog, DialogActions, DialogContent } from '@mui/material';
import AppAddressBookUnitControlPanel from './AppAddressBookUnitControlPanel';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useEffect, useState } from 'react';
import FilterByAddedDevices from 'features/RemoteManagement/DeviceDashboard/callSettings/callDestination/FilterByAddedDevices';
import AppAvailableDeviceList from './AppAvailableDeviceList';
import SelectedDevicesGrid from '../../../../components/AddDeviceToUnit/SelectedDevicesGrid';
import { LoadingButton } from '@mui/lab';
import { useBatchUpdateAppsMutation, useLazyGetAppWithSiteIdQuery } from 'services/aiphoneCloud';
import { useParams } from 'react-router-dom';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { useTranslation } from 'react-i18next';

interface IAppAddressBookUnitDialogProps {
  open: boolean;
  onClose: () => void;
}

const AppAddressBookUnitDialog = ({ open, onClose }: IAppAddressBookUnitDialogProps) => {
  const [filteredDevices, setFilteredDevices] = useState<string[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const sitePublicId = useParams().id;
  const unitPublicId = useParams().unitid || '';
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const appList = useSelector((state: RootState) => state.apps.AppList);
  const appListByUnit = useSelector((state: RootState) => state.apps.AppGroup);
  const appsInSelectedUnit = appListByUnit[unitPublicId];
  const [batchUpdateApps] = useBatchUpdateAppsMutation();
  const [fetchApps] = useLazyGetAppWithSiteIdQuery();
  const { t } = useTranslation();

  useEffect(() => {
    if (appListByUnit[unitPublicId]) {
      setSelectedDevices(appList[appListByUnit[unitPublicId][0]].addressBook);
      setFilteredDevices(Object.keys(deviceList));
    }
  }, [appListByUnit, unitPublicId, appList, deviceList]);

  const handleFilterDevices = (field: string, query: string | boolean) => {
    if (!query) {
      setFilteredDevices(Object.keys(deviceList));
      return;
    }

    switch (field) {
      case 'stationName': {
        const filteredDevices = Object.keys(deviceList).filter((device) =>
          deviceList[device].basicInfo.stationName.toLowerCase().includes(query.toString().toLowerCase())
        );
        setFilteredDevices(filteredDevices);
      }
    }
  };

  const handleAddToSelectedDevices = (id: string) => {
    setSelectedDevices([...selectedDevices, id]);
  };

  const handleRemoveFromSelectedDevices = (id: string) => {
    setSelectedDevices(selectedDevices.filter((device) => device !== id));
  };

  const handleSaveToAddressBook = async () => {
    setIsLoading(true);
    const params = Object.entries(appList)
      .map(([key, app]) => {
        if (appsInSelectedUnit.includes(key)) {
          return {
            appData: {
              ...app,
              addressBook: selectedDevices.map((devicePublicId) => devicePublicId)
            }
          };
        }

        return null;
      })
      .filter((app) => app !== null);

    await batchUpdateApps({ apps: params })
      .unwrap()
      .then(() => {
        setIsSuccess(true);
        setSuccessMessage(t('App_AddressBook_Update_Success'));
        fetchApps({
          sitePublicId: sitePublicId,
          qty: 500,
          page: 0
        });
        onClose();
      })
      .catch(() => {
        setIsError(true);
        setErrorMessage(t('App_AddressBook_Update_Error'));
      });
    setIsLoading(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
        <DialogContent>
          <Box sx={styles.controlPanelWrapper}>
            <AppAddressBookUnitControlPanel />
          </Box>
          <Card sx={styles.dialogCard}>
            <Card sx={styles.filterWrapper}>
              <FilterByAddedDevices handleFilterDevices={handleFilterDevices} />
            </Card>
            <Box sx={styles.availableDevicesWrapper}>
              <AppAvailableDeviceList
                filteredDevices={filteredDevices}
                selectedDevices={selectedDevices}
                handleAddToSelectedDevices={handleAddToSelectedDevices}
                handleRemoveFromSelectedDevices={handleRemoveFromSelectedDevices}
              />
            </Box>
            <Box sx={styles.selectedDevicesWrapper}>
              <SelectedDevicesGrid
                selectedDevices={selectedDevices}
                handleRemoveFromSelectedDevices={handleRemoveFromSelectedDevices}
              />
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <LoadingButton onClick={handleSaveToAddressBook} color="primary" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
        <SnackbarAlert
          type="success"
          text={successMessage}
          time={3000}
          isOpen={isSuccess}
          onClose={() => setIsSuccess(false)}
        />
        <SnackbarAlert
          type="error"
          text={errorMessage}
          time={3000}
          isOpen={isError}
          onClose={() => setIsError(false)}
        />
      </Dialog>
    </>
  );
};

const styles = {
  controlPanelWrapper: {
    marginY: '20px'
  },
  dialogCard: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '530px',
    overflow: 'auto'
  },
  filterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '220px',
    padding: '20px'
  },
  availableDevicesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
    maxHeight: 'var(--window-height)',
    overflow: 'auto',
    backgroundColor: '#EFEFEF'
  },
  selectedDevicesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    width: '100%',
    height: '100%'
  }
};

export default AppAddressBookUnitDialog;
