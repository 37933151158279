import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Grid } from '@mui/material';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next'; // Import useTranslation for i18n
import { IRoleBase } from 'store/slices/usersSlice';

interface IRoleSelectProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  roles: IRoleBase[];
}

const RoleSelect: FC<IRoleSelectProps> = ({ handleChange, roles }) => {
  const { t } = useTranslation(); // Initialize the translation hook

  const renderRoleOptions = () => {
    if (!roles || !roles.length) {
      return (
        <MenuItem value="">
          <em>{t('No_Roles_Found')}</em>
        </MenuItem>
      );
    }
    return roles.map((role) => (
      <MenuItem key={role.publicId} value={role.publicId}>
        {role.roleName}
      </MenuItem>
    ));
  };

  return (
    <Grid item xs={12}>
      <FormControl size="small" fullWidth>
        <InputLabel id="role-label">{t('Select_A_Role')}</InputLabel>{' '}
        <Field
          name="role"
          id="role"
          as={Select}
          labelId="role-label"
          label={t('Select_A_Role')}
          onChange={handleChange}
        >
          {renderRoleOptions()}
        </Field>
      </FormControl>
    </Grid>
  );
};

export default RoleSelect;
