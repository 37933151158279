import React from 'react';
import Introduction from '../steps/Introduction';
import RegisterGateway from '../steps/RegisterGateway/RegisterGateway';
import AddDevices from '../steps/AddDevices/AddDevices';
import AddApps from '../steps/AddApps/AddApps';

import SiteConfirmation from '../steps/SiteConfirmation/SiteConfirmation';
import SyncSystemSettings from '../steps/SyncSystemSettings';
import DeviceConfigurationGrid from 'features/RemoteManagement/NewSiteWizard/steps/DeviceConfiguration/DeviceConfigurationGrid';

enum CommercialWizardSteps {
  Introduction,
  RegisterGateway,
  AddDevices,
  AddApps,
  Devices,
  Confirmation,
  SyncSiteSettings
}

const CommercialSiteWizard: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState<CommercialWizardSteps>(CommercialWizardSteps.Introduction);
  const [isChecked1, setIsChecked1] = React.useState(false);
  const [isChecked2, setIsChecked2] = React.useState(false);
  const [isChecked3, setIsChecked3] = React.useState(false);
  const [isRegistered, setIsRegistered] = React.useState(false);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => {
      return prevStep + 1;
    });
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => {
      return prevStep - 1;
    });
  };

  const renderStep = (step: CommercialWizardSteps) => {
    switch (step) {
      case CommercialWizardSteps.Introduction:
        return (
          <Introduction
            isChecked1={isChecked1}
            isChecked2={isChecked2}
            isChecked3={isChecked3}
            setIsChecked1={setIsChecked1}
            setIsChecked2={setIsChecked2}
            setIsChecked3={setIsChecked3}
            handleNextStep={handleNextStep}
          />
        );
      case CommercialWizardSteps.RegisterGateway:
        return (
          <RegisterGateway
            isRegistered={isRegistered}
            setIsRegistered={setIsRegistered}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case CommercialWizardSteps.AddDevices:
        return (
          <AddDevices
            isRegistered={isRegistered}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case CommercialWizardSteps.AddApps:
        return <AddApps handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;
      case CommercialWizardSteps.Devices:
        return <DeviceConfigurationGrid handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;
      case CommercialWizardSteps.Confirmation:
        return <SiteConfirmation handleNextStep={handleNextStep} />;
      case CommercialWizardSteps.SyncSiteSettings:
        return <SyncSystemSettings />;
      default:
        return <SyncSystemSettings />;
    }
  };

  return <React.Fragment>{renderStep(currentStep)}</React.Fragment>;
};

export default CommercialSiteWizard;
