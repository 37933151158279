import { Box, List } from '@mui/material';
import AvailableDeviceItem from '../../../../components/AddDeviceToUnit/AvailableDeviceItem';

interface IAppAvailableDeviceListProps {
  filteredDevices: string[];
  selectedDevices: string[];
  handleAddToSelectedDevices: (id: string) => void;
  handleRemoveFromSelectedDevices: (id: string) => void;
}

const AppAvailableDeviceList = ({
  filteredDevices,
  selectedDevices,
  handleAddToSelectedDevices,
  handleRemoveFromSelectedDevices
}: IAppAvailableDeviceListProps) => {
  const isSelected = (devicePublicId: string) => {
    if (!selectedDevices) return false;
    return selectedDevices.includes(devicePublicId);
  };

  const getDeviceList = (arr: string[]) => {
    return arr.map((devicePublicId) => {
      return (
        <AvailableDeviceItem
          key={devicePublicId}
          devicePublicId={devicePublicId}
          displaySettings={['stationName', 'deviceType']}
          isSelected={isSelected(devicePublicId)}
          handleRemoveFromSelectedDevices={handleRemoveFromSelectedDevices}
          handleAddToSelectedDevices={handleAddToSelectedDevices}
        />
      );
    });
  };

  return (
    <Box sx={styles.addedDevicesListWrapper}>
      <List>
        <Box>{getDeviceList(filteredDevices)}</Box>
      </List>
    </Box>
  );
};

const styles = {
  addedDevicesListWrapper: {
    width: '300px',
    height: '100%'
  },
  noDevicesWrapper: {
    marginTop: '50px'
  }
};

export default AppAvailableDeviceList;
