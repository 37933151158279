import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Typography, Paper, Alert, AlertTitle } from '@mui/material';
import SiteDetail from 'features/SimBilling/Components/Entrypoints/Site/Children/SiteDetail';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { useAclAuth, usePropertyLogic } from 'features/SimBilling/Hooks';
import { ErrorType, ErrorTypeValues } from 'shared/api/ApiError';
import { useTranslation } from 'react-i18next';

const Site = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { aclToken, aclUserName } = useAclAuth();
  const { user, userError, siteDetails, siteError } = usePropertyLogic();
  const { t } = useTranslation('simbillings'); // Use 'simbillings' namespace

  useEffect(() => {
    if (siteError === undefined || siteError === null || siteError === '') {
      setErrorMessage('');
      return;
    }
    const isValidErrorKey = (key: any): key is keyof typeof ErrorTypeValues => {
      return key in ErrorTypeValues;
    };

    if (isValidErrorKey(siteError)) {
      setErrorMessage(ErrorTypeValues[siteError].message1);
    } else {
      setErrorMessage(ErrorTypeValues[ErrorType.UNEXPECTED].message1);
    }
  }, [siteError]);

  useEffect(() => {
    if (siteDetails) {
      setErrorMessage('');
    }
  }, [siteDetails]);

  if (aclToken === null || aclUserName === null) {
    return <Navigate replace to="/simbilling" />;
  }
  if (userError) {
    return <Navigate replace to="/simbilling" />;
  }

  return (
    <>
      {(!user || !siteDetails) && !siteError && <Spinner />}
      <Box>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="sectionHeader" gutterBottom>
            {t('Site.Title')}
          </Typography>
          {errorMessage && errorMessage !== '' ? (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2}>
              <Alert severity="error" sx={{ mb: 1 }}>
                <AlertTitle>{t('Site.Error')}</AlertTitle>
                {errorMessage}
              </Alert>
            </Box>
          ) : !user || !siteDetails ? (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2}>
              <Typography variant="body1" sx={{ mt: 2 }}>
                {t('Site.Loading_Site')}
              </Typography>
            </Box>
          ) : (
            <SiteDetail user={user} siteDetails={siteDetails} />
          )}
        </Paper>
      </Box>
    </>
  );
};

export default Site;
