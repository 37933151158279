import { createQuikspec, updateQuikspec } from '../../../shared/api/Aws/QuikSpecApi';
import { FormValues } from '../Types/QuikspecFormTypes';
import { createQuikSpecData, updateQuikSpecData } from './QuikspecC2DataValues';

export async function createQuikSpecAndNavigate(values: FormValues, actions: any, navigate: any) {
  const filteredItems = values.items.filter((item) => item.quantity > 0);
  const filteredItemsOnUI = values.itemsOnUI.filter((item) => {
    if (item.quantity > 0) {
      return true;
    } else {
      const accessoryQuantities = item.accessories.map((accessory: any) => accessory.quantity);
      const totalAccessoryQuantity = accessoryQuantities.reduce((accumulator: number, currentValue: number) => {
        return accumulator + currentValue;
      }, 0);
      if (totalAccessoryQuantity > 0) {
        return true;
      }
    }

    return false;
  });

  const filteredValues = { ...values, items: filteredItems, itemsOnUI: filteredItemsOnUI };

  /*Country code for quote products. There can be 2 types of countryCode: USA(3), CA(1)*/
  const countryCode = localStorage.getItem('C2CompanyCountry');

  //constructing data to be sent to C2 API based of QuickSpec form values
  const data = createQuikSpecData(filteredValues, countryCode);
  try {
    await createQuikspec(data);
    navigate('/quikspec-summary', { state: { values: filteredValues } });
  } catch (error) {
    console.error('Error creating QuikSpec:', error);
  } finally {
    actions.setSubmitting(false);
  }
}

export async function updateQuikspecAndNavigate(values: FormValues, actions: any, navigate: any) {
  const filteredItems = values.items.filter((item) => item.quantity > 0);
  const filteredValues = { ...values, items: filteredItems };
  /*Country code for quote products. There can be 2 types of countryCode: USA(3), CA(1)*/
  const countryCode = localStorage.getItem('C2CompanyCountry');

  //constructing data to be sent to C2 API based of QuickSpec form values
  const data = updateQuikSpecData(filteredValues, countryCode);
  try {
    await updateQuikspec(data);
    navigate('/quikspec-summary', { state: { values: filteredValues } });
  } catch (error) {
    console.error('Error creating QuikSpec:', error);
  } finally {
    actions.setSubmitting(false);
  }
}
