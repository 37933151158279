import React from 'react';
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import './LanguageSelector.css';
import { usePermission, PermissionContextType } from '../context/PermissionContext';
import { FeatureName } from 'permissions/utils';

// Custom hook to handle language change logic
const useLanguageChangeHandler = () => {
  const { i18n } = useTranslation();
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value as string); // Change the language code
  };

  return { i18n, handleLanguageChange };
};

// Consolidated Language Selector Component for Header
const LanguageSelector: React.FC = () => {
  const { i18n, handleLanguageChange } = useLanguageChangeHandler(); // Get language change handler
  const { hasFeatureAccess }: PermissionContextType = usePermission(); // Get permissions context and user information

  // Check if the user has access to the language selector
  const isVisibleForUser = hasFeatureAccess(FeatureName.LANGUAGESELECTOR);

  if (!isVisibleForUser) return null; // Do not render if not allowed

  return (
    <div className="language-selector-header-wrapper">
      <FormControl variant="outlined" className="language-selector-header" aria-label="Language Selector">
        <Select
          value={i18n.language}
          onChange={handleLanguageChange}
          displayEmpty
          renderValue={() => <LanguageIcon style={{ color: '#fff', fontSize: 30 }} />} // Render only the language icon
          className="language-selector-header"
          MenuProps={{
            PaperProps: {
              className: 'MuiPaper-root' // Updated CSS class for dropdown styling
            }
          }}
        >
          {/* Language Options */}
          <MenuItem value="en">
            🇺🇸 {i18n.t('Language.en')} {/*i18n to get localized language names */}
          </MenuItem>
          <MenuItem value="fr">🇫🇷 {i18n.t('Language.fr')}</MenuItem>
          <MenuItem value="es">🇪🇸 {i18n.t('Language.es')}</MenuItem>
          <MenuItem value="ja">🇯🇵 {i18n.t('Language.ja')}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguageSelector;
