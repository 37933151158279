import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AuthContext } from 'context/AuthContext';
import { isApiError } from 'shared/api/ApiError';
import { loginUser } from 'shared/api/Aws/authApi';
import PasswordField from '../../../shared/components/PasswordField';
import SnackbarAlert from '../../../shared/components/SnackbarAlert';
import { BaseLoginStyles } from 'styles/form-input';
import '../../../styles/frontshine.css';
import { getAndSetC2Contact } from '../utils/c2Operations';
import { getCognitoErrorMessage } from '../utils/cognitoOperations';
import useValidationSchemas from '../validationSchemas'; // Ensure this import path is correct

const Login: React.FC = () => {
  const { setPassword, setUsername, setErrorMessage, setSession, errorMessage, setSessionUserAttributes } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setErrorMessage(''); // Clear error message when component mounts
  }, [setErrorMessage]);

  const { loginValidation } = useValidationSchemas(); // Correctly use the hook to get the validation schema

  type FormValues = yup.InferType<typeof loginValidation>; // Correctly infer the type

  const handleLogin = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const formattedEmail = values.email.toLowerCase();
    try {
      /* C2 processes */
      await getAndSetC2Contact(values.email);

      setUsername(values.email);
      setPassword(values.password);

      /* Authorization in Cognito process */
      const loginUserPayload = {
        userData: {
          email: formattedEmail,
          password: values.password
        }
      };
      const loginUserResponse = await loginUser(loginUserPayload);
      if (loginUserResponse?.data?.type === 'NewPasswordRequired') {
        setSessionUserAttributes(loginUserResponse?.data?.userAttributes);
        navigate('/auth/new-password');
        return;
      }
      localStorage.setItem('refreshToken', loginUserResponse?.data.refreshToken);
      localStorage.setItem('token', loginUserResponse?.data.token);
      localStorage.setItem('userId', loginUserResponse?.data.userId);
      navigate('/');
    } catch (error: unknown) {
      if (isApiError(error)) {
        handleApiError(error); // Separate error handling logic
      } else if (`${error}` === 'Error: User account not active.') {
        setErrorMessage(t('Error_UserAccountNotActive')); // Use i18n for error message
      } else {
        setErrorMessage(t('Error_Unexpected_Registering')); // Use i18n for error message
      }
    } finally {
      setSubmitting(false); // Ensure submitting is set to false on error or success
    }
  };

  // Separate error handling logic to avoid direct state updates in render
  const handleApiError = (error: any) => {
    if (error.code === 'UserNotConfirmedException') {
      navigate('/auth/confirm-account');
    }
    if (error.code === 'NewPasswordRequired') {
      setSession(error.session);
      navigate('/auth/new-password');
    }
    if (error.code === 'PasswordResetRequiredException') {
      navigate('/auth/reset-required');
    }
    const errorMessages = getCognitoErrorMessage(error.code);
    setErrorMessage(errorMessages);
  };

  return (
    <Box>
      {/* Snackbar for error and success messages */}
      <SnackbarAlert type="error" time={7000} text={error ?? ''} isOpen={!!error} onClose={() => setError(null)} />
      <SnackbarAlert
        type="success"
        time={7000}
        text={successMessage ?? ''}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      {/* Formik Form for Login */}
      <Formik initialValues={{ email: '', password: '' }} validationSchema={loginValidation} onSubmit={handleLogin}>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="email"
                  as={TextField}
                  sx={BaseLoginStyles.inputField}
                  label={t('Email_Address')}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="password"
                  as={PasswordField}
                  sx={BaseLoginStyles.inputField}
                  label={t('Password')}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              )}
              <Grid item xs={12} sx={BaseLoginStyles.otherOptions}>
                <Grid container sx={BaseLoginStyles.otherOptionContainer}>
                  <Grid item xs={12}>
                    <Button sx={BaseLoginStyles.forgotPasswordButton} onClick={() => navigate('/auth/reset-password')}>
                      {t('Button_ForgotPassword')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={BaseLoginStyles.loginButtonContainer}>
                <Box sx={BaseLoginStyles.centerContent}>
                  <LoadingButton
                    type="submit"
                    sx={BaseLoginStyles.loginButton}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    variant="aiphoneOrange"
                    loadingIndicator={<CircularProgress size="20px" color="white" />}
                  >
                    {t('Button_LogIn')}
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={12} sx={BaseLoginStyles.createAccountContainer}>
                <Box sx={BaseLoginStyles.centerContent}>
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                      fontFamily: "'Roboto Condensed', sans-serif"
                    }}
                  >
                    {t('Button_NeedAnAccount')}
                    <Button sx={BaseLoginStyles.registrationButton} onClick={() => navigate('/auth/registration')}>
                      {t('Button_RegisterHere')}
                    </Button>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Login;
