import { DeviceList } from 'store/slices/devicesSlice';
import { getDeviceTypeIntFromSearch, getDeviceModelFromString, getStationName } from './helperFunctions';

/**
 * The maximum number of answering stations that can be added to a unit based on the unit type
 */
export const unitTypeAnsweringStationLimits: { [key: number]: number } = {
  1: 8,
  2: 0,
  3: 8,
  4: 8,
  5: 8,
  6: 0
};

/**
 * The maximum number of door stations that can be added to a unit based on the unit type
 */
export const unitTypeDoorStationLimits: { [key: number]: number } = {
  1: 2,
  2: 0,
  3: 2,
  4: 2,
  5: 2,
  6: 10
};

/**
 * The maximum number of entrance stations that can be added to a unit based on the unit type
 */
export const unitTypeEntranceStationLimits: { [key: number]: number } = {
  1: 0,
  2: 10,
  3: 0,
  4: 0,
  5: 0,
  6: 0
};

/**
 * The device types that are considered answering stations
 */
export const answeringStationTypes = [4, 5, 14, 16];

/**
 * The device types that are considered door stations
 */
export const doorStationTypes = [8, 9, 10, 11, 12, 20];

/**
 * The device types that are considered entrance stations
 */
export const entranceStationTypes = [15];

/**
 * Get the device type of a device from the device list
 * @param id publicId of the device
 * @param deviceList the device list contained in the store
 * @returns the device type of the device as a string
 */
export const getDeviceType = (id: string, deviceList: DeviceList) => deviceList[id]?.basicInfo.deviceType;

/**
 * Provides the number of devices of a certain type in a list of devices
 * @param deviceIds publicIds of the devices
 * @param deviceList device list contained in the store
 * @param types station types to count
 * @returns the number of devices of the specified type
 */
export const countDevicesByType = (deviceIds: string[], deviceList: DeviceList, types: number[]) =>
  deviceIds.filter((id) => types.includes(getDeviceType(id, deviceList))).length;

export const getUnitTypeString = (unitType: number) => {
  switch (unitType) {
    case 1:
      return 'Guard Unit';
    case 2:
      return 'Entrance Unit';
    case 3:
      return 'Inside Area Unit';
    case 4:
      return 'Residential Unit';
    case 5:
      return 'Commercial Unit';
    case 6:
      return 'Outside Area Unit';
    default:
      return 'Unknown';
  }
};

export const buildDevicePayload = (
  devices: any[],
  isUnitRequired = false,
  isBuildingRequired = false,
  buildingNumber?: string,
  unitNumber?: string
) => {
  return devices
    .map((device) => {
      // TODO: This needs to be improved with next iteration of Add Devices Wizard
      const deviceType =
        device.device_type === 'IX-DV' && device.model_number === 'IX-EA'
          ? '11'
          : device.device_type === 'IX-DV' && device.model_number === 'IX-DVM'
          ? '20'
          : device.device_type === 'IX-SSA' && device.model_number === 'IX-NVP'
          ? '10'
          : device.device_type === 'IX-SSA' && device.model_number === 'IX-SS-2G'
          ? '10'
          : getDeviceTypeIntFromSearch(device.device_type);
      const deviceModel = getDeviceModelFromString(device.model_number);
      const deviceMacAddress = device.mac_addr;
      const deviceStationNumber = device.station_number || '';
      const deviceStationName = getStationName(device.device_type, device.station_name);

      if (deviceType) {
        const deviceObj: any = {
          deviceType: deviceType,
          deviceModel: deviceModel,
          macAddress: deviceMacAddress,
          stationNumber: deviceStationNumber,
          stationName: deviceStationName
        };

        // Add unitPublicId if required
        if (isUnitRequired && unitNumber) {
          deviceObj.unitPublicId = unitNumber;
        }

        // Add buildingPublicId if required
        if (isBuildingRequired && buildingNumber) {
          deviceObj.buildingPublicId = buildingNumber;
        }

        return deviceObj;
      }
      return null;
    })
    .filter((device) => device !== null); // Filter out null values
};
