import { getString } from 'shared/utils/LocalizationUtils';

/* All Page tab stub */
export const AllPageLabel = () => {
  const allPageLabel = getString('All_Page');
  return <span>{allPageLabel}</span>;
};

const AllPage = () => {
  const allPageLabel = getString('All_Page');
  return <span>{allPageLabel}</span>;
};

export default AllPage;
