import { useSelector } from 'react-redux';
import { AccessoryItem, Item, QuikspecCategory } from '../Types/QuikSpecTypes';
import { FormValues } from '../Types/QuikspecFormTypes';
import { getCurrentUser } from 'store/slices/usersSlice';

export const getInitialFormValues = (
  systemName: string,
  categories: QuikspecCategory[],
  state: any,
  systemforC2: string
): FormValues => {
  const currentUser = useSelector(getCurrentUser);
  let initialValues = {
    systemName: systemName,
    systemforC2: systemforC2,
    quikspecName: '',
    marketType: '',
    branchPublicId: '',
    userEmail: currentUser?.email ?? '',
    callingUserPublicId: localStorage.getItem('userId') ?? '',
    items: categories.reduce((accumulator, category) => {
      const categoryItems = category.items.map(
        (item: Item) =>
          ({
            itemName: item.deviceName,
            quantity: item?.defaultQuantity ?? 0,
            category: category.title,
            accessories:
              item.accessories?.map((accessory: AccessoryItem) => ({
                accessoryName: accessory.accessoryName,
                modelName: accessory.deviceName,
                quantity: item?.defaultQuantity ?? 0
              })) || []
          } as FormValues['items'][number])
      );
      return [...accumulator, ...categoryItems];
    }, [] as FormValues['items']),
    quikspecId: '',
    companyid: localStorage.getItem('C2CompanyId') ?? '',
    contactid: localStorage.getItem('C2ContactId') ?? '',
    description: '',
    ownerId: '',
    createdDate: '',
    lastUpdateDate: '',
    notes: [],
    wires: [],
    itemsOnUI: []
  };

  // If `state` is defined, use its values to override the default initial values
  if (state) {
    initialValues = {
      ...initialValues,
      quikspecId: state.quikspecId,
      quikspecName: state.quikspecName,
      marketType: state.marketType,
      branchPublicId: state.branchPublicId,
      callingUserPublicId: state.callingUserPublicId,
      userEmail: currentUser?.email ?? '',
      createdDate: state.createdDate,
      lastUpdateDate: state.lastUpdateDate,
      companyid: state.companyid,
      contactid: state.contactid,
      items: initialValues.items.map((item) => {
        // itemsOnUI is added on version 24.6.2 for Edit Spec
        if (state.itemsOnUI !== undefined) {
          const matchingStateItem = state.itemsOnUI.find((stateItem: any) => stateItem.itemName === item.itemName);
          if (matchingStateItem) {
            return { ...matchingStateItem };
          }
          return item;

          // Specs made before version 24.6.2 need to use the following process once
        } else {
          // Find the item in `state.items` that has the same `itemName`
          const matchingStateItem = state.items.find((stateItem: any) => stateItem.itemName === item.itemName);
          // If there is a matching item, use its `quantity` value
          if (matchingStateItem) {
            let updatedItem = {
              ...item,
              quantity: matchingStateItem.quantity,
              accessories:
                item.accessories?.map((accessory) => {
                  // Find the accessory in `matchingStateItem.accessories` that has the same `accessoryName`
                  const matchingStateAccessory = matchingStateItem.accessories.find(
                    (stateAccessory: any) => stateAccessory.accessoryName === accessory.accessoryName
                  );
                  // If there is a matching accessory, use its `quantity` value
                  if (matchingStateAccessory) {
                    return {
                      ...accessory,
                      quantity: matchingStateAccessory.quantity
                    };
                  }
                  // If there is no matching accessory, return the original accessory
                  return accessory;
                }) ?? []
            };

            // Run converting processes for each series
            if (state.systemName === 'IX | IXG Series') {
              updatedItem = specialIXGProcessing(initialValues, updatedItem);
              updatedItem = specialACProcessing(initialValues, updatedItem);
            }

            if (state.systemName === 'AC Series') {
              updatedItem = specialACProcessing(initialValues, updatedItem);
            }

            if (state.systemName === 'JP Series') {
              updatedItem = specialACProcessing(initialValues, updatedItem);
            }

            if (state.systemName === 'JO Series') {
              updatedItem = specialJOProcessing(initialValues, updatedItem, state);
            }

            if (state.systemName === 'JV Series') {
              updatedItem = specialJVProcessing(initialValues, updatedItem, state);
            }

            // Non-converted hidden item will default to be quantity 0
            if (updatedItem.category === '') {
              updatedItem.quantity = 0;
            }

            return updatedItem;
          }
          // If there is no matching item, return the original item
          return item;
        }
      })
    };
  }

  return initialValues;
};

const specialIXGProcessing = (initialValues: FormValues, item: FormValues['items'][number]) => {
  const updatedItem = { ...item, accessories: item.accessories ?? [] };

  if (item.itemName === 'IXG-APP') {
    const lifetimeLicenseItem = initialValues.items.find((i) => i.itemName === 'LIFETIME_LICENSE');
    if (lifetimeLicenseItem) {
      lifetimeLicenseItem.quantity = 1;
    }
  }

  return updatedItem;
};

const specialACProcessing = (initialValues: FormValues, item: FormValues['items'][number]) => {
  const updatedItem = { ...item, accessories: item.accessories ?? [] };

  // Credentials are different from the regular scenario with end item + accessory
  //
  // Regular scenario example: IX-DVF
  // IX-DVF is the parent item and SBX-IDVF is the child item (accessory)
  // When the quantity of SBX-IDVF is 1, the quantity of IX-DVF is always 1 (or higher)
  // Regular scenario is handled in getInitialFormValues()
  //
  // Special credential scenario example: AC-PC-40
  // AC-PC is the parent item and AC-PC-40 is the child item
  // When the quantity of AC-PC-40 is 1, the quantity of AC-PC is always 0 because there is no end item named AC-PC
  // AC-PC is just the group name for AC-PC-40 and AC-PC-H26
  // Special scenario needs to be handled in specialACProcessing()
  if (item.itemName === 'AC-PC-40' || item.itemName === 'AC-PC-H26') {
    const acPcItem = initialValues.items.find((i) => i.itemName === 'AC-PC');
    if (acPcItem) {
      acPcItem.accessories.find((accessory: any) => accessory.modelName === item.itemName).quantity = item.quantity;
      updatedItem.quantity = 0;
    }
  }

  if (item.itemName === 'AC-PF-40' || item.itemName === 'AC-PF-H26') {
    const acPcItem = initialValues.items.find((i) => i.itemName === 'AC-PF');
    if (acPcItem) {
      acPcItem.accessories.find((accessory: any) => accessory.modelName === item.itemName).quantity = item.quantity;
      updatedItem.quantity = 0;
    }
  }

  if (item.itemName === 'AC-PT-40' || item.itemName === 'AC-PT-H26') {
    const acPcItem = initialValues.items.find((i) => i.itemName === 'AC-PT');
    if (acPcItem) {
      acPcItem.accessories.find((accessory: any) => accessory.modelName === item.itemName).quantity = item.quantity;
      updatedItem.quantity = 0;
    }
  }

  return updatedItem;
};

const specialJOProcessing = (initialValues: FormValues, item: FormValues['items'][number], state: any) => {
  const updatedItem = { ...item, accessories: item.accessories ?? [] };

  if (item.itemName === 'JOS-1AW') {
    updatedItem.quantity = 0;
    const joDaItem = initialValues.items.find((i) => i.itemName === 'JO-DA');
    if (joDaItem) {
      joDaItem.quantity = 1;
    }
  }

  if (item.itemName === 'JOS-1VW') {
    updatedItem.quantity = 0;
    const joDvItem = initialValues.items.find((i) => i.itemName === 'JO-DV');
    if (joDvItem) {
      joDvItem.quantity = 1;
    }
  }

  if (item.itemName === 'JOS-1FW') {
    const sbxDvf = state.items.find((stateItem: any) => stateItem.itemName === 'SBX-DVF');
    if (sbxDvf && sbxDvf.quantity > 0) {
      updatedItem.quantity = 0;
      const joDvfItem = initialValues.items.find((i) => i.itemName === 'JO-DVF');
      if (joDvfItem) {
        joDvfItem.quantity = 0;
        const surfaceMountAccessory = (joDvfItem.accessories ?? []).find((a) => a.accessoryName === 'Surface Mount');
        if (surfaceMountAccessory) {
          surfaceMountAccessory.quantity = 1;
        }
      }
    } else {
      updatedItem.quantity = 0;
      const joDvfItem = initialValues.items.find((i) => i.itemName === 'JO-DVF');
      if (joDvfItem) {
        joDvfItem.quantity = 0;
        const flushMountAccessory = (joDvfItem.accessories ?? []).find((a) => a.accessoryName === 'Flush Mount');
        if (flushMountAccessory) {
          flushMountAccessory.quantity = 1;
        }
      }
    }
  }

  return updatedItem;
};

const specialJVProcessing = (initialValues: FormValues, item: FormValues['items'][number], state: any) => {
  const updatedItem = { ...item, accessories: item.accessories ?? [] };

  if (item.itemName === 'JVS-1V') {
    updatedItem.quantity = 0;
    const jvDvItem = initialValues.items.find((i) => i.itemName === 'JV-DV');
    if (jvDvItem) {
      jvDvItem.quantity = 1;
    }
  }

  if (item.itemName === 'JVS-1F') {
    const sbxDvf = state.items.find((stateItem: any) => stateItem.itemName === 'SBX-DVF');
    if (sbxDvf && sbxDvf.quantity > 0) {
      updatedItem.quantity = 0;
      const jvDvfItem = initialValues.items.find((i) => i.itemName === 'JV-DVF');
      if (jvDvfItem) {
        jvDvfItem.quantity = 0;
        const surfaceMountAccessory = (jvDvfItem.accessories ?? []).find((a) => a.accessoryName === 'Surface Mount');
        if (surfaceMountAccessory) {
          surfaceMountAccessory.quantity = 1;
        }
      }
    } else {
      updatedItem.quantity = 0;
      const jvDvfItem = initialValues.items.find((i) => i.itemName === 'JV-DVF');
      if (jvDvfItem) {
        jvDvfItem.quantity = 0;
        const flushMountAccessory = (jvDvfItem.accessories ?? []).find((a) => a.accessoryName === 'Flush Mount');
        if (flushMountAccessory) {
          flushMountAccessory.quantity = 1;
        }
      }
    }
  }

  return updatedItem;
};

export default getInitialFormValues;
