import { Box, Button, Link, ListItemIcon, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridValidRowModel,
  useGridApiRef,
  GridCellModes,
  GridCellParams,
  GridCellModesModel,
  GridToolbarContainer
} from '@mui/x-data-grid';
import React, { useCallback, useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { SearchDevice } from 'features/RemoteManagement/Types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getSite } from 'store/slices/siteSlice';
import { stationInfo } from 'store/slices/rmGatewaySlice';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { useTranslation } from 'react-i18next';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { useHandleGatewayCommandMutation, useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { IDevice } from 'store/slices/devicesSlice';

interface Props {
  searchSelectedDevices: StationDeviceSearchResult[];
  setSearchSelectedDevices: (devices: []) => void;
  setIsAddingManually: (isAddingManually: boolean) => void;
  restrictedMacAddresses: string[];
  setRestrictedMacAddresses: (macAddresses: string[]) => void;
  deviceTypeFilter?: string[];
  selectedUnitType?: number;
}

const DEVICE_FILTER = ['IX-DA', 'IX-BA', 'IX-MV', 'IX-FA', 'IX-SPMIC', 'IX-SOFT'];

const getModelNumberOptions = (deviceType: string) => {
  switch (deviceType) {
    case 'IX-MV7':
      return ['IX-MV7-HB-L', 'IX-MV7-B', 'IX-MV7-W', 'IX-MV7-HB', 'IX-MV7-HW-JP'];
    case 'IX-RS':
      return ['IX-RS-B', 'IX-RS-W'];
    case 'IXW-MA':
      return ['IXW-MA', 'IXW-MAA'];
    case 'IX-SSA':
      return ['IX-SSA', 'IX-SSA-RA', 'IX-SSA-2RA'];
    case 'IX-DV':
      return [
        'IX-DV',
        'IX-DVF',
        'IX-DVF-4A',
        'IX-DVF-6',
        'IX-DVF-HW',
        'IX-DVF-L',
        'IX-DVF-PR',
        'IX-DVF-RA',
        'IX-DVF-2RA',
        'IX-DVF-10KP'
      ];
    case 'IX-SS-2G':
      return ['IX-SS-2G', 'IX-NVP'];
    case 'IX-EA':
      return ['IX-EA'];
    case 'IXG-2C7':
      return ['IXG-2C7', 'IXG-2C7-L'];
    case 'IXG-DM7':
      return ['IXG-DM7-HID'];
    case 'IXG-MK':
      return ['IXG-MK'];
    case 'IXGW-LC':
      return ['IXGW-LC'];
    case 'IXGW-GW':
      return ['IXGW-GW', 'IXGW-TGW'];
    case 'IX-DVM':
      return ['IX-DVM'];
    default:
      return [];
  }
};

// Get rid of type errors with this
type StationDeviceSearchResult = (SearchDevice | stationInfo) & {
  station_type: string;
  id: string;
  model_number: string;
};

const StationSearchDataGrid = ({
  searchSelectedDevices,
  setSearchSelectedDevices,
  setIsAddingManually,
  restrictedMacAddresses,
  deviceTypeFilter,
  selectedUnitType
}: Props) => {
  const selectedDeviceIds = React.useMemo(
    () => searchSelectedDevices.map((device) => device.id),
    [searchSelectedDevices]
  );
  const setCurrentRows = React.useCallback(() => {
    if (searchSelectedDevices.length > 0) {
      return searchSelectedDevices;
    }
    return [];
  }, [searchSelectedDevices]);
  const site = useSelector((state: RootState) => getSite(state));
  const [rows, setRows] = useState<StationDeviceSearchResult[]>(setCurrentRows);
  const [isSearching, setIsSearching] = useState(false);

  const gateway = useSelector((state: RootState) => state.devices.DeviceList[site.siteInfo.registeredGatewayPublicId]);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  useSelector((state: RootState) => state.devices);
  const gatewayLoading = useSelector((state: RootState) => state.gateway.loading);
  const gatewayList = useSelector((state: RootState) => state.gateway.gateway) || [];
  const stationList = useSelector((state: RootState) => state.gateway.stations) || [];
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const awsPropertyId = useSelector((state: RootState) => state.site.siteInfo?.awsPropertyId);
  const [updateDevice] = useUpdateDeviceMutation();
  const [isDeviceTypeAllowed, setIsDeviceTypeAllowed] = useState(true);

  const { t } = useTranslation();

  // Check if the user has permission to edit the site
  const sitePublicId = site?.siteInfo?.publicId;
  const { isAllowedTo } = usePermission();
  const hasEditPermission = isAllowedTo('site:edit', sitePublicId, PermissionsContextType.SITE);

  const disallowedDeviceTypes: { [key: number]: string[] } = {
    1: ['Video Master Station', 'Tenant Station', 'Video Entrance Station'],
    2: [
      'Video Master Station',
      'Sub Master Station',
      'Tenant Station',
      'Video Guard Station',
      'Video Door Station',
      'Audio Door Station',
      'Emergency Station',
      'Stainless Steel Audio Door Station',
      'Mullion Video Door Station',
      'Plastic Video Door Station'
    ],
    3: ['Tenant Station', 'Video Entrance Station'],
    4: ['Video Master Station', 'Video Guard Station', 'Video Entrance Station'],
    5: ['Tenant Station', 'Video Guard Station', 'Video Entrance Station'],
    6: ['Video Master Station', 'Sub Master Station', 'Tenant Station', 'Video Guard Station', 'Video Entrance Station']
  };

  const isDeviceTypeDisallowed = (deviceTypeFilter: string[], disallowedTypes: string[]): boolean => {
    return disallowedTypes.some((type) => deviceTypeFilter.includes(type));
  };

  useEffect(() => {
    if (deviceTypeFilter !== undefined && selectedUnitType !== undefined) {
      const disallowedTypes = disallowedDeviceTypes[selectedUnitType] || [];
      const isDisallowed = deviceTypeFilter && isDeviceTypeDisallowed(deviceTypeFilter, disallowedTypes);

      setIsDeviceTypeAllowed(!isDisallowed);
    }
  }, [selectedUnitType, deviceTypeFilter]);

  const searchColumns: GridColDef[] = React.useMemo(
    () => [
      {
        field: 'mac_addr',
        headerName: 'MAC Address',
        minWidth: 150,
        display: 'flex',
        align: 'center',
        renderCell: (params) => {
          const isRestricted = restrictedMacAddresses.includes(params.value);
          return (
            <div>
              <Typography variant="body2">{params.value}</Typography>
              {isRestricted && (
                <Typography variant="caption" color="error">
                  Already added to site
                </Typography>
              )}
            </div>
          );
        }
      },
      { field: 'device_type', headerName: 'Device Type', minWidth: 130 },
      { field: 'ip_addr', headerName: 'IP Address', minWidth: 130 },
      { field: 'station_type', headerName: 'Station Type', minWidth: 170 },
      {
        field: 'model_number',
        headerName: 'Model Number',
        width: 300,
        editable: true,
        align: 'left',

        type: 'singleSelect',
        cellClassName: (params) => {
          // If the model number is not selected, the cellClassName should be "needs-selection"
          return params.value ? '' : 'needs-selection';
        },
        valueOptions: (params) => getModelNumberOptions(params.row.device_type),
        renderCell(params) {
          const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${params.value}.png`;
          if (params.value) {
            return (
              <MenuItem sx={{ paddingLeft: 0, paddingRight: 0, display: 'flex', justifyContent: 'flex-end' }}>
                {params.value ? (
                  <ListItemIcon sx={styles.stationIcons}>
                    <img src={imgUrl} alt="" />
                  </ListItemIcon>
                ) : null}
                <ListItemText style={{ alignItems: 'left' }} primary={params.row.station_type} />
              </MenuItem>
            );
          } else {
            const arrayOfOptions = getModelNumberOptions(params.row.device_type);
            if (arrayOfOptions.length === 1) {
              // set the row as the first option and update the row so the checkbox is enabled
              processRowUpdate({ ...params.row, model_number: arrayOfOptions[0] }, params.row);
              return (params.value = arrayOfOptions[0]);
            } else {
              return t('Select_Model_Number');
            }
          }
        },
        renderEditCell(params) {
          return (
            <Select
              variant={'standard'}
              SelectDisplayProps={{
                style: { display: 'flex', alignItems: 'center' }
              }}
              value={params.value}
              onChange={(event) => {
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: event.target.value
                });
              }}
              inputProps={{
                'aria-label': 'Select Model Number'
              }}
              fullWidth
              placeholder="Select Model Number"
              defaultOpen
            >
              {getModelNumberOptions(params.row.device_type).map((option) => {
                const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${option}.png`;
                return (
                  <MenuItem key={option} value={option} disableGutters sx={{ width: '100%' }}>
                    <ListItemIcon sx={styles.stationIcons}>
                      <img src={imgUrl} alt={option} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                );
              })}
            </Select>
          );
        }
      },
      { field: 'station_name', headerName: 'Name', width: 120 },
      { field: 'station_number', headerName: 'No.', width: 75 }
    ],
    [restrictedMacAddresses, t]
  );

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (gatewayLoading) {
      setIsSearching(true);
    } else {
      const formattedGatewayList = sortDevices([
        ...gatewayList,
        ...stationList
      ]) as unknown as StationDeviceSearchResult[];
      if (deviceTypeFilter && deviceTypeFilter.length > 0) {
        const filteredGatewayList = formattedGatewayList.filter((device) =>
          deviceTypeFilter?.includes(device?.station_type)
        );
        setRows(filteredGatewayList);
        setIsSearching(false);
        return;
      } else {
        setRows(formattedGatewayList);
        setIsSearching(false);
      }
    }
  }, [gatewayLoading]);

  const sortDevices = React.useCallback((searchFromGateway: (SearchDevice | stationInfo)[]) => {
    return (
      searchFromGateway
        .map((device: SearchDevice | stationInfo, index: number) => {
          // Removing specific devices as per: https://aiphone-corporation.atlassian.net/browse/RM-464
          if (DEVICE_FILTER.includes(device.device_type)) {
            return null;
          }
          switch (device.device_type) {
            case 'IX-MV7':
              return { ...device, station_type: 'Video Master Station', id: index + 1, model_number: '' };
            case 'IX-RS':
              return { ...device, station_type: 'Sub Master Station', id: index + 1, model_number: '' };
            case 'IXW-MA':
              return { ...device, station_type: 'I/O Network Adaptor', id: index + 1, model_number: '' };
            case 'IX-DV':
              return { ...device, station_type: 'Video Door Station', id: index + 1, model_number: '' };
            case 'IX-SSA':
            case 'IX-SS':
              return {
                ...device,
                device_type: 'IX-SSA',
                station_type: 'Audio Door Station',
                id: index + 1,
                model_number: ''
              };
            case 'IX-SS-2G':
              return { ...device, station_type: 'Stainless Steel Audio Door Station', id: index + 1, model_number: '' };
            case 'IXG-2C7':
              return { ...device, station_type: 'Apartment', id: index + 1, model_number: '' };
            case 'IXG-DM7':
              return { ...device, station_type: 'Video Entrance Station', id: index + 1, model_number: '' };
            case 'IXG-MK':
              return { ...device, station_type: 'Video Guard Station', id: index + 1, model_number: '' };
            case 'IXG-LC':
              return { ...device, station_type: 'Lift Controller', id: index + 1, model_number: '' };
            case 'IXGW-GW':
              return { ...device, station_type: 'Gateway Adaptor', id: index + 1, model_number: '' };
            case 'IX-DVM':
              return { ...device, station_type: 'Mullion Video Door Station', id: index + 1, model_number: '' };
            case 'IX-EA':
              return { ...device, station_type: 'Video Door Station', id: index + 1, model_number: '' };
            case 'IX-SOFT':
              return { ...device, station_type: 'Master Door Station', id: index + 1, model_number: '' };
            default:
              return { ...device, id: index + 1 };
          }
        })
        // Filters out the null devices, ones that we found matches for for exclusions.
        .filter((device) => device !== null) as unknown as StationDeviceSearchResult[]
    );
  }, []);

  const handleStationSearch = React.useCallback(async () => {
    if (!gateway) {
      throw new Error('Gateway not found');
    }

    if (!hasEditPermission) {
      throw new Error('You have view-only access to this site.');
    }

    let whichId = site.siteInfo?.systemId ? site.siteInfo?.systemId : gateway.basicInfo?.adminId;
    let whichPassword = site.siteInfo?.systemPassword ? site.siteInfo?.systemPassword : gateway.basicInfo?.adminPass;
    const isGatewayFirstSync = gateway?.lastSyncedOn === null;
    if (isGatewayFirstSync && !site.siteInfo?.systemId && !site.siteInfo?.systemPassword) {
      whichId = 'admin';
      whichPassword = 'admin';
    }

    const gatewayInfo = {
      awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwIpAddress: gateway?.networkSettings?.ipV4Address ?? '',
      gwId: whichId,
      gwPassword: whichPassword
    };

    setIsSearching(true);
    try {
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
      await handleGatewayCommand(ioTPayload).unwrap();
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      setIsSearching(false);

      // Update Firmware version for the gateway
      // There was a reference error for "gwMacAddress", so I am referencing "gatewayInfo.gwMacAddress" instead
      const gateway = fetchResponse.find(
        (device: { mac_addr: string }) => device.mac_addr === gatewayInfo.gwMacAddress
      );
      if (gateway) {
        const params = {
          device: {
            basicInfo: {
              ...gateway.basicInfo,
              firmwareVersion: gateway.basicInfo?.firmwareVersion
            },
            publicId: gateway.publicId,
            unitPublicId: gateway.unitPublicId
          }
        };
        updateDevice(params);
      }

      const searchFromGateway = fetchResponse?.payload;
      if (searchFromGateway?.length > 0) {
        const updatedSearchFromGateway = sortDevices(searchFromGateway);
        setRows(updatedSearchFromGateway);
      } else {
        setRows([]);
      }
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      setRows([]);
    }
  }, [gateway, site.siteInfo.systemId, site.siteInfo.systemPassword]);

  const handleCellClick = useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: params.field === 'model_number' ? GridCellModes.Edit : GridCellModes.View }
        }
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleSearchRowSelection = React.useCallback(
    (selection: GridValidRowModel) => {
      const selectedRows = selection.map((rowId: any) => {
        // Simplified return statement
        return rows.find((row) => row.id === rowId);
      });
      setSearchSelectedDevices(selectedRows);
    },
    [rows]
  );

  const processRowUpdate = React.useCallback((updatedRow: any, originalRow: any) => {
    if (updatedRow.model_number !== originalRow.model_number) {
      setRows((prevRows) => prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
      return { ...updatedRow, model_number: updatedRow.model_number };
    } else {
      return updatedRow;
    }
  }, []);

  return (
    <Box>
      <Box sx={{ height: 550, width: '100%' }}>
        <DataGrid
          apiRef={apiRef}
          rows={!isDeviceTypeAllowed ? [] : rows}
          columns={searchColumns}
          checkboxSelection
          disableRowSelectionOnClick
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          onRowSelectionModelChange={handleSearchRowSelection}
          processRowUpdate={(updatedRow, originalRow) => processRowUpdate(updatedRow, originalRow)}
          onProcessRowUpdateError={(
            params //TODO: Handle Error
          ) => console.error('Error updating row:', params)}
          rowSelectionModel={selectedDeviceIds}
          isRowSelectable={(params) => {
            if (restrictedMacAddresses.includes(params.row.mac_addr)) {
              return false;
            }

            // If there is a registered gateway in the site, do not allow an additional gateway to be added
            if (params.row.device_type === 'IXGW-GW' || params.row.device_type === 'IXGW-TGW') {
              return false;
            }

            // If there are already 500 IO Adaptors, disallow adding any more
            if (params.row.device_type === 'IXW-MA') {
              const ioAdaptorCountFromSelection = searchSelectedDevices.filter(
                (device) => device.device_type === 'IXW-MA'
              ).length;
              const ioAdaptorCountFromDeviceList = Object.values(deviceList).filter(
                (device: IDevice) => device.basicInfo.deviceType === 6
              ).length;

              if (ioAdaptorCountFromSelection + ioAdaptorCountFromDeviceList >= 500) {
                return false;
              }
            }

            // If there are already 16 Lift Controls, disallow adding any more
            if (params.row.device_type === 'IXGW-LC' || params.row.device_type === 'IXG-LC-RY20') {
              const liftControlCountFromSelection = searchSelectedDevices.filter(
                (device) => device.device_type === 'IXGW-LC' || device.device_type === 'IXG-LC-RY20'
              ).length;
              const liftControlCountFromDeviceList = Object.values(deviceList).filter(
                (device: IDevice) => device.basicInfo.deviceType === 17
              ).length;

              if (liftControlCountFromSelection + liftControlCountFromDeviceList >= 16) {
                return false;
              }
            }

            return params.row.model_number !== '';
          }}
          loading={isSearching}
          slots={{
            noRowsOverlay: () => {
              if (!isDeviceTypeAllowed) {
                return (
                  <Box sx={styles.noStation}>
                    <Typography variant="h6">The selected unit does not support this device type.</Typography>
                    <Typography variant="body1">Select a different device type or unit type to add devices.</Typography>
                  </Box>
                );
              }

              return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography variant="h5">
                    {t('No_Stations_Found')},{' '}
                    <Link
                      sx={styles.clickableLink}
                      onClick={() => {
                        setIsAddingManually(true);
                      }}
                    >
                      {t('Try_Adding_Devices_Manually')}
                    </Link>{' '}
                    {t('Or_Search_Again')}
                    <Button
                      color="primary"
                      startIcon={<SearchIcon />}
                      onClick={handleStationSearch}
                      disabled={!hasEditPermission}
                    />
                  </Typography>
                </Box>
              );
            },
            toolbar: () => {
              return (
                <GridToolbarContainer>
                  <Button
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={handleStationSearch}
                    disabled={!isDeviceTypeAllowed || !hasEditPermission}
                  >
                    {t('Search_for_stations')}
                  </Button>
                </GridToolbarContainer>
              );
            }
          }}
        />
      </Box>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  stationIcons: {
    minWidth: '80px !important',
    display: 'flex',
    justifyContent: 'center'
  },
  clickableLink: {
    cursor: 'pointer'
  },
  restrictedMacAddress: {
    backgroundColor: 'gray'
  },
  allowedMacAddress: {
    backgroundColor: 'white'
  },
  noStation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    p: 3
  }
};

export default StationSearchDataGrid;
