import { getString } from 'shared/utils/LocalizationUtils';

/* Option Input Page tab stub */
export const OptionInputPageLabel = () => {
  const optionInputPage = getString('Option_Input_Page');
  return <span>{optionInputPage}</span>;
};

const OptionInputPage = () => {
  const optionInputPage = getString('Option_Input_Page');
  return <span>{optionInputPage}</span>;
};

export default OptionInputPage;
