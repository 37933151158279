import { useSelector } from 'react-redux';
import { Box, ListItemIcon, Typography, ListItem, Checkbox } from '@mui/material';
import { IDevice } from 'store/slices/devicesSlice';
import { IUnit } from 'store/slices/unitsSlice';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { getUnitsById } from 'features/RemoteManagement/selectors/unit.selectors';
import ListItemButton from '@mui/material/ListItemButton';
import DevicesIcon from '@mui/icons-material/Devices';
import { getDeviceTypeStringFromInt } from 'shared/utils/helperFunctions';
import theme from 'data/theme';

interface IAddedDevicesItemProps {
  device: IDevice;
  isSelected: boolean;
  handleSelect: (id: string) => void;
  handleRemoveCallDestinationDevice: (id: string) => void;
}

const AddedDevicesItem = ({
  device,
  isSelected,
  handleSelect,
  handleRemoveCallDestinationDevice
}: IAddedDevicesItemProps) => {
  const selectedDevice = useSelector(getSelectedDevice)?.basicInfo;
  if (!selectedDevice) return <Box>Error Loading Device</Box>;
  const defaultValue = '-';

  const { basicInfo, publicId } = device;
  const unitPublicId = device?.unitPublicId || null;

  const unit: IUnit | null = getUnitsById(unitPublicId);
  const deviceType = getDeviceTypeStringFromInt(basicInfo.deviceType);

  const handleSelectDevice = () => {
    if (isSelected) {
      handleRemoveCallDestinationDevice(publicId);
      return;
    }
    handleSelect(publicId);
  };

  return (
    <ListItem key={publicId} disablePadding>
      <ListItemButton sx={styles.menuItem} onClick={handleSelectDevice} selected={true}>
        <Box style={styles.iconWrapper}>
          <ListItemIcon>
            {isSelected ? (
              <Checkbox edge="start" checked={isSelected} onChange={handleSelectDevice} tabIndex={-1} disableRipple />
            ) : (
              <DevicesIcon />
            )}
          </ListItemIcon>
        </Box>
        <Box sx={styles.deviceDetailsWrapper}>
          <Typography variant="body2" sx={styles.deviceDetailsItem}>
            <Typography variant="h7" sx={styles.itemTitle}>
              Device:
            </Typography>
            {deviceType}
          </Typography>
          <Typography variant="body2" sx={styles.deviceDetailsItem}>
            <Typography variant="h7" sx={styles.itemTitle}>
              Station Name:
            </Typography>
            {device.basicInfo.stationName}
          </Typography>
          <Typography variant="body2" sx={styles.deviceDetailsItem}>
            <Typography variant="h7" sx={styles.itemTitle}>
              Unit Number:
            </Typography>
            {unit?.unitNumber || defaultValue}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

const styles = {
  menuItem: {
    backgroundColor: theme.palette.neutral.light
  },
  iconWrapper: {
    width: '15%',
    cursor: 'pointer'
  },
  deviceDetailsWrapper: {},
  deviceDetailsItem: {},
  itemTitle: {
    marginRight: '5px',
    fontWeight: 'bold'
  }
};

export default AddedDevicesItem;
