import React from 'react';

import 'styles/frontshine.css';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';

export interface ILoadingProgressStepCounterProps {
  currentStep: number;
  totalSteps: number;
  message?: string;
}

/**
 * Loading progress step counter component
 *
 * @param currentStep
 * @param totalSteps
 * @param message
 * @constructor
 */
export const LoadingProgressStepCounter: React.FC<ILoadingProgressStepCounterProps> = ({
  currentStep,
  totalSteps,
  message
}) => {
  // Return nothing if these are unfilled
  if (totalSteps === 0 || currentStep === 0) {
    return null;
  }
  return (
    <Spinner
      aboveSpinner={message ? <h3 className={'text-center font-semibold'}>{message}</h3> : null}
      belowSpinner={<p className={'text-center'}>{`Step ${currentStep} of ${totalSteps}`}</p>}
    />
  );
};
