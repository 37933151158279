import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useMemo } from 'react';

const useReduxBranches = () => {
  const branches = useSelector((state: RootState) => state.branches.Branches);
  const branchesLoading = useSelector((state: RootState) => state.branches.Loading);
  const currentBranch = useSelector((state: RootState) => state.branches.currentBranch);
  const selectedBranch = useSelector((state: RootState) => state.branches.selectedBranch);

  return useMemo(
    () => ({ branches, branchesLoading, currentBranch, selectedBranch }),
    [branches, branchesLoading, currentBranch, selectedBranch]
  );
};
export default useReduxBranches;
