import { getString } from 'shared/utils/LocalizationUtils';

/* Network Camera Integration tab stub */
export const NetworkCameraIntegrationLabel = () => {
  const networkCameraIntegration = getString('Network_Camera_Integration');
  return <span>{networkCameraIntegration}</span>;
};

const NetworkCameraIntegration = () => {
  const networkCameraIntegration = getString('Network_Camera_Integration');
  return <span>{networkCameraIntegration}</span>;
};

export default NetworkCameraIntegration;
