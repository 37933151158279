import { Box, Card } from '@mui/material';
import DeviceRelays from './components/relays/DeviceRelays';
import ControlPanel from './components/ControlPanel';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';
import RelayOutputSettings from './components/selectedRelay/RelayOutputSettings';
import RelayInputSettings from './components/selectedRelay/RelayInputSettings';

/**TODO: Move to utils */
const getAllRelays = (inputList: IDeviceContactInput[] | undefined, outputList: IContactOutput[] | undefined) => {
  const combinedRelays: { [key: string]: IDeviceContactInput | IContactOutput } = {};

  inputList?.forEach((input: IDeviceContactInput) => {
    combinedRelays[input.publicId] = input;
  });

  outputList?.forEach((output: IContactOutput) => {
    combinedRelays[output.publicId] = output;
  });

  return combinedRelays;
};

const isContactOutput = (relay: IDeviceContactInput | IContactOutput | null | undefined): relay is IContactOutput => {
  return relay !== null && relay !== undefined && 'outputNumber' in relay;
};

const isDeviceContactInput = (
  relay: IDeviceContactInput | IContactOutput | null | undefined
): relay is IDeviceContactInput => {
  return relay !== null && relay !== undefined && 'inputNumber' in relay;
};

const InputOutput = () => {
  const [selectedRelay, setSelectedRelay] = useState<IDeviceContactInput | IContactOutput | null>(null);
  const [needsSave, setNeedsSave] = useState<boolean>(false);
  const [newRelaySettings, setNewRelaySettings] = useState<Array<IDeviceContactInput | IContactOutput>>([]);
  const selectedDevicePublicId = useSelector((state: RootState) => state.devices.SelectedDevice) || '';

  const inputList = useSelector(
    (state: RootState) => state.devices.DeviceList[selectedDevicePublicId]?.contactInputList
  );
  const outputList = useSelector(
    (state: RootState) => state.devices.DeviceList[selectedDevicePublicId]?.contactOutputList
  );

  const allRelays = getAllRelays(inputList, outputList);

  const [selectedRelayId, setSelectedRelayId] = useState<string>('');

  useEffect(() => {
    const relay = allRelays[selectedRelayId];
    if (relay !== undefined) {
      // Ensure relay is not undefined before setting it
      setSelectedRelay(relay);
    }
  }, [selectedRelayId, allRelays]);

  const handleSelectRelay = (relayId: string) => {
    setSelectedRelayId(relayId);
  };

  const handleTabChange = (newValue: string) => {
    if (newValue === 'output') {
      if (outputList && outputList.length > 0) {
        setSelectedRelayId(outputList[0].publicId);
      }
    } else if (newValue === 'input') {
      if (inputList && inputList.length > 0) {
        setSelectedRelayId(inputList[0].publicId);
      }
    }
  };

  return (
    <>
      <Box sx={styles.inputOutputWrapper}>
        <Box sx={styles.controlPanelWrapper}>
          <ControlPanel needsSave={needsSave} setNeedsSave={setNeedsSave} newRelaySettings={newRelaySettings} />
        </Box>
        <Card sx={styles.relayAndSettingsWrapper}>
          <Box sx={styles.deviceRelaysWrapper}>
            <DeviceRelays
              handleSelectRelay={handleSelectRelay}
              selectedRelayId={selectedRelayId}
              handleTabChange={handleTabChange}
            />
          </Box>
          <Box sx={styles.relaySettingsWrapper}>
            {isContactOutput(selectedRelay) ? (
              <RelayOutputSettings
                selectedRelay={selectedRelay}
                setNeedsSave={setNeedsSave}
                newRelaySettings={newRelaySettings}
                setNewRelaySettings={setNewRelaySettings}
              />
            ) : isDeviceContactInput(selectedRelay) ? (
              <RelayInputSettings
                selectedRelay={selectedRelay}
                setNeedsSave={setNeedsSave}
                newRelaySettings={newRelaySettings}
                setNewRelaySettings={setNewRelaySettings}
              />
            ) : null}
          </Box>
        </Card>
      </Box>
    </>
  );
};

const styles = {
  inputOutputWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  relayAndSettingsWrapper: {
    display: 'flex',
    height: '90%',
    overflow: 'auto'
  },
  controlPanelWrapper: {
    width: '100%',
    height: '10%'
  },
  deviceRelaysWrapper: {
    display: 'flex',
    minWidth: '360px',
    height: '100%',
    bgcolor: 'neutral.light',
    overflow: 'auto',
    justifyContent: 'center',
    backgroundColor: '#EFEFEF'
  },
  relaySettingsWrapper: {
    width: '79%',
    minWidth: '1000px',
    height: '100%',
    paddingTop: '10px',
    backgroundColor: '#FFFFFF',
    overflow: 'auto'
  }
};

export default InputOutput;
