import { useFormikContext } from 'formik';
import { FormValues } from '../Types/QuikspecFormTypes';

export const useQuantityChange = (itemName: string, multipleAllowed: boolean, maxQuantityPerItem: number) => {
  const { values, setFieldValue, getFieldProps } = useFormikContext<FormValues>();

  const findFieldProps = (accessoryName?: string) => {
    const payload = { name: '', value: 0 };
    if (!values || !values.items) {
      return payload;
    }
    const itemIndex = values.items.findIndex((item) => item.itemName === itemName && !multipleAllowed);
    if (itemIndex !== -1) {
      if (accessoryName) {
        const accessories = values.items[itemIndex].accessories;
        if (accessories) {
          const accessoryIndex = accessories.findIndex((accessory) => accessory.accessoryName === accessoryName);

          if (accessoryIndex !== -1) {
            return getFieldProps(`items[${itemIndex}].accessories[${accessoryIndex}].quantity`);
          }
        }
      } else {
        return getFieldProps(`items[${itemIndex}].quantity`);
      }
    }
    return payload;
  };

  const handleQuantityChange = (value: string, accessoryName?: string) => {
    const fieldProps = findFieldProps(accessoryName);
    if (!fieldProps?.name) {
      return;
    }

    const quantity: number = parseInt(value, 10);
    if (isNaN(quantity) || quantity < 0) {
      setFieldValue(fieldProps.name, 0);
    } else if (maxQuantityPerItem && quantity > maxQuantityPerItem) {
      setFieldValue(fieldProps.name, maxQuantityPerItem);
    } else {
      setFieldValue(fieldProps.name, quantity);
    }
  };

  const incrementQuantity = (accessoryName?: string) => {
    const fieldProps = findFieldProps(accessoryName);
    if (!fieldProps?.name) {
      return;
    }
    const updatedQuantity =
      !maxQuantityPerItem || fieldProps.value < maxQuantityPerItem ? fieldProps.value + 1 : fieldProps.value;
    setFieldValue(fieldProps.name, updatedQuantity);
  };

  const decrementQuantity = (accessoryName?: string) => {
    const fieldProps = findFieldProps(accessoryName);
    if (!fieldProps?.name) {
      return;
    }
    const updatedQuantity = fieldProps.value > 0 ? fieldProps.value - 1 : 0;
    setFieldValue(fieldProps.name, updatedQuantity);
  };

  const directChangeQuantity = (device: string, value: string) => {
    const quantity: number = parseInt(value, 10);
    const itemIndex = values.items.findIndex((item) => item.itemName === device);
    setFieldValue(`items[${itemIndex}].quantity`, quantity);
  };

  const directGetQuantity = (device: string) => {
    const itemIndex = values.items.findIndex((item) => item.itemName === device);
    return values.items[itemIndex].quantity;
  };

  return {
    findFieldProps,
    handleQuantityChange,
    incrementQuantity,
    decrementQuantity,
    directChangeQuantity,
    directGetQuantity
  };
};

export default useQuantityChange;
