import { useSelector } from 'react-redux';
import { RootState } from 'store';

const useReduxAclUser = () => {
  const user = useSelector((state: RootState) => state.aclUser.User);
  const error = useSelector((state: RootState) => state.aclUser.Error);

  return { user, error };
};

export default useReduxAclUser;
