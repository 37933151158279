/**
 * Communication Timeout - deviceSettings.callTimeout (Number) default: 120
 *      Note: IX devices have option to have Infinite communication timeout. IXG devices do not.
 *
 * Communication Start Tone - deviceSettings.communicationStartSound (soundPublicId) default: 25d1269b-e34f-4755-94d5-f77519c4875b
 *
 *
 *
 */
import { Tooltip } from '@mui/material';

export const CommunicationLabel = () => {
  return (
    <Tooltip title="Communication, Communication after Door Release">
      <span>Communication</span>
    </Tooltip>
  );
};

const Communication = () => {
  return <span>Communication</span>;
};

export default Communication;
