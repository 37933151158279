import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ACLUser, initialACLUserState } from 'features/SimBilling/Types/SimBillingTypes';
import { handleError } from 'features/SimBilling/Utils/SimBillingUtils';
import { createACLUser } from 'shared/api/Aws/awsApi';
import { ErrorType } from 'shared/api/ApiError';
import { GetUserInfo } from 'shared/api/Acl/IxgAclApi';
import { convertAuthorityCodeToUserRole } from 'features/SimBilling/Utils/SimBillingUtils';
import { RootState } from 'store';
import { EnumList, ICountryValue, IStateValue, fetchLocalEnumList } from 'shared/utils/EnumUtils';

export const processAclUserData = createAsyncThunk<
  ACLUser,
  { userName: string; aclToken: string },
  { rejectValue: { errorMessage: ErrorType } }
>('aclUsersData/processAclUserData', async (params, thunkAPI) => {
  const enumList: EnumList = fetchLocalEnumList();
  try {
    const aclUserInfo = await GetUserInfo(params.userName, params.aclToken);

    const state = thunkAPI.getState() as RootState;
    const orgInfo = state.branches.currentBranch;

    const authorityCode = convertAuthorityCodeToUserRole(aclUserInfo.Data.Authority);

    if ((!aclUserInfo?.Data?.CompanyName && !orgInfo) || (!aclUserInfo?.Data?.AddressDetail && !orgInfo)) {
      return thunkAPI.rejectWithValue({ errorMessage: ErrorType.INCOMPLETE_USER_ACCOUNT });
    }
    const payload = {
      emailAddress: aclUserInfo.Data.email,
      aclUserId: aclUserInfo.Data.UserID,
      dutyFree: aclUserInfo.Data.DutyFree,
      authorityCode: authorityCode || null,
      companyName: aclUserInfo.Data.CompanyName || orgInfo?.branchName || null,
      companyArea: aclUserInfo.Data.AddressDetail?.Area || null,
      companyState:
        aclUserInfo.Data.AddressDetail?.State ||
        (enumList.state[<number>orgInfo?.stateId] as IStateValue).isoCode ||
        null,
      companyZipCode: aclUserInfo.Data.AddressDetail?.ZipCode || orgInfo?.postalCode || null,
      companyAddress1: aclUserInfo.Data.AddressDetail?.Address1 || orgInfo?.address || null,
      companyAddress2: aclUserInfo.Data.AddressDetail?.Address2 || orgInfo?.address2 || null,
      companyAddress3: aclUserInfo.Data.AddressDetail?.Address3 || orgInfo?.address3 || null,
      companyCity: aclUserInfo.Data.AddressDetail?.City || orgInfo?.city || null,
      companyCountryCode:
        aclUserInfo.Data.AddressDetail?.CountryCode ||
        (enumList.country[(enumList.state[<number>orgInfo?.stateId] as IStateValue).countryId] as ICountryValue)
          .numericCode ||
        null,
      companyPhoneNumber: aclUserInfo.Data.AddressDetail?.PhoneNumber || orgInfo?.phoneNumber || null,
      propertyIDList: aclUserInfo.Data.PropertyIDList ?? []
    };
    const response = await createACLUser(payload);
    if (!response?.data) {
      return thunkAPI.rejectWithValue({ errorMessage: ErrorType.SERVER });
    }
    const userData = response.data;
    const transformedData: ACLUser = {
      PublicId: userData.public_id,
      AclUserId: userData.acl_user_id,
      EmailAddress: userData.email_address,
      DutyFree: userData.duty_free,
      AuthorityCode: userData.authority_code,
      StripeCustomerId: userData.stripe_customer_id || '',
      CompanyName: userData.company_name,
      CompanyAddress1: userData.company_address1,
      CompanyAddress2: userData.company_address2 || '',
      CompanyAddress3: userData.companyAddress3 || '',
      CompanyArea: userData.company_area || '',
      CompanyCity: userData.company_city,
      CompanyState: userData.company_state,
      CompanyZipCode: userData.company_zip_code,
      CompanyCountryCode: userData.company_country_code,
      CompanyPhoneNumber: userData.company_phone_number
    };
    return transformedData;
  } catch (error) {
    return thunkAPI.rejectWithValue(handleError(error));
  }
});

const aclUserSlice = createSlice({
  name: 'aclUser',
  initialState: initialACLUserState,
  reducers: {
    initAclUser: (state) => {
      state.User = undefined;
      state.Loading = false;
      state.Error = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(processAclUserData.pending, (state) => {
        state.Loading = true;
      })
      .addCase(processAclUserData.fulfilled, (state, action) => {
        state.Loading = false;
        state.User = action.payload;
      })
      .addCase(processAclUserData.rejected, (state, action) => {
        state.Loading = false;
        state.Error = action.payload?.errorMessage || action.error.message;
      });
  }
});

export const { initAclUser } = aclUserSlice.actions;
export const aclUserReducer = aclUserSlice.reducer;
