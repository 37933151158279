/* Custom Sound Registry tab stub */
export const CustomSoundRegistryLabel = () => {
  return <span>Custom Sound Registry</span>;
};

const CustomSoundRegistry = () => {
  return <span>We will update Aiphone Cloud for Custom Sound Registry soon!</span>;
};

export default CustomSoundRegistry;
