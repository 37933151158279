import TransferSettings, { TransferSettingsInput } from '../components/TransferSettings';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { getString } from 'shared/utils/LocalizationUtils';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';

export const DelayTransferLabel = () => {
  const delayTitle = getString('Title_Delay_Transfer');
  return <span>{delayTitle}</span>;
};
const DelayTransfer = () => {
  const dispatch = useDispatch();
  const selectedDevice = useSelector(getSelectedDevice);
  const [updateDevice] = useUpdateDeviceMutation();

  const handleSaveDelayTransfer = async (transferSettings: TransferSettingsInput) => {
    const { selectedDeviceIds, selectedAppUnitId, enableTransfers, delay } = transferSettings;

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.transferSettings = {
      ...newDevice.transferSettings,
      transferDelay: enableTransfers,
      transferDelayTime: delay,
      deviceTransferDelayDestinationList: selectedDeviceIds,
      ...(selectedAppUnitId && { delayDestinationUnitPublicId: selectedAppUnitId })
    };

    const params = {
      device: {
        publicId: selectedDevice.publicId,
        transferSettings: {
          transferDelay: enableTransfers,
          transferDelayTime: delay,
          deviceTransferDelayDestinationList: selectedDeviceIds,
          ...(selectedAppUnitId && { delayDestinationUnitPublicId: selectedAppUnitId })
        }
      }
    };
    await updateDevice(params).unwrap();
    dispatch(updateSelectedDevice({ device: newDevice }));
  };

  return (
    <TransferSettings
      title="Delay Transfer"
      helperText="Enter a value between 1-300 seconds."
      isDelayTransfer={true}
      showDelayField={true}
      handleSave={handleSaveDelayTransfer}
    />
  );
};

export default DelayTransfer;
