import React from 'react';
import InfoScreen from '../../../layouts/InfoScreen';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const PwdSuccessfullyChanged: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <InfoScreen
      title={t('PwdSuccessfullyChanged_Success')}
      message={t('PwdSuccessfullyChanged_Message')}
      iconComponent={<CheckCircleRoundedIcon sx={{ fontSize: '48px', color: 'success.main' }} />}
      button={<Button onClick={() => navigate('/auth/login')}>{t('Button_Password_ReturnToLogin')}</Button>}
    />
  );
};
