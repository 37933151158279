import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';

/**
 * LEF Series QuikSpec Categories and items
 *
 */
export const masterStations: QuikspecCategory[] = [
  {
    id: 1,
    title: '3-Call Master Stations',
    description: '',
    items: [{ deviceName: 'LEF-3' }, { deviceName: 'LEF-3L' }, { deviceName: 'LEF-3C' }]
  },
  {
    id: 2,
    title: '5-Call Master Stations',
    description: '',
    items: [
      { id: 1, deviceName: 'LEF-5', descriptions: 'LEF-5' },
      { id: 2, deviceName: 'LEF-5C', descriptions: 'LEF-5C' }
    ]
  },
  {
    id: 3,
    title: '10-Call Master Stations',

    description: '',
    items: [
      { id: 1, deviceName: 'LEF-10', descriptions: 'LEF-10' },
      { id: 2, deviceName: 'LEF-10S', descriptions: 'LEF-10S' },
      { id: 3, deviceName: 'LEF-10C', descriptions: 'LEF-10C' }
    ]
  }
];

export const subStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Sub Stations',
    description: '',
    items: [
      { deviceName: 'LE-A' },
      { deviceName: 'LE-AN' },
      { deviceName: 'LE-B' },
      { deviceName: 'LE-BN' },
      { deviceName: 'LEF-1C' }
    ]
  }
];

export const doorStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Door Stations',
    description: '',
    items: [
      { deviceName: 'LE-D' },
      { deviceName: 'LE-SS-1G' },
      { deviceName: 'LE-DA' },
      {
        deviceName: 'LE-SS/A',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-2G/A' }]
      },
      {
        deviceName: 'LE-SSR',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-2G/A' }]
      },
      {
        deviceName: 'LS-NVP/C',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-NVP/A' }]
      }
    ]
  }
];

export const accessories: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Accessories',
    description: 'Optional Accessories.',
    items: [
      { deviceName: 'BG-10C', maxQuantityAllowedPerItem: 1 },
      { deviceName: 'BA-1', maxQuantityAllowedPerItem: 10 },
      { deviceName: 'RY-PA', maxQuantityAllowedPerItem: 10 },
      { deviceName: 'RY-AC/A' },
      { deviceName: 'NP-25V' }
    ]
  }
];

// Hidden products are designed not to be displayed.
export const lefHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      { deviceName: 'SBX-NVP/A' },
      { deviceName: 'SBX-2G/A' },
      { deviceName: 'PS-1225UL' },
      { deviceName: 'PS-1208UL' }
    ]
  }
];
