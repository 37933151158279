import { Container, Box, Grid } from '@mui/material';
import TenantsCard from './components/TenantsCard';
import AppsCard from './components/AppsCard';
import DevicesCard from './components/DevicesCard';
import UnitInfoCard from './components/UnitInfoCard';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useParams } from 'react-router-dom';
import { useLazyGetAppWithSiteIdQuery } from 'services/aiphoneCloud';
import { useEffect } from 'react';

const UnitInformation = () => {
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const sitePublicId = useParams().id;
  const unitPublicId = useParams().unitid || '';
  const selectedUnit = unitList[unitPublicId];
  const selectedUnitType = selectedUnit?.unitType || '';
  const [fetchApps] = useLazyGetAppWithSiteIdQuery();

  useEffect(() => {
    if (sitePublicId && (selectedUnitType === 1 || selectedUnitType === 4 || selectedUnitType === 5)) {
      fetchApps({ sitePublicId: sitePublicId, qty: 500, page: 0 });
    }
  }, [sitePublicId]);

  return (
    <Container maxWidth="lg">
      <Box sx={styles.unitDashboardWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UnitInfoCard />
          </Grid>
          {selectedUnitType === 1 || selectedUnitType === 4 || selectedUnitType === 5 ? (
            <>
              <Grid item xs={12}>
                <TenantsCard />
              </Grid>
              <Grid item xs={5}>
                <DevicesCard />
              </Grid>
              <Grid item xs={7}>
                <AppsCard />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <DevicesCard />
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '100%'
  },
  description: {},
  unitDashboardWrapper: {
    marginTop: '20px'
  },

  twoCardWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  devicesWrapper: {
    marginTop: '20px',
    width: '50%'
  },
  appsWrapper: {
    marginTop: '20px',
    width: '50%'
  }
};

export default UnitInformation;
