import React from 'react';
import { Box, Button, TextField, Checkbox } from '@mui/material';

interface IFilterByAddedDevicesProps {
  handleFilterDevices: (field: string, query: string | boolean) => void;
}
const FilterByAddedDevices = ({ handleFilterDevices }: IFilterByAddedDevicesProps) => {
  const [stationName, setStationName] = React.useState('');
  const [unitNumber, setUnitNumber] = React.useState('');
  const [stationNumber, setStationNumber] = React.useState('');
  const [showAppGroup, setShowAppGroup] = React.useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Handle input change based on the field name and value
    setStationName(name === 'stationName' ? value : stationName);
    setUnitNumber(name === 'unitNumber' ? value : unitNumber);
    setStationNumber(name === 'stationNumber' ? value : stationNumber);
    handleFilterDevices(name, value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;

    setShowAppGroup(name === 'showAppGroup' ? event.target.checked : showAppGroup);
    handleFilterDevices(name, event.target.checked);
  };

  const handleClearFilters = () => {
    setStationName('');
    setUnitNumber('');
    setStationNumber('');
    setShowAppGroup(false);
    handleFilterDevices('clear', '');
  };

  return (
    <>
      <TextField
        id="name-filter"
        name="stationName"
        label="Search by Station name"
        value={stationName}
        variant="standard"
        onChange={handleInputChange}
        helperText="Enter a station name to filter device list"
      />
      <TextField
        id="unit-filter"
        name="unitNumber"
        label="Search by Unit number"
        value={unitNumber}
        variant="standard"
        onChange={handleInputChange}
        helperText="Enter a unit number to filter device list"
      />
      <TextField
        id="number-filter"
        name="stationNumber"
        label="Search by Station number"
        value={stationNumber}
        variant="standard"
        onChange={handleInputChange}
        helperText="Enter a station number to filter device list"
      />
      <Box sx={showAppGroup ? styles.checkBoxWrapper : styles.uncheckBoxWrapper}>
        <Checkbox name="showAppGroup" checked={showAppGroup} onChange={handleCheckboxChange} />
        <label>Display App Group Only</label>
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button variant="contained" onClick={handleClearFilters}>
          Clear Filters
        </Button>
      </Box>
    </>
  );
};

const styles = {
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '30px'
  },
  checkBoxWrapper: {
    marginTop: '20px',
    color: 'black',
    borderRadius: '2px',
    border: 'solid 1px #e5e4e2'
  },
  uncheckBoxWrapper: {
    marginTop: '20px',
    color: 'grey',
    borderRadius: '2px',
    border: 'solid 1px #e5e4e2'
  }
};

export default FilterByAddedDevices;
