/**
 * Station Information has 2 tabs (Station Identification, Units & Stations)
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';
import { useSelector } from 'react-redux';
import { getDeviceCategoryFromDeviceTypeModel } from 'shared/utils/helperFunctions';
import { DeviceCategory } from 'features/RemoteManagement/Types';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';

const StationInfo = () => {
  // Switch to ?? operator for nullish coalescing
  const devicePublicId = useParams().deviceid ?? '';
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const selectedDevice = deviceList[devicePublicId];
  const selectedDeviceBasicInfo = selectedDevice?.basicInfo;
  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(
    selectedDeviceBasicInfo?.deviceType,
    selectedDeviceBasicInfo?.deviceModel
  );
  let stationInfoTabs: DeviceTabType[] = [];

  if (!selectedDevice) return <Box>Error Loading Device</Box>;

  switch (deviceCategory) {
    case DeviceCategory.VideoDoorStation:
    case DeviceCategory.AudioDoorStation:
    case DeviceCategory.EmergencyStation:
    case DeviceCategory.EntranceStation:
    case DeviceCategory.GuardStation:
    case DeviceCategory.MasterStation:
    case DeviceCategory.SubMasterStation:
    case DeviceCategory.IOAdaptor:
    case DeviceCategory.GatewayAdaptor:
    case DeviceCategory.TenantStation:
      stationInfoTabs = [DeviceTabType.StationInfo_StationIdentification];
      break;
    case DeviceCategory.LiftControl:
      stationInfoTabs = [DeviceTabType.StationInfo_StationIdentification];
      break;
  }

  return <TabsContainer tabTypes={stationInfoTabs} />;
};

export default StationInfo;
