import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography, Box } from '@mui/material';
import theme from 'data/theme';
import { FormValuesWires } from 'features/QuikSpec/Types/QuikspecFormTypes';
import { getString } from 'shared/utils/LocalizationUtils';

interface Props {
  wireInfoArray: FormValuesWires[];
}

const WireInfoTable = ({ wireInfoArray }: Props) => {
  const system = getString('WireInfo_System');
  const wireNumber = getString('WireInfo_Wire#');
  const description = getString('WireInfo_Description');
  const maxDistance = getString('WireInfo_Max_Distance');
  const jacket = getString('WireInfo_Jacket');
  const capacitance = getString('WireInfo_Capacitance');
  return (
    <TableContainer component={Box}>
      <Table>
        <TableBody>
          {/* Header Row */}
          <TableRow>
            <TableCell align="center" sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>{system}</strong>
              </Typography>
            </TableCell>
            <TableCell align="center" sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>{wireNumber}</strong>
              </Typography>
            </TableCell>
            <TableCell sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>{description}</strong>
              </Typography>
            </TableCell>
            <TableCell align="center" sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>{maxDistance}</strong>
              </Typography>
            </TableCell>
            <TableCell align="center" sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>{jacket}</strong>
              </Typography>
            </TableCell>
            <TableCell align="center" sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>{capacitance}</strong>
              </Typography>
            </TableCell>
          </TableRow>

          {/* Data Rows */}
          {wireInfoArray.map((wireInfo, index) => (
            <TableRow key={index}>
              <TableCell align="center">
                <Typography variant="body2">{wireInfo.system}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2">{wireInfo.wire}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{wireInfo.description}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2">{wireInfo.maxDistance}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2">{wireInfo.jacketNomOD}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2">{wireInfo.capacitanceLoopRes}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const styles = {
  headerCell: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white.main'
  }
};
export default WireInfoTable;
