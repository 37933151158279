import { Box, Typography, LinearProgress, styled, useTheme } from '@mui/material';
import { SubscriptionStatus } from 'features/SimBilling/Hooks/useFetchSubscriptionDetail';
import { useTranslation } from 'react-i18next';

interface Props {
  totalData: number;
  usedData: number;
  dataUnit: string;
  additionalData: number;
  subscriptionData: number;
  subscriptionStatus: string | null;
}

const DataProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.neutral[300],
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main
  }
}));

const DataProgressWarning = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.neutral[300],
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.warning.light
  }
}));

const DataProgressOverused = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: 'transparent',
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: theme.palette.error.main
  }
}));

const Marker = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  height: 10,
  width: 2,
  backgroundColor: theme.palette.primary.dark
}));

const MarkerLabelTop = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: -41,
  fontSize: '0.90rem',
  color: theme.palette.text.secondary,
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap'
}));

const MarkerLabelBottom = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 11,
  fontSize: '0.90rem',
  color: theme.palette.text.secondary,
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap',
  textAlign: 'center'
}));

const roundToHalf = (value: number) => {
  return (Math.round(value * 2) / 2).toFixed(1);
};

const DataUsageCard = ({
  totalData,
  usedData,
  dataUnit,
  additionalData,
  subscriptionData,
  subscriptionStatus
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('simbillings');
  const decimalPlaces = 4;
  const formattedUsedData = Number(usedData).toFixed(decimalPlaces);

  const totalDataUsedPercentage = Math.min((usedData / totalData) * 100, 100);
  const additionalDataUsedPercentage = Math.min(((usedData - subscriptionData) / additionalData) * 100, 100);

  const additionalBarLeft = `${(subscriptionData / totalData) * 100}%`;
  const additionalBarWidth = `${(additionalData / totalData) * 100}%`;
  const subscriptionMarkerLeft = `${(subscriptionData / totalData) * 100}%`;
  const usedMarkerLeft = usedData > totalData ? '100%' : `${totalDataUsedPercentage}%`;
  const totalMarkerLeft = usedData > totalData ? `${(totalData / usedData) * 100}%` : '100%';
  const overusedBarLeft = totalMarkerLeft;
  const overusedBarWidth = `${((usedData - totalData) / usedData) * 100}%`;

  return (
    <Box sx={styles.container}>
      <Typography variant="h5" gutterBottom>
        {t('Site_Sim_Data')}
      </Typography>
      <Typography variant="h6" sx={styles.labelsWithMargin}>
        {t('Subscription_Status')}:
        <Typography variant="body1" display="inline" sx={styles.subscriptionStatus(subscriptionStatus)}>
          {subscriptionStatus || t('N/A')}
        </Typography>
      </Typography>
      <Typography variant="h6" sx={styles.labelsWithMargin}>
        {t('Data_Used')}:
        <Typography variant="body1" display="inline" sx={styles.siteInformationValues}>
          {formattedUsedData} {dataUnit} / {roundToHalf(totalData)} {dataUnit}
        </Typography>
      </Typography>
      <Box sx={styles.progressContainer}>
        <Box sx={styles.progressBarContainer}>
          <Box sx={styles.progressBar}>
            {/* Plan Data */}
            <DataProgress
              variant="determinate"
              value={totalDataUsedPercentage}
              sx={{ width: '100%', position: 'absolute' }}
            />
            {/* Additional Data */}
            {usedData > subscriptionData && (
              <DataProgressWarning
                variant="determinate"
                value={additionalDataUsedPercentage}
                sx={{ width: additionalBarWidth, position: 'absolute', left: additionalBarLeft }}
              />
            )}
            {/* Overused Data */}
            {usedData > totalData && (
              <DataProgressOverused
                variant="determinate"
                value={100}
                sx={{ width: overusedBarWidth, position: 'absolute', left: overusedBarLeft }}
              />
            )}
            {/* Markers */}
            {subscriptionData !== totalData && (
              <>
                <Marker sx={{ left: subscriptionMarkerLeft }} />
                <MarkerLabelTop sx={{ left: subscriptionMarkerLeft }}>
                  {roundToHalf(subscriptionData)} {dataUnit}
                  <br />
                  {t('Plan_Data')}
                </MarkerLabelTop>
              </>
            )}
            <Marker
              sx={{
                left: totalMarkerLeft,
                backgroundColor: usedData > totalData ? 'black' : theme.palette.primary.dark
              }}
            />
            <MarkerLabelTop sx={{ left: totalMarkerLeft }}>
              {roundToHalf(totalData)} {dataUnit}
              <br />
              {t('Total_Data')}
            </MarkerLabelTop>
            <Marker sx={{ left: usedMarkerLeft, backgroundColor: 'red' }} />
            <MarkerLabelBottom sx={{ left: usedMarkerLeft }}>
              {formattedUsedData} {dataUnit}
              <br />
              {t('Used_Data')}
            </MarkerLabelBottom>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.legendContainer}>
        <Typography variant="body2">
          {t('Plan_Data')}: {roundToHalf(subscriptionData)} {dataUnit}
        </Typography>
        {additionalData >= 0.5 && (
          <>
            <Typography variant="body2">
              {t('Additional_Data')}: {roundToHalf(additionalData)} {dataUnit}
            </Typography>
            <Typography variant="body2">
              {t('Total_Data')}: {roundToHalf(totalData)} {dataUnit}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    mt: 2,
    mb: 2
  },
  labelsWithMargin: {
    mt: 1,
    mb: 1
  },
  siteInformationValues: {
    ml: 1
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    mb: 5,
    mt: 3,
    ml: 2
  },
  progressBarContainer: {
    width: '100%',
    paddingRight: '2%',
    position: 'relative'
  },
  progressBar: {
    width: '98%',
    mr: 1,
    position: 'relative'
  },
  legendContainer: {
    mt: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  subscriptionStatus: (status: string | null) => {
    let color;
    switch (status) {
      case SubscriptionStatus.ACTIVE:
        color = '#4caf50';
        break;
      case SubscriptionStatus.CANCELED:
        color = '#f44336';
        break;
      case SubscriptionStatus.PAST_DUE:
        color = '#ff9800';
        break;
      default:
        color = 'black';
        break;
    }
    return {
      color: color,
      marginLeft: 1,
      fontWeight: 'bold'
    };
  }
};

export default DataUsageCard;
