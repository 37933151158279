import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  IconButton,
  Select,
  MenuItem,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Typography,
  Box
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik } from 'formik';
import { useGetAssignableRolesQuery, useInviteGlobalUserMutation } from 'services/aiphoneCloud';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import * as yup from 'yup';
import { ContextType } from 'store/slices/usersSlice';

interface IInviteAiphoneUserDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsError: (isError: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
}

const InviteAiphoneUserDialog = ({
  isOpen,
  setIsOpen,
  setErrorMessage,
  setIsError,
  setSuccessMessage,
  setIsSuccess
}: IInviteAiphoneUserDialogProps) => {
  const { t } = useTranslation(); // Initialize the translation hook

  const { data: assignableRoles, isFetching: isRolesFetching } = useGetAssignableRolesQuery({
    contextPublicId: null,
    contextType: ContextType.GLOBAL
  });
  const [inviteUser, { isLoading: isInviteLoading }] = useInviteGlobalUserMutation();

  const initialValues = {
    email: '',
    role: ''
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('Email_Invalid'))
      .matches(/@aiphone\.com$/, t('Email_Must_End_Aiphone'))
      .required(t('Email_Required')),
    role: yup.string().required(t('Role_Required'))
  });

  const handleInviteAiphoneUser = (values: any) => {
    const formattedEmail = values.email.toLowerCase();
    inviteUser({
      email: formattedEmail,
      role: values.role,
      countryId: 2,
      languageId: 2
    })
      .unwrap()
      .then((response) => {
        if (response.error) {
          setIsError(true);
          setErrorMessage(response.error.message);
          return;
        }
        setIsSuccess(true);
        setSuccessMessage(t('User_Invited_Successfully'));
        setIsOpen(false);
      })
      .catch((error) => {
        setErrorMessage(JSON.parse(error.data)?.errorDetails || t('Unexpected_Error'));
        setIsError(true);
      });
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} aria-labelledby="" maxWidth="lg" fullWidth>
      <Grid container spacing={8}>
        <Grid item xs={11}>
          <DialogTitle>{t('Invite_Aiphone_User')}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          handleInviteAiphoneUser(values);
        }}
      >
        {(props) => {
          return (
            <Form>
              <DialogContent>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body1" color="initial">
                    {t('Invite_User_Details')}
                  </Typography>
                  <Typography variant="body2" color="initial">
                    {t('User_Email_Ends_With_Aiphone')}
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      name="email"
                      as={TextField}
                      size="small"
                      label={t('Email')}
                      type="email"
                      fullWidth
                      error={props.touched.email && !!props.errors.email}
                      helperText={props.touched.email && props.errors.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="user-role-label">{t('Select_Role')}</InputLabel>
                      <Field
                        name="role"
                        as={Select}
                        size="small"
                        label={t('Select_Role')}
                        id="user-role"
                        labelId="user-role-label"
                        fullWidth
                        required
                        disabled={isRolesFetching}
                      >
                        <MenuItem value="" disabled>
                          {t('Select_Role')}
                        </MenuItem>
                        {isRolesFetching
                          ? []
                          : assignableRoles.map((role) => (
                              <MenuItem key={role.publicId} value={role.publicId}>
                                {role.roleName}
                              </MenuItem>
                            ))}
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={() => setIsOpen(false)}>
                  {t('Cancel')}
                </Button>
                <LoadingButton variant="contained" type="submit" loading={isInviteLoading} disabled={isInviteLoading}>
                  {t('Invite')}
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default InviteAiphoneUserDialog;
