/**
 * Transfer has 3 tabs (Absent Transfer, Delay Transfer, Schedule Transfer)
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from '../DeviceTabs';

const Transfer = () => {
  // This page is not available for Door Stations so we should not need to filter tabs.
  const TransferTabs: DeviceTabType[] = [
    DeviceTabType.Transfer_DelayTransfer,
    DeviceTabType.Transfer_AbsentTransfer
    //Hiding Schedule Transfer, non-mvp: DeviceTabType.Transfer_ScheduleTransfer
  ];

  return <TabsContainer tabTypes={TransferTabs} />;
};

export default Transfer;
