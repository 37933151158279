import { getString } from 'shared/utils/LocalizationUtils';

/* SIF tab stub */
export const SIFLabel = () => {
  const sif = getString('SIF');
  return <span>{sif}</span>;
};

const SIF = () => {
  const sif = getString('SIF');
  return <span>{sif}</span>;
};

export default SIF;
