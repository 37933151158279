import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  IAuthenticationDetailsData,
  ICognitoUserData,
  ICognitoUserPoolData
} from 'amazon-cognito-identity-js';
import CONFIG from '../../../config';

/**
 * Class holds static methods to interact with ACL Cognito user pool
 * @class
 */
export default class Login {
  /**
   * Data used to connect with ACL Cognito pool
   */
  protected static poolData: ICognitoUserPoolData = {
    UserPoolId: CONFIG.ixgCognitoUserPool, // Your user pool id here
    ClientId: CONFIG.ixgCognitoClientId // Your client id here
  };

  // Language set in signup
  protected static LANGUAGE_ENG = '2';

  // User pool object that is connected to ACL Cognito pool
  protected static userPool: CognitoUserPool = new CognitoUserPool(Login.poolData);

  static base64UrlDecode(input: any) {
    const base64 = input.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  /**
   * Get user token
   * @param username Name of user
   * @param password User password
   * @returns token
   */
  static getToken(username: string, password: string) {
    return new Promise<{ idToken: string; username: string }>((resolve, reject) => {
      const authenticationData: IAuthenticationDetailsData = {
        Username: username,
        Password: password
      };

      const authenticationDetails = new AuthenticationDetails(authenticationData);

      const userData: ICognitoUserData = {
        Username: username,
        Pool: Login.userPool
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result: any) {
          const idToken = result.getIdToken().getJwtToken();

          // Decode the JWT token to extract the cognito:username
          const payload = decodeURIComponent(
            atob(idToken.split('.')[1])
              .split('')
              .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join('')
          );

          const decoded = JSON.parse(payload);
          const cognitoUsername = decoded['cognito:username'];

          // Resolve with both the ID token and the username
          resolve({ idToken, username: cognitoUsername });
        },

        onFailure: function (err: any) {
          reject(new Error(err));
        }
      });
    });
  }

  /**
   * Sign up user to ACL Cognito
   * @param username Name of user
   * @param password User password
   * @param email User email
   * @returns Username
   */
  static signup(username: string, password: string, email: string) {
    return new Promise<string>((resolve, reject) => {
      const attributeList = [];

      const dataEmail = {
        Name: 'email',
        Value: email
      };

      const dataLanguage = {
        Name: 'custom:language',
        Value: Login.LANGUAGE_ENG
      };

      const attributeEmail = new CognitoUserAttribute(dataEmail);
      const attributeLanguage = new CognitoUserAttribute(dataLanguage);

      attributeList.push(attributeEmail);
      attributeList.push(attributeLanguage);

      Login.userPool.signUp(username, password, attributeList, [], function (err: any, result: any) {
        if (err) {
          reject(err.message);
          return;
        }

        if (result !== undefined) {
          const cognitoUser = result.user;
          resolve(cognitoUser.getUsername());
        } else {
          reject('User is underfined');
        }
      });
    });
  }

  /**
   * Ativate user accoutn with verification code
   * @param username Name of user
   * @param verifacationCode Verification code
   * @returns result of verifacation call
   */
  static confirm(username: string, verifacationCode: string) {
    return new Promise<any>((resolve, reject) => {
      const userData = {
        Username: username,
        Pool: Login.userPool
      };

      const cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmRegistration(verifacationCode, true, function (err: any, result: any) {
        if (err) {
          reject(err.message);
        }
        resolve(result);
      });
    });
  }
}
