/**
 * This is a complication of the error messages that can be returned from the gateway API.
 * This is a work in progress and will be updated as we get more information.
 *
 * returns a string of the error message that corresponds to the Gateway_Error message in common.json
 */
interface IGatewayError {
  message: string;
}

// TODO: once we have the list of error messages, will add to the translation file
export const getGWErrorCode = (error: IGatewayError): string => {
  const defaultErrorMessage = 'Gateway_Error_message.RM-GW-001';

  if (!error || !error.message) return defaultErrorMessage;

  if (errorMessageObj[error.message]) {
    return errorMessageObj[error.message];
  }

  return error.message;
};

const errorMessageObj: { [key: string]: string } = {
  CONNECTION_LOST: 'Gateway_Error_message.RM-GW-002',
  MQTT_KEEP_ALIVE_TIMEOUT: 'Gateway_Error_message.RM-GW-002',
  DUPLICATE_CLIENTID: 'Gateway_Error_message.RM-GW-020',
  SERVER_INITIATED_DISCONNECT: 'Gateway_Error_message.RM-GW-021',
  CLIENT_INITIATED_DISCONNECT: 'Gateway_Error_message.RM-GW-022',
  Firmware_Update_Error: 'Gateway_Error_message.RM-GW-019',
  Gateway_status_error: 'Gateway_Error_message.RM-GW-002',

  // GW StatusCode errors
  402: 'Gateway_Error_message.RM-GW-004', // Invalid credentials
  420: 'Gateway_Error_message.RM-GW-005', // GW using previous version credentials
  401: 'Gateway_Error_message.RM-GW-006', // SYNC error - GW rejected the config file
  413: 'Gateway_Error_message.RM-GW-010', // Check ip address or connection
  414: 'Gateway_Error_message.RM-GW-011', // Check ip address or connection
  415: 'Gateway_Error_message.RM-GW-012', // SYNC error - presigned URL expired or invalid
  416: 'Gateway_Error_message.RM-GW-013', // SYNC error - error uploading file
  421: 'Gateway_Error_message.RM-GW-015', // Unable to get THING certificate from AWS
  422: 'Gateway_Error_message.RM-GW-016', // Empty Payload
  423: 'Gateway_Error_message.RM-GW-017', // Not error. Info about device check completed
  424: 'Gateway_Error_message.RM-GW-018', // Firmware update error
  425: 'Gateway_Error_message.RM-GW-019' // Firmware update timeout

  // TODO: Add more error messages here
};
