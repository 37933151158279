import { Container, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { RootState } from 'store';

interface HeaderProps {
  siteName: string;
  awsPropertyId: number;
  selectedDevice: string;
}
const styles = {
  maincontainer: {
    display: 'flex',
    marginLeft: '0',
    marginBottom: '50px'
  },
  sitinfo: {
    margin: '0'
  }
};

export const Header: FC<HeaderProps> = ({ siteName, awsPropertyId, selectedDevice }) => {
  const device = useSelector((state: RootState) => state.devices.DeviceList[selectedDevice]);
  const stationInfo = useSelector((state: RootState) => state.devices.DeviceList[selectedDevice].basicInfo);
  const stationNumber = stationInfo.stationNumber;
  const imageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
    device.basicInfo.deviceModel,
    device.basicInfo.deviceType
  )}.png`;
  return (
    <Container sx={styles.maincontainer}>
      <img src={imageUrl} alt="Device Image" />

      <Container sx={styles.sitinfo}>
        <Typography>
          Site: {siteName} / Site ID: {awsPropertyId} / Station Number: {stationNumber}
        </Typography>
        <Typography variant="h4">
          {getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType)}
        </Typography>
      </Container>
    </Container>
  );
};
