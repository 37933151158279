import React, { useEffect, useState } from 'react';
import { getSelectedDevice, IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';
import InputCallDialog from '../../dialogs/InputCallDialog';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface IInputCallSettingProps {
  selectedRelay: IDeviceContactInput;
  setNeedsSave: (needsSave: boolean) => void;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
  inputFunction: number;
}

const InputCallSetting = ({
  selectedRelay,
  setNeedsSave,
  setNewRelaySettings,
  inputFunction
}: IInputCallSettingProps) => {
  const { t } = useTranslation();
  const title = t('Call_Settings_Title');
  const description = t('Call_Settings_Description');
  const button = t('Call_Settings_Button');
  const selectGroup = t('Call_Settings_Select_Group');
  const [isCallDestinationDialogOpen, setIsCallDestinationDialogOpen] = useState<boolean>(false); // Default [false]
  const [callGroupNumber, setCallGroupNumber] = useState<number>(1);
  const relayNumber = selectedRelay.inputNumber;
  const selectedDevice = useSelector(getSelectedDevice);
  const navigate = useNavigate();

  // Set the initial call group number from the selected device or relay
  useEffect(() => {
    const initialCallGroupSelectedDevice = selectedRelay?.deviceContactGroupPublicId;
    const callGroupList: { [key: string]: any } = selectedDevice?.callSettings?.contactGroupList || {};
    const matchedGroupNumber = Object.keys(callGroupList).find(
      (key) => callGroupList[key]?.publicId === initialCallGroupSelectedDevice
    );

    if (matchedGroupNumber) {
      setCallGroupNumber(Number(matchedGroupNumber));
    }
  }, [selectedRelay, selectedDevice]);

  const handleCallGroupChange = (event: SelectChangeEvent<number>) => {
    setCallGroupNumber(event.target.value as number);
    const callGroupValue = event.target.value as number;
    const callGroupList: { [key: string]: any } = selectedDevice?.callSettings?.contactGroupList || {};
    const callGroupObj = callGroupList[callGroupValue];
    const callGroupPublicId = callGroupObj?.publicId;
    if (selectedRelay.deviceContactGroupPublicId !== callGroupPublicId) {
      setNeedsSave(true);
      setNewRelaySettings((prev) => {
        const newSettingsArray = prev;
        const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);
        const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
        if (index !== -1) {
          if (currentRelaySettings)
            newSettingsArray[index] = { ...currentRelaySettings, deviceContactGroupPublicId: callGroupPublicId };
        } else {
          newSettingsArray.push({ ...selectedRelay, deviceContactGroupPublicId: callGroupPublicId });
        }
        return newSettingsArray;
      });
    } else {
      setNeedsSave(false);
      setNewRelaySettings((prev) => {
        return prev.filter((relay) => relay.publicId !== selectedRelay.publicId);
      });
    }
  };

  const handleMoveToCallGroupSettings = () => {
    navigate(`../callsettings?inputNumber=${relayNumber}&callGroup=${callGroupNumber}`, { relative: 'path' });
  };
  if (inputFunction === 1) {
    return null;
  }

  return (
    <>
      <Box sx={styles.settingsWrapper}>
        <Box sx={styles.descriptionWrapper}>
          <Box sx={styles.title}>{title}</Box>
          <Box sx={styles.description}> {description}</Box>
        </Box>
        <Box sx={styles.buttonWrapper}>
          <Grid container spacing={0}>
            <Grid item xs={8} style={{ marginLeft: '-125px' }}>
              <FormControl sx={styles.select}>
                <InputLabel id="call-group-label">{t('Select_Call_Group')}</InputLabel>
                <Select
                  labelId="call-group-label"
                  id="call-group"
                  value={callGroupNumber}
                  onChange={handleCallGroupChange}
                  label="Select Call Group"
                >
                  {Array.from({ length: 10 }, (_, i) => {
                    const groupNumber = (i + 1).toString().padStart(2, '0');
                    return (
                      <MenuItem key={i} value={i + 1}>
                        {groupNumber}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Box sx={styles.previewButton}>
                <Tooltip title={button}>
                  <IconButton size="large" onClick={() => setIsCallDestinationDialogOpen(true)}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Button variant="contained" sx={{ float: 'right' }} onClick={handleMoveToCallGroupSettings}>
                  {selectGroup}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
      </Box>
      <InputCallDialog
        isOpen={isCallDestinationDialogOpen}
        setIsOpen={setIsCallDestinationDialogOpen}
        callGroupNumber={callGroupNumber}
      />
    </>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  select: {
    width: '63%',
    float: 'right',
    marginLeft: '-100px'
  },
  description: {},
  buttonWrapper: {
    width: '50%'
  },
  previewButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '-10px'
  }
};

export default InputCallSetting;
