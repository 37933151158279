import { useState, useEffect } from 'react';
import { ApiError, ErrorType } from 'shared/api/ApiError';
import { useStripeFunctions } from 'shared/api/Aws/awsApi';

/**
 * It contains many more items.
 * Look for additional items if needed and add them here.
 * */
export type CustomerInvoice = {
  number: string;
  created: number;
  period_start: number;
  period_end: number;
  total: number;
  currency: string;
  paid: boolean;
  hosted_invoice_url: string;
  description: string;
};

const useFetchInvoices = (subscriptionId: string | null) => {
  const [invoicesList, setInvoicesList] = useState<CustomerInvoice[] | null>(null);
  const [invoicesError, setError] = useState<string | null>(null);

  useEffect(() => {
    if (subscriptionId) {
      (async () => {
        try {
          const params = { subscriptionId, action: 'getInvoices' };
          const invoices = await useStripeFunctions(params);
          if (!invoices?.data) {
            setError(ErrorType.SERVER);
            return;
          }

          // Convert the received invoices to CustomerInvoice type and set the description
          const formattedInvoices: CustomerInvoice[] = invoices.data.map((invoice: any) => {
            let description = '';
            if (invoice.metadata?.isSubscriptionUpdate === 'true') {
              description = invoice.metadata.description;
            } else {
              const isMetadataEmpty = Object.keys(invoice.metadata).length === 0;
              const isAdditionalData = invoice.metadata?.isAdditionalData === 'true';
              if (isMetadataEmpty || isAdditionalData) {
                description = invoice.lines.data[0]?.description.replace('Time on', 'Subscribe to');
              }
            }

            return {
              number: invoice.number,
              created: invoice.created,
              period_start: invoice.period_start,
              period_end: invoice.period_end,
              total: invoice.total,
              currency: invoice.currency,
              paid: invoice.paid,
              hosted_invoice_url: invoice.hosted_invoice_url,
              description: description // Set the description based on the condition
            };
          });

          setInvoicesList(formattedInvoices);
        } catch (error) {
          if (error instanceof ApiError) {
            setError(error.type);
          }
          setError(ErrorType.UNEXPECTED);
        }
      })();
    }
  }, [subscriptionId]);

  return { invoicesList, invoicesError };
};

export default useFetchInvoices;
