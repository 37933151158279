import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';

export const updateFormValuesTW = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesTW(values);
  updateWiresTW(values);
  updateNotesTW(values);
  return values;
};

const updateItemQuantityWithAccessoriesTW = (values: FormValues) => {
  const items = Object.values(values.items);

  const LEM1Index = items.findIndex((item) => item.itemName === 'LEM-1');
  const LEM1DLIndex = items.findIndex((item) => item.itemName === 'LEM-1DL');
  const LEM1DLCIndex = items.findIndex((item) => item.itemName === 'LEM-1DLC');
  const LEM1DLSIndex = items.findIndex((item) => item.itemName === 'LEM-1DLS');
  const LEDIndex = items.findIndex((item) => item.itemName === 'LE-D');
  const LEDAIndex = items.findIndex((item) => item.itemName === 'LE-DA');
  const LSNVPCIndex = items.findIndex((item) => item.itemName === 'LS-NVP/C');
  const CCS1AIndex = items.findIndex((item) => item.itemName === 'CCS-1A');
  const CMLAIndex = items.findIndex((item) => item.itemName === 'C-ML/A');
  const C123LAIndex = items.findIndex((item) => item.itemName === 'C-123L/A');
  const C123LWIndex = items.findIndex((item) => item.itemName === 'C-123LW');
  const SBXNVPAIndex = items.findIndex((item) => item.itemName === 'SBX-NVP/A');
  const DAK2SIndex = items.findIndex((item) => item.itemName === 'DAK-2S');
  const RYACAIndex = items.findIndex((item) => item.itemName === 'RY-AC/A');
  const PS1225ULIndex = items.findIndex((item) => item.itemName === 'PS-1225UL');
  const PT1210NAIndex = items.findIndex((item) => item.itemName === 'PT-1210NA');
  const SKK620CIndex = items.findIndex((item) => item.itemName === 'SKK-620C');
  const doorFlushMountIndex = items.findIndex((item) => item.itemName === 'DOOR_FLUSH_MOUNT');
  const vandalIndex = items.findIndex((item) => item.itemName === 'VANDAL');
  const numberOfMastersIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_MASTERS');
  const masterDeskMountIndex = items.findIndex((item) => item.itemName === 'MASTER_DESK_MOUNT');
  const doorReleaseIndex = items.findIndex((item) => item.itemName === 'DOOR_RELEASE');
  const externalSignalingIndex = items.findIndex((item) => item.itemName === 'EXTERNAL_SIGNAL');

  const doorFlushMount = items[doorFlushMountIndex].quantity;
  const vandal = items[vandalIndex].quantity;
  const totalMasterStations = items[numberOfMastersIndex].quantity;
  const masterDeskMount = items[masterDeskMountIndex].quantity;
  const doorRelease = items[doorReleaseIndex].quantity;
  const externalSignaling = items[externalSignalingIndex].quantity;

  // System determination
  if (masterDeskMount === 1 && doorRelease === 0) {
    // LEM System
    // Master Station
    if (totalMasterStations === 1) {
      items[LEM1Index].quantity = totalMasterStations;
      items[SKK620CIndex].quantity = totalMasterStations;
    } else {
      items[LEM1DLCIndex].quantity = totalMasterStations;
      items[PT1210NAIndex].quantity = totalMasterStations;
    }

    // Door Station
    if (doorFlushMount === 0 && vandal === 0) {
      items[LEDIndex].quantity = 1;
    } else if (doorFlushMount === 1 && vandal === 0) {
      items[LEDAIndex].quantity = 1;
    } else if (vandal === 1) {
      items[LSNVPCIndex].quantity = 1;
      if (doorFlushMount === 0) {
        items[SBXNVPAIndex].quantity = 1;
      }
    }
  } else if (masterDeskMount === 1 && doorRelease === 1) {
    // LEM-1DL System
    if (doorFlushMount === 0 && vandal === 0 && totalMasterStations === 1) {
      // LEM Kit
      items[LEM1DLSIndex].quantity = 1;
    } else {
      // Non Kit
      // Master Stations
      if (totalMasterStations === 1) {
        items[LEM1DLIndex].quantity = totalMasterStations;
      } else if (totalMasterStations === 2) {
        items[LEM1DLCIndex].quantity = totalMasterStations;
      }

      // Door Station
      if (doorFlushMount === 0 && vandal === 0) {
        items[LEDIndex].quantity = 1;
      } else if (doorFlushMount === 1 && vandal === 0) {
        items[LEDAIndex].quantity = 1;
      } else if (vandal === 1) {
        items[LSNVPCIndex].quantity = 1;
        if (doorFlushMount === 0) {
          items[SBXNVPAIndex].quantity = 1;
        }
      }

      // Power Supply
      items[PT1210NAIndex].quantity = totalMasterStations;
    }
  } else if (
    totalMasterStations === 2 ||
    doorFlushMount === 1 ||
    doorRelease === 1 ||
    vandal === 1 ||
    externalSignaling === 1
  ) {
    // C123 System
    if (totalMasterStations === 1 && vandal === 0 && doorFlushMount === 0) {
      // C-123L/A Kit
      items[C123LAIndex].quantity = 1;
      items[SKK620CIndex].quantity = totalMasterStations;
    } else if (totalMasterStations === 2 && vandal === 0 && doorFlushMount === 0) {
      // C-123LW Kit
      items[C123LWIndex].quantity = 1;
      items[SKK620CIndex].quantity = totalMasterStations;
    } else {
      // No Kit
      items[CMLAIndex].quantity = totalMasterStations;
      items[SKK620CIndex].quantity = totalMasterStations;
      if (doorFlushMount === 0 && vandal === 1) {
        items[LSNVPCIndex].quantity = 1;
        items[SBXNVPAIndex].quantity = 1;
      } else if (doorFlushMount === 1 && vandal === 1) {
        items[LSNVPCIndex].quantity = 1;
      } else if (doorFlushMount === 1 && vandal === 0) {
        items[LEDAIndex].quantity = 1;
      } else {
        // This pattern falls into a kit
      }
    }
  } else {
    // CCS System
    items[CCS1AIndex].quantity = 1;
    items[PT1210NAIndex].quantity = 1;
  }

  if (externalSignaling === 1) {
    items[DAK2SIndex].quantity = 1;
    items[RYACAIndex].quantity = 1;
    items[PS1225ULIndex].quantity = 1;
  }

  items[doorFlushMountIndex].quantity = 0;
  items[vandalIndex].quantity = 0;
  items[numberOfMastersIndex].quantity = 0;
  items[masterDeskMountIndex].quantity = 0;
  items[doorReleaseIndex].quantity = 0;
  items[externalSignalingIndex].quantity = 0;
};

const updateWiresTW = (values: FormValues) => {
  if (
    values.items
      .filter((item) => item.itemName === 'CCS-1A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'CCS',
      wire: '822202',
      description: "2 Cond., 22AWG, Shielded, Available in: 500' & 1000'",
      maxDistance: "250'",
      jacketNomOD: 'PVC .141"',
      capacitanceLoopRes: '48.2 pf/ft, 16.6 Ohms'
    });
  } else if (
    values.items
      .filter((item) => item.itemName === 'LEM-1')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'LEM-1DL')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'LEM-1DLC')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'LEM-1DLS')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'LEM',
      wire: '822202',
      description: "2 Cond., 22AWG, Shielded, Available in: 500' & 1000'",
      maxDistance: "690'",
      jacketNomOD: 'PVC .141"',
      capacitanceLoopRes: '48.2 pf/ft, 16.6 Ohms'
    });
  } else if (
    values.items
      .filter((item) => item.itemName === 'C-ML/A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'C-123L/A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'C-123LW')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'C-123L',
      wire: '822202',
      description: "2 Cond., 22AWG, Shielded, Available in: 500' & 1000'",
      maxDistance: "250'",
      jacketNomOD: 'PVC .141"',
      capacitanceLoopRes: '48.2 pf/ft, 16.6 Ohms'
    });
  }
};

const updateNotesTW = (values: FormValues) => {
  if (
    values.items
      .filter((item) => item.itemName === 'LEM-1DLS')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'LEM-1DL')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'LEM-1DLC')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'C-123L/A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'C-123LW')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'C-ML/A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The master station(s) provide a normally open dry contact. Door release mechanisms and power for them are not included in this quote.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'DAK-2S')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'A DAK-2S is required to connect the RY-AC to your system. Please contact Technical Support for information on connecting the RY-AC.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-AC/A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The RY-AC requires a 12V DC power supply. (Aiphone PS-1225UL or any 12V DC power supply, 500mA or greater.)'
    );
  }
};
