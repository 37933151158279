import { Field, Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Link,
  Typography,
  Alert,
  IconButton,
  AlertTitle,
  Tooltip
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LoadingButton } from '@mui/lab';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import * as yup from 'yup';
import { boolean } from 'yup';
import { IRegisterGatewayWithCloud } from '../../RegisterGateway';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { PromptDownloadLatestFirmwareDialog } from 'features/RemoteManagement/NewSiteWizard/steps/RegisterGateway/components/dialogs/PromptDownloadLatestFirmwareDialog';
import { getSiteSubscriptionStatus } from 'shared/api/Aws/SimBillingApi';
import { useTranslation } from 'react-i18next';
import { getGWErrorCode } from 'shared/rmGateway/gwErrorHandler';
import { useGetGatewayStatus } from 'shared/rmGateway/gwHelperFunction';

interface IRegisterGatewayFormProps {
  awsPropertyId: string;
  onSubmitCallback: (values: IRegisterGatewayWithCloud, gwSubscribedSite: string) => void;
  isLoading: boolean;
  gatewayType: number;
  setGatewayType: (gatewayType: number) => void;
}

interface IFormValues {
  macAddress: string;
}

const initialValues: IFormValues = {
  macAddress: ''
};

const macValidation = yup.object().shape({
  macAddress: yup
    .string()
    .required('MAC address is required')
    .matches(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/, 'Invalid MAC address')
});

export const RegisterGatewayForm: FC<IRegisterGatewayFormProps> = ({
  awsPropertyId,
  onSubmitCallback,
  isLoading,
  gatewayType,
  setGatewayType
}) => {
  const [gwReadyToRegister, setGwReadyToRegister] = useState(false);
  const [gwSubscribedSite, setGwSubscribedSite] = useState('');
  const [downloadIXGWGWModalOpen, setDownloadIXGWGWModalOpen] = useState(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [showSimBillingLink, setShowSimBillingLink] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const { t } = useTranslation();

  /** API request */
  const getGatewayStatus = useGetGatewayStatus();

  const checkUserSiteSubscriptionStatus = React.useCallback(async () => {
    const siteSubscriptionStatusResponse = await getSiteSubscriptionStatus([Number(awsPropertyId)]);

    if (siteSubscriptionStatusResponse?.data[0] !== null && siteSubscriptionStatusResponse?.data.length > 0) {
      setIsSubscriptionActive(true);
    } else {
      setIsSubscriptionActive(false);
    }
  }, [awsPropertyId, setIsSubscriptionActive]);

  const closeDownloadIXGWGWModal = () => {
    setDownloadIXGWGWModalOpen(false);
  };

  const checkIoTConnection = async (macAddress: string) => {
    const inQueue = 'gw/queue';

    try {
      const gwStatus = await getGatewayStatus(macAddress);

      if (!gwStatus.gateway_is_connected) {
        if (['MQTT_KEEP_ALIVE_TIMEOUT', 'CONNECTION_LOST'].includes(gwStatus?.disconnect_reason)) {
          throw new Error(gwStatus.disconnect_reason);
        }
        return;
      }

      // Parse the site ID if the topic is not 'inQueue'
      const parseSiteId = gwStatus?.topic.split('/')[1];
      setGwSubscribedSite(parseSiteId);

      if (gwStatus.topic === inQueue || parseSiteId === awsPropertyId) {
        setGwReadyToRegister(true);
        return;
      }

      if (parseSiteId !== awsPropertyId) {
        throw {
          message: 'Gateway_Already_Registered_To_Site',
          site_id: gwStatus.site_id
        };
      }
    } catch (error) {
      /**
       * TODO: Need to handle error 404, payload not found
       *
       */
      if (error.message === 'Gateway_Already_Registered_To_Site') {
        const customError = error as { message: string; site_id: string };
        setErrorMessage(`${t(`Gateway_Error_message.${customError.message}`)} # ${customError.site_id}`);
        setShowErrorMessage(true);
        return;
      }
      setErrorMessage(t(getGWErrorCode(error)));
      setShowErrorMessage(true);
    }
  };

  const handleSubmit = async (
    values: IFormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    // Reset the state
    setSubmitting(true);
    setGwSubscribedSite('');
    setGwReadyToRegister(false);
    setShowErrorMessage(false);
    setErrorMessage('');

    // if the gateway is not connected, check the connection
    if (!gwReadyToRegister) {
      await checkIoTConnection(values.macAddress);
    } else {
      onSubmitCallback(values, gwSubscribedSite);
    }
    setSubmitting(false);
  };

  React.useEffect(() => {
    (async () => {
      await checkUserSiteSubscriptionStatus();
    })();
  }, [checkUserSiteSubscriptionStatus]);

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={macValidation} onSubmit={handleSubmit}>
        {({ dirty, isValid, errors, touched, isSubmitting, setFieldValue }) => {
          const handleMacAddressChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
            let value = event.target.value.replace(/[^0-9A-Fa-f]/g, '').toUpperCase();
            if (value.length > 12) value = value.slice(0, 12);
            const formattedValue = value.match(/.{1,2}/g)?.join(':') ?? value;
            await setFieldValue('macAddress', formattedValue);
          };

          const handleGatewayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setGatewayType(parseInt(event.target.value));
          };

          return (
            <Form>
              <Grid container spacing={2} sx={{ mt: 2, mb: 6 }}>
                <Grid item xs={5}>
                  <Box sx={styles.centerContent}>
                    <Typography sx={{ mt: 0 }}>{t('Please_Select_The_Model_Of_Your_Gateway')}</Typography>
                    <RadioGroup
                      row
                      aria-label="gatewayType"
                      name="gatewayType"
                      onChange={handleGatewayChange}
                      value={gatewayType}
                    >
                      <FormControlLabel
                        sx={{ mr: 14 }}
                        value="IXGW-TGW"
                        control={
                          <Radio
                            value={14}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const isChecked = boolean().cast(event.target.checked);
                              if (isChecked) {
                                setShowSimBillingLink(true);
                              }
                            }}
                          />
                        }
                        label="IXGW-TGW"
                        labelPlacement="bottom"
                      />

                      <FormControlLabel
                        value="IXGW-GW"
                        control={
                          <Radio
                            value={1}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const isChecked = boolean().cast(event.target.checked);
                              if (isChecked) {
                                setDownloadIXGWGWModalOpen(true);
                                setShowSimBillingLink(false);
                              }
                            }}
                          />
                        }
                        label="IXGW-GW"
                        labelPlacement="bottom"
                      />
                    </RadioGroup>
                    <Grid container spacing={20} sx={{ mb: 3 }}>
                      <Grid item sx={{ ml: 3 }}>
                        <img src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/IXGW-TGW.png`} alt="" />
                      </Grid>
                      <Grid item>
                        <img src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/IXGW-GW.png`} alt="" />
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={styles.centerContent}>
                        <Typography variant="h6" sx={{ mb: 4 }}>
                          {t('Enter_MAC_Address_Below')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Box sx={(styles.centerContent, styles.macAddressFieldBox)}>
                      <Field
                        name="macAddress"
                        as={TextField}
                        label="IXGW-(T)GW MAC Address"
                        variant="outlined"
                        onChange={handleMacAddressChange}
                        error={errors.macAddress && !!touched.macAddress}
                        helperText={errors.macAddress && !!touched.macAddress}
                        sx={styles.macAddressField}
                        maxLength={17}
                      />
                      {gwReadyToRegister && (
                        <Tooltip title={t('Gateway_Connected')}>
                          <IconButton sx={styles.checkIcon}>
                            <CheckCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      loading={isSubmitting || isLoading}
                      disabled={isSubmitting || !isValid || !dirty}
                      loadingIndicator={<CircularProgress size="20px" color="white" />}
                    >
                      {!gwReadyToRegister ? t('Check_Gateway_Connection') : t('Register_Gateway')}
                    </LoadingButton>
                  </Box>
                </Grid>
                <Grid item xs={2} sx={styles.centerContent}>
                  <Box sx={styles.separator}>
                    <Divider orientation="vertical" sx={styles.divider} />
                  </Box>
                </Grid>

                <Grid item xs={5} sx={styles.centerContent}>
                  {showSimBillingLink && (
                    <Alert
                      className={'mt-2 w-full'}
                      variant={'filled'}
                      severity={isSubscriptionActive ? 'success' : 'warning'}
                    >
                      {isSubscriptionActive ? (
                        <>
                          <AlertTitle>
                            {' '}
                            {t('IXG_Property_ID')} {':'} {awsPropertyId}
                          </AlertTitle>
                          {t('Subscription_Active')}
                          {t('You_Can_Now_Register_Gateway')}
                        </>
                      ) : (
                        <>
                          <AlertTitle>
                            {' '}
                            {t('IXG_Property_ID')} {':'} {awsPropertyId}
                          </AlertTitle>
                          {t('No_SIM_Subscription')} <br />
                          {t('Sign_up_for_SIM_Billing')}
                          <Link
                            className={'text-white'}
                            sx={{
                              textDecorationColor: 'white'
                            }}
                            href={`/simbilling/sites/${awsPropertyId}/site`}
                            target={'_blank'}
                          >
                            {t('Sim_Billing')}
                          </Link>
                        </>
                      )}
                    </Alert>
                  )}
                  <Box sx={styles.centerContent}>
                    <Box
                      component="img"
                      sx={{ width: '100%' }}
                      src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/IXGW-Mac-Location.png`}
                      alt="location of mac address on ixgw-gw device"
                    ></Box>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <PromptDownloadLatestFirmwareDialog
        deviceType={'IXGW-GW'}
        onClose={closeDownloadIXGWGWModal}
        open={downloadIXGWGWModalOpen}
      />

      <Grid>
        <SnackbarAlert
          type="error"
          time={10000}
          text={errorMessage}
          isOpen={showErrorMessage}
          onClose={() => setShowErrorMessage(false)}
        />
      </Grid>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 1
  },
  iconButton: {
    color: 'primary.main',
    fontSize: '2rem'
  },
  iconButtonSelected: {
    color: 'secondary.main',
    fontSize: '2rem'
  },
  separator: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectedGatewayTypeCard: {
    width: '100%',
    border: '1px solid #0071ce',
    backgroundColor: '#f0f8ff'
  },
  gatewayTypeCard: {
    width: '100%'
  },
  typeSelection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    border: '1px solid #e0e0e0',
    width: '100%'
  },
  macAddressFieldBox: {
    width: '100%',
    mb: 2,
    display: 'flex',
    flexDirection: 'row'
  },
  macAddressField: {
    width: '100%'
  },
  divider: {
    height: '125%'
  },

  troubleshooting: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'Row'
  },
  checkIcon: {
    color: '#0071ce',
    ml: 1
  }
};
