/**
 * This is only for the manual testing to check the connection with the Gateway directly without creating a site
 * will remove for the production
 */
import { useState } from 'react';
import { Grid, TextField, Button, Card, Typography, CardContent, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { useHandleGatewayCommandMutation } from 'services/aiphoneCloud';
import { gwCommand } from 'shared/rmGateway/gwCommand';

const GatewayTest = () => {
  const [unregister, setUnregister] = useState(false);
  const [awsPropertyId, setAwsPropertyId] = useState(() => '');
  const [gwId, setGwId] = useState(() => '');
  const [gwPassword, setGwPassword] = useState(() => '');
  const [gwMacAddress, setGwMacAddress] = useState(() => '');
  const [handleGatewayCommand, { isError: gwCommandError, isLoading: gwLoading }] = useHandleGatewayCommandMutation();

  const unregisterGW = async () => {
    const gwInfo = {
      awsPropertyId: awsPropertyId.toString(),
      gwMacAddress: gwMacAddress.toString(),
      gwId: gwId.toString(),
      gwPassword: gwPassword.toString()
    };
    try {
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.UNREGISTER, gwInfo, null, null);
      await handleGatewayCommand(ioTPayload).unwrap();
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.UNREGISTER, gwInfo, null, null);
      await handleGatewayCommand(fetchPayload).unwrap();
      setUnregister(true);
    } catch (error) {
      console.error('Error unregistering gateway:', error);
    }
  };

  return (
    <>
      <Typography variant="h6" color="initial">
        Aiphone Tech Toolbox
      </Typography>
      <Grid sx={styles.info}>
        <CardContent>
          <Card sx={styles.card}>
            <Typography variant="h6" color="initial">
              Unregister Gateway
            </Typography>

            <CardContent>
              <TextField
                id="outlined-basic"
                label="Site ID"
                name="siteId"
                size="small"
                style={styles.textFld}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAwsPropertyId(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Gateway Mac Address"
                name="gwMacAddress"
                size="small"
                style={styles.textFld}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGwMacAddress(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Gateway id"
                name="gwId"
                size="small"
                style={styles.textFld}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGwId(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Gateway password"
                name="gwPassword"
                size="small"
                style={styles.textFld}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGwPassword(e.target.value)}
              />
              {gwCommandError && <Typography variant="body1">Error unregistering gateway</Typography>}
              <Button variant="contained" sx={styles.testButton} onClick={unregisterGW}>
                {unregister ? <CheckIcon /> : gwLoading ? <CircularProgress /> : 'Unregistered'}
              </Button>
            </CardContent>
          </Card>
        </CardContent>
      </Grid>
    </>
  );
};

const styles = {
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    margin: '12px 1px 0px 1px'
  },
  infoValue: {
    display: 'flex',
    margin: '5px'
  },
  textFld: {
    width: '200px',
    margin: '5px 5px 0px 0px'
  },
  langItemContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  langIconElem: {
    margin: '0px 5px 0px 0px',
    width: '30px'
  },
  submitButton: {
    fontSize: '1rem',
    fontWeight: '700',
    letterSpacing: '1px',
    borderRadius: '0.25 rem',
    margin: '12px 5px 0px 5px',
    width: '200px',
    '&:hover': {
      backgroundColor: '#f85000'
    },
    color: 'white',
    marginBottom: '5px'
  },
  //WILL REMOVE THIS FOR PROD
  testButton: {
    fontSize: '1rem',
    fontWeight: '700',
    letterSpacing: '1px',
    borderRadius: '0.25 rem',
    margin: '12px 5px 0px 5px',
    '&:hover': {
      backgroundColor: '#f85000'
    },
    color: 'white',
    marginBottom: '5px'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px'
  }
};

export default GatewayTest;
