import { ACLUser, ACLSite, SimBillingSite, ACLUserType } from 'features/SimBilling/Types/SimBillingTypes';
import { ApiError, ErrorType } from 'shared/api/ApiError';
import { EnumList, ICountryValue } from 'shared/utils/EnumUtils';

export const convertAuthorityCodeToUserRole = (authority: number) => {
  switch (authority) {
    case 2:
      return 'Aiphone Admin';
    case 5:
      return 'Dealer';
    case 6:
      return 'Property Manager';
  }
};

export const convertPayerToUserRole = (payer: number) => {
  switch (payer) {
    case 0:
      return 'Dealer';
    case 1:
      return 'Property Manager';
    default:
      return 'Dealer';
  }
};

export const convertJsonToAclSites = (json: any[]): ACLSite[] => {
  try {
    if (!json) {
      return [];
    }
    return json.map((item) => ({
      AppLimit: item.AppLimit,
      CountryCode: item.CountryCode,
      PropertyID: item.PropertyID,
      PropertyName: item.PropertyName && item.PropertyName.length > 0 ? item.PropertyName : ['-'],
      Billing: item.Billing,
      PropertyAddressDetail: item.PropertyAddressDetail,
      ServiceStatus: item.ServiceStatus,
      FormatVersion: item.FormatVersion,
      GwStatusList: item.GwStatusList,
      Payer: convertPayerToUserRole(item.Payer),
      Status: item.Status,
      Contract: item.Contract,
      GWList: [],
      Remarks: item.Remarks
    }));
  } catch (error) {
    return [];
  }
};

export const handleError = (error: any): { errorMessage: ErrorType } => {
  if (error instanceof ApiError) {
    return { errorMessage: error.type || ErrorType.UNEXPECTED };
  }
  return { errorMessage: ErrorType.UNEXPECTED };
};

export const getAlpha2CodeForCountry = (countryCode: string, countryEnumList: EnumList['country']): string => {
  const country = Object.values(countryEnumList).find(
    (item): item is ICountryValue => (item as ICountryValue).numericCode === countryCode
  );
  return country ? country.alpha2Code : 'N/A';
};

export const isAclUserThePayer = (aclUser: ACLUser, site: SimBillingSite | ACLSite) => {
  if (!aclUser || !site) {
    return false;
  }
  return aclUser.AuthorityCode === site.Payer;
};

export const isValidAclUserType = (value: any): value is ACLUserType => {
  return ['Aiphone Admin', 'Dealer', 'Property Manager'].includes(value);
};
