import React from 'react';
import { Switch, FormControlLabel, FormControlLabelProps, Box, BoxProps, SwitchProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * Interface representing the properties for a ToggleSwitch component.
 *
 * @property {boolean} isChecked - Determines if the switch is initially checked.
 * @property {function(boolean): void} onToggle - Callback function invoked when the switch state changes.
 * @property {BoxProps} [boxProps] - Additional properties passed to the Box component.
 * @property {FormControlLabelProps} [formControlLabelProps] - Additional properties passed to the FormControlLabel component.
 * @property {SwitchProps} [switchProps] - Additional properties passed to the Switch component.
 */
export interface ToggleSwitchCellProps {
  isChecked: boolean;
  onToggle: (checked: boolean) => void;
  boxProps?: BoxProps;
  formControlLabelProps?: FormControlLabelProps;
  switchProps?: SwitchProps;
}

/**
 * CustomSwitch component that encapsulates a customizable switch with props for handling
 * initial state, callbacks, and styling.
 *
 * This is more of a "general" component that can be used in multiple places.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.initialChecked - Initial state of the switch, whether it is checked or not.
 * @param {function} props.onToggle - Callback function called with the new checked state when the switch is toggled.
 * @param {Object} [props.boxProps] - Additional props for the Box component for layout customization.
 * @param {Object} [props.formControlLabelProps] - Additional props for the FormControlLabel component.
 * @param {Object} [props.switchProps] - Additional props for the Switch component.
 *
 * @returns {React.ReactElement} A React component that renders a customizable switch.
 *
 * @component
 */
const ToggleSwitchCell: React.FC<ToggleSwitchCellProps> = ({
  isChecked,
  onToggle,
  boxProps,
  formControlLabelProps,
  switchProps
}: ToggleSwitchCellProps): React.ReactElement => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;

    onToggle(newChecked);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...boxProps?.sx }} {...boxProps}>
      <FormControlLabel
        control={<Switch checked={isChecked ?? false} onChange={handleChange} {...switchProps} />}
        label={isChecked ? t('Enabled') : t('Disabled')}
        labelPlacement="end"
        sx={{ ...formControlLabelProps?.sx }}
        {...formControlLabelProps}
      />
    </Box>
  );
};

export default ToggleSwitchCell;
