import { Box, Card, Grid, Typography } from '@mui/material';
import AppsDatagrid from './datagrids/AppsDatagrid';
import AppsQr from './AppsQr';

const AppsCard = () => {
  return (
    <>
      <Card sx={styles.appsCard}>
        <Typography variant="sectionHeader">Mobile Apps</Typography>
        <Box sx={styles.appsDatagridWrapper}>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <AppsDatagrid />
            </Grid>
            <Grid item xs={5} sx={styles.centerVertically}>
              <AppsQr />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

const styles = {
  appsCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '20px'
  },
  appsDatagridWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    marginTop: '10px'
  },
  addAppButton: {
    float: 'right'
  },
  centerVertically: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default AppsCard;
