import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDeleteSiteMutation } from 'services/aiphoneCloud';
import { useTranslation } from 'react-i18next';

interface DeleteSiteDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  publicId: string;
}

const DeleteSiteDialog: React.FC<DeleteSiteDialogProps> = ({ isOpen, setIsOpen, publicId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const [deleteSite] = useDeleteSiteMutation();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDeleteSite = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      await deleteSite(publicId).unwrap();
      setLoading(false);
      setIsOpen(false);
      navigate('/remotemanagement');
    } catch (error) {
      setErrorMessage(t('Failed_to_delete_site_Please_try_again'));
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('Permanently_Delete_Site')}</DialogTitle>
      <DialogContent>
        <Typography>
          {t('Are_you_sure_you_want_to_permanently_delete_this_site_This_action_cannot_be_undone')}
        </Typography>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" disabled={loading}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={handleDeleteSite}
          variant="contained"
          color="error"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSiteDialog;
