import { Box, Grid, Link, Card, CardContent, Container, Typography, CardHeader } from '@mui/material';
import { SupportAgent as SupportAgentIcon, Email as EmailIcon, Map as MapIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Support = () => {
  const { t } = useTranslation();
  return (
    <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* Aiphone Logo */}
      <Card>
        <CardContent>
          <Box
            sx={styles.logoWrapper}
            component="img"
            src="/AiphoneCloud-Logo_Blue.png"
            alt={t('Aiphone_Cloud_Logo')}
          />
        </CardContent>
      </Card>

      {/* Email Support Card */}
      <Card sx={styles.cardContainer}>
        <CardHeader sx={styles.cardHeaderContent} title={t('Email_Support')} />
        <CardContent sx={styles.cardContentContainer}>
          <Grid sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
            <Grid sx={styles.innerContainer}>
              <Typography variant="h6" sx={{ marginLeft: 1 }}>
                {t('Aiphone_Cloud_Contact')}
              </Typography>
              <Typography variant="h6" sx={{ marginLeft: 1 }}>
                {' '}
                <Link href="mailto:cloudadmin@aiphone.com?subject=AiphoneCloud Help">
                  <EmailIcon />
                  {t('Aiphone_Cloud_Admin_Team')}
                </Link>
              </Typography>
            </Grid>
            <Grid sx={styles.innerContainer}>
              <Typography variant="h6" sx={{ marginLeft: 1 }}>
                {t('Aiphone_Support_Contact')}
              </Typography>
              <Typography variant="h6" sx={{ marginLeft: 1 }}>
                <Link href="https://www.aiphone.com/sales/regional-sales" target="_blank">
                  <MapIcon /> {t('Aiphone_Sales_Team')}
                </Link>
              </Typography>
              <Typography variant="h6" sx={{ marginLeft: 1 }}>
                <Link href="https://www.aiphone.com/support/technical-support" target="_blank">
                  <EmailIcon /> {t('Aiphone_Technical_Support')}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Phone Support */}
      <Card sx={styles.cardContainer}>
        <CardHeader sx={styles.cardHeaderContent} title="Phone Support" />
        <CardContent sx={styles.cardContentContainer}>
          <Grid sx={{ padding: '20px', display: 'flex', flexDirection: 'row', alignmentBaseline: 'center' }}>
            <SupportAgentIcon style={{ fontSize: 30, color: '#003366' }} />
            <Typography variant="h6" sx={{ marginLeft: 1 }}>
              <Link href="tel:+18006920200"> {t('Phone_Support')}</Link>
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

const styles = {
  logoWrapper: {
    padding: 3,
    display: 'block',
    maxWidth: '60%',
    margin: '0 auto'
  },
  comingSoonText: {
    marginTop: 4,
    textAlign: 'center',
    fontWeight: 'medium',
    letterSpacing: 2
  },
  link: {
    textDecoration: 'none',
    textAlign: 'center',
    color: '#0071CE',
    fontWeight: 'bold',
    fontSize: '1.5rem'
  },
  cardHeaderContent: {
    padding: '40px',
    width: '30%',
    backgroundColor: 'primary.dark',
    color: '#fff'
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    marginY: '20px'
  },
  cardContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  innerContainer: {
    width: '100%',
    margin: '10px',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: 'rgba(242, 242, 242, 0.5)'
  }
};

export default Support;
