import { TabPanel } from '@mui/lab';
import AddDevicesTabContent from '../../steps/AddDevices/components/AddDevicesTabContent';

interface IDeviceTabPanelsProps {
  deviceListByType: { [key: string]: string[] };
}

const DeviceTabPanels = ({ deviceListByType }: IDeviceTabPanelsProps) => {
  // Fixing error of returning null[]
  const deviceTypes = Object.keys(deviceListByType);
  if (deviceTypes.length === 0) return null;

  return (
    // Fixing this component so it returns Element instead of Element[]
    <>
      {Object.keys(deviceListByType).map((deviceType) => {
        return (
          <TabPanel key={deviceType} value={deviceType}>
            <AddDevicesTabContent category={deviceType} />
          </TabPanel>
        );
      })}
    </>
  );
};

export default DeviceTabPanels;
