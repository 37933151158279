import { Localization, enUS, frFR, esES } from '@mui/material/locale';
import { ES, FR, FlagComponent, US } from 'country-flag-icons/react/3x2';
import langEN from 'data/Lang/AppStrings_EN';
import { getSelectedLocaleStrings } from 'store/slices/localizationSlice';
import { useSelector } from 'react-redux';

export interface IStringDictionary {
  [key: string]: string;
}

export class Locale {
  localization: Localization;
  countryCode: string;
  countryName: string;
  language: string;
  flagIcon: FlagComponent;
  localizationStrings: IStringDictionary;

  constructor(
    localization: Localization,
    countryCode: string,
    countryName: string,
    language: string,
    flagIcon: FlagComponent,
    localizationStrings: IStringDictionary
  ) {
    this.localization = localization;
    this.countryCode = countryCode;
    this.countryName = countryName;
    this.language = language;
    this.flagIcon = flagIcon;
    this.localizationStrings = localizationStrings;
  }
}

// TODO: Create and insert the proper lang packs for ES and FR
export const SupportedLocales: Map<string, Locale> = new Map<string, Locale>([
  ['US', new Locale(enUS, 'US', 'United States', 'English', US, langEN)],
  ['ES', new Locale(esES, 'ES', 'Spain', 'Español', ES, langEN)],
  ['FR', new Locale(frFR, 'FR', 'France', 'Français', FR, langEN)]
]);

export const getString = (key: string): string => {
  const localeStrings = useSelector(getSelectedLocaleStrings);

  if (localeStrings[key]) {
    return localeStrings[key];
  } else {
    throw new Error(`String ${key} not found`);
  }
};
