import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Badge, Box, Drawer, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import LanguageSelector from 'localization/LanguageSelector';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation from react-i18next
import { resetUsersState } from 'store';
import { getCurrentUser } from 'store/slices/usersSlice';
import GatewayTest from 'test/TestGateway'; // Will remove for production
import CurrentBranchSelect from './components/CurrentBranchSelect';

interface AppHeaderProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  toggled: boolean;
  setToggled: (toggled: boolean) => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({ collapsed, setCollapsed, toggled, setToggled }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const [isNotificationsDrawerOpen, setIsNotificationsDrawerOpen] = useState(false);
  const [isGatewayDrawerOpen, setIsGatewayDrawerOpen] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const hasGlobalRole = currentUser?.permissions.global.roleList !== null;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = () => {
    setCollapsed(!collapsed);
    if (window.innerWidth < 600) {
      setToggled(!toggled);
    }
  };

  const handleLogout = () => {
    dispatch(resetUsersState());
    localStorage.clear();
    navigate('/');
    window.location.reload();
  };

  return (
    <AppBar position="sticky" sx={styles.appBar}>
      <Toolbar sx={styles.toolBar}>
        <IconButton onClick={handleMenuClick} className="app-header-icon">
          <MenuIcon fontSize="large" htmlColor="#ffffff" sx={styles.menuIcon} />
        </IconButton>
        <Hidden smDown>
          <Link to="/">
            <Box component="img" sx={styles.appLogo} src="/AiphoneCloud-Logo_Header.png" />
          </Link>
        </Hidden>
        <Box sx={styles.branchSelector}>
          <CurrentBranchSelect />
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        {/* Updated Language Selector Integration */}
        <LanguageSelector />

        {/* Hide for production and staging - start */}
        {hasGlobalRole && (
          <Tooltip title={t('Aiphone_TechTools')}>
            <IconButton
              aria-label={t('Aiphone_TechTools')}
              onClick={() => setIsGatewayDrawerOpen(!isGatewayDrawerOpen)}
              className="app-header-icon"
            >
              <Badge badgeContent={0} overlap="circular">
                <EngineeringIcon fontSize="large" htmlColor="#ffffff" sx={styles.menuIcon} />
              </Badge>
            </IconButton>
          </Tooltip>
        )}

        <IconButton aria-label={t('profile')} onClick={() => navigate('/profile')} className="app-header-icon">
          <AccountCircleIcon
            fontSize="large"
            htmlColor={location.pathname === '/profile' ? '#1CACFC' : '#ffffff'}
            sx={styles.menuIcon}
          />
        </IconButton>

        {/* Logout Button */}
        <Link to="/">
          <IconButton aria-label={t('Logout')} onClick={handleLogout} className="app-header-icon">
            <LogoutIcon fontSize="large" htmlColor="#ffffff" sx={styles.menuIcon} />
          </IconButton>
        </Link>
      </Toolbar>

      <Drawer anchor="right" open={isGatewayDrawerOpen} onClose={() => setIsGatewayDrawerOpen(false)}>
        <Box sx={styles.drawer}>
          <GatewayTest />
        </Box>
      </Drawer>

      <Drawer anchor="right" open={isNotificationsDrawerOpen} onClose={() => setIsNotificationsDrawerOpen(false)}>
        <Box sx={styles.drawer}>
          <Typography variant="h6" color="initial">
            {t('Notifications')}
          </Typography>
        </Box>
      </Drawer>
    </AppBar>
  );
};

const styles = {
  branchSelector: {
    width: '20%',
    marginLeft: '2rem'
  },
  appBar: {
    bgcolor: 'primary',
    paddingLeft: 0,
    top: 0
  },
  appLogo: {
    ml: 2,
    cursor: 'pointer',
    width: '10rem',
    marginTop: '.5rem'
  },
  menuIcon: {
    '&:hover': {
      color: '#1CACFC'
    }
  },
  toolBar: {
    paddingLeft: '12px',
    '@media (min-width: 600px)': {
      paddingLeft: '12px'
    }
  },
  drawer: {
    padding: '2rem',
    width: '20rem'
  }
};

export default AppHeader;
