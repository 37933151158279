import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import TenantsControlPanel from './components/TenantsControlPanel';
import TenantsDataGrid from './components/TenantsDataGrid';
import AddTenantDialog from './components/AddTenantDialog';
import { useSearchParams } from 'react-router-dom';
import { GridFilterItem } from '@mui/x-data-grid';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import EditTenantDialog from './components/EditTenantDialog';

const Tenants = () => {
  const [isAddTenantDialogOpen, setIsAddTenantDialogOpen] = useState(false);
  const [isEditTenantDialogOpen, setIsEditTenantDialogOpen] = useState(false);
  const [selectedTenantId, setSelectedTenantId] = useState<string>('');
  const [filterModel, setFilterModel] = useState<{
    items: GridFilterItem[];
  }>({ items: [] });
  const [searchParams, setSearchParams] = useSearchParams();
  const [showResetButton, setShowResetButton] = useState(false);
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const { isAllowedTo } = usePermission();
  const canTenantView = isAllowedTo('tenant:view', sitePublicId, PermissionsContextType.SITE);

  useEffect(() => {
    setShowResetButton(searchParams.toString().length > 0);
  }, [searchParams]);

  const resetFilters = () => {
    setFilterModel({ items: [] });
    setSearchParams();
  };

  return (
    <>
      {canTenantView ? (
        <>
          <Box sx={styles.tenantsWrapper}>
            <Box sx={styles.controlPanelWrapper}>
              <TenantsControlPanel
                setIsOpen={setIsAddTenantDialogOpen}
                showResetButton={showResetButton}
                resetFilters={resetFilters}
              />
            </Box>
            <Box sx={styles.tenantsDataGridWrapper}>
              <TenantsDataGrid
                searchParams={searchParams}
                filterModel={filterModel}
                setFilterModel={setFilterModel}
                setIsEditTenantDialogOpen={setIsEditTenantDialogOpen}
                setSelectedTenantId={setSelectedTenantId}
              />
            </Box>
          </Box>
          <AddTenantDialog isOpen={isAddTenantDialogOpen} setIsOpen={setIsAddTenantDialogOpen} />
          <EditTenantDialog
            isOpen={isEditTenantDialogOpen}
            setIsOpen={setIsEditTenantDialogOpen}
            selectedTenantId={selectedTenantId}
            setSelectedTenantId={setSelectedTenantId}
          />
        </>
      ) : (
        <div>You do not have permission to view this content.</div>
      )}
    </>
  );
};

const styles = {
  tenantsWrapper: {
    width: '100%',
    minWidth: '1250px',
    height: '100%'
  },
  tenantsDataGridWrapper: {
    display: 'flex',
    height: '90%'
  },
  controlPanelWrapper: {
    width: '100%',
    height: '10%'
  }
};

export default Tenants;
