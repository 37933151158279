import { Dialog, DialogTitle } from '@mui/material';
import React from 'react';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import * as yup from 'yup';
import { Formik } from 'formik';
import EditTenantForm from './EditTenantForm';
import { useLazyGetTenantListWithSiteIdQuery, useUpdateTenantMutation } from 'services/aiphoneCloud';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';

interface IEditTenantDialogProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedTenantId: string;
  setSelectedTenantId: (value: string) => void;
}

interface IEditTenantParams {
  tenantData: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    languageId: number;
    isPrimaryTenant: boolean;
    publicId: string;
  };
}

const EditTenantDialog = ({ isOpen, setIsOpen, selectedTenantId, setSelectedTenantId }: IEditTenantDialogProps) => {
  const tenantsList = useSelector((state: RootState) => state.tenants.TenantList);
  const selectedTenant = tenantsList[selectedTenantId];
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = React.useState(false);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = React.useState(false);
  const [isEditingTenant, setIsEditingTenant] = React.useState(false);
  const [updateTenant] = useUpdateTenantMutation();
  const [fetchTenants] = useLazyGetTenantListWithSiteIdQuery();
  const sitePublicId = useParams().id;
  const { t } = useTranslation();

  const initialValues = {
    firstName: selectedTenant?.firstName,
    lastName: selectedTenant?.lastName,
    email: selectedTenant?.email,
    phoneNumber: selectedTenant?.phoneNumber,
    primaryTenant: selectedTenant?.isPrimaryTenant
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email')
  });

  const handleEditTenant = async (values: any) => {
    setIsEditingTenant(true);

    const params = {
      tenantData: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phoneNumber,
        languageId: 2,
        isPrimaryTenant: values.primaryTenant,
        publicId: selectedTenant?.publicId
      }
    };

    const updateTenantAndHandleResponse = async (params: IEditTenantParams) => {
      try {
        const response = await updateTenant(params);
        if (response.data) {
          setSuccessMessage(t('Edit_Tenant_Success'));
          setIsSuccessAlertOpen(true);
          setIsOpen(false);
        }
        fetchTenants({
          sitePublicId: sitePublicId,
          qty: 500,
          page: 0
        });
      } catch (error) {
        setErrorMessage(t('Edit_Tenant_Failed'));
        setIsErrorAlertOpen(true);
      } finally {
        setIsEditingTenant(false);
      }
    };

    const updateOtherPrimaryTenants = async () => {
      const tenants = Object.values(tenantsList).filter(
        (tenant) => tenant.unitPublicId === selectedTenant?.unitPublicId
      );

      for (const tenant of tenants) {
        if (tenant.publicId !== selectedTenant?.publicId && tenant.isPrimaryTenant) {
          try {
            await updateTenant({
              tenantData: {
                firstName: tenant.firstName,
                lastName: tenant.lastName,
                email: tenant.email,
                phone: tenant.phoneNumber,
                languageId: 2,
                isPrimaryTenant: false,
                publicId: tenant.publicId
              }
            });
          } catch (error) {
            setErrorMessage(t('Edit_Tenant_Failed'));
            setIsErrorAlertOpen(true);
          }
        }
      }
    };

    if (values.primaryTenant) {
      await updateOtherPrimaryTenants();
    }

    await updateTenantAndHandleResponse(params);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedTenantId('');
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>{t('Edit_Tenant')}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleEditTenant}
          enableReinitialize
        >
          {({ ...props }) => (
            <EditTenantForm formikProps={props} setIsOpen={setIsOpen} isEditingTenant={isEditingTenant} />
          )}
        </Formik>
      </Dialog>
      <SnackbarAlert
        type="success"
        time={3000}
        text={successMessage}
        isOpen={isSuccessAlertOpen}
        onClose={() => setIsSuccessAlertOpen(false)}
      />
      <SnackbarAlert
        type="error"
        time={3000}
        text={errorMessage}
        isOpen={isErrorAlertOpen}
        onClose={() => setIsErrorAlertOpen(false)}
      />
    </>
  );
};

export default EditTenantDialog;
