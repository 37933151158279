import React, { useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

/**
 * Props for the CheckedCheckbox component.
 *
 * @typedef {Object} CheckedCheckboxProps
 * @property {boolean} initialChecked - The initial checked state of the checkbox.
 * @property {function} onToggle - Callback function to handle the toggle event.
 * @property {BoxProps} [boxProps] - Optional additional properties for the checkbox container.
 */
export interface CheckedCheckboxProps {
  initialChecked: boolean;
  onToggle: (checked: boolean) => void;
  boxProps?: BoxProps;
}

/**
 * Component representing a checkbox that toggles its checked state when clicked.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.initialChecked - Initial checked state of the checkbox.
 * @param {Function} props.onToggle - Callback function that is triggered when the checkbox is toggled.
 * @param {Object} props.boxProps - Additional properties to pass to the Box component.
 *
 * @returns {React.ReactElement} The rendered checkbox component.
 */
const CheckedCheckbox: React.FC<CheckedCheckboxProps> = ({
  initialChecked,
  onToggle,
  boxProps
}: CheckedCheckboxProps): React.ReactElement => {
  const [checked, setChecked] = useState(initialChecked);

  const handleClick = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onToggle(newChecked);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        paddingLeft: '0px',
        paddingRight: '0px',
        marginLeft: '0px',
        marginRight: '0px',
        width: '100%', // Ensure the box takes the full width of the cell
        height: '100%', // Ensure the box takes the full height of the cell
        backgroundColor: checked ? 'rgba(33, 150, 243, 0.08)' : 'transparent', // Apply 8% opacity to the blue color using rgba
        ...boxProps?.sx // Merge additional styles if they are provided
      }}
      {...boxProps}
    >
      {checked && <CheckIcon color={'primary'} />}
    </Box>
  );
};

export default CheckedCheckbox;
