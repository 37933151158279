import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { getQuikspecs } from 'shared/api/Aws/awsApi';
import { useNavigate } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import { getQuikSpecsData } from '../../Utils/QuikspecC2DataValues';
import { FormValues } from 'features/QuikSpec/Types/QuikspecFormTypes';
import { getString } from 'shared/utils/LocalizationUtils';

const UserQuikspecsDataGrid = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [quikspecs, setQuikspecs] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const companyId = localStorage.getItem('C2CompanyId') ?? '';
  const contactId = localStorage.getItem('C2ContactId') ?? '';
  const selectedSpec = getString('Title_Selected_Spec');
  const close = getString('Button_Close');
  const siteName = getString('Site_Name');
  const quantity = getString('Quantity');
  const model = getString('Model');

  const handleClose = () => {
    setOpen(false);
  };
  const getAllQuikspecs = async () => {
    setLoading(true);
    // Call getQuikspecs API
    const data = getQuikSpecsData({ c2CompanyId: companyId, c2ContactId: contactId });
    await getQuikspecs(data).then((response) => {
      const files = response?.data?.files;
      if (files) {
        const filesAsObjects = files.map((file: string) => JSON.parse(file));
        setQuikspecs(filesAsObjects);
        setLoading(false);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getAllQuikspecs();
  }, []);

  const navigate = useNavigate();

  function handleEdit(row) {
    const selectedSpec = quikspecs.find((spec) => spec.quikspecId === row.id);
    //sanitize systemName because it will be going into url
    let sanitizedSystemName = selectedSpec.systemName.replace(/\s/g, '');

    //edge case for IXIXG having '|' in the systemName
    if (sanitizedSystemName.includes('|')) {
      sanitizedSystemName = sanitizedSystemName.replace(/\|/g, '');
    }

    //replace 'Series' with 'System' to match the route path
    const systemName = sanitizedSystemName.replace('Series', 'System');

    //systemName is needed for each systemPage to determine which system to display
    navigate(`/quikspec/${systemName}/${selectedSpec.quikspecId}`, {
      state: { siteName: `new${systemName}`, formValues: selectedSpec }
    });
  }

  return (
    <div>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DataGrid
            rows={quikspecs.map((item: FormValues) => ({
              id: item.quikspecId,
              name: item.description,
              system: item.systemName,
              createdDate: item.createdDate,
              lastUpdateDate: item.lastUpdateDate
            }))}
            columns={[
              { field: 'name', headerName: 'Name', flex: 1, minWidth: 100 },
              { field: 'system', headerName: 'System', flex: 1, minWidth: 100 },
              { field: 'createdDate', headerName: 'Created Date', flex: 1, minWidth: 100 },
              { field: 'lastUpdateDate', headerName: 'Last Updated', flex: 1, minWidth: 100 },
              {
                field: 'edit',
                headerName: 'Edit',
                width: 100, // Set a fixed pixel width for the 'Edit' column
                renderCell: (params) => (
                  <Box display="flex" justifyContent="center">
                    <Button onClick={() => handleEdit(params.row)}>
                      <EditIcon />
                    </Button>
                  </Box>
                )
              }
            ]}
            initialState={{
              sorting: {
                sortModel: [{ field: 'lastUpdateDate', sort: 'desc' }]
              }
            }}
            onRowClick={(row) => {
              const selected = quikspecs.find((item: FormValues) => item.quikspecId === row.id);
              setSelectedRow(selected);
              setOpen(true);
            }}
          />
          <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>{selectedSpec}</DialogTitle>
            {selectedRow && (
              <DialogContent>
                <Box sx={styles.sectionBox}>
                  {selectedRow && (
                    <Typography variant="h6" component={'h6'}>
                      {siteName} {selectedRow.quikspecName}
                    </Typography>
                  )}
                  {/* <Typography variant="h6">Market Type: {selectedRow.marketType}</Typography> */}
                  <TableContainer component={Box}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{quantity}</TableCell>
                          <TableCell>{model}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedRow.items.map((item) => (
                          <TableRow key={item.itemName}>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>{item.itemName}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </DialogContent>
            )}
            <DialogActions>
              <Button onClick={handleClose}>{close}</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

const styles = {
  sectionBox: {
    padding: '1rem',
    textAlign: 'center'
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2f2f2'
    }
  },
  tableBody: {
    borderRadius: '1rem'
  },
  column: {
    flex: 1,
    textAlign: 'center'
  },
  editColumn: {
    flex: 0.5,
    textAlign: 'center'
  }
};

export default UserQuikspecsDataGrid;
