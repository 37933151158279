import React, { useEffect, useState } from 'react';
import Introduction from '../steps/Introduction';
import RegisterGateway from '../steps/RegisterGateway/RegisterGateway';
import AddDevices from '../steps/AddDevices/AddDevices';
import AddUnits from '../steps/AddUnits/AddUnits';
import AddBuildings from '../steps/AddBuildings/AddBuildings';
import SiteConfirmation from '../steps/SiteConfirmation/SiteConfirmation';
import SyncSystemSettings from '../steps/SyncSystemSettings';
import DeviceConfigurationGrid from 'features/RemoteManagement/NewSiteWizard/steps/DeviceConfiguration/DeviceConfigurationGrid';
import { useSelector } from 'react-redux';
import { selectBuildings } from 'store/slices/buildingsSlice';

export enum WizardStep {
  Introduction,
  RegisterGateway,
  AddDevices,
  AddBuildings,
  AddUnits,
  Devices,
  Confirmation,
  SyncSystemSettings
}

const MultiTenantSiteWizard: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<WizardStep>(WizardStep.Introduction);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [numberOfBuildings, setNumberOfBuildings] = useState(1);
  const buildingList = useSelector(selectBuildings);

  useEffect(() => {
    if (currentStep === WizardStep.AddBuildings) {
      setNumberOfBuildings(Object.keys(buildingList).length);
    }
  }, [currentStep]);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => {
      return prevStep + 1;
    });
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => {
      return prevStep - 1;
    });
  };

  const renderStep = () => {
    switch (currentStep) {
      case WizardStep.Introduction:
        return (
          <Introduction
            isChecked1={isChecked1}
            isChecked2={isChecked2}
            isChecked3={isChecked3}
            setIsChecked1={setIsChecked1}
            setIsChecked2={setIsChecked2}
            setIsChecked3={setIsChecked3}
            handleNextStep={handleNextStep}
          />
        );
      case WizardStep.RegisterGateway:
        return (
          <RegisterGateway
            isRegistered={isRegistered}
            setIsRegistered={setIsRegistered}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case WizardStep.AddDevices:
        return (
          <AddDevices
            isRegistered={isRegistered}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case WizardStep.Devices:
        return <DeviceConfigurationGrid handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;

      case WizardStep.AddUnits:
        return <AddUnits handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;
      case WizardStep.AddBuildings:
        return (
          <AddBuildings
            numberOfBuildings={numberOfBuildings}
            setNumberOfBuildings={setNumberOfBuildings}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case WizardStep.Confirmation:
        return <SiteConfirmation handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;
      case WizardStep.SyncSystemSettings:
        return <SyncSystemSettings />;
      default:
        return <SyncSystemSettings />;
    }
  };

  return <div>{renderStep()}</div>;
};

export default MultiTenantSiteWizard;
