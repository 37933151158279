/**
 * @description Helper functions for the gateway
 *
 */
import { useHandleGatewayCommandMutation } from 'services/aiphoneCloud';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';

/**
 *
 * @returns getGatewayStatus payload
 */
export const useGetGatewayStatus = () => {
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();

  const getGatewayStatus = async (macAddress: string) => {
    const payload = {
      action: 'checkConnection',
      payload: {
        mac_addr: macAddress
      }
    };
    const response = await handleGatewayCommand(payload).unwrap();
    return response;
  };

  return getGatewayStatus;
};

export const useUnregisterGateway = () => {
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();

  const unregisterGateway = async (gatewayInfo) => {
    // Send command to gateway via IoT
    const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.UNREGISTER, gatewayInfo, null, null);
    if (ioTPayload === 'Missing information') {
      throw new Error('Missing information');
    }
    await handleGatewayCommand(ioTPayload).unwrap();

    // Create a timeout for 60 seconds
    await new Promise((resolve) => setTimeout(resolve, 60000));

    // Fetch the result from the gateway
    const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.UNREGISTER, gatewayInfo, null, null);
    const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

    return fetchResponse;
  };

  return unregisterGateway;
};
