import {
  Alert,
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { useGetUserAttrQuery, useResendTempPasswordMutation, useResetPasswordMutation } from 'services/aiphoneCloud';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next'; // Import useTranslation

interface IResetPasswordDialog {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedUser: string | undefined;
  setErrorMessage: (message: string) => void;
  setIsError: (isError: boolean) => void;
  setSuccessMessage: (message: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  resetUserType: string;
}

const ResetPasswordDialog = ({
  isOpen,
  setIsOpen,
  selectedUser,
  setErrorMessage,
  setIsError,
  setSuccessMessage,
  setIsSuccess
}: IResetPasswordDialog) => {
  const { t } = useTranslation(); // Initialize the translation hook

  const {
    data: userData,
    isLoading: isUserDataLoading,
    isFetching: isFetchingUserData
  } = useGetUserAttrQuery(selectedUser || '', {
    skip: !selectedUser
  });
  const [resetPassword, { isLoading: isResetPasswordLoading }] = useResetPasswordMutation();
  const [resendTempPassword, { isLoading: isResendTempPasswordLoading }] = useResendTempPasswordMutation();

  const handlePasswordReset = () => {
    if (userData?.UserStatus === 'FORCE_CHANGE_PASSWORD') {
      resendTempPassword({ email: userData.UserAttributes[0].Value })
        .unwrap()
        .then((response) => {
          if (response.error) {
            setErrorMessage(t('Password_Reset_Failed'));
            setIsError(true);
          } else {
            setSuccessMessage(t('Password_Reset_Email_Sent'));
            setIsSuccess(true);
            setIsOpen(false);
          }
        });
    } else {
      resetPassword({ username: selectedUser || '' })
        .unwrap()
        .then((response) => {
          if (response.error) {
            setErrorMessage(t('Password_Reset_Failed'));
            setIsError(true);
          } else {
            setSuccessMessage(t('Password_Reset_Initiated'));
            setIsSuccess(true);
            setIsOpen(false);
          }
        });
    }
  };

  const UserStatusInfo = () => {
    let alertSeverity: AlertColor = 'info';
    let alertMessage = '';
    switch (userData?.UserStatus) {
      case 'RESET_REQUIRED':
        alertSeverity = 'warning';
        alertMessage = t('User_Reset_Required_Message');
        break;
      case 'FORCE_CHANGE_PASSWORD':
        alertSeverity = 'info';
        alertMessage = t('User_Force_Change_Password_Message');
        break;
      case 'CONFIRMED':
        alertSeverity = 'success';
        alertMessage = t('User_Confirmed_Message');
        break;
      default:
        alertSeverity = 'info';
    }
    return <Alert severity={alertSeverity}>{alertMessage}</Alert>;
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} aria-labelledby="" maxWidth="md" fullWidth>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{t('Reset_User_Password')}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        {isUserDataLoading ? (
          <Box sx={styles.centerContent}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Typography variant="body1">{t('Password_Reset_Description')}</Typography>
            <Box sx={styles.my2}>
              {isFetchingUserData ? (
                <Box sx={styles.centerContent}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <UserStatusInfo />

                  <DialogActions>
                    <Button variant="contained" onClick={() => setIsOpen(false)} color="primary">
                      {t('Cancel')}
                    </Button>
                    {userData?.UserStatus === 'RESET_REQUIRED' ? null : (
                      <LoadingButton
                        variant="contained"
                        onClick={handlePasswordReset}
                        loading={isResendTempPasswordLoading || isResetPasswordLoading}
                        color="primary"
                      >
                        {t('Reset_Password')}
                      </LoadingButton>
                    )}
                  </DialogActions>
                </>
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  my2: {
    marginY: 2
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default ResetPasswordDialog;
