import { Box, Checkbox, FormControlLabel, List } from '@mui/material';
import { IDevice } from 'store/slices/devicesSlice';
import { IUnit } from 'store/slices/unitsSlice';
import AddedDevicesItem from './AddedDevicesItem';
import theme from 'data/theme';
import Tooltip from '@mui/material/Tooltip';

interface IAddedDevicesProps {
  answeringStations: IDevice[];
  callDestinationDevices: string[];
  isSelectAll: boolean;
  callDestinationAppUnit: IUnit[];
  onSelectDevice: (id: string) => void;
  toggleSelectAll: () => void;
  handleRemoveCallDestinationDevice: (id: string) => void;
}

const AddedDevices = ({
  answeringStations,
  callDestinationDevices,
  isSelectAll,
  callDestinationAppUnit,

  onSelectDevice,
  toggleSelectAll,
  handleRemoveCallDestinationDevice
}: IAddedDevicesProps) => {
  const getDeviceList = (arr: IDevice[]) => {
    return arr.map((device: IDevice) => {
      return (
        <AddedDevicesItem
          key={device.publicId}
          device={device}
          isSelected={callDestinationDevices?.includes(device.publicId)}
          handleSelect={onSelectDevice}
          handleRemoveCallDestinationDevice={handleRemoveCallDestinationDevice}
        />
      );
    });
  };

  //TODOs: check the logic again now we have apps group
  if (!answeringStations?.length && !callDestinationAppUnit?.length) {
    return (
      <Box style={styles.addedDeviceWrapper}>
        <Box style={styles.noDevicesWrapper}>No answering stations found</Box>
      </Box>
    );
  }

  return (
    <Box sx={styles.addedDevicesListWrapper}>
      {answeringStations?.length > 0 && (
        <Tooltip title="Only top 20 devices will be selected" arrow>
          <FormControlLabel
            sx={{ paddingLeft: '20px' }}
            control={
              <Checkbox
                size="small"
                checked={callDestinationDevices.length === answeringStations.length && isSelectAll}
                onChange={toggleSelectAll}
              />
            }
            label="Select All"
          />
        </Tooltip>
      )}

      <List sx={styles.newDevicesWrapper}>
        <Box>{getDeviceList(answeringStations)}</Box>
      </List>
    </Box>
  );
};

const styles = {
  addedDeviceWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center'
  },
  noDevicesWrapper: {
    marginTop: '50px'
  },
  addedDevicesListWrapper: {
    width: '300px'
  },
  newDevicesWrapper: {
    marginBottom: '25px',
    overflowY: 'auto'
  },
  multiselectWrapper: {
    display: 'flex',
    paddingLeft: '20px',
    backgroundColor: theme.palette.neutral.light,
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    height: '50px'
  },
  newDevicesTitle: {
    marginLeft: '15px'
  }
};

export default AddedDevices;
