/**
 * Reusable firmware warning component
 */
import { Box, Card, CardHeader, Divider, ListItem, ListItemIcon, List, ListItemText, Button } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export const FirmwareWarning = ({ firmwareWarningLog }: { firmwareWarningLog: any }) => {
  const warningMessage = firmwareWarningLog.description;
  const solution = firmwareWarningLog.solution;
  const solutionLink = firmwareWarningLog.link;
  const steps = firmwareWarningLog.steps;
  return (
    <>
      <Card sx={styles.firmwareWarningWrapper}>
        <CardHeader title={warningMessage} />
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
          {solution}
          <Button href={solutionLink} target="_blank" rel="noopener noreferrer">
            Instructions Link : {solutionLink}
          </Button>
          <List>
            {steps.map((log: string, index: number) => (
              <ListItem key={index}>
                <ListItemIcon sx={{ minWidth: '20px', marginRight: '4px' }}>
                  <RadioButtonUncheckedIcon sx={{ fontSize: '12px' }} />
                </ListItemIcon>
                <ListItemText primary={log} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Card>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  firmwareWarningWrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#d1d1d9',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '20px',
    margin: '20px',
    overflow: 'auto',
    height: '350px'
  }
};
