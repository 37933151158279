import { QuikspecCategory, SystemConstraint } from 'features/QuikSpec/Types/QuikSpecTypes';
/**
 * JP Series QuikSpec Categories and items
 *
 */
export const jpMasterStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Master Stations',
    description:
      'A JP-4MED master station is required in every system and will be included in this spec by default. JP-4MED master stations can receive incoming calls, activate door release, establish room to room communication, and initiate an all call. In addition, each door station wires to the master station, as well as their corresponding door release connections and relays. Sub master stations have similar basic features when compared to a master station, such as 2-way communication and the ability to activate door release, but is limited in other advanced features. When choosing the addition of a sub master station, ensure the station is appropriate for the application.',
    items: [
      { deviceName: 'JP-4MED', defaultQuantity: 1, readOnly: true, maxQuantityAllowedPerItem: 1 },
      { deviceName: 'JP-4HD', maxQuantityAllowedPerItem: 7 },
      { deviceName: 'MCW-S/B', maxQuantityAllowedPerItem: 8 }
    ]
  }
];

export const jpDoorStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Door Stations',
    description: 'Please select up to 4 Door stations',
    items: [
      {
        deviceName: 'JP-DA',
        accessories: [{ accessoryName: 'Surface Mount' }, { accessoryName: '45° Angled Mount', deviceName: 'KMB-45' }],
        countWithAccessories: true,
        maxQuantityAllowedPerItem: 4
      },
      {
        deviceName: 'JP-DV',
        accessories: [{ accessoryName: 'Surface Mount' }, { accessoryName: 'Mullion Mount', deviceName: 'JK-MB' }],
        countWithAccessories: true,
        maxQuantityAllowedPerItem: 4
      },
      {
        deviceName: 'JP-DVF',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-DVF' }],
        countWithAccessories: true,
        maxQuantityAllowedPerItem: 4
      },
      {
        deviceName: 'JP-DVF-PR',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IDVFRA' }],
        countWithAccessories: true,
        maxQuantityAllowedPerItem: 4
      }
    ],
    maxQuantityAllowedPerCategory: 4
  }
];

export const jpAccessories: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Accessories',
    description: 'Optional Accessories.',
    items: [
      { deviceName: 'IER-2', maxQuantityAllowedPerItem: 1 },
      { deviceName: 'RY-ES', maxQuantityAllowedPerItem: 2 }
      // AC-10S will be discontinued
    ]
  }
];

// Hidden products are designed not to be displayed.
export const jpHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      { deviceName: 'JPS-4AED' },
      { deviceName: 'JPS-4AEDV' },
      { deviceName: 'JPS-4AEDF' },
      { deviceName: 'KMB-45' },
      { deviceName: 'JK-MB' },
      { deviceName: 'SBX-DVF' },
      { deviceName: 'SBX-IDVFRA' },
      { deviceName: 'RY-1824L' },
      { deviceName: 'RY-3DL' },
      { deviceName: 'JP-8Z' },
      { deviceName: 'PS-2420UL' }
    ]
  }
];

export const jpSystemConstraints: SystemConstraint[] = [
  { description: 'Master & Sub-Master Stations allowed', max: 8 },
  { description: 'Door Stations allowed', max: 4 }
];
