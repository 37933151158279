import { Box, List } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import AvailableDeviceItem from './AvailableDeviceItem';
import { UUID } from 'crypto';

interface IAvailableDeviceListProps {
  unitType: number;
  selectedDevices: (UUID[] | string)[];
  handleAddToSelectedDevices: (id: string) => void;
  handleRemoveFromSelectedDevices: (id: string) => void;
}

const AvailableDeviceList = ({
  unitType,
  selectedDevices,
  handleAddToSelectedDevices,
  handleRemoveFromSelectedDevices
}: IAvailableDeviceListProps) => {
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);

  // Get the list of device public ids based on the unit type
  const availableDevicePublicIds = useMemo(() => {
    if (unitType === 1) {
      return deviceListByType['GuardStation'].concat(
        deviceListByType['VideoDoorStation'],
        deviceListByType['SubMasterStation'],
        deviceListByType['AudioDoorStation']
      );
    }

    if (unitType === 2) {
      return deviceListByType['EntranceStation'];
    }

    if (unitType === 4) {
      return deviceListByType['TenantStation'].concat(
        deviceListByType['SubMasterStation'],
        deviceListByType['VideoDoorStation'],
        deviceListByType['AudioDoorStation']
      );
    }

    if (unitType === 5) {
      return deviceListByType['MasterStation'].concat(
        deviceListByType['SubMasterStation'],
        deviceListByType['VideoDoorStation'],
        deviceListByType['AudioDoorStation']
      );
    }

    if (unitType === 6) {
      return deviceListByType['EmergencyStation'].concat(
        deviceListByType['VideoDoorStation'],
        deviceListByType['AudioDoorStation']
      );
    }

    return [];
  }, [unitType, deviceListByType]);

  const isSelected = (devicePublicId: string) => {
    if (!selectedDevices) return false;
    return selectedDevices.includes(devicePublicId);
  };

  const getDeviceList = (arr: string[]) => {
    return arr.map((devicePublicId) => {
      return (
        <AvailableDeviceItem
          key={devicePublicId}
          devicePublicId={devicePublicId}
          displaySettings={['deviceModel', 'macAddress']}
          isSelected={isSelected(devicePublicId)}
          handleRemoveFromSelectedDevices={handleRemoveFromSelectedDevices}
          handleAddToSelectedDevices={handleAddToSelectedDevices}
        />
      );
    });
  };

  if (!availableDevicePublicIds) {
    return (
      <Box style={styles.addedDevicesListWrapper}>
        <Box style={styles.noDevicesWrapper}>No available stations</Box>
      </Box>
    );
  }

  return (
    <Box sx={styles.addedDevicesListWrapper}>
      <List>
        <Box>{getDeviceList(availableDevicePublicIds)}</Box>
      </List>
    </Box>
  );
};

const styles = {
  addedDevicesListWrapper: {
    width: '300px',
    height: '100%'
  },
  noDevicesWrapper: {
    marginTop: '50px'
  }
};

export default AvailableDeviceList;
