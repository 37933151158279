export type ACLUserType = 'Aiphone Admin' | 'Dealer' | 'Property Manager';

export interface ACLUser {
  PublicId: string;
  AclUserId: number;
  EmailAddress: string;
  DutyFree: number;
  AuthorityCode: ACLUserType;
  StripeCustomerId?: string;
  CompanyName: string;
  CompanyAddress1: string;
  CompanyAddress2?: string;
  CompanyAddress3?: string;
  CompanyArea?: string;
  CompanyCity: string;
  CompanyState: string;
  CompanyZipCode: string;
  CompanyCountryCode: string;
  CompanyPhoneNumber: string;
}

export interface ACLUserState {
  User: ACLUser | undefined;
  Loading: boolean;
  Error: string | undefined;
}

export const initialACLUserState: ACLUserState = {
  User: undefined,
  Loading: false,
  Error: undefined
};

export interface ACLSite {
  AppLimit: number;
  CountryCode: string;
  PropertyID: number;
  PropertyName: string[];
  Billing: number;
  PropertyAddressDetail: {
    PhoneNumber: string;
    Address1: string;
    City: string;
    State: string;
    ZipCode: string;
  };
  ServiceStatus: number;
  SubscriptionStatus: string | null;
  FormatVersion: number;
  GwStatusList: GWStatusInfo[];
  Payer: ACLUserType;
  Status: number[];
  Contract: {
    ContractPeriod: number;
  };
  Remarks?: string;
  GWList: GWInfo[];
}

export interface GWStatusInfo {
  MACaddress: string;
  LastAccessDate: number;
  ClientNo: string;
}

export interface GWInfo {
  ClientNo: string;
  ClientName: string[];
  IPaddress: string;
  MACaddress: string;
  ICCID: string | null;
  IsSynchronized: string;
  LoginID: string;
  LoginPW: string;
}

export interface SiteDetails {
  AclSiteId: string;
  PublicId: string;
  SiteName: string;
  Billing: number;
  ServiceStatus: number;
  ContractPeriod: number;
  SiteAddress1: string;
  SiteAddress2: string;
  SiteAddress3: string;
  SiteCity: string;
  SiteZipCode: string;
  StateId: string;
  SitePhoneNumber: string;
  SitePhoneExtensionNumber: string;
  Payer: ACLUserType;
  SubscriptionId: string;
  GWDetail: GWDetail[];
}

export interface GWDetail {
  PublicId: string;
  ClientNo: string;
  ClientName: string;
  MACaddress: string;
  ICCID: string;
  Carrier: any; // TODO
  SimActivationDate: any; // TODO
  InUse: boolean;
  SimStatusPublicId: string;
  AssignedPhoneNumber: string;
  CurrentDataUsage: number;
  DataCalculatedMessageId: string;
}

export interface SimBillingSite {
  AclSiteId: number;
  PublicId: string;
  SiteName: string;
  RemoteManagementSitePublicId: string | null;
  Billing: any; // TODO
  ServiceStatus: any; // TODO
  ContractPeriod: any; // TODO
  Address1: string;
  Address2: string;
  Address3: string;
  City: string;
  ZipCode: string;
  State: string;
  CountryCode: string;
  PhoneNumber: string;
  PhoneExtension: string | null;
  Payer: ACLUserType;
  SubscriptionId: string | null;
  SubscriptionCreatedByAclUserPublicId: string | null;
  SubscriptionStatus: string | null;
  StripePriceId: string | null;
  SubscriptionEndDate: Date;
  GwAndSim: GwAndSim[];
  AdditionalData: number;
  SubscriptionData: number;
  CurrentDataUsage: number;
}

export interface GwAndSim {
  PublicId: string;
  InUse: string | boolean | null;
  Carrier: number;
  Iccid: string | null;
  SimStatus: string | null;
  GwClientNo: string | null;
  GwClientName: string | null;
  GwMacAddress: string | null;
  SimActivationDate: string | null;
  AssignedPhoneNumber: string | null;
  DataCalculatedMessageId: string | null;
  Msisdn: string | null;
  CurrentDataUsage: number;
  IsSynchronized: boolean | null;
}

export interface GwAndSimRes {
  assigned_phone_number: string | null;
  carrier: number;
  current_data_usage: number | null;
  data_calculated_message_id: string | null;
  gw_client_name: string | null;
  gw_client_no: string;
  gw_mac_address: string | null;
  iccid: string | null;
  in_use: string | boolean | null;
  is_synchronized: boolean | null;
  public_id: string;
  sim_activation_date: string | null;
  sim_status: string | null;
}

export interface ACLSiteState {
  sites: ACLSite[] | undefined;
  sitesDetails: { [siteId: number]: SimBillingSite };
  selectedSite: ACLSite | null | undefined;
  loading: any;
  error: any;
}

export const initialACLSiteState: ACLSiteState = {
  sites: undefined,
  sitesDetails: {},
  selectedSite: undefined,
  loading: {
    fetchAclSites: 'loading', // 'idle', 'loading', or 'loaded'
    processAndFetchSitesDetails: 'loading'
  },
  error: {
    fetchAclSites: undefined,
    processAndFetchSitesDetails: undefined
  }
};

export interface SubscriptionPlan {
  priceID: string;
  price: number;
  productID: string;
  productName: string;
  currency: string;
  dataAmount: number;
  description: string;
  featured: boolean;
}

export interface SubscriptionPlansState {
  plans: SubscriptionPlan[] | undefined;
  loading: boolean;
  error: string | undefined;
}

export const initialSubscriptionPlansState: SubscriptionPlansState = {
  plans: undefined,
  loading: false,
  error: undefined
};
