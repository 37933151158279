import { useEffect } from 'react';
import useReduxUsers from './useReduxUsers';
import useFetchBranches from './useFetchBranches';
import useReduxBranches from './useReduxBranches';
import { UserInfo } from '../../../shared/utils/UserUtils';

/**
 * Initially, we get user and organization information on the home screen.
 * We retrieve this data from our database and save it to Redux.
 *
 * If the user reloads the website on a different page (outside of home), our user and organization info will be lost.
 * This component helps us to refetch this information.
 * */
export const useRefetchData = () => {
  const { getBranchById } = useFetchBranches();

  const { users, currentUser, userLoading } = useReduxUsers();
  const { branches, currentBranch, branchesLoading } = useReduxBranches();
  /**
   * We assume that the user info is always in local storage,
   * as we save this data there after authentication, along with the tokens.
   *
   * If the user info isn't in local storage, we should redirect the user to the login screen.
   * */
  const savedUserInfo = new UserInfo();
  const userSub = savedUserInfo.publicId;

  useEffect(() => {
    (async () => {
      if (!userSub) {
        // The following navigate() causes the duplicated login issue.
        //navigate('/login');
        return;
      }
      /**
       * We retrieve user and organization information together from the same place.
       * We assume that if we have user data, we also have organization data.
       * If the user data is null, we should also consider the organization data as null.
       * */
      if (currentBranch === null) {
        await getBranchById(branches[0]);
      }
    })();
  }, [currentUser, currentBranch]);

  return {
    currentUser,
    users,
    userLoading,
    branches,
    currentBranch,
    branchesLoading
  };
};

export default useRefetchData;
