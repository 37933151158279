import { Alert, AlertTitle, Box, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import { accessories, doorStations, subStations, masterStations, lefHidden } from '../../../Types/LEFTypes';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import { StandardSystems } from 'features/QuikSpec/Types/QuikSpecTypes';
import { updateFormValuesLEF } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesLEF';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';
import { getString } from 'shared/utils/LocalizationUtils';

const atLeastOneQuantityGreaterThanZero = (values: any) => {
  const items = Object.values(values);
  const masterStationsNames = masterStations.flatMap((station) => station.items.map((item) => item.deviceName));
  const relevantItems = items.filter((item: any) => masterStationsNames.includes(item.itemName));
  return relevantItems.some((item: any) => item.quantity > 0);
};

const systemSizeCheck = (values: any) => {
  const items = Object.values(values);
  const accessoriesNames = accessories[0].items.map((item) => item.deviceName);
  const relevantItems = items.filter((item: any) => !accessoriesNames.includes(item.itemName));
  const totalQuantity = relevantItems.reduce((total: number, item: any) => total + item.quantity, 0);
  return totalQuantity <= 11;
};

const validationSchema = Yup.object().shape({
  quikspecName: Yup.string().required('Quikspec Name is required'),
  marketType: Yup.string().required('Market Type is required'),
  items: Yup.array()
    .of(
      Yup.object().shape({
        itemName: Yup.string().required('Item Name is required'),
        quantity: Yup.number().min(0, 'Quantity must be greater than or equal to 0').required('Quantity is required')
      })
    )
    .test('atLeastOneQuantity', 'At least one Master Station is required', atLeastOneQuantityGreaterThanZero)
    .test(
      'SystemSizeCheck',
      'The LEF system supports 11 total stations. Please reassess you requirements or look into using the IX series.',
      systemSizeCheck
    )
});

const QuikspecLEF = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = StandardSystems.findIndex((system) => system.siteName === siteName);
  const systemName = StandardSystems[siteIndex].seriesName;
  const systemforC2 = StandardSystems[siteIndex].systemforC2;

  const categories = [...masterStations, ...subStations, ...doorStations, ...accessories, ...lefHidden];
  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);
  const alertError = getString('Error');

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      //filter out items with quantity set to zero in order to submit quikspec
      values = updateFormValuesLEF(values);

      //determine if form is in edit mode
      const editing = stateFormValues != undefined;

      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
      }
    } catch (error) {
      console.error(JSON.stringify(error));
      actions.setSubmitting(false);
      actions.setErrors({ submit: error.message });
    }
  };

  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              {/* Render QuikspecCategoryTabs for each category */}
              <QuikspecCategoryTabs categories={masterStations} />
              <QuikspecCategoryTabs categories={subStations} />
              <QuikspecCategoryTabs categories={doorStations} />
              <QuikspecCategoryTabs categories={accessories} />
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>{alertError}</AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton editing={state.formValues != undefined} errors={errors} submitting={isSubmitting} />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecLEF;
