import React from 'react';
import {
  DataGrid,
  GridCellModesModel,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid';
import {
  TimeSelectorType,
  handleRowClick,
  handleCellModesModelChange,
  handleCellClick,
  rowData,
  RelayNumber,
  useDataGridHandlers
} from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/common';
import { RelayDelayColHeader } from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/headers/RelayDelayColHeader';
import StationNameNumberCell from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/cells/StationNameNumberCell';
import CheckedCheckbox from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/cells/CheckedCheckbox';
import { useTranslation } from 'react-i18next';
import CustomGridToolbarWithToggle from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/headers/CustomGridToolbarWithToggle';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { Check } from '@mui/icons-material';

const ArrivalFloorDataGrid: React.FC = () => {
  const dataGridRef = React.useRef<HTMLDivElement>(null);
  const apiRef = useGridApiRef();
  const [timeSelectorType, setTimeSelectorType] = React.useState<TimeSelectorType>('s');
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);
  const entranceDeviceList = deviceListByType['EntranceStation'].map((publicId) => {
    return deviceList[publicId];
  });

  const generateRowData = () => {
    const rowData = entranceDeviceList.map((device) => {
      return {
        id: device.publicId,
        stationName: device.basicInfo.stationName,
        stationNumber: device.basicInfo.stationNumber,
        //TODO: This is a hardcoded value, we need to get the number of relays from the backend
        relay_1: { enabled: false, delay: 0 },
        relay_2: { enabled: false, delay: 0 },
        relay_3: { enabled: false, delay: 0 },
        relay_4: { enabled: false, delay: 0 },
        relay_5: { enabled: false, delay: 0 },
        relay_6: { enabled: false, delay: 0 },
        relay_7: { enabled: false, delay: 0 },
        relay_8: { enabled: false, delay: 0 },
        relay_9: { enabled: false, delay: 0 },
        relay_10: { enabled: false, delay: 0 },
        relay_11: { enabled: false, delay: 0 },
        relay_12: { enabled: false, delay: 0 },
        relay_13: { enabled: false, delay: 0 },
        relay_14: { enabled: false, delay: 0 },
        relay_15: { enabled: false, delay: 0 },
        relay_16: { enabled: false, delay: 0 },
        relay_17: { enabled: false, delay: 0 },
        relay_18: { enabled: false, delay: 0 },
        relay_19: { enabled: false, delay: 0 },
        relay_20: { enabled: false, delay: 0 }
      };
    });

    return rowData;
  };

  const { t } = useTranslation();

  const { submitHandler, handleUpdateRelayDelay, handleCheckCell } = useDataGridHandlers(apiRef);

  const columns: GridColDef[] = React.useMemo(() => {
    const firstColumn: GridColDef = {
      field: 'Stations',
      editable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      pinnable: true,
      resizable: true,
      groupable: false,
      sortable: false,
      disableReorder: true,
      hideSortIcons: true,
      filterable: false,
      headerClassName: 'font-bold pl_16px',
      headerName: t('Stations'),
      renderCell: (params: GridRenderCellParams) => {
        // Extracting stationName and stationNumber from params.row
        const stationName = params.row.stationName;
        const stationNumber = params.row.stationNumber;

        return <StationNameNumberCell title={stationName} subtitle={stationNumber.toString()} />;
      },
      renderEditCell: (params: GridRenderCellParams) => {
        // Extracting stationName and stationNumber from params.row
        const stationName = params.row.stationName;
        const stationNumber = params.row.stationNumber;

        return <StationNameNumberCell title={stationName} subtitle={stationNumber.toString()} />;
      },
      cellClassName: 'unselectable m-0 p-0'
    };

    const relayCols: GridColDef[] = Array.from({ length: 20 }, (_, index) => {
      const relayNum = index + 1;
      return {
        field: `relay_${relayNum}`,
        sortable: false,
        disableReorder: true,
        hideSortIcons: true,
        filterable: false,
        pinnable: true,
        groupable: false,
        disableColumnMenu: true,
        type: 'boolean',
        editable: false,
        headerClassName: 'm-0 p-0',
        hideable: false,
        cellClassName: 'm-0 p-0',
        renderHeader: (params: GridColumnHeaderParams) => {
          const r = rowData[0];
          const relay = `relay_${relayNum}` as RelayNumber;
          // Get the delay

          const delay = r[relay]?.delay || 0;
          return (
            <RelayDelayColHeader
              params={params}
              timeType={timeSelectorType}
              headerLabel={`${t('Relay')} ${relayNum}`}
              value={rowData.length > 0 ? delay : 0}
              onValueChange={(newValue: number) => {
                handleUpdateRelayDelay(params, newValue);
              }}
              suffix={timeSelectorType.toString()}
            />
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CheckedCheckbox
              key={params.id}
              initialChecked={params.row[params.field]?.enabled || false}
              onToggle={(newChecked: boolean) => {
                handleCheckCell(params, newChecked);
              }}
            />
          );
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return (
            <CheckedCheckbox
              key={params.id}
              initialChecked={params.row[params.field]?.enabled || false}
              onToggle={(newChecked: boolean) => {
                handleCheckCell(params, newChecked);
              }}
            />
          );
        }
      };
    });

    return [firstColumn, ...relayCols];
  }, [handleCheckCell, handleUpdateRelayDelay, t, timeSelectorType]);

  return (
    <DataGrid
      sx={{
        overflowX: 'scroll'
      }}
      components={{
        Toolbar: () => (
          <CustomGridToolbarWithToggle
            description={t('Select_output_Trigger_IXG')}
            valueToggle={
              // We need to set the opposite value of the current timeSelectorType
              // because the toggle will change the value after the state is updated
              [
                { value: 's', label: t('Seconds_Text') },
                { value: 'ms', label: t('Milliseconds_Text') }
              ]
            }
            buttonLeftIcon={<Check />}
            label={t('Time_Units')}
            buttonText={t('Button_SaveChanges')}
            submitHandler={submitHandler}
            selectedValue={timeSelectorType === 's' ? t('Seconds_Text') : t('Milliseconds_Text')}
            handleSelectChange={(event) => {
              setTimeSelectorType(event.target.value as TimeSelectorType);
            }}
          />
        )
      }}
      cellModesModel={cellModesModel}
      onRowClick={(params, event) => handleRowClick(params, event as unknown as MuiEvent<MouseEvent>)}
      onCellModesModelChange={(cellModesModel) => handleCellModesModelChange(cellModesModel, setCellModesModel)}
      showCellVerticalBorder={true}
      showColumnVerticalBorder={true}
      columns={columns}
      autoHeight={true}
      rows={generateRowData()}
      ref={dataGridRef}
      apiRef={apiRef}
      onCellClick={(params, event) => handleCellClick(params, event as React.MouseEvent, setCellModesModel)}
    />
  );
};

export default ArrivalFloorDataGrid;
