import React, { useState } from 'react';
import { IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';
import OutputFunctionSetting from './settings/OutputFunctionSetting';
import OutputTimeSetting from './settings/OutputTimeSetting';
import DoorReleaseCodeSetting from './settings/DoorReleaseCodeSetting';
import DoorReleaseSoundSetting from './settings/DoorReleaseSoundSetting';
import OutputNameSetting from './settings/OutputNameSetting';
import StatusOutputSetting from './settings/StatusOutputSetting';
import ContactChangeStationEventSetting from './settings/ContactChangeStationEventSetting';
import { useSelector } from 'react-redux';
import { getDeviceTypeModelMapping } from 'store/slices/devicesSlice';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { useTranslation } from 'react-i18next';

interface IRelayOutputSettingsProps {
  selectedRelay: IContactOutput;
  setNeedsSave: (needsSave: boolean) => void;
  newRelaySettings: Array<IContactOutput | IDeviceContactInput>;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
}

const RelayOutputSettings = ({
  selectedRelay,
  setNeedsSave,
  newRelaySettings,
  setNewRelaySettings
}: IRelayOutputSettingsProps) => {
  const [outputFunction, setOutputFunction] = useState<number>(selectedRelay.outputFunction);
  const selectedDevice = useSelector(getSelectedDevice);
  const { t } = useTranslation(); // Use the useTranslation hook for translations

  const isSelectedDeviceIOAdaptorType = () => {
    return (
      getDeviceTypeModelMapping(selectedDevice.basicInfo.deviceType, selectedDevice.basicInfo.deviceModel) ===
      'IOAdaptor'
    );
  };

  if (!selectedRelay) {
    return <div>{t('No_Relay_Selected')}</div>;
  }

  return (
    <>
      {!isSelectedDeviceIOAdaptorType() ? (
        <>
          <OutputNameSetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            newRelaySettings={newRelaySettings}
            setNewRelaySettings={setNewRelaySettings}
          />
          <OutputFunctionSetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            newRelaySettings={newRelaySettings}
            setNewRelaySettings={setNewRelaySettings}
            outputFunction={outputFunction}
            setOutputFunction={setOutputFunction}
          />
          <OutputTimeSetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            newRelaySettings={newRelaySettings}
            setNewRelaySettings={setNewRelaySettings}
            outputFunction={outputFunction}
          />
          <StatusOutputSetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            newRelaySettings={newRelaySettings}
            setNewRelaySettings={setNewRelaySettings}
            outputFunction={outputFunction}
          />
          <DoorReleaseCodeSetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            newRelaySettings={newRelaySettings}
            setNewRelaySettings={setNewRelaySettings}
            outputFunction={outputFunction}
          />
          <DoorReleaseSoundSetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            setNewRelaySettings={setNewRelaySettings}
            outputFunction={outputFunction}
          />
        </>
      ) : (
        <ContactChangeStationEventSetting
          selectedRelay={selectedRelay}
          setNeedsSave={setNeedsSave}
          setNewRelaySettings={setNewRelaySettings}
        />
      )}
    </>
  );
};

export default RelayOutputSettings;
