import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { SupportedLocales, IStringDictionary } from 'shared/utils/LocalizationUtils';

interface LocalizationState {
  SelectedLocaleStrings: IStringDictionary;
}

const initialState: LocalizationState = {
  SelectedLocaleStrings: SupportedLocales.get('US')?.localizationStrings as IStringDictionary
};

const localizationSlice = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    updateLocale: (state, action) => {
      if (SupportedLocales.has(action.payload)) {
        state.SelectedLocaleStrings = SupportedLocales.get(action.payload)?.localizationStrings as IStringDictionary;
      } else {
        throw new Error(`Locale ${action.payload} not supported`);
      }
    }
  }
});

export const getSelectedLocaleStrings = (state: RootState) => {
  return state.locale.SelectedLocaleStrings;
};

export const { updateLocale } = localizationSlice.actions;
export const localizationReducer = localizationSlice.reducer;
