import React from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';

interface IOptionType {
  value: string;
  label: string;
}

interface ICallGroupProps {
  setNeedSave: (value: boolean) => void;
  fieldName: string;
  value: string;
  callGroupList: IOptionType[];
  onChange: (fieldName: string, value: string) => void;
}

const CallGroup = ({ setNeedSave, fieldName, value, callGroupList, onChange }: ICallGroupProps) => {
  const handleCallGroupChange = (event: React.SyntheticEvent<Element, Event>, option: IOptionType | null) => {
    if (option !== null) {
      setNeedSave(true);
      onChange(fieldName, option.value);
    }
  };

  return (
    <Box>
      <Autocomplete
        value={callGroupList.find((option) => option.value === value) || dropdownOptions[0]}
        defaultValue={callGroupList[0]}
        options={callGroupList}
        getOptionLabel={(option) => option.label}
        onChange={handleCallGroupChange}
        renderInput={(params) => <TextField {...params} label="Call Group" />}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
    </Box>
  );
};

export default CallGroup;
