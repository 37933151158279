import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import AddTenantForm from './AddTenantForm';
import { useCreateTenantMutation, useLazyGetTenantListWithSiteIdQuery } from 'services/aiphoneCloud';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { NANP_PHONE_REGEX } from 'shared/constants/regex';
import { convertPhoneNumberToE164Format } from 'shared/utils/helperFunctions';

interface IAddTenantDialogProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  unitPublicId?: string;
}

const AddTenantDialog = ({ isOpen, setIsOpen, unitPublicId }: IAddTenantDialogProps) => {
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = React.useState(t('Unit_Added_Successfully'));
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = React.useState(false);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = React.useState(false);
  const [createTenant] = useCreateTenantMutation();
  const [fetchTenants] = useLazyGetTenantListWithSiteIdQuery();
  const siteId = useParams().id;

  const tenantList = useSelector((state: RootState) => state.tenants.TenantList);

  const initialValues = {
    buildingNumber: '',
    unitNumber: unitPublicId ?? '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '+',
    primaryTenant: false
  };

  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(t('Invalid_Email')),
        phoneNumber: yup.string().matches(NANP_PHONE_REGEX, t('NANP_Description'))
      }),
    [t]
  );

  const handleAddTenant = React.useCallback(
    async (values: any) => {
      const isOnlyTenant =
        Object.values(tenantList).filter((tenant) => tenant.unitPublicId === values.unitNumber).length === 0;

      const params = {
        tenantData: {
          firstName: values.firstName,
          firstNameOtherLanguage: null,
          lastName: values.lastName,
          lastNameOtherLanguage: null,
          email: values.email,
          phone: convertPhoneNumberToE164Format(values.phoneNumber),
          isPrimaryTenant: isOnlyTenant ? true : values.primaryTenant,
          languageId: 2,
          unitPublicId: values.unitNumber
        }
      };

      await createTenant(params)
        .unwrap()
        .then(() => {
          setSuccessMessage(t('Tenant_Added_Successfully'));
          setIsSuccessAlertOpen(true);
          setIsOpen(false);
          fetchTenants({ sitePublicId: siteId, qty: 500, page: 0 });
        })
        .catch((error) => {
          setErrorMessage(error.data.message);
          setIsErrorAlertOpen(true);
        });
    },
    [createTenant, fetchTenants, setIsOpen, siteId, tenantList, t]
  );

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>{t('Add_Tenant')}</DialogTitle>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAddTenant}>
          {({ ...props }) => <AddTenantForm formikProps={props} setIsOpen={setIsOpen} unitPublicId={unitPublicId} />}
        </Formik>
      </Dialog>
      <SnackbarAlert
        type="success"
        time={3000}
        text={successMessage}
        isOpen={isSuccessAlertOpen}
        onClose={() => setIsSuccessAlertOpen(false)}
      />
      <SnackbarAlert
        type="error"
        time={3000}
        text={errorMessage}
        isOpen={isErrorAlertOpen}
        onClose={() => setIsErrorAlertOpen(false)}
      />
    </>
  );
};

export default AddTenantDialog;
