import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { DataGrid, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import DownloadIcon from '@mui/icons-material/Download';
import AddAppToUnitDialog from '../../../components/AddAppToUnit/AddAppToUnitDialog';
import { useState } from 'react';
import AppAddressBookUnitDialog from '../dialogs/AddAppAddressBookUnitDialog/AppAddressBookUnitDialog';
import { QRCodeSVG } from 'qrcode.react';
import AppRegistrationDoc from 'features/RemoteManagement/Components/AppRegistrationDoc';
import { PDFDownloadLink } from '@react-pdf/renderer';

const AppsDatagrid = () => {
  const [isAddAppDialogOpen, setIsAddAppDialogOpen] = useState<boolean>(false);
  const [isAppAddressBookDialogOpen, setIsAppAddressBookDialogOpen] = useState<boolean>(false);
  const selectedUnitPublicId = useParams().unitid || '';
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const selectedUnit = unitList[selectedUnitPublicId];
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const selectedBuilding = buildingList[selectedUnit.buildingPublicId];
  const appGroups = useSelector((state: RootState) => state.apps.AppGroup);
  const appList = useSelector((state: RootState) => state.apps.AppList);
  const appsInUnit = appGroups[selectedUnitPublicId] || [];

  const [dialogQrCode, setDialogQrCode] = useState('');
  const [isQrDialogOpen, setIsQrDialogOpen] = useState(false);

  const handleAddApp = () => {
    setIsAddAppDialogOpen(true);
  };

  const handleAppAddressBook = () => {
    setIsAppAddressBookDialogOpen(true);
  };

  const generateRows = () => {
    return appsInUnit.map((appId) => {
      const app = appList[appId];
      return {
        id: appId,
        name: app.stationName,
        //registered: 'No', // TODO: This is hardcoded, need to use ACL IXG 'GetOperationStatus' to get registration status
        qrCode: app.qrCode
      };
    });
  };

  const columns = [
    { field: 'name', headerName: 'Name' },
    //{ field: 'registered', headerName: 'Registered' }, // TODO: Uncomment when registration status is available
    {
      field: 'qrCode',
      headerName: 'QR Code',
      width: 70,
      renderCell: (params: GridRenderCellParams) =>
        params.value === '' ? (
          <></>
        ) : (
          <QRCodeSVG onClick={() => handleOpenDialog(params.value)} value={params.value} size={40} />
        )
    }
  ];

  const rows = generateRows();

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddApp}>
          Add Apps
        </Button>
        <Button color="primary" startIcon={<SettingsIcon />} onClick={handleAppAddressBook}>
          Configure Address Book
        </Button>
        {appsInUnit.length > 0 && (
          <PDFDownloadLink
            document={
              <AppRegistrationDoc
                buildingNumber={selectedBuilding.buildingNumber}
                unitNumber={selectedUnit.unitNumber}
                qrCodeData={appsInUnit.map((appId) => appList[appId].qrCode).filter((code) => code !== '')}
              />
            }
          >
            <Button color="primary" startIcon={<DownloadIcon />}>
              Download App Registration Doc
            </Button>
          </PDFDownloadLink>
        )}
      </GridToolbarContainer>
    );
  };

  const handleOpenDialog = (qrCode: string) => {
    setDialogQrCode(qrCode);
    setIsQrDialogOpen(true);
  };

  const QRDialog = () => (
    <Dialog open={isQrDialogOpen} onClose={() => setIsQrDialogOpen(false)}>
      <DialogTitle style={styles.qrCodeTitle}>App QR Code</DialogTitle>
      <DialogContent>
        <QRCodeSVG value={dialogQrCode} size={300} level="H" />
      </DialogContent>
      <Typography variant="caption" textAlign="center">
        Scan with Aiphone IXG App to register to this unit.
      </Typography>
    </Dialog>
  );

  return (
    <>
      <Box sx={styles.appsDatagridWrapper}>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter={true}
          slots={{
            toolbar: Toolbar,
            noRowsOverlay: () => <Box sx={styles.noRows}>No apps assigned to this unit</Box>
          }}
        />
      </Box>
      <AddAppToUnitDialog
        selectedUnit={selectedUnitPublicId}
        open={isAddAppDialogOpen}
        setIsOpen={setIsAddAppDialogOpen}
      />
      <AppAddressBookUnitDialog
        open={isAppAddressBookDialogOpen}
        onClose={() => setIsAppAddressBookDialogOpen(false)}
      />
      <QRDialog />
    </>
  );
};

const styles = {
  appsDatagridWrapper: {
    height: '100%'
  },
  noRows: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  qrCodeTitle: {
    display: 'flex',
    justifyContent: 'center'
  }
};

export default AppsDatagrid;
