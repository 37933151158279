import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Field, Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useDeleteCompanyBatchMutation, useDeleteCompanyMutation } from 'services/aiphoneCloud';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectionModel: GridRowSelectionModel;
  setIsError: (isError: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
}

const DeleteCompanyDialog = ({
  isOpen,
  setIsOpen,
  selectionModel,
  setIsError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage
}: Props) => {
  const [canDelete, setCanDelete] = useState(false);
  const [deleteCompanyBatch] = useDeleteCompanyBatchMutation();
  const [deleteCompany] = useDeleteCompanyMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation(); // Initialize translation hook

  const handleCloseImportClick = () => {
    setIsOpen(false);
    setCanDelete(false);
  };

  const verifyDeletion = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'delete') {
      setCanDelete(true);
    } else {
      setCanDelete(false);
    }
  };

  const initialForm = () => ({
    delete: ''
  });

  const onSubmit = async () => {
    console.log(selectionModel);

    setIsDeleting(true);
    if (selectionModel.length === 1) {
      deleteCompany(selectionModel[0].toString())
        .unwrap()
        .then((response) => {
          if (response.error) {
            setIsError(true);
            setIsDeleting(false);
            setErrorMessage(t('Error_Deleting_Company')); // Localization
          } else {
            setIsOpen(false);
            setIsSuccess(true);
            setIsDeleting(false);
            setCanDelete(false);
            setSuccessMessage(t('Company_Delete_Success')); // Localization
            return;
          }
        })
        .catch((error) => {
          setErrorMessage(JSON.parse(error.data)?.errorDetails || t('Error_Unexpected')); // Localization
          setIsError(true);
          setIsDeleting(false);
        });
    } else {
      deleteCompanyBatch({ companyPublicIds: selectionModel })
        .unwrap()
        .then((response) => {
          if (response.error) {
            setIsError(true);
            setErrorMessage(t('Error_Deleting_Companies')); // Localization
          } else {
            setIsOpen(false);
            setIsSuccess(true);
            setCanDelete(false);
            setSuccessMessage(t('companiesDeletedSuccessfully')); // Localization
          }
        })
        .catch((error) => {
          setErrorMessage(JSON.parse(error.data)?.errorDetails || t('Error_Unexpected')); // Localization
          setIsError(true);
        });
    }
  };

  return (
    <Dialog onClose={handleCloseImportClick} open={isOpen} disableEscapeKeyDown={true} maxWidth="sm" fullWidth={true}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{t('Button_DeleteCompany')}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={handleCloseImportClick}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box>
        <Formik initialValues={initialForm()} onSubmit={onSubmit} validateOnBlur={true}>
          {(props) => {
            const { handleChange, errors, touched } = props;
            return (
              <Form>
                <DialogContent>
                  <Box>
                    <Typography variant="body1" color="text.primary">
                      {t('Message_DeleteCompany')}
                    </Typography>
                  </Box>
                  <Box sx={styles.my2}>
                    <Field
                      name="delete"
                      as={TextField}
                      sx={styles.inputField}
                      label={t('Enter_Delete_To_Confirm')}
                      size="small"
                      helperText={touched.delete && errors.delete ? errors.delete : ''}
                      error={touched.delete && !!errors.delete}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        verifyDeletion(e);
                      }}
                    />
                    <Typography variant="caption" color="GrayText">
                      <strong>{t('Message_Note')}</strong> {t('Message_CantUndo')}
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={handleCloseImportClick}>
                    {t('Cancel')}
                  </Button>
                  <LoadingButton variant="contained" type="submit" loading={isDeleting} disabled={!canDelete}>
                    {t('Delete_Company')}
                  </LoadingButton>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Dialog>
  );
};

const styles = {
  my2: {
    marginY: 2
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  }
};

export default DeleteCompanyDialog;
