/**
 * @file DialogContext.tsx
 * @description This file defines the DialogContext and DialogProvider components.
 *
 * The DialogContext is a custom global hook that provides a dialog context to the application.
 *
 * The DialogProvider is a component that wraps the application and provides the dialog context to all child components.
 *
 * The `useDialog` hook is used to access the dialog context in any component.
 *
 * It can be called like this:
 * ```tsx
 * // First import the useDialog hook
 * import { useDialog } from 'features/RemoteManagement/Components/Dialog/DialogContext';
 * // Component
 * const MyComponent = () => {
 *  const { openDialog } = useDialog();
 *
 *  return (
 *  <div>
 *    <button onClick={() => openDialog('Dialog Title', 'Dialog Message')}>
 *      Open Dialog
 *      </button>
 *     </button>
 *   </div>
 *   );
 * };
 * ```
 */
import React, { createContext, useState, useContext, ReactNode, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// Import frontshine.css
import 'styles/frontshine.css';

interface DialogContextProps {
  title: string;
  message?: string;
  isOpen: boolean;
  openDialog: (title: string, message?: string) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export interface DialogProviderProps {
  children: ReactNode;
}

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  /**
   * @function openDialog
   * @description Opens the dialog with the specified title and message.
   *
   * @param {string} dialogTitle - The title to display in the dialog.
   * @param {string} [dialogMessage] - The optional message to display in the dialog.
   */
  const openDialog = (dialogTitle: string, dialogMessage?: string) => {
    setTitle(dialogTitle);
    setMessage(dialogMessage || '');
    setIsOpen(true);
  };

  /**
   * @function closeDialog
   * @description Manually closes the dialog.
   */
  const closeDialog = () => {
    setIsOpen(false);
  };

  const value = useMemo(
    () => ({
      title,
      message,
      isOpen,
      openDialog,
      closeDialog
    }),
    [title, message, isOpen]
  );

  return (
    <DialogContext.Provider value={value}>
      {children}
      <Dialog open={isOpen} onClose={closeDialog} className={'w-full'} maxWidth={'md'}>
        <div className={'w-full'} style={{ minWidth: 400 }}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{message}</DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Close</Button>
          </DialogActions>
        </div>
      </Dialog>
    </DialogContext.Provider>
  );
};

/**
 * @function useDialog
 * @description A custom global hook that returns the dialog context.
 */
export const useDialog = (): DialogContextProps => {
  const context = useContext(DialogContext);
  // Wait for the context to load, or else this will not work.
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
