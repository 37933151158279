import { QuikspecCategory, SystemConstraint } from 'features/QuikSpec/Types/QuikSpecTypes';

/**
 * NHX Series QuikSpec Categories and items
 *
 */
export const nhxMasterStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Master Station',
    description: 'Expandable to 80 stations',
    items: [{ deviceName: 'NHX-50M', defaultQuantity: 1, readOnly: true, maxQuantityAllowedPerItem: 1 }],
    maxQuantityAllowedPerCategory: 1
  }
];

export const nhxPatientStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Patient Stations',
    description: 'How many of each of the following style of patient stations are required?',
    items: [
      { deviceName: 'NH-1SA/A', maxQuantityAllowedPerItem: 80 },
      { deviceName: 'NH-2SA/A', maxQuantityAllowedPerItem: 80 }
    ],
    maxQuantityAllowedPerCategory: 80
  }
];

export const nhxAccessories: QuikspecCategory[] = [
  {
    id: 1,
    title: 'System Accessories',
    description: 'How many Common Area Call Stations and Duty Stations are required?',
    items: [
      { deviceName: 'NHR-3TS', maxQuantityAllowedPerItem: 80 },
      { deviceName: 'NHR-SP', maxQuantityAllowedPerItem: 1 }
    ]
  }
];

// Hidden products are designed not to be displayed.
export const nhxHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      { deviceName: 'NHX-30G' },
      { deviceName: 'NHX-80X' },
      { deviceName: 'NHR-30K' },
      { deviceName: 'NHR-8C' },
      { deviceName: 'NHR-7A' },
      { deviceName: 'NHR-4A/A' },
      { deviceName: 'PS-2420UL' },
      { deviceName: 'EXCLUDE_BATHROOM_PULL_CORD' },
      { deviceName: 'EXCLUDE_CORRIDOR_LAMP' }
    ]
  }
];

export const nhxSystemConstraints: SystemConstraint[] = [
  { description: 'Patient Stations allowed', max: 80 },
  { description: 'Master Stations allowed', max: 1 },
  { description: 'Duty Stations allowed', max: 1 }
];
