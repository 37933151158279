/* IEEE 802.1X tab stub */
export const IEEE8021XLabel = () => {
  return <span>IEEE 802.1X</span>;
};

const IEEE8021X = () => {
  return <span>We will update Aiphone Cloud for IEEE 802.1X soon!</span>;
};

export default IEEE8021X;
