/* Display Mode tab stub */
import { Tooltip } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';

export const DisplayModeLabel = () => {
  const displayMode = getString('Display_Mode');
  return (
    <Tooltip title="Display Mode, Misc. setting for Sensor">
      <span>{displayMode}</span>
    </Tooltip>
  );
};

const DisplayMode = () => {
  const displayMode = getString('Display_Mode');
  return <span>{displayMode}</span>;
};

export default DisplayMode;
