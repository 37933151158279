/**
 * store the IXG-GW command that will sent to IoT device through Lambda
 * Those commands are pre-configure in the IoT device that written in Firmware
 * reference: https://github.com/AiphoneCorporation/ixgw_gw_remote_management/blob/main/doc/99_MQTT_COMMUNICATION_EXAMPLES.md
 */
export const gwCommand: { [key: string]: string } = {
  REGISTER: '00FF',
  REGISTER_RESP: '01FF',
  UNREGISTER: '00FE',
  UNREGISTER_RESP: '01FE',
  STATION_SEARCH: '0001',
  STATION_SEARCH_RESP: '0101',
  ASSOCIATE: '0016',
  ASSOCIATE_RESP: '0116',
  SYNC: '000A',
  SYNC_RESP: '010A',
  FIRMWARE_UPDATE: '0008',
  FIRMWARE_UPDATE_RESP: '0108',
  INITIALIZE: '000F',
  INITIALIZE_RESP: '010F',
  SET_TIME: '0010',
  SET_TIME_RESP: '0110',
  DEVICE_CHECK: '0018',
  DEVICE_CHECK_RESP: '0118',
  POWER_CYCLE: '00FD',
  POWER_CYCLE_RESP: '01FD',
  POWER_OFF: '00FC',
  POWER_OFF_RESP: '01FC',
  PING: '00FB',
  PING_RESP: '01FB',
  TEST: '00FA',
  TEST_RESP: '01FA',
  TEST_MINUTE: '00F9',
  TEST_MINUTE_RESP: '01F9',
  TEST_MEMORY: '00F8',
  TEST_MEMORY_RESP: '01F8',
  INVALID_CMD: '01XX',
  SOUND_UPLOAD: '0019',
  SOUND_UPLOAD_RESP: '0119'
};
