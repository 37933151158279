import { Box, Button } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridRowId, GridRowParams, GridToolbarContainer } from '@mui/x-data-grid';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import EditTenantDialog from 'features/RemoteManagement/SiteDashboard/TenantManagement/Tenants/components/EditTenantDialog';
import { useDeleteTenantMutation, useLazyGetTenantListWithSiteIdQuery } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { getCurrentUser } from 'store/slices/usersSlice';
import CONFIG from 'config';
import { useTranslation } from 'react-i18next';

interface ITenantsDatagridProps {
  setIsAddTenantDialogOpen: (isOpen: boolean) => void;
}

const TenantsDatagrid = ({ setIsAddTenantDialogOpen }: ITenantsDatagridProps) => {
  const [isEditTenantDialogOpen, setIsEditTenantDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState<any[]>([]);
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const [selectedTenantId, setSelectedTenantId] = React.useState<string>('');
  const tenantsList = useSelector((state: RootState) => state.tenants.TenantList);
  const unitPublicId = useParams().unitid;
  const siteId = useParams().id;
  const [fetchTenants] = useLazyGetTenantListWithSiteIdQuery();
  const [deleteTenant] = useDeleteTenantMutation();
  const currentUser = useSelector(getCurrentUser);
  const propertyId = useSelector((state: RootState) => state.site?.siteInfo?.awsPropertyId);
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const appList = useSelector((state: RootState) => state.apps.AppList);
  const { t } = useTranslation();

  const aclToken = localStorage.getItem('acltoken');

  const generateRows = () => {
    return Object.entries(tenantsList)
      .map(([key, tenant]) => {
        if (tenant.unitPublicId !== unitPublicId) return null;

        return {
          id: key,
          firstName: tenant.firstName,
          lastName: tenant.lastName,
          email: tenant.email,
          phone: tenant.phoneNumber,
          isPrimaryTenant: tenant.isPrimaryTenant ? 'Yes' : 'No'
        };
      })
      .filter((row) => row !== null);
  };

  const handleAddTenant = () => {
    setIsAddTenantDialogOpen(true);
  };

  const handleMoveOutTenant = async () => {
    if (unitPublicId) {
      const selectedUnit = unitList[unitPublicId];

      await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'leaving',
          userId: currentUser?.publicId,
          propertyId,
          token: aclToken,
          roomId: selectedUnit.unitSequentialNumber,
          unitId: selectedUnit.publicId
        })
      });

      const unitIds = Array.from(Object.entries(appList), ([_, unit]) => unit.unitPublicId);
      const appIds = Array.from(Object.entries(appList), ([id, _]) => id);

      await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'setqrcodes',
          userId: currentUser?.publicId,
          unitIds,
          appIds
        })
      });
    }
  };

  const handleEditTenant = useCallback(
    (id: GridRowId) => async () => {
      await setSelectedTenantId(id as string);
      setIsEditTenantDialogOpen(true);
    },
    []
  );

  const handleDeleteTenant = useCallback(
    (id: GridRowId) => async () => {
      setLoading(true);
      try {
        await deleteTenant(id as string);
        setSuccess('Tenant deleted successfully');
        fetchTenants({ sitePublicId: siteId, qty: 500, page: 0 });
      } catch (err) {
        setError('Failed to delete tenant');
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const columns = [
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'isPrimaryTenant', headerName: 'Primary Tenant', width: 150 },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem label="Edit Tenant" onClick={handleEditTenant(params.id)} showInMenu={true} />,
        <GridActionsCellItem label="Delete Tenant" onClick={handleDeleteTenant(params.id)} showInMenu={true} />
      ]
    }
  ];

  useEffect(() => {
    setRows(generateRows());
  }, [tenantsList]);

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddTenant}>
          {t('Add_Tenant_to_Unit')}
        </Button>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleMoveOutTenant} disabled={aclToken === null}>
          {t('Move_out_Tenants')}
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{
          toolbar: Toolbar,
          noRowsOverlay: () => <Box sx={styles.noRows}>No tenants found for this unit</Box>
        }}
        autoHeight
        loading={loading}
      />
      <EditTenantDialog
        isOpen={isEditTenantDialogOpen}
        setIsOpen={setIsEditTenantDialogOpen}
        selectedTenantId={selectedTenantId}
        setSelectedTenantId={setSelectedTenantId}
      />
      <SnackbarAlert
        type={error ? 'error' : 'success'}
        time={5000}
        text={error || success || ''}
        isOpen={!!error || !!success}
        onClose={() => {
          setError(null);
          setSuccess(null);
        }}
      />
    </>
  );
};

const styles = {
  noRows: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
};

export default TenantsDatagrid;
