import { GetNextPropertyId } from 'shared/api/Acl/IxgAclApi';

// Fetch site id for registered site, otherwise will get the site ID from the ACL using the token
// ACL TOKEN is fetch when user log into the IXG account

export const fetchAclSiteId = async () => {
  const aclToken: string | null = localStorage.getItem('acltoken');
  const getAwsPropertyIdFromLocalStorage = JSON.parse(localStorage.getItem('gwStorage'))?.awsPropertyId ?? '';

  if (getAwsPropertyIdFromLocalStorage) {
    return getAwsPropertyIdFromLocalStorage;
  } else {
    try {
      if (!aclToken) {
        return 'Please log in to fetch site ID from ACL';
      }
      const getAclId = await GetNextPropertyId(aclToken);
      if (getAclId.StatusCode === 200) {
        return getAclId.Data.toString();
      }
    } catch (error) {
      console.error('Error getting site ID from ACL: ', error);
      return 'error fetching site ID';
    }
  }
};
