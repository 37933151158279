import { Fragment } from 'react';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { SystemConstraint } from 'features/QuikSpec/Types/QuikSpecTypes';
import theme from 'data/theme';

interface Props {
  systemConstraints: SystemConstraint[];
}

const QuikspecSystemConstraints = ({ systemConstraints }: Props) => {
  return (
    <Paper sx={styles.mainPaper}>
      <Typography align="center" variant="h6" component={'h6'} style={styles.systemDescription}>
        System Constraints
      </Typography>
      <Divider />
      <Grid container justifyContent="center" spacing={2}>
        {systemConstraints.map((constraint, index, array) => (
          <Fragment key={index}>
            <Grid item sx={styles.gridItem} key={index}>
              <Typography textAlign="center">{`${constraint.description}:`}</Typography>
              <Typography variant="h4" component="h4" textAlign="center">{`${constraint.max}`}</Typography>
            </Grid>
            {index !== array.length - 1 && (
              <Grid item key={`divider-${index}`}>
                <Divider orientation="vertical" />
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>
    </Paper>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainPaper: {
    mt: 4
  },
  systemDescription: {
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    whiteSpace: 'nowrap',
    padding: '0.5rem'
  },
  gridItem: {
    flexGrow: 1,
    flexBasis: 0
  }
};

export default QuikspecSystemConstraints;
