import { Box, List, Checkbox, FormControlLabel } from '@mui/material';
import AddedDevicesItem from './AddedDevicesItem';
import { useTranslation } from 'react-i18next';
import { IDevice } from 'store/slices/devicesSlice';

interface IAddedDevicesProps {
  devices: IDevice[];
  isSelectAll: boolean;
  isMultiselect: boolean;
  selectedDevicesIds: string[];
  onSelectDevice: (id: string) => void;
  toggleMultiselect: () => void;
  toggleSelectAll: () => void;
  handleDelete?: () => void;
  showDeleteButton?: boolean;
}

const AddedDevices = ({
  devices,
  isSelectAll,
  isMultiselect,
  selectedDevicesIds,
  onSelectDevice,
  toggleMultiselect,
  toggleSelectAll,
  handleDelete,
  showDeleteButton = true
}: IAddedDevicesProps) => {
  const { t } = useTranslation();
  const getDevicesList = (arr: IDevice[]) => {
    return arr.map((device: IDevice) => (
      <AddedDevicesItem
        key={device.publicId}
        device={device}
        isSelected={selectedDevicesIds.includes(device.publicId)}
        handleDelete={handleDelete}
        handleSelect={onSelectDevice}
        isMultiselect={isMultiselect}
        showDeleteButton={showDeleteButton}
      />
    ));
  };

  return (
    <Box sx={styles.unitsWrapper}>
      <Box sx={styles.title}>{t('Device_Group')}</Box>
      {devices.length > 0 && (
        <Box sx={styles.multiselectWrapper}>
          <FormControlLabel
            control={<Checkbox size="small" checked={isMultiselect} onChange={toggleMultiselect} />}
            label="Select Several"
          />
          {isMultiselect && (
            <FormControlLabel
              control={<Checkbox size="small" checked={isSelectAll} onChange={toggleSelectAll} />}
              label="Select All"
            />
          )}
        </Box>
      )}
      <List>
        {devices.length > 0 ? getDevicesList(devices) : <Box sx={styles.noDevicesWrapper}>{t('No_Devices_Text')}</Box>}
      </List>
    </Box>
  );
};

const styles = {
  unitsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  title: {
    fontSize: '18px',
    padding: '10px',
    marginLeft: '15px',
    backgroundColor: '#F0F0F0'
  },
  multiselectWrapper: {
    paddingLeft: '15px',
    paddingBottom: '10px',
    backgroundColor: '#F0F0F0'
  },
  noDevicesWrapper: {
    padding: '20px',
    marginLeft: '15px',
    color: '#A0A0A0'
  }
};

export default AddedDevices;
