import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams, Grid, TextField } from '@mui/material';
import { Field, useField } from 'formik';
import { useTranslation } from 'react-i18next'; // Import useTranslation for i18n
import { ICompany, ICompanyList } from '../../Dialogs/EditRegisteredUserDialog';

interface CompanySelectProps {
  companies: ICompanyList;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, value: ICompany) => void;
}

const CompanySelect: React.FC<CompanySelectProps> = ({ companies, handleChange }: CompanySelectProps) => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [, meta] = useField('company');

  // Format options from the companies object
  const formattedOptions = Object.entries(companies).map(([key, value]) => {
    return { publicId: key, name: value.name, state: value.state, postalCode: value.postalCode };
  });

  return (
    <Grid item xs={6}>
      <Field
        name="company" // Use "company" directly for form field name
        as={Autocomplete}
        id="company" // Set the id as "company"
        onChange={handleChange}
        options={formattedOptions}
        getOptionLabel={(option: ICompany) => option.name}
        isOptionEqualToValue={(option: ICompany, value: ICompany) => {
          return option.publicId === value.publicId;
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={t('Select_A_Company')}
            variant="outlined"
            size="small"
            fullWidth
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error ? meta.error : ''}
          />
        )}
      />
    </Grid>
  );
};

export default CompanySelect;
