import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect } from 'react';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { getString } from 'shared/utils/LocalizationUtils';
import { IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';

interface IInputPolaritySettingProps {
  selectedRelay: IDeviceContactInput;
  setNeedsSave: (needsSave: boolean) => void;
  newRelaySettings: Array<IContactOutput | IDeviceContactInput>;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
  inputFunction: number;
}
const strings = {
  title: 'Input Polarity',
  description: 'Choose the polarity that will trigger the function.'
};
const InputPolaritySetting = ({
  selectedRelay,
  setNeedsSave,
  newRelaySettings,
  setNewRelaySettings,
  inputFunction
}: IInputPolaritySettingProps) => {
  const [inputPolarity, setInputPolarity] = React.useState<number>(selectedRelay.inputPolarity); // Default [1]
  const inputPolarityText = getString('Input_Polarity');
  useEffect(() => {
    setInputPolarity(selectedRelay.inputPolarity);
  }, [selectedRelay]);

  useEffect(() => {
    const matchingRelay = newRelaySettings.find((relay) => relay.publicId === selectedRelay.publicId);
    if (
      matchingRelay &&
      'inputPolarity' in matchingRelay &&
      matchingRelay.inputPolarity !== selectedRelay.inputPolarity
    ) {
      setInputPolarity(matchingRelay.inputPolarity);
    } else {
      setInputPolarity(selectedRelay.inputPolarity);
    }
  }, [newRelaySettings, selectedRelay]);

  const handleChange = (event: SelectChangeEvent<number>) => {
    const inputPolarity = event.target.value as number;
    if (selectedRelay.inputPolarity !== inputPolarity) {
      setNeedsSave(true);
      setNewRelaySettings((prev) => {
        const newSettingsArray = prev;
        const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);
        const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
        if (index !== -1) {
          if (currentRelaySettings) newSettingsArray[index] = { ...currentRelaySettings, inputPolarity };
        } else {
          newSettingsArray.push({ ...selectedRelay, inputPolarity });
        }
        return newSettingsArray;
      });
    } else {
      setNeedsSave(false);
      setNewRelaySettings((prev) => {
        return prev.filter((relay) => relay.publicId !== selectedRelay.publicId);
      });
    }
    setInputPolarity(event.target.value as number);
  };

  const enumList: EnumList = fetchLocalEnumList();
  const polarityOptions = enumList.contactInputPolarity;
  if (inputFunction === 1) {
    return null;
  }

  return (
    <Box sx={styles.settingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{strings.title}</Box>
        <Box sx={styles.description}> {strings.description}</Box>
      </Box>
      <Box sx={styles.selectWrapper}>
        <FormControl sx={styles.selectField}>
          <InputLabel id="input-polarity-label">{inputPolarityText}</InputLabel>
          <Select
            labelId="input-polarity-label"
            id="input-polarity"
            value={inputPolarity}
            label="Input Polarity"
            onChange={handleChange}
          >
            {Object.entries(polarityOptions).map(([key, option]) => (
              <MenuItem key={key} value={key}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  selectWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  selectField: {
    width: '60%'
  }
};

export default InputPolaritySetting;
