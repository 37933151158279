import { ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Typography, Grid, Divider } from '@mui/material';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useLocation, Link, useParams } from 'react-router-dom';

import { Apartment as ApartmentIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { HomeIcon, RemoteManagementIcon } from 'shared/icons/icons';
import { useTranslation } from 'react-i18next';

const UnitSideBar = (props: any) => {
  const unitPublicId = useParams().unitid ?? '';
  const sitePublicId = useParams().id ?? '';
  const unit = useSelector((state: RootState) => state.units.UnitList[unitPublicId]);
  const unitNumber = unit?.unitNumber || '';
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();

  const handleAllUnitsClick = () => {
    //TODO: clear selected unit id
  };

  interface DeviceMenu {
    name: string;
    icon: ReactNode;
  }

  const baseMenuItems: DeviceMenu[] = [
    { name: t('Unit_Information'), icon: <ApartmentIcon /> },
    { name: t('QR_&_Access_Codes'), icon: <QrCodeIcon /> }
  ];

  const menuItems: DeviceMenu[] = [...baseMenuItems];

  return (
    <Sidebar
      style={styles.sidebar as any}
      breakPoint="md"
      backgroundColor={theme.palette.neutral.light}
      collapsed={props.collapsed}
      toggled={props.toggled}
    >
      <Link style={styles.siteLink} to={`/site/${sitePublicId}/units/${unitPublicId}/unitinformation`}>
        <Box sx={styles.siteBadge}>
          <Grid container spacing={0} direction="column" justifyContent="center">
            <Grid xs={12} item>
              <Box sx={styles.centerContent}>
                <NightShelterIcon color="primary" fontSize="large" />
              </Box>
            </Grid>
            <Grid xs={12} item>
              {/* TODO - add image based on unit type once we can fetch that data */}
              <Grid sx={styles.unit}>
                <Typography sx={styles.name}>{`${t('Unit')}: ${unitNumber}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Link>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? '#c9e7ff' : undefined,
              color: active ? '#0071CE' : undefined,
              '&:hover': {
                backgroundColor: active ? '#c9e7ff' : '#e2f2ff'
              }
            };
          }
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            active={
              location.pathname ===
              `/site/${sitePublicId}/units/${unitPublicId}/${item.name.toLowerCase().replace(/ /g, '')}`
            }
            component={
              <Link to={`/site/${sitePublicId}/units/${unitPublicId}/${item.name.toLowerCase().replace(/ /g, '')}`} />
            }
            icon={item.icon}
          >
            <Typography variant="body2">{item.name}</Typography>
          </MenuItem>
        ))}
        <MenuItem
          active={location.pathname === '/remotemanagement'}
          component={<Link to={`/site/${sitePublicId}/units`} onClick={handleAllUnitsClick} />}
          icon={<ArrowBackIcon />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            {t('Return_to_All_Units')}
          </Typography>
        </MenuItem>
      </Menu>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? theme.palette.neutral.medium : undefined
            };
          }
        }}
        rootStyles={styles.secondMenu}
      >
        <Divider />
        <MenuItem
          active={location.pathname === '/'}
          component={<Link to="/" />}
          icon={<HomeIcon viewBox="0 0 25 25" />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            {t('Home')}
          </Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === '/remotemanagement'}
          component={<Link to="/remotemanagement" />}
          icon={<RemoteManagementIcon viewBox="0 0 25 25" />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            {t('Remote_Management')}
          </Typography>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  sidebar: {
    height: 'calc(100%-64px)',
    position: 'sticky',
    top: '0',
    left: '0'
  },
  siteLink: {
    textDecoration: 'none'
  },
  siteBadge: {
    border: '2px solid #D7D7D7',
    boxShadow: '.5px .5px .5px .5px rgba(0,0,0,0.2)',
    borderRadius: '2%',
    marginX: '.5rem',
    marginY: '.5rem',
    '&:hover': {
      backgroundColor: '#f3f3f3'
    }
  },
  name: {
    color: '#003366',
    textDecoration: 'none',
    fontSize: '1.25rem',
    fontWeight: '700',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    marginY: '.5rem',
    paddingX: '.5rem'
  },
  progressBar: {
    backgroundColor: '#ffd5b1 ',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FF7500'
    },
    padding: '.30rem',
    marginLeft: '.5rem',
    marginRight: '.5rem'
  },
  progressBarText: {
    textAlign: 'center',
    color: '#003366'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  secondMenu: {
    position: 'absolute',
    bottom: '0',
    '@media (max-height: 500px)': {
      position: 'relative'
    }
  },
  unit: {
    display: 'flex',
    flexdirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    color: '#6D6E71',
    fontSize: '10px'
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default UnitSideBar;
