import { ApiError, ErrorType, classifyAndThrowError } from '../ApiError';
import axiosApiInstance from '../axiosConfig';
import { CreateQuikSpecData, GetBranchSpecsData, GetQuikspecsData, UpdateQuikSpecData } from './apiParamsTypes';
import CONFIG from '../../../config';

export const createQuikspec = async (data: CreateQuikSpecData) => {
  try {
    const response = await axiosApiInstance({
      method: 'post',
      url: CONFIG.apiEndpoint + '/c2Functions',
      params: {
        action: 'createQuote'
      },
      data
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const getQuikspecs = async (data: GetQuikspecsData) => {
  try {
    const response = await axiosApiInstance({
      method: 'post',
      url: CONFIG.apiEndpoint + '/c2Functions',
      params: {
        action: 'getAllQuotesByContactId'
      },
      data
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const updateQuikspec = async (data: UpdateQuikSpecData) => {
  try {
    const response = await axiosApiInstance({
      method: 'post',
      url: CONFIG.apiEndpoint + '/c2Functions',
      params: {
        action: 'updateQuote'
      },
      data
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const getBranchSpecs = async (data: GetBranchSpecsData) => {
  try {
    const response = await axiosApiInstance({
      method: 'post',
      url: CONFIG.apiEndpoint + '/c2Functions',
      params: {
        action: 'getAllBranchSpecs'
      },
      data
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
