import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { IDevice } from '../../../../../store/slices/devicesSlice';

interface ISearchableDropdownProps {
  dropdownOptions: IDevice[];
  selectDeviceHandler: (event: any, value: any) => void; // todo change types
}

const SearchableDropdown = (props: ISearchableDropdownProps) => {
  const defaultValue = '-';
  const { dropdownOptions, selectDeviceHandler } = props;
  return (
    <Autocomplete
      getOptionLabel={(option: IDevice) =>
        `${option.basicInfo.stationName || defaultValue} / ${option.basicInfo.stationNumber || defaultValue}`
      } //station number
      getOptionKey={(option: IDevice) => option.publicId}
      isOptionEqualToValue={(option: IDevice, value: IDevice) => {
        return option.publicId === value.publicId;
      }}
      disablePortal
      id="devices-for-address-book"
      options={dropdownOptions}
      sx={{ width: '100%' }}
      onChange={selectDeviceHandler}
      blurOnSelect
      clearOnBlur
      renderInput={(params) => <TextField {...params} label="Search device" />}
    />
  );
};

export default SearchableDropdown;
