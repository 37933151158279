/**
 * This will be the centralized place for redux
 * This will help solve some 'circular imports' issues
 */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {
  siteReducer,
  addSite,
  updateSite,
  setSiteLoading,
  setIsWizardComplete,
  addAwsPropertyId
} from './slices/siteSlice';
import { devicesReducer } from './slices/devicesSlice';
import { unitsReducer } from './slices/unitsSlice';
import { usersReducer, addUsers, resetUsersState, setCurrentUser } from './slices/usersSlice';
import { tenantsReducer } from './slices/tenantsSlice';
import { appsReducer } from './slices/appsSlice';
import { ixgAclApiSlice } from './api/ixgAclApiSlice';
import { aclUserReducer, initAclUser } from 'features/SimBilling/Stores/aclUserSlice';
import {
  aclSitesReducer,
  resetAclSites,
  resetAclSiteError,
  selectAclSite
} from 'features/SimBilling/Stores/aclSitesSlice';
import {
  subscriptionPlansReducer,
  resetfetchSubPlansDataError
} from 'features/SimBilling/Stores/subscriptionPlansSlice';
import {
  gatewayReducer,
  addStationsFromGw,
  resetGatewayState,
  addGatewayInfo,
  updateGWId
} from './slices/rmGatewaySlice';
import { aiphoneCloudApi } from 'services/aiphoneCloud';
import { setupListeners } from '@reduxjs/toolkit/query';
import { buildingsReducer } from './slices/buildingsSlice';
import { localizationReducer } from './slices/localizationSlice';
import { branchReducer } from './slices/branchSlice';
import 'react-toastify/dist/ReactToastify.css';

// Configuration for redux-persist
const persistConfig = {
  key: 'root', // The key for storing the data
  storage, // Use localStorage to persist the data
  whitelist: ['users', 'site', 'devices', 'tenants'] // Add reducers you want to persist
};

// Combine your reducers
const rootReducer = combineReducers({
  site: siteReducer,
  buildings: buildingsReducer,
  devices: devicesReducer,
  aclSites: aclSitesReducer,
  aclUser: aclUserReducer,
  subscriptionPlans: subscriptionPlansReducer,
  units: unitsReducer,
  users: usersReducer,
  tenants: tenantsReducer,
  locale: localizationReducer,
  apps: appsReducer,
  branches: branchReducer,
  gateway: gatewayReducer,
  [ixgAclApiSlice.reducerPath]: ixgAclApiSlice.reducer,
  [aiphoneCloudApi.reducerPath]: aiphoneCloudApi.reducer
});

// Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store with persisted reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE']
      }
    }).concat(ixgAclApiSlice.middleware, aiphoneCloudApi.middleware)
});

// Create a persistor
const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor };

// All the reducers' actions will be exported right here

export {
  addSite,
  updateSite,
  setSiteLoading,
  setIsWizardComplete,
  addUsers,
  resetUsersState,
  setCurrentUser,
  initAclUser,
  addAwsPropertyId,
  resetAclSites,
  selectAclSite,
  resetAclSiteError,
  resetfetchSubPlansDataError,
  addStationsFromGw,
  resetGatewayState,
  addGatewayInfo,
  updateGWId
};

/**
 * Extract the 'RootState' type and the 'Dispatch' type
 * so that they can be referenced as needed with correct update
 */
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
