import { store } from 'store';

export const updateSiteJson = async () => {
  const _siteStore = store.getState();
};

const IPV4_REGEX =
  /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;

/**
 * @function isValidIPV4Address
 * @description Validates an IPV4 address
 * @param ip - The IP address to validate
 * @returns {boolean} - True if the IP address is valid, false otherwise
 */
export const isValidIPV4Address = (ip: string): boolean => ip !== '0.0.0.0' && IPV4_REGEX.test(ip);
