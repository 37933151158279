import TransferSettings, { TransferSettingsInput } from '../components/TransferSettings';

import { useDispatch, useSelector } from 'react-redux';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { getString } from 'shared/utils/LocalizationUtils';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
export const AbsentTransferLabel = () => {
  const absentTitle = getString('Title_Absent_Transfer');
  return <span>{absentTitle}</span>;
};
const AbsentTransfer = () => {
  const dispatch = useDispatch();
  const selectedDevice = useSelector(getSelectedDevice);
  const [updateDevice] = useUpdateDeviceMutation();

  const handleSaveAbsentTransfer = async (transferSettings: TransferSettingsInput) => {
    const { selectedDeviceIds, selectedAppUnitId, enableTransfers } = transferSettings;

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.transferSettings = {
      ...newDevice.transferSettings,
      transferAbsent: enableTransfers,
      deviceTransferAbsentDestinationList: selectedDeviceIds,
      ...(selectedAppUnitId && { absentDestinationUnitPublicId: selectedAppUnitId })
    };

    const params = {
      device: {
        publicId: selectedDevice.publicId,
        transferSettings: {
          transferAbsent: enableTransfers,
          deviceTransferAbsentDestinationList: selectedDeviceIds,
          ...(selectedAppUnitId && { absentDestinationUnitPublicId: selectedAppUnitId })
        }
      }
    };
    await updateDevice(params).unwrap();

    dispatch(updateSelectedDevice({ device: newDevice }));
  };

  return (
    <TransferSettings
      title="Absent Transfer"
      helperText="Absent transfer prevents the device from receiving calls."
      isDelayTransfer={false}
      handleSave={handleSaveAbsentTransfer}
    />
  );
};

export default AbsentTransfer;
