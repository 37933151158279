/* SSL Certificate tab stub */
export const SSLCertificateLabel = () => {
  return <span>SSL Certificate</span>;
};

const SSLCertificate = () => {
  return <span>We will update Aiphone Cloud for SSL Certificate soon!</span>;
};

export default SSLCertificate;
