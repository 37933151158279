import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';

export const updateFormValuesJO = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesJO(values);
  updateWiresJO(values);
  updateNotesJO(values);
  return values;
};

const updateItemQuantityWithAccessoriesJO = (values: FormValues) => {
  const items = Object.values(values.items);

  // Add RY-1824L depending on the number of doors
  const totalDoorStations = items
    .filter((item) => item.category === 'Door Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const RY1824LIndex = items.findIndex((item) => item.itemName === 'RY-1824L');
  items[RY1824LIndex].quantity = totalDoorStations;

  // Kit determination
  const JO1MDWIndex = items.findIndex((item) => item.itemName === 'JO-1MDW');
  const JODAIndex = items.findIndex((item) => item.itemName === 'JO-DA');
  const JODVIndex = items.findIndex((item) => item.itemName === 'JO-DV');
  const JODVFIndex = items.findIndex((item) => item.itemName === 'JO-DVF');
  const JOS1AWIndex = items.findIndex((item) => item.itemName === 'JOS-1AW');
  const JOS1VWIndex = items.findIndex((item) => item.itemName === 'JOS-1VW');
  const JOS1FWIndex = items.findIndex((item) => item.itemName === 'JOS-1FW');
  items[JOS1AWIndex].quantity = 0;
  items[JOS1VWIndex].quantity = 0;
  items[JOS1FWIndex].quantity = 0;

  if (items[JO1MDWIndex].quantity > 0) {
    if (items[JODAIndex].quantity > 0) {
      items[JOS1AWIndex].quantity = 1;
      items[JO1MDWIndex].quantity = 0;
      items[JODAIndex].quantity -= 1;
    } else if (items[JODVIndex].quantity > 0) {
      items[JOS1VWIndex].quantity = 1;
      items[JO1MDWIndex].quantity = 0;
      items[JODVIndex].quantity -= 1;
    } else if (items[JODVFIndex].quantity > 0) {
      items[JOS1FWIndex].quantity = 1;
      items[JO1MDWIndex].quantity = 0;
      items[JODVFIndex].quantity -= 1;
    }
  }

  const JOW2DIndex = items.findIndex((item) => item.itemName === 'JOW-2D');
  if (items[JOW2DIndex].quantity > 0) {
    items[JODVIndex].quantity = 1;
  }
};

const updateWiresJO = (values: FormValues) => {
  values.wires.push(
    {
      system: 'Door to Master Station',
      wire: '871802',
      description: "2 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: "330'",
      jacketNomOD: 'PVC .190"',
      capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
    },
    {
      system: 'Power to Master Station',
      wire: '871802',
      description: "2 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: "33'",
      jacketNomOD: 'PVC .190"',
      capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
    }
  );

  if (
    values.items
      .filter((item) => item.itemName === 'JO-1FD')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'Master Station to Expansion Station',
      wire: '871804',
      description: "4 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: "330'",
      jacketNomOD: 'PVC .290"',
      capacitanceLoopRes: '13.6 pf/ft, 6.385 Ohms'
    });
  }
};

const updateNotesJO = (values: FormValues) => {
  if (
    values.items
      .filter((item) => item.itemName === 'JO-1MDW')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The JO Mobile App is an app for iOS® and Android® mobile devices as a sub master station for the JO Series. The cloud-based app can receive and place calls, have two-way communication, view video and release doors from anywhere.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-ES')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The RY-ES provides a momentary contact upon activation. Any external device with its own power supply may be connected to this contact. (Not supplied by Aiphone.)'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-1824L')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    if (
      values.items
        .filter((item) => item.itemName === 'JOW-2D')
        ?.reduce((total: number, item) => total + item.quantity, 0) > 0
    ) {
      values.notes.push(
        'An RY-1824L has been added to the parts list for door release. The JO Series has a N/O dry contact, rated at 24V AC/DC, 500 mA. If this specification meets your system requirements, then a separate relay is not required. If the door release hardware is rated at a higher voltage or current draw, or if you are using a magnetic lock, include the door release relay specified above.'
      );
    } else {
      values.notes.push(
        'An RY-1824L has been added to the parts list for door release. The JO Series Mobile-Ready master station has a N/O dry contact, rated at 24V AC/DC, 1A. If this specification meets your system requirements, then a separate relay is not required. If the door release hardware is rated at a higher voltage or current draw, or if you are using a magnetic lock, include the door release relay specified above.'
      );
    }
  }
};
