/********************* Sim Billing ************************** */
import {
  BuyAdditionalDataParams,
  CancelSubscriptionParams,
  CreateACLUserParams,
  DeviceDetailsUpdateParams,
  GetACLSiteJSONParams,
  GetACLUserParams,
  GetInvoicesParams,
  GetPaymentUpdateUrl,
  GetSimCarrierParams,
  GetSubDetailsParams,
  GetSubscriptionPlans,
  GetUpcomingInvParams,
  MakeSubscription,
  ProcessACLSiteParams,
  SaveSiteIdToATT,
  UpdateJsonForSiteParams,
  UpdateSubscription
} from './apiParamsTypes';
import axiosApiInstance from '../axiosConfig';
import CONFIG from '../../../config';
import { ApiError, classifyAndThrowError, ErrorType } from '../ApiError';

/** Process Sites */
export const processACLSite = async (params: ProcessACLSiteParams) => {
  try {
    const response = await axiosApiInstance.post(CONFIG.apiEndpoint + '/v1/aclsites', params);
    switch (response.status) {
      case 200:
        return response;
      case 201:
        return response;
      case 202:
        return response;
      case 501:
        return response;
      case 500:
        return response;
      case 404:
        return response;
      default:
        throw new ApiError('Error', ErrorType.UNEXPECTED);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const getACLSitesJSONContent = async (params: GetACLSiteJSONParams) => {
  try {
    const response = await axiosApiInstance.get(CONFIG.apiEndpoint + '/v1/aclsites', { params });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const updateJsonForSite = async (params: UpdateJsonForSiteParams) => {
  try {
    const response = await axiosApiInstance.post(CONFIG.apiEndpoint + '/updateSite', params);
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

/** Process Users */
export const getACLUser = async (params: GetACLUserParams) => {
  try {
    const response = await axiosApiInstance.get(CONFIG.apiEndpoint + '/v1/acluser', { params });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const createACLUser = async (userData: CreateACLUserParams) => {
  try {
    const response = await axiosApiInstance.post(CONFIG.apiEndpoint + '/v1/acluser', { userData });
    switch (response.status) {
      case 200:
        return response;
      case 201:
        return response;
      case 202:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

/** Process Sim */
export const validateICCID = async (iccid: string) => {
  try {
    const response = await axiosApiInstance.get(CONFIG.apiEndpoint + '/v1/iccidValidation', {
      params: {
        iccid
      }
    });
    switch (response.status) {
      case 200:
        return response;
      case 201:
        return response;
      case 202:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const deviceDetailsUpdate = async (params: DeviceDetailsUpdateParams) => {
  try {
    const response = await axiosApiInstance.post(CONFIG.apiEndpoint + '/v1/updateGwAndSim', params);
    switch (response.status) {
      case 200:
        return response;
      case 201:
        return response;
      case 202:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const getSimCarrierDetails = async (params: GetSimCarrierParams) => {
  try {
    const response = await axiosApiInstance.get(CONFIG.apiEndpoint + '/v1/simCarrierFunctions', { params });
    switch (response.status) {
      case 200:
        return response;
      case 201:
        return response;
      case 202:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const getSiteSubscriptionStatus = async (aclSitesIds: number[]) => {
  try {
    const params = { params: { aclSitesIds: aclSitesIds.join(',') } };

    const response = await axiosApiInstance.get(CONFIG.apiEndpoint + '/v1/subscriptions', params);
    switch (response.status) {
      case 200:
        return response;
      case 201:
        return response;
      case 202:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const saveSiteId = async (payload: SaveSiteIdToATT) => {
  try {
    const params = { params: payload };

    const response = await axiosApiInstance.put(CONFIG.apiEndpoint + '/v1/simCarrierFunctions', {}, params);
    switch (response.status) {
      case 200:
        return response;
      case 201:
        return response;
      case 202:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

/**Process Stripe**/
export const useStripeFunctions = async (
  params:
    | GetInvoicesParams
    | GetSubDetailsParams
    | GetUpcomingInvParams
    | BuyAdditionalDataParams
    | CancelSubscriptionParams
    | GetPaymentUpdateUrl
    | UpdateSubscription
    | MakeSubscription
    | GetSubscriptionPlans
) => {
  try {
    const response = await axiosApiInstance.get(CONFIG.apiEndpoint + '/v1/stripeFunctions', { params });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
/********************* END Sim Billing ************************** */
