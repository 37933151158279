import { getString } from 'shared/utils/LocalizationUtils';

/* Email tab stub */
export const EmailLabel = () => {
  const emailLabel = getString('Email_Label');
  return <span>{emailLabel}</span>;
};

const Email = () => {
  const emailLabel = getString('Email_Label');
  return <span>{emailLabel}</span>;
};

export default Email;
