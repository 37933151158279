/**
 * DataGrid cell component for text input
 *
 * Pass an error if there is one
 *
 * Note: Must set `display: 'flex'` and `align: 'center'`, in the GridRowProps to make the error message appear
 * below the input
 *
 * This data grid cell **will stay open** if the input is invalid.
 *
 * This component has an underline text input and an error message underneath it if there is an error.
 */
import React from 'react';
import { GridRenderEditCellParams, GridEditInputCell } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import 'styles/frontshine.css';

export interface TextInputDataGridCellProps extends GridRenderEditCellParams {
  checkForEmpty?: boolean;
}

const TextInputDataGridCell = React.forwardRef<HTMLDivElement, TextInputDataGridCellProps>((props, ref) => {
  const { error, checkForEmpty, ...restProps } = props;

  // Validate initial mount when text is processed for null or empty values
  let borderBottomColor = 'lightgrey';
  let errorTextColor = 'text-red';
  if (checkForEmpty && (!props.value || props.value.length === 0)) {
    borderBottomColor = 'orange';
    errorTextColor = 'text-orange';
  } else if (error) {
    borderBottomColor = 'red';
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          borderBottom: `2px solid ${borderBottomColor}`,
          paddingLeft: '2px',
          paddingRight: '2px'
        }}
        className={'center-text-inputs'}
      >
        <GridEditInputCell {...restProps} error={!!error} />
      </div>
      {error && <small className={`${errorTextColor} text-center mx-auto`}>{error}</small>}
    </Box>
  );
});

export default TextInputDataGridCell;

// Make a cell that essentially is the same thing as above, but is not for editing. It should show the error and value
// but not allow editing. It should also not show the error if the value is empty.
// Use the same style as above

export const NonEditableTextInputDataGridCell = React.forwardRef<HTMLDivElement, TextInputDataGridCellProps>(
  (props, ref) => {
    const { error, checkForEmpty } = props;

    // Validate initial mount when text is processed for null or empty values
    let borderBottomColor = 'lightgrey';
    let errorTextColor = 'text-red';
    if (checkForEmpty && (!props.value || props.value.length === 0)) {
      borderBottomColor = 'orange';
      errorTextColor = 'text-orange';
    } else if (error) {
      borderBottomColor = 'red';
    }

    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          ref={ref}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            borderBottom: `2px solid ${borderBottomColor}`,
            paddingLeft: '2px',
            paddingRight: '2px'
          }}
          className={'center-text-inputs'}
        >
          <div>{props.value}</div>
        </div>
        {error && <small className={`${errorTextColor} text-center mx-auto`}>{error}</small>}
      </Box>
    );
  }
);
