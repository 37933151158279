import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isDeviceWithAddressBook } from 'shared/utils/helperFunctions';
import { RootState } from 'store';

const AppAddressBookUnitControlPanel = () => {
  const [selectedDevice, setSelectedDevice] = React.useState<string>('');
  const unitPublicId = useParams().unitid || '';
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);

  const answeringStationsInUnit = unitList[unitPublicId]?.devicePublicIds
    ? unitList[unitPublicId].devicePublicIds
        .map((devicePublicId) => {
          const device = deviceList[devicePublicId];
          return isDeviceWithAddressBook(device) ? device : null;
        })
        .filter((answeringStation) => answeringStation !== null)
    : [];

  const strings = {
    title: 'Address book settings for Mobile Apps',
    description: ''
  };

  return (
    <Box style={styles.controlPanelWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{strings.title}</Box>
        <Box sx={styles.description}> {strings.description}</Box>
      </Box>
      <Grid container spacing={2} sx={styles.actionsWrapper}>
        <Grid item xs={6}>
          <Select
            sx={styles.selectInput}
            value={selectedDevice}
            onChange={(event) => setSelectedDevice(event.target.value as string)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {answeringStationsInUnit.length === 0 ? (
              <MenuItem value="" disabled>
                No answering stations in this unit
              </MenuItem>
            ) : (
              <MenuItem value="" disabled>
                Select answering station
              </MenuItem>
            )}
            {answeringStationsInUnit?.map((answeringStation) => {
              if (!answeringStation) {
                return null;
              }

              return (
                <MenuItem key={answeringStation.publicId} value={answeringStation.publicId}>
                  {answeringStation.basicInfo.stationNumber} - {answeringStation.basicInfo.stationName}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={6} sx={styles.centerVertically}>
          <LoadingButton disabled={!selectedDevice} variant="contained">
            Copy Address Book
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = {
  controlPanelWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  selectWrapper: {
    width: '100%'
  },
  buttonsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end'
  },
  selectInput: {
    width: '100%'
  },
  actionsWrapper: {
    width: '50%'
  },
  centerVertically: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default AppAddressBookUnitControlPanel;
