import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import React from 'react';
import { formatPhoneNumber } from 'shared/utils/helperFunctions';

interface IAddTenantFormProps {
  formikProps: any;
  setIsOpen: (value: boolean) => void;
  unitPublicId?: string;
}

const AddTenantForm = ({ formikProps, setIsOpen, unitPublicId }: IAddTenantFormProps) => {
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const buildingOptions = Object.entries(buildingList)
    .map(([key, value]) => ({
      value: key,
      label: value.buildingNumber
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const generateUnitOptions = () => {
    const allowedUnitTypes = [1, 4, 5];
    const selectedBuildingPublicId = formikProps.values.buildingNumber;
    return Object.entries(unitList).map(([key, value]) => {
      if (value.buildingPublicId === selectedBuildingPublicId && allowedUnitTypes.includes(value.unitType)) {
        return { value: key, label: value.unitNumber };
      }
      return null;
    });
  };

  const unitOptions = generateUnitOptions();

  const handleClose = () => {
    formikProps.handleReset();
    setIsOpen(false);
  };

  const { t } = useTranslation();

  return (
    <Form>
      <DialogContent>
        <Box sx={styles.descriptionWrapper}>
          <Box sx={styles.title}>{t('Add_Tenant')}</Box>
          <Box sx={styles.description}>{t('Add_Tenant_Desc')}</Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label={t('First_Name')}
              name="firstName"
              variant="outlined"
              fullWidth
              required
              error={formikProps.touched.firstName && Boolean(formikProps.errors.firstName)}
              helperText={formikProps.touched.firstName && formikProps.errors.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label={t('Last_Name')}
              name="lastName"
              variant="outlined"
              fullWidth
              required
              error={formikProps.touched.lastName && Boolean(formikProps.errors.lastName)}
              helperText={formikProps.touched.lastName && formikProps.errors.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label={t('Email_Address')}
              name="email"
              variant="outlined"
              fullWidth
              error={formikProps.touched.email && Boolean(formikProps.errors.email)}
              helperText={formikProps.touched.email && formikProps.errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              as={TextField}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;
                if (value === '' || value === '+') {
                  formikProps.setFieldValue('phoneNumber', '+');
                } else if (value.startsWith('+1') && value.replace(/\D/g, '').length <= 11) {
                  const formattedValue = formatPhoneNumber(value);
                  formikProps.setFieldValue('phoneNumber', formattedValue);
                }
              }}
              label={t('Phone_Number')}
              name="phoneNumber"
              variant="outlined"
              value={formikProps.values.phoneNumber}
              fullWidth
              error={formikProps.touched.phoneNumber && Boolean(formikProps.errors.phoneNumber)}
              helperText={formikProps.touched.phoneNumber && formikProps.errors.phoneNumber}
            />
          </Grid>
          {!unitPublicId && (
            <>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="building-number-label">{t('Building_Number')}</InputLabel>
                  <Field
                    as={Select}
                    labelId="building-number-label"
                    label={t('Building_Number')}
                    name="buildingNumber"
                    variant="outlined"
                    fullWidth
                    required
                  >
                    {buildingOptions.map((building) => (
                      <MenuItem key={building.value} value={building.value}>
                        {building.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="unit-number-label">{t('Unit_Number')}</InputLabel>
                  <Field
                    as={Select}
                    labelId="unit-number-label"
                    label={t('Unit_Number')}
                    name="unitNumber"
                    variant="outlined"
                    fullWidth
                  >
                    {unitOptions.map((unit) => {
                      if (unit) {
                        return (
                          <MenuItem key={unit.value} value={unit.value}>
                            {unit.label}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Field>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          {t('Cancel_Button')}
        </Button>
        <LoadingButton type="submit" color="primary" variant="contained" loading={formikProps.isSubmitting}>
          {t('Add_Tenant')}
        </LoadingButton>
      </DialogActions>
    </Form>
  );
};

const styles = {
  descriptionWrapper: {
    width: '50%',
    marginBottom: '20px'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {}
};

export default AddTenantForm;
