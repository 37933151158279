import { Box, Card, Typography } from '@mui/material';
import DevicesDatagrid from './datagrids/DevicesDatagrid';

const DevicesCard = () => {
  return (
    <>
      <Card sx={styles.devicesCard}>
        <Typography variant="sectionHeader">Devices</Typography>

        <Box sx={styles.devicesDatagridWrapper}>
          <DevicesDatagrid />
        </Box>
      </Card>
    </>
  );
};

const styles = {
  devicesCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '20px'
  },
  devicesDatagridWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    marginTop: '10px'
  },
  loadingButton: {
    float: 'right'
  }
};

export default DevicesCard;
