import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useMemo } from 'react';

const useReduxUsers = () => {
  const users = useSelector((state: RootState) => state.users.users);
  const userLoading = useSelector((state: RootState) => state.users.loading);
  const currentUser = useSelector((state: RootState) => state.users.currentUser);

  return useMemo(() => ({ users, userLoading, currentUser }), [users, userLoading, currentUser]);
};
export default useReduxUsers;
