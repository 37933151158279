import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';
import { updateItemQuantityWithAccessoriesAC } from './QuikspecUpdateFormValuesAC';

export const updateFormValuesGT = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesGT(values);
  updateItemQuantityWithAccessoriesAC(values);
  updateWiresGT(values);
  updateNotesGT(values);
  return values;
};

const updateItemQuantityWithAccessoriesGT = (values: FormValues) => {
  const items = Object.values(values.items);

  // NOTE: Commented out unused variables, confirm if they are needed - Cody
  // Update quantities of GT system
  const GTDBIndex = items.findIndex((item) => item.itemName === 'GT-DB');
  const GTDBVNIndex = items.findIndex((item) => item.itemName === 'GT-DB-VN');
  const GTDBPIndex = items.findIndex((item) => item.itemName === 'GT-DBP');
  const GTVBIndex = items.findIndex((item) => item.itemName === 'GT-VB');
  const GTVPIndex = items.findIndex((item) => item.itemName === 'GT-VP');
  const GTNSBIndex = items.findIndex((item) => item.itemName === 'GT-NSB');
  const GTNSPLIndex = items.findIndex((item) => item.itemName === 'GT-NSP-L');
  const GT10KIndex = items.findIndex((item) => item.itemName === 'GT-10K');
  const GF10KPIndex = items.findIndex((item) => item.itemName === 'GF-10KP');
  //const GTADIndex = items.findIndex((item) => item.itemName === 'GT-AD');
  //const GTAPIndex = items.findIndex((item) => item.itemName === 'GT-AP');
  const GFBPIndex = items.findIndex((item) => item.itemName === 'GF-BP');
  //const GTACIndex = items.findIndex((item) => item.itemName === 'GT-AC');
  //const GTHIDIndex = items.findIndex((item) => item.itemName === 'GT-HID');
  const GTSWIndex = items.findIndex((item) => item.itemName === 'GT-SW');
  const GF1PIndex = items.findIndex((item) => item.itemName === 'GF-1P');
  const GF2PIndex = items.findIndex((item) => item.itemName === 'GF-2P');
  const GF3PIndex = items.findIndex((item) => item.itemName === 'GF-3P');
  const GF4PIndex = items.findIndex((item) => item.itemName === 'GF-4P');
  const GF2FIndex = items.findIndex((item) => item.itemName === 'GF-2F');
  const GF2BIndex = items.findIndex((item) => item.itemName === 'GF-2B');
  const GT102HIndex = items.findIndex((item) => item.itemName === 'GT-102H');
  const GT102HBIndex = items.findIndex((item) => item.itemName === 'GT-102HB');
  const GT202HIndex = items.findIndex((item) => item.itemName === 'GT-202H');
  const GT202HBIndex = items.findIndex((item) => item.itemName === 'GT-202HB');
  const GF3FIndex = items.findIndex((item) => item.itemName === 'GF-3F');
  const GF3BIndex = items.findIndex((item) => item.itemName === 'GF-3B');
  const GT103HIndex = items.findIndex((item) => item.itemName === 'GT-103H');
  const GT103HBIndex = items.findIndex((item) => item.itemName === 'GT-103HB');
  const GT203HIndex = items.findIndex((item) => item.itemName === 'GT-203H');
  const GT203HBIndex = items.findIndex((item) => item.itemName === 'GT-203HB');
  const GT303HBIndex = items.findIndex((item) => item.itemName === 'GT-303HB');
  //const GT4FIndex = items.findIndex((item) => item.itemName === 'GT-4F');
  //const GT4BIndex = items.findIndex((item) => item.itemName === 'GT-4B');
  //const GT104HIndex = items.findIndex((item) => item.itemName === 'GT-104H');
  //const GT104HBIndex = items.findIndex((item) => item.itemName === 'GT-104HB');
  const GTBCIndex = items.findIndex((item) => item.itemName === 'GT-BC');
  const GTVBCIndex = items.findIndex((item) => item.itemName === 'GT-VBC');
  const GTBCXBIndex = items.findIndex((item) => item.itemName === 'GT-BCXB-N');
  const GTVBXIndex = items.findIndex((item) => item.itemName === 'GT-VBX');
  const GTWDPIndex = items.findIndex((item) => item.itemName === 'GTW-DP');
  const GT4ZIndex = items.findIndex((item) => item.itemName === 'GT-4Z');
  const GT4ZPIndex = items.findIndex((item) => item.itemName === 'GT-4ZP');
  const WDIN11Index = items.findIndex((item) => item.itemName === 'W-DIN11');
  const PS2420ULIndex = items.findIndex((item) => item.itemName === 'PS-2420UL');
  const numberOfTenantIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_TENANT');

  // Module Video Entrance
  const numberOfVideoModuleIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_MODULE_VIDEO_ENTRANCE');
  const VideoModuleKeypadIndex = items.findIndex((item) => item.itemName === 'MODULE_VIDEO_ENTRANCE_KEYPAD');
  const VideoModuleNFCIndex = items.findIndex((item) => item.itemName === 'MODULE_VIDEO_ENTRANCE_NFC');
  const VideoModuleRainhoodIndex = items.findIndex((item) => item.itemName === 'MODULE_VIDEO_ENTRANCE_RAINHOOD');
  const numberOfVideoModuleFlushIndex = items[numberOfVideoModuleIndex].accessories.findIndex(
    (item) => item.accessoryName === 'Flush Mount'
  );
  const numberOfVideoModuleSurfaceIndex = items[numberOfVideoModuleIndex].accessories.findIndex(
    (item) => item.accessoryName === 'Surface Mount'
  );
  if (numberOfVideoModuleIndex > 0 && items[numberOfVideoModuleIndex].quantity > 0) {
    let numberOfModule = 0;
    // GT-VB
    numberOfModule += 1;
    items[GTVBIndex].quantity += items[numberOfVideoModuleIndex].quantity;
    items[GTVPIndex].quantity += items[numberOfVideoModuleIndex].quantity;
    // GT-DB(-VN)
    numberOfModule += 1;
    if (items[VideoModuleNFCIndex].quantity > 0) {
      items[GTDBVNIndex].quantity += items[numberOfVideoModuleIndex].quantity;
    } else {
      items[GTDBIndex].quantity += items[numberOfVideoModuleIndex].quantity;
    }
    items[GTDBPIndex].quantity += items[numberOfVideoModuleIndex].quantity;
    if (items[VideoModuleKeypadIndex].quantity > 0) {
      //GT-NSB-N + GT-10K
      numberOfModule += 2;
      items[GTNSBIndex].quantity += items[numberOfVideoModuleIndex].quantity;
      items[GTNSPLIndex].quantity += items[numberOfVideoModuleIndex].quantity;
      items[GT10KIndex].quantity += items[numberOfVideoModuleIndex].quantity;
      items[GF10KPIndex].quantity += items[numberOfVideoModuleIndex].quantity;
    } else {
      // GT-SW
      numberOfModule += Math.ceil(items[numberOfTenantIndex].quantity / 4);
      items[GTSWIndex].quantity +=
        Math.ceil(items[numberOfTenantIndex].quantity / 4) * items[numberOfVideoModuleIndex].quantity;
      items[GF4PIndex].quantity +=
        Math.floor(items[numberOfTenantIndex].quantity / 4) * items[numberOfVideoModuleIndex].quantity;
      if (items[numberOfTenantIndex].quantity % 4 === 1) {
        items[GF1PIndex].quantity += items[numberOfVideoModuleIndex].quantity;
      } else if (items[numberOfTenantIndex].quantity % 4 === 2) {
        items[GF2PIndex].quantity += items[numberOfVideoModuleIndex].quantity;
      } else if (items[numberOfTenantIndex].quantity % 4 === 3) {
        items[GF3PIndex].quantity += items[numberOfVideoModuleIndex].quantity;
      }
    }
    // Size determination
    if (numberOfModule <= 3) {
      // 1x3
      items[GF3FIndex].quantity += items[numberOfVideoModuleIndex].quantity;
      items[GF3BIndex].quantity += items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleFlushIndex].quantity;
      items[GT103HIndex].quantity +=
        items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleFlushIndex].quantity *
        items[VideoModuleRainhoodIndex].quantity;
      items[GT103HBIndex].quantity +=
        items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleSurfaceIndex].quantity;
      items[GFBPIndex].quantity += 3 - numberOfModule;
    } else if (numberOfModule <= 4) {
      // 2x2
      items[GF2FIndex].quantity += 2 * items[numberOfVideoModuleIndex].quantity;
      items[GF2BIndex].quantity +=
        2 * items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleFlushIndex].quantity;
      items[GT202HIndex].quantity +=
        items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleFlushIndex].quantity *
        items[VideoModuleRainhoodIndex].quantity;
      items[GT202HBIndex].quantity +=
        items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleSurfaceIndex].quantity;
      items[GFBPIndex].quantity += 4 - numberOfModule;
    } else if (numberOfModule <= 6) {
      // 2x3
      items[GF3FIndex].quantity += 2 * items[numberOfVideoModuleIndex].quantity;
      items[GF3BIndex].quantity +=
        2 * items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleFlushIndex].quantity;
      items[GT203HIndex].quantity +=
        items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleFlushIndex].quantity *
        items[VideoModuleRainhoodIndex].quantity;
      items[GT203HBIndex].quantity +=
        items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleSurfaceIndex].quantity;
      items[GFBPIndex].quantity += 6 - numberOfModule;
    } else if (numberOfModule <= 9) {
      // 3x3
      items[GF3FIndex].quantity += 3 * items[numberOfVideoModuleIndex].quantity;
      items[GF3BIndex].quantity +=
        3 * items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleFlushIndex].quantity;
      items[GT303HBIndex].quantity +=
        items[numberOfVideoModuleIndex].accessories[numberOfVideoModuleSurfaceIndex].quantity;
      items[GFBPIndex].quantity += 9 - numberOfModule;
    }
  }

  // Module Audio Entrance
  const numberOfAudioModuleIndex = items.findIndex((item) => item.itemName === 'NUMBER_OF_MODULE_AUDIO_ENTRANCE');
  const AudioModuleKeypadIndex = items.findIndex((item) => item.itemName === 'MODULE_AUDIO_ENTRANCE_KEYPAD');
  const AudioModuleNFCIndex = items.findIndex((item) => item.itemName === 'MODULE_AUDIO_ENTRANCE_NFC');
  const AudioModuleRainhoodIndex = items.findIndex((item) => item.itemName === 'MODULE_AUDIO_ENTRANCE_RAINHOOD');
  const numberOfAudioModuleFlushIndex = items[numberOfAudioModuleIndex].accessories.findIndex(
    (item) => item.accessoryName === 'Flush Mount'
  );
  const numberOfAudioModuleSurfaceIndex = items[numberOfAudioModuleIndex].accessories.findIndex(
    (item) => item.accessoryName === 'Surface Mount'
  );
  if (numberOfAudioModuleIndex > 0 && items[numberOfAudioModuleIndex].quantity > 0) {
    let numberOfModule = 0;
    // GT-DB(-VN)
    numberOfModule += 1;
    if (items[AudioModuleNFCIndex].quantity > 0) {
      items[GTDBVNIndex].quantity += items[numberOfAudioModuleIndex].quantity;
    } else {
      items[GTDBIndex].quantity += items[numberOfAudioModuleIndex].quantity;
    }
    items[GTDBPIndex].quantity += items[numberOfAudioModuleIndex].quantity;
    if (items[AudioModuleKeypadIndex].quantity > 0) {
      //GT-NSB-N + GT-10K
      numberOfModule += 2;
      items[GTNSBIndex].quantity += items[numberOfAudioModuleIndex].quantity;
      items[GTNSPLIndex].quantity += items[numberOfAudioModuleIndex].quantity;
      items[GT10KIndex].quantity += items[numberOfAudioModuleIndex].quantity;
      items[GF10KPIndex].quantity += items[numberOfAudioModuleIndex].quantity;
    } else {
      // GT-SW
      numberOfModule += Math.ceil(items[numberOfTenantIndex].quantity / 4);
      items[GTSWIndex].quantity +=
        Math.ceil(items[numberOfTenantIndex].quantity / 4) * items[numberOfAudioModuleIndex].quantity;
      items[GF4PIndex].quantity +=
        Math.floor(items[numberOfTenantIndex].quantity / 4) * items[numberOfAudioModuleIndex].quantity;
      if (items[numberOfTenantIndex].quantity % 4 === 1) {
        items[GF1PIndex].quantity += items[numberOfAudioModuleIndex].quantity;
      } else if (items[numberOfTenantIndex].quantity % 4 === 2) {
        items[GF2PIndex].quantity += items[numberOfAudioModuleIndex].quantity;
      } else if (items[numberOfTenantIndex].quantity % 4 === 3) {
        items[GF3PIndex].quantity += items[numberOfAudioModuleIndex].quantity;
      }
    }
    // Size determination
    if (numberOfModule <= 2) {
      // 1x2
      items[GF2FIndex].quantity += items[numberOfAudioModuleIndex].quantity;
      items[GF2BIndex].quantity += items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleFlushIndex].quantity;
      items[GT102HIndex].quantity +=
        items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleFlushIndex].quantity *
        items[AudioModuleRainhoodIndex].quantity;
      items[GT102HBIndex].quantity +=
        items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleSurfaceIndex].quantity;
      items[GFBPIndex].quantity += 2 - numberOfModule;
    } else if (numberOfModule <= 3) {
      // 1x3
      items[GF3FIndex].quantity += items[numberOfAudioModuleIndex].quantity;
      items[GF3BIndex].quantity += items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleFlushIndex].quantity;
      items[GT103HIndex].quantity +=
        items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleFlushIndex].quantity *
        items[AudioModuleRainhoodIndex].quantity;
      items[GT103HBIndex].quantity +=
        items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleSurfaceIndex].quantity;
      items[GFBPIndex].quantity += 3 - numberOfModule;
    } else if (numberOfModule <= 4) {
      // 2x2
      items[GF2FIndex].quantity += 2 * items[numberOfAudioModuleIndex].quantity;
      items[GF2BIndex].quantity +=
        2 * items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleFlushIndex].quantity;
      items[GT202HIndex].quantity +=
        items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleFlushIndex].quantity *
        items[AudioModuleRainhoodIndex].quantity;
      items[GT202HBIndex].quantity +=
        items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleSurfaceIndex].quantity;
      items[GFBPIndex].quantity += 4 - numberOfModule;
    } else if (numberOfModule <= 6) {
      // 2x3
      items[GF3FIndex].quantity += 2 * items[numberOfAudioModuleIndex].quantity;
      items[GF3BIndex].quantity +=
        2 * items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleFlushIndex].quantity;
      items[GT203HIndex].quantity +=
        items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleFlushIndex].quantity *
        items[AudioModuleRainhoodIndex].quantity;
      items[GT203HBIndex].quantity +=
        items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleSurfaceIndex].quantity;
      items[GFBPIndex].quantity += 6 - numberOfModule;
    } else if (numberOfModule <= 9) {
      // 3x3
      items[GF3FIndex].quantity += 3 * items[numberOfAudioModuleIndex].quantity;
      items[GF3BIndex].quantity +=
        3 * items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleFlushIndex].quantity;
      items[GT303HBIndex].quantity +=
        items[numberOfAudioModuleIndex].accessories[numberOfAudioModuleSurfaceIndex].quantity;
      items[GFBPIndex].quantity += 9 - numberOfModule;
    }
  }

  // System Adapters
  const GTWLCIndex = items.findIndex((item) => item.itemName === 'GTW-LC');
  const GTMCXIndex = items.findIndex((item) => item.itemName === 'GT-MCX');
  const GTMKBNIndex = items.findIndex((item) => item.itemName === 'GT-MKB-N');
  const GTDMBNIndex = items.findIndex((item) => item.itemName === 'GT-DMB-N');
  const GT1C7WIndex = items.findIndex((item) => item.itemName === 'GT-1C7W');
  const totalVideoTenantStations = items
    .filter((item) => item.category === 'Audio Video Tenant Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalAudioTenantStations = items
    .filter((item) => item.category === 'Audio Only Tenant Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalTenantStations = totalVideoTenantStations + totalAudioTenantStations;
  const totalVideoEntrancePanels = items[GTDMBNIndex].quantity + items[GTVBIndex].quantity;
  const totalEntrancePanels = items[GTDMBNIndex].quantity + items[GTDBIndex].quantity + items[GTDBVNIndex].quantity;
  if (
    items[GTMKBNIndex].quantity + items[GTMCXIndex].quantity <= 2 &&
    totalTenantStations <= 48 &&
    totalEntrancePanels + items[GTMCXIndex].quantity <= 5 &&
    items[GTWLCIndex].quantity <= 0
  ) {
    // Standard System
    items[GTBCIndex].quantity = 1;
    if (totalVideoEntrancePanels > 0) {
      items[GTVBCIndex].quantity = 1;
    }
  } else {
    // Expanded System
    items[GTBCXBIndex].quantity = 1;
    if (totalVideoEntrancePanels > 0) {
      items[GTVBXIndex].quantity = 1;
    }

    // Common 1
    if (
      items[GTMKBNIndex].quantity + items[GTMCXIndex].quantity > 0 ||
      totalEntrancePanels + items[GTMCXIndex].quantity > 0
    ) {
      items[GTBCIndex].quantity += 1;
    }

    // Common 2
    if (
      items[GTMKBNIndex].quantity + items[GTMCXIndex].quantity > 2 ||
      totalEntrancePanels + items[GTMCXIndex].quantity > 8
    ) {
      items[GTBCIndex].quantity += 1;
    }

    // Sub 1 (Guard)
    if (items[GTMKBNIndex].quantity + items[GTMCXIndex].quantity > 0) {
      items[GTVBCIndex].quantity += 1;
      items[PS2420ULIndex].quantity += 1;
    }

    // Sub 2 (Guard)
    if (items[GTMKBNIndex].quantity + items[GTMCXIndex].quantity > 2) {
      items[GTVBCIndex].quantity += 1;
      items[PS2420ULIndex].quantity += 1;
    }

    // Sub 1 and 2 (Tenant)
    items[GTBCIndex].quantity += Math.ceil(totalTenantStations / 125);
    if (totalVideoEntrancePanels > 0) {
      items[GTVBCIndex].quantity += Math.ceil(totalTenantStations / 125);
    }

    // Lift Controller
    items[GTBCIndex].quantity += items[GTWLCIndex].quantity;
  }

  // Signal Distributors
  items[GTWDPIndex].quantity += items[GTBCIndex].quantity;

  // Power Supplies final update
  items[PS2420ULIndex].quantity += items[GTBCIndex].quantity;
  items[PS2420ULIndex].quantity += items[GTDMBNIndex].quantity;

  // GT-NSB module requires a power supply. Other modules do not require power supplies.
  items[PS2420ULIndex].quantity += items[GTNSBIndex].quantity;

  items[PS2420ULIndex].quantity += items[GTMKBNIndex].quantity;

  // Video Distributors
  if (totalVideoEntrancePanels > 0) {
    items[GT4ZPIndex].quantity = Math.ceil(items[GT1C7WIndex].quantity / 4);
    items[GT4ZIndex].quantity = Math.ceil((totalVideoTenantStations - items[GT1C7WIndex].quantity) / 4);
    items[PS2420ULIndex].quantity += items[GT4ZPIndex].quantity;
  }

  // Din rails
  items[WDIN11Index].quantity += items[GTBCIndex].quantity;
  items[WDIN11Index].quantity += items[GTVBCIndex].quantity;
  items[WDIN11Index].quantity += items[GT4ZIndex].quantity;
  items[WDIN11Index].quantity += items[GT4ZPIndex].quantity;

  // Reset items not to spec in
  items[numberOfVideoModuleIndex].quantity = 0;
  items[numberOfAudioModuleIndex].quantity = 0;
  items[numberOfTenantIndex].quantity = 0;
  items[VideoModuleKeypadIndex].quantity = 0;
  items[VideoModuleNFCIndex].quantity = 0;
  items[VideoModuleRainhoodIndex].quantity = 0;
  items[AudioModuleKeypadIndex].quantity = 0;
  items[AudioModuleNFCIndex].quantity = 0;
  items[AudioModuleRainhoodIndex].quantity = 0;
};

const updateWiresGT = (values: FormValues) => {
  const numberOfVideoEntrances =
    values.items
      .filter((item) => item.itemName === 'GT-DMB-N')
      ?.reduce((total: number, item) => total + item.quantity, 0) +
    values.items.filter((item) => item.itemName === 'GT-VB')?.reduce((total: number, item) => total + item.quantity, 0);
  const numberOfEntrances =
    values.items
      .filter((item) => item.itemName === 'GT-DMB-N')
      ?.reduce((total: number, item) => total + item.quantity, 0) +
    values.items
      .filter((item) => item.itemName === 'GT-DB')
      ?.reduce((total: number, item) => total + item.quantity, 0) +
    values.items
      .filter((item) => item.itemName === 'GT-DB-VN')
      ?.reduce((total: number, item) => total + item.quantity, 0);
  const numberOfGuards = values.items
    .filter((item) => item.itemName === 'GT-MKB-N')
    ?.reduce((total: number, item) => total + item.quantity, 0);

  // Audio System
  if (numberOfVideoEntrances === 0) {
    if (numberOfEntrances > 0) {
      values.wires.push({
        system: 'Entrance(s) to GT-BC',
        wire: '872002 for Audio signal',
        description: "2 Cond., 20AWG, Solid, Non-shielded, PE Ins., Available in: 500' & 1000'",
        maxDistance: "980'",
        jacketNomOD: 'PVC .134"',
        capacitanceLoopRes: '20.96 pf/ft, 10.15 Ohms'
      });
    } else if (numberOfGuards > 0) {
      values.wires.push({
        system: 'Guard(s) to GT-BC',
        wire: '872002 for Audio signal',
        description: "2 Cond., 20AWG, Solid, Non-shielded, PE Ins., Available in: 500' & 1000'",
        maxDistance: "490'",
        jacketNomOD: 'PVC .134"',
        capacitanceLoopRes: '20.96 pf/ft, 10.15 Ohms'
      });
    }

    values.wires.push(
      {
        system: 'GT-BC to farthest apartment',
        wire: '872002 for Audio signal',
        description: "2 Cond., 20AWG, Solid, Non-shielded, PE Ins., Available in: 500' & 1000'",
        maxDistance: "980'",
        jacketNomOD: 'PVC .134"',
        capacitanceLoopRes: '20.96 pf/ft, 10.15 Ohms'
      },
      {
        system: 'GT-BC to PS-2420UL',
        wire: '872002 for Audio signal',
        description: "2 Cond., 20AWG, Solid, Non-shielded, PE Ins., Available in: 500' & 1000'",
        maxDistance: "490'",
        jacketNomOD: 'PVC .134"',
        capacitanceLoopRes: '20.96 pf/ft, 10.15 Ohms'
      }
    );
  }
  // Video System
  else {
    values.wires.push(
      {
        system: 'Entrance(s) to GT-BC',
        wire: '872002 for Audio signal',
        description: "2 Cond., 20AWG, Solid, Non-shielded, PE Ins., Available in: 500' & 1000'",
        maxDistance: "980'",
        jacketNomOD: 'PVC .134"',
        capacitanceLoopRes: '20.96 pf/ft, 10.15 Ohms'
      },
      {
        system: 'Entrance(s) to GT-VBC',
        wire: '871802 for Video signal',
        description: "2 Cond., 18AWG, Solid, Non-shielded, PE Ins., Low-cap, Available in: 500' & 1000'",
        maxDistance: "980'",
        jacketNomOD: 'PVC .152"',
        capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
      },
      {
        system: 'GT-BC to Farthest Tenant',
        wire: '872002 for Audio signal',
        description: "2 Cond., 20AWG, Solid, Non-shielded, PE Ins., Available in: 500' & 1000'",
        maxDistance: "490'",
        jacketNomOD: 'PVC .134"',
        capacitanceLoopRes: '20.96 pf/ft, 10.15 Ohms'
      },
      {
        system: 'GT-VBC to Farthest Tenant',
        wire: '871802 for Video signal',
        description: "2 Cond., 18AWG, Solid, Non-shielded, PE Ins., Low-cap, Available in: 500' & 1000'",
        maxDistance: "490'",
        jacketNomOD: 'PVC .152"',
        capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
      },
      {
        system: 'GT-BC to PS-2420UL',
        wire: '871802',
        description: "2 Cond., 18AWG, Solid, Non-shielded, PE Ins., Low-cap, Available in: 500' & 1000'",
        maxDistance: "16'",
        jacketNomOD: 'PVC .152"',
        capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
      },
      {
        system: 'GT-VBC to PS-2420UL',
        wire: '871802',
        description: "2 Cond., 18AWG, Solid, Non-shielded, PE Ins., Low-cap, Available in: 500' & 1000'",
        maxDistance: "16'",
        jacketNomOD: 'PVC .152"',
        capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
      }
    );
  }

  // Accessories
  if (
    values.items
      .filter((item) => item.itemName === 'GT-VBX')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push(
      {
        system: 'GT-BCXB-N to GT-VBX',
        wire: 'N/A',
        description: 'Pre-wired ribbon cable; supplied with GT-BCXB-N; Note: Units must be adjacent.',
        maxDistance: '-',
        jacketNomOD: 'N/A',
        capacitanceLoopRes: 'N/A'
      },
      {
        system: 'GT-VBX to GT-VBC',
        wire: '871802',
        description: "2 Cond., 18AWG, Solid, Non-shielded, PE Ins., Low-cap, Available in: 500' & 1000'",
        maxDistance: "490'",
        jacketNomOD: 'PVC .152"',
        capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
      }
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'GT-BCXB-N')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'GT-BCXB-N to farthest Tenant Station (Sub Trunk; includes GT-4Z)',
      wire: '872002',
      description: "2 Cond., 20AWG, Solid, Non-shielded, PE Ins., Available in: 500' & 1000'",
      maxDistance: "980'",
      jacketNomOD: 'PVC .134"',
      capacitanceLoopRes: '20.96 pf/ft, 10.15 Ohms'
    });
  }

  if (
    values.items
      .filter((item) => item.itemName === 'GT-VOA')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'GTW-VOA to GT-VBC',
      wire: '871802',
      description: "2 Cond., 18AWG, Solid, Non-shielded, PE Ins., Low-cap, Available in: 500' & 1000'",
      maxDistance: "490'",
      jacketNomOD: 'PVC .152"',
      capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
    });
  }

  if (
    values.items
      .filter((item) => item.itemName === 'GT-MCX')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push(
      {
        system: 'GT-MCX to GT-BC',
        wire: '871802',
        description: "2 Cond., 18AWG, Solid, Non-shielded, PE Ins., Low-cap, Available in: 500' & 1000'",
        maxDistance: "490'",
        jacketNomOD: 'PVC .152"',
        capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
      },
      {
        system: 'GT-MCX to PS-2420UL',
        wire: '871802',
        description: "2 Cond., 18AWG, Solid, Non-shielded, PE Ins., Low-cap, Available in: 500' & 1000'",
        maxDistance: "490'",
        jacketNomOD: 'PVC .152"',
        capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
      },
      {
        system: 'GT-MCX to Network Drop',
        wire: 'CAT-5e/6',
        description: 'UTP4 non-shielded Category 5e/6 cable (Not Available from Aiphone)',
        maxDistance: 'Per Spec',
        jacketNomOD: 'Per Spec',
        capacitanceLoopRes: 'Per Spec'
      }
    );
  }
};

const updateNotesGT = (values: FormValues) => {
  values.notes.push(
    'The entrance panel(s) provides Form-C dry contacts for door release (normally open or normally closed). The door where communication is established will be released. Door release mechanisms and power for them are not included in this quote.'
  );
  values.notes.push(
    'The door release contact is adjustable on each entrance panel from a momentary to a 20 second closure.'
  );
  values.notes.push(
    'One GTW-DP has been added to your system for every GT-BC bus control unit. The GTW-DP is a distribution point which, while not required, will increase installation speed and efficiency.'
  );

  if (
    values.items
      .filter((item) => item.itemName === 'GT-MKB-N')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push('The GT-MKB-N can be wall or desk mounted without additional parts.');
  }

  if (
    values.items
      .filter((item) => item.itemName === 'GT-4ZP')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      "One GT-4ZP has been added to your system for every 4 tenant locations, and is required for homerunning groups of 4 GT-1C7W stations. Alternate installation method is to loop wire up to 25 tenant stations per trunk line from the GT-VBC with a PS-2420UL wired to each station. Audio line (R1/R2) does not have to be terminated at GT-4ZP's but can be t-tapped to minimize total wire distance. Observe distance specifications in both cases."
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'GT-4Z')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      "One GT-4Z has been added to your system for every 4 tenant locations, and is recommended for homerunning groups of 4 tenant stations. Alternate installation method is to loop wire up to 25 tenant stations per trunk line from the GT-VBC. Audio line (R1/R2) does not have to be terminated at GT-4Z's but can be t-tapped to minimize total wire distance. Observe distance specifications in both cases."
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'GT-MCX')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'One GT-MCX has been added to your equipment list to allow the tenant directory to be programmed remotely.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'GTW-LC')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      "One GTW-LC has been added to your system and provides up to 20 individually programmable contacts. Up to 16 GTW-LC's can be used in a single system. Adjust quantity of GTW-LC adaptors depending on your system requirements."
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'W-DIN11')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'One W-DIN11 mounting rail has been added for each of your adaptors which do not already include them. If adaptors will be shelf mounted, remove that quantity from this equipment list.'
    );
  }
};
