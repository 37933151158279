import { getString } from 'shared/utils/LocalizationUtils';

/* Recording tab stub */
export const RecordingLabel = () => {
  const recordingLabel = getString('Recording');
  return <span>{recordingLabel}</span>;
};

const Recording = () => {
  const recordingLabel = getString('Recording');
  return <span>{recordingLabel}</span>;
};

export default Recording;
