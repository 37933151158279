import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { RootState } from 'store';

interface IUnitsControlPanelProps {
  setIsOpen: (isOpen: boolean) => void;
}

const UnitsControlPanel = ({ setIsOpen }: IUnitsControlPanelProps) => {
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const { isAllowedTo } = usePermission();
  const canUnitCreate = isAllowedTo('unit:create', sitePublicId, PermissionsContextType.SITE);

  const handleAdd = () => {
    setIsOpen(true);
  };

  const strings = {
    title: 'Units',
    description: 'Add, edit, and delete units for your site.'
  };

  return (
    <>
      <SnackbarAlert
        type="success"
        time={7000}
        text={`${successMessage}`}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage('')}
      />
      <SnackbarAlert
        type="error"
        time={7000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
      {canUnitCreate && (
        <Box style={styles.unitsControlPanelWrapper}>
          <Box sx={styles.descriptionWrapper}>
            <Box sx={styles.title}>{strings.title}</Box>
            <Box sx={styles.description}> {strings.description}</Box>
          </Box>
          <Box style={styles.buttonsWrapper}>
            <LoadingButton variant="contained" onClick={handleAdd}>
              Add a unit
            </LoadingButton>
          </Box>
        </Box>
      )}
    </>
  );
};

const styles = {
  unitsControlPanelWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  buttonsWrapper: {
    display: 'flex',
    width: '50%',
    justifyContent: 'end'
  }
};

export default UnitsControlPanel;
