import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSite } from 'store/slices/siteSlice';
import { getSelectedDevice, setDeviceBasicInfo } from 'store/slices/devicesSlice';
import { useHandleGatewayCommandMutation, useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import StringUtils from 'shared/utils/StringUtils';
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  Autocomplete,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  CardHeader,
  Divider,
  ListItemIcon
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UpdateIcon from '@mui/icons-material/Update';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useParams } from 'react-router-dom';
import { checkFirmwareSeries, checkCurrentFirmwareStatus, capitalizeFirstLetter } from 'shared/utils/firmwareFunctions';
import { FirmwareWarning } from './FirmwareWarning';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import Initialization from './Initialization';
import Tools from './Tools';
import DeleteDeviceSection from './DeleteDeviceSection';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useTranslation } from 'react-i18next';
import { getGWErrorCode } from 'shared/rmGateway/gwErrorHandler';

interface FirmwareWarningProps {
  description: string;
  solution: string;
  link: string;
  steps: string[];
}
interface DropDownList {
  value: string;
  label: string;
}
interface standardFirmwareList {
  name: string;
  version: number;
  updateLog: string[];
}
interface latestFirmwareList {
  [key: string]: {
    standard: standardFirmwareList;
    enhanced?: standardFirmwareList;
    dev?: standardFirmwareList;
    warningLog?: FirmwareWarningProps;
  };
}

export const FirmwareUpdateLabel = () => {
  const { t } = useTranslation();

  const firmwareUpdate = t('Firmware_Update');
  return <span>{firmwareUpdate}</span>;
};

const firmwareUrl = 'https://openapi.prod.aiphone.cloud' + '/firmware/download/';
const latestFirmwareUrl = 'https://openapi.prod.aiphone.cloud' + '/firmware/latest';
const firmwareInfoUrl = 'https://www.aiphone.com/kbtopic/firmware-ix-ixg';

const FirmwareUpdate = () => {
  const { t } = useTranslation();
  const hostname = window.location.hostname;
  const isProductionAccount = !['preprod', 'beta', 'localhost'].some((substring) => hostname.includes(substring));

  const { deviceid } = useParams<{ deviceid: string }>();
  const site = useSelector(getSite);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  let selectedDevice = useSelector(getSelectedDevice);
  if (deviceid !== undefined) {
    selectedDevice = useSelector(getSelectedDevice) ?? deviceList[deviceid];
  }

  // Check if the user has permission to edit the site
  const sitePublicId = site?.siteInfo?.publicId;
  const { isAllowedTo } = usePermission();
  const hasEditPermission = isAllowedTo('site:edit', sitePublicId, PermissionsContextType.SITE);

  const defaultOption = [{ value: t('Loading'), label: t('Loading') }];
  const currentFirmware = selectedDevice?.basicInfo?.firmwareVersion || null;
  const currentFirmwareSeries = checkFirmwareSeries(currentFirmware) || t('Checking');

  const [latestFirmwareList, setLatestFirmwareList] = useState<{ [key: string]: any }>({});
  const [firmwareWarningLog, setFirmwareWarningLog] = useState<FirmwareWarningProps | null>(null);
  const [firmwareSeriesOptions, setFirmwareSeriesOptions] = useState<DropDownList[] | undefined>(defaultOption);
  const [selectedFirmwareModel, setSelectedFirmwareModel] = useState('');
  const [isTGW, setIsTGW] = useState(false);
  const [firmwareStatus, setFirmwareStatus] = useState('');
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const [updateDevice] = useUpdateDeviceMutation();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedFirmwareSeries, setSelectedFirmwareSeries] = useState(currentFirmwareSeries || t('Loading'));
  const [availableFirmware, setAvailableFirmware] = useState('');
  const [firmwareLog, setFirmwareLog] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const getDeviceModel = (deviceType: string) => {
    let deviceModel = deviceType;
    switch (true) {
      case /^IX-DVF.*/.test(deviceModel):
        deviceModel = 'IX-DV';
        break;
      case /^IX-RS-.*/.test(deviceModel):
        deviceModel = 'IX-RS';
        break;
      case /^IX-MV7-.*/.test(deviceModel):
        deviceModel = 'IX-MV7';
        break;
      case /^IX-SSA-.*/.test(deviceModel):
        deviceModel = 'IX-SSA';
        break;
      case /^IXW-MA.*/.test(deviceModel):
        deviceModel = 'IXW-MA';
        break;
      case /^IXGW-LC.*/.test(deviceModel):
        deviceModel = 'IXGW-LC';
        break;
      case /^IXG-DM7.*/.test(deviceModel):
        deviceModel = 'IXG-DM7';
        break;
      case /^IXG-2C7.*/.test(deviceModel):
        deviceModel = 'IXG-2C7';
        break;
      case deviceModel === 'IXGW-GW' || deviceModel === 'IXGW-TGW':
        deviceModel = 'IXGW-GW';
        break;
      default:
        break;
    }
    return deviceModel;
  };

  /** update available download based on user choice of firmware series */
  const checkFirmwareVersion = async () => {
    // if latestFirmwareList is empty then return
    if (Object.keys(latestFirmwareList).length === 0) return;

    const deviceModel =
      getDeviceModelNumberFromModelType(selectedDevice?.basicInfo.deviceModel, selectedDevice?.basicInfo?.deviceType) ||
      '';
    setIsTGW(deviceModel === 'IXGW-TGW');
    const deviceModelForFirmWare = getDeviceModel(deviceModel);
    setSelectedFirmwareModel(deviceModelForFirmWare);
    if (deviceModelForFirmWare === 'IXGW-GW') {
      if (selectedFirmwareSeries === 'dev') {
        const fwVersion = Number(latestFirmwareList[deviceModelForFirmWare]?.dev?.version / 100).toFixed(2);
        setAvailableFirmware(fwVersion);
        setFirmwareLog(latestFirmwareList[deviceModelForFirmWare]?.dev?.updateLog);
      } else {
        /** For Production: The firmware is IXGW-GW_V380.bin */
        const enhancedFirmware = latestFirmwareList[deviceModelForFirmWare]?.enhanced;
        if (enhancedFirmware) {
          const fwVersion = Number(enhancedFirmware.version / 100).toFixed(2);
          setAvailableFirmware(fwVersion);
          setFirmwareLog(enhancedFirmware.updateLog);
        }
      }
    } else {
      if (selectedFirmwareSeries === 'standard') {
        const fwVersion = Number(latestFirmwareList[deviceModelForFirmWare]?.standard?.version / 100).toFixed(2);
        setAvailableFirmware(fwVersion);
        setFirmwareLog(latestFirmwareList[deviceModelForFirmWare]?.standard?.updateLog);
      } else if (selectedFirmwareSeries === 'enhanced') {
        const enhancedFirmware = latestFirmwareList[deviceModelForFirmWare]?.enhanced;
        if (enhancedFirmware) {
          const fwVersion = Number(enhancedFirmware.version / 100).toFixed(2);
          setAvailableFirmware(fwVersion);
          setFirmwareLog(enhancedFirmware.updateLog);
        }
      }
    }
    await fetch(latestFirmwareUrl);
  };

  /** if the device model is IXGW-GW, check if the production account */

  const fetchDropDownList = (deviceModel: string, latestVersion: latestFirmwareList) => {
    if (!deviceModel || !latestVersion || !latestVersion[deviceModel]) return;
    let dropDownOptions;

    if (deviceModel === 'IXGW-GW' && !isProductionAccount) {
      return [{ value: 'dev', label: 'Dev Enhanced' }];
    }

    if (isProductionAccount && deviceModel === 'IXGW-GW') {
      setFirmwareSeriesOptions([{ value: 'enhanced', label: 'Enhanced' }]);
    } else {
      const dropDownList = Object.keys(latestVersion[deviceModel]);
      dropDownOptions = dropDownList.map((item) => ({
        value: item,
        label: item.charAt(0).toUpperCase() + item.slice(1)
      }));
    }
    return dropDownOptions;
  };

  const fetchFirmwareUpdateResult = async (gatewayInfo, deviceInfo) => {
    const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.FIRMWARE_UPDATE, gatewayInfo, deviceInfo, null);
    try {
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
      return fetchResponse;
    } catch (error) {
      return null;
    }
  };

  // NOTE: time measure in ioT core for IXGW-GW is about 5 minutes
  /**
   * Initial fetch will happen after 30 seconds for any error message
   * Then wait for 5 mins for any devices (This also includes IXG-TGW that doesn't use SIM)
   * If the device is IXGW-TGW (use SIM CARD),  wait for 15 minutes before retrying
   * Add a retry mechanism for 3 times with a delay of 30 seconds
   * @param gatewayInfo
   * @param deviceInfo
   * @param retry
   * @param delay
   * @returns
   */
  const fetchWithRetry = async (gatewayInfo, deviceInfo, retry = 3, delay = 300000) => {
    // initial fetch for instant error message like 404 or wrong id and password
    let fetchResponse = await fetchFirmwareUpdateResult(gatewayInfo, deviceInfo);

    if (!fetchResponse) {
      // all the devices will wait for 5 minutes before retrying
      await new Promise((resolve) => setTimeout(resolve, 300000));
      fetchResponse = await fetchFirmwareUpdateResult(gatewayInfo, deviceInfo);

      // if the device is IXGW-TGW, will wait for another 15 minutes before retrying

      if (isTGW && !fetchResponse) {
        delay = 900000;
        await new Promise((resolve) => setTimeout(resolve, delay));
        fetchResponse = await fetchFirmwareUpdateResult(gatewayInfo, deviceInfo);
      }

      // if the fetchResponse is still null, retry fetching every 30 seconds
      for (let i = 0; i < retry; i++) {
        if (fetchResponse) {
          break;
        }
        delay = 30000;
        await new Promise((resolve) => setTimeout(resolve, delay));
        fetchResponse = await fetchFirmwareUpdateResult(gatewayInfo, deviceInfo);
      }
    }
    return fetchResponse;
  };

  /** Determine if the current firmware version requires an update based on the available firmware list. */
  useEffect(() => {
    (async () => {
      const firmwareVersionCheck = await fetch(latestFirmwareUrl);
      const latestVersion = await firmwareVersionCheck.json();
      setLatestFirmwareList(latestVersion);
      if (selectedDevice) {
        // check if the device have the firmware version
        if (!currentFirmware) {
          setFirmwareStatus('N/A');
          return;
        }
        const getStatus = checkCurrentFirmwareStatus(selectedDevice, latestVersion);

        if (getStatus) {
          setFirmwareStatus(getStatus);
        }

        let deviceModel: string =
          getDeviceModelNumberFromModelType(
            selectedDevice.basicInfo.deviceModel,
            selectedDevice.basicInfo.deviceType
          ) ?? '';
        deviceModel = getDeviceModel(deviceModel);

        /** drop down menu based on the API call list of latest firmware available */
        const dropDownList = fetchDropDownList(deviceModel, latestVersion);
        setFirmwareSeriesOptions(dropDownList);

        // check for warning message from the firmware check
        if (latestVersion[deviceModel]?.standard?.warningLog) {
          setFirmwareWarningLog(latestVersion[deviceModel]?.standard?.warningLog);
        }
      }
    })();
  }, [selectedDevice]);

  useEffect(() => {
    checkFirmwareVersion();
  }, [selectedFirmwareSeries, latestFirmwareList]);

  const handleUpdateFirmwareButton = async () => {
    // Fetch the latest firmware version
    const firmwareVersionCheck = await fetch(latestFirmwareUrl);
    const latestVersion = await firmwareVersionCheck.json();

    setIsLoading(true);
    let latestFirmwareName, deviceFirmwareUrl;
    if (selectedFirmwareSeries === 'enhanced') {
      latestFirmwareName = latestVersion[selectedFirmwareModel]?.enhanced.name;
      deviceFirmwareUrl = firmwareUrl + latestVersion[selectedFirmwareModel]?.enhanced.name;
    } else if (selectedFirmwareSeries === 'dev') {
      latestFirmwareName = latestVersion[selectedFirmwareModel]?.dev.name;
      deviceFirmwareUrl = firmwareUrl + latestVersion[selectedFirmwareModel]?.dev.name;
    } else {
      latestFirmwareName = latestVersion[selectedFirmwareModel]?.standard.name;
      deviceFirmwareUrl = firmwareUrl + latestVersion[selectedFirmwareModel]?.standard.name;
    }
    const downloadResponse = await fetch(deviceFirmwareUrl);
    const isDeviceFirstSync = selectedDevice.lastSyncedOn === null;

    const url = await downloadResponse.text();
    const systemId = site.siteInfo.systemId;
    const systemPassword = site.siteInfo.systemPassword;
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : gateway?.lastSyncedOn ? gateway?.basicInfo.adminId : 'admin',
      gwPassword: systemPassword ? systemPassword : gateway?.lastSyncedOn ? gateway?.basicInfo.adminPass : 'admin'
    };
    const deviceInfo = {
      deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
      deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
      deviceType: selectedDevice?.basicInfo.deviceType,
      deviceId: systemId ? systemId : isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
      devicePassword: systemPassword
        ? systemPassword
        : isDeviceFirstSync
        ? 'admin'
        : selectedDevice?.basicInfo.adminPass,
      deviceFirmwareFileName: latestFirmwareName,
      deviceFirmwareLink: url
    };

    try {
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.FIRMWARE_UPDATE, gatewayInfo, deviceInfo, null);
      await handleGatewayCommand(ioTPayload).unwrap();

      // Check the status of the firmware update after 30 seconds
      await new Promise((resolve) => setTimeout(resolve, 30000));
      let fetchResponse = await fetchWithRetry(gatewayInfo, deviceInfo);

      // 206 status code means the firmware update is in progress
      if (fetchResponse?.statusCode.includes('206')) {
        fetchResponse = await fetchWithRetry(gatewayInfo, deviceInfo);
      }

      // Check the payload for the ip address to see if firmware update was successful
      if (fetchResponse?.payload[0].statusCode.includes('200')) {
        setShowAlert(true);
        // update the device firmware version in redux and the database
        const updateDevicePayload = {
          publicId: selectedDevice.publicId,
          basicInfo: {
            ...selectedDevice.basicInfo,
            firmwareVersion: availableFirmware
          }
        };
        await updateDevice({ device: updateDevicePayload });
        dispatch(setDeviceBasicInfo(updateDevicePayload));
        setIsLoading(false);
      } else {
        throw new Error('Failed to update firmware for device');
      }
    } catch (error) {
      setErrorMessage(t(getGWErrorCode({ message: 'Firmware_Update_Error' })));
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ height: '100%', mt: -2 }}>
        <Card elevation={3} sx={styles.paper}>
          <Box sx={{ padding: '10px' }}>
            <Typography variant="h5" color="text.primary" sx={{ paddingBottom: '5px', mt: -2 }}>
              {' '}
              {t('Firmware_Update')}
            </Typography>
            <Typography>
              {t('Firmware_Update_link')}
              <Typography
                component="a"
                href={firmwareInfoUrl}
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {t('Here')}
              </Typography>
            </Typography>
          </Box>
          {!hasEditPermission ? (
            <Box sx={styles.headerWrapper}>
              <Typography color="text.primary">{t('Device_No_Edit_Permission')}</Typography>
            </Box>
          ) : (
            <Box sx={styles.headerWrapper}>
              <Typography sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                {t('Current_Firmware')}
                <Typography variant="h6"> {capitalizeFirstLetter(firmwareStatus)}</Typography>
              </Typography>

              <Typography>
                {currentFirmware} ({capitalizeFirstLetter(currentFirmwareSeries)})
              </Typography>
            </Box>
          )}

          {currentFirmware ? (
            <CardContent sx={styles.firmwareMainWrapper}>
              <Box sx={styles.firmwareSeriesWrapper}>
                <Box sx={styles.descriptionWrapper}>
                  <Typography variant="h6" sx={{ mt: -4 }}>
                    {t('Firmware_Series')}
                  </Typography>
                  <Typography variant="body1">
                    {selectedFirmwareSeries
                      ? t('You_selected_firmware_series', { series: selectedFirmwareSeries })
                      : t('Select_firmware_series')}
                  </Typography>
                </Box>
                <Box sx={styles.dropDownWrapper}>
                  <Autocomplete
                    value={firmwareSeriesOptions?.find((option) => option.value === selectedFirmwareSeries) || null}
                    options={firmwareSeriesOptions || []}
                    sx={{ width: '100%', mt: -4 }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedFirmwareSeries(newValue.value);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} label={t('Firmware_Series')} />}
                  />
                </Box>
              </Box>

              {availableFirmware && (
                <Box>
                  <Box sx={styles.availableFirmwareWrapper}>
                    <Box sx={styles.descriptionWrapper2}>
                      <Typography variant="body1">
                        {t('Latest_Firmware_Version_Available', { series: selectedFirmwareSeries })}
                      </Typography>
                      <Typography variant="h6"> {availableFirmware}</Typography>
                    </Box>
                    <Box sx={styles.updateButtonWrapper}>
                      <LoadingButton
                        loading={isLoading}
                        loadingIndicator={<CircularProgress size="20px" color="info" />}
                        onClick={handleUpdateFirmwareButton}
                        disabled={!hasEditPermission}
                      >
                        <UpdateIcon />
                        {t('Update_Firmware')}
                      </LoadingButton>
                    </Box>
                  </Box>

                  {isTGW && (
                    <Box sx={styles.tgwWrapper}>
                      <Typography color="text.primary">{t('Gateway_Firmware_Update_TGW')}</Typography>
                    </Box>
                  )}

                  <Card sx={styles.firmwareLogWrapper}>
                    <CardHeader title={t('Firmware_Update_Log')} />
                    <Divider />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <List>
                        {firmwareLog?.map((log, index) => (
                          <ListItem key={index}>
                            <ListItemIcon sx={{ minWidth: '20px', marginRight: '4px' }}>
                              <RadioButtonUncheckedIcon sx={{ fontSize: '12px' }} />
                            </ListItemIcon>
                            <ListItemText primary={log} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Card>
                  {firmwareWarningLog && <FirmwareWarning firmwareWarningLog={firmwareWarningLog} />}
                </Box>
              )}
            </CardContent>
          ) : (
            <Card>
              <CardContent>
                <Typography variant="body1">{t('No_Firmware_available')}</Typography>
              </CardContent>
            </Card>
          )}
          <Initialization hasEditPermission={hasEditPermission} />
          <DeleteDeviceSection hasEditPermission={hasEditPermission} />
          <Tools hasEditPermission={hasEditPermission} />
        </Card>
      </Box>
      <SnackbarAlert
        type="error"
        time={10000}
        text={errorMessage || ''}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={StringUtils.format(t('Update_Success_Firmware'), t('Site'))}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  paper: {
    padding: '20px',
    height: '100%'
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#d1d1d9',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '5px',
    marginY: '10px',
    mt: -1
  },
  firmwareMainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    border: '1px solid #ccc',
    borderRadius: '5px',
    overflow: 'auto',
    height: '100%',
    boxSizing: 'border-box',
    paddingBottom: '10px',
    minHeight: '500px',
    overflowY: 'auto'
  },
  dropDownWrapper: {
    padding: '1rem',
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  firmwareSeriesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px'
  },
  firmwareLogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px',
    padding: '10px'
  },
  tgwWrapper: {
    margin: '20px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px'
  },
  descriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    gap: '5px'
  },
  availableFirmwareWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '5px'
  },
  descriptionWrapper2: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '5px',
    gap: '5px',
    alignItems: 'center',
    mt: -2
  },
  updateButtonWrapper: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    alignItems: 'flex-end',
    position: 'relative'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end'
  },
  buttonGWContainer: {
    display: 'flex',
    justifyContent: 'end',
    padding: '1rem'
  }
};

export default FirmwareUpdate;
