import { Box, Container, Button, Alert } from '@mui/material';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useState } from 'react';
import DeleteSiteDialog from './Dialogs/DeleteSiteDialog';
import { useTranslation } from 'react-i18next';
import { getSite } from 'store/slices/siteSlice';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const SiteSettings = () => {
  const { t } = useTranslation();
  const publicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const site = useSelector(getSite);
  const navigate = useNavigate();

  const { isAllowedTo } = usePermission();
  const canDeleteSite = isAllowedTo('site:delete', publicId, PermissionsContextType.SITE);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" color="text.primary" sx={{ paddingBottom: '5px' }}>
        {t('Site_Settings')}
      </Typography>
      <Box sx={styles.gwContainer}>
        <Box sx={styles.buttonGWContainer}>
          <Button
            variant="contained"
            disabled={!site.siteInfo.registeredGatewayPublicId}
            onClick={() =>
              navigate(`/site/${site.siteInfo.publicId}/devices/${site.siteInfo.registeredGatewayPublicId}/maintenance`)
            }
          >
            {t('Initialize_Gateway_first')}
          </Button>
        </Box>
      </Box>
      <Typography variant="h5" color="text.primary" sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
        {t('Delete_Site')}
      </Typography>
      <Box sx={styles.gwContainer}>
        <Box sx={styles.buttonGWContainer}>
          <Alert severity="warning">{t('Warning_Delete_Site')}</Alert>
          <br />
          <Button variant="contained" onClick={() => setIsDeleteDialogOpen(true)} disabled={!canDeleteSite}>
            {t('Delete_Site')}
          </Button>
        </Box>
      </Box>
      {/* Delete confirmation dialog */}
      <DeleteSiteDialog isOpen={isDeleteDialogOpen} setIsOpen={setIsDeleteDialogOpen} publicId={publicId} />
    </Container>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  buttonGWContainer: {
    display: 'flex',
    justifyContent: 'end',
    padding: '1rem'
  },
  gwContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '1rem'
  }
};

export default SiteSettings;
