import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';

/**
 * AX Series QuikSpec Categories and items
 *
 */
export const axMasterStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Master Stations',
    description: 'Maximum 8 combined audio only and audio/video master stations.',
    items: [
      { deviceName: 'AX-8MV', maxQuantityAllowedPerItem: 8 },
      { deviceName: 'AX-8M', maxQuantityAllowedPerItem: 8 }
    ],
    maxQuantityAllowedPerCategory: 8
  },
  {
    id: 2,
    title: 'Sub Stations',
    description: 'How many of each of the following style of sub stations does the system require?',
    items: [
      { deviceName: 'AX-A', maxQuantityAllowedPerItem: 120 },
      { deviceName: 'AX-AN', maxQuantityAllowedPerItem: 120 },
      { deviceName: 'AX-B', maxQuantityAllowedPerItem: 120 },
      { deviceName: 'AX-BN', maxQuantityAllowedPerItem: 120 }
    ]
  }
];

export const axDoorStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Audio/Video Door Stations',
    description: '',
    items: [
      { deviceName: 'AX-DV', maxQuantityAllowedPerItem: 120 },
      { deviceName: 'AX-DVF', maxQuantityAllowedPerItem: 120 },
      { deviceName: 'AX-DV-P', maxQuantityAllowedPerItem: 120 },
      { deviceName: 'AX-DVF-P', maxQuantityAllowedPerItem: 120 }
    ]
  },
  {
    id: 2,
    title: 'Audio Only Door Stations',
    description: '',
    items: [
      { deviceName: 'AX-DM', maxQuantityAllowedPerItem: 120 },
      { deviceName: 'IE-JA', maxQuantityAllowedPerItem: 120 },
      { deviceName: 'IF-DA', maxQuantityAllowedPerItem: 120 },
      {
        deviceName: 'IE-SS/A',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-2G/A' }],
        countWithAccessories: true,
        maxQuantityAllowedPerItem: 120
      },
      {
        deviceName: 'IE-SSR',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-2G/A' }],
        countWithAccessories: true,
        maxQuantityAllowedPerItem: 120
      }
    ]
  }
];

export const axAdaptors: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Accessories',
    description: 'Optional Accessories.',
    items: [{ deviceName: 'IER-2', maxQuantityAllowedPerItem: 1 }, { deviceName: 'RY-1824L' }, { deviceName: 'RY-ES' }]
  }
];

export const axHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      { deviceName: 'AX-16SW' },
      { deviceName: 'AX-084C' },
      { deviceName: 'AX-248C' },
      { deviceName: 'AX-320C' },
      { deviceName: 'PS-2420UL' },
      { deviceName: 'SBX-2G/A' }
    ]
  }
];
