/* Schedule Transfer tab stub */
export const ScheduleTransferLabel = () => {
  return <span>Schedule Transfer</span>;
};

const ScheduleTransfer = () => {
  return <span>Schedule Transfer</span>;
};

export default ScheduleTransfer;
