import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';

export const updateFormValuesAX = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesAX(values);
  updateWiresAX(values);
  updateNotesAX(values);
  return values;
};

const updateItemQuantityWithAccessoriesAX = (values: FormValues) => {
  const items = Object.values(values.items);

  // Update quantities of AX system
  const totalMasterStations = items
    .filter((item) => item.category === 'Master Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalSubMasterStations = items
    .filter((item) => item.category === 'Sub Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalVideoDoorStations = items
    .filter((item) => item.category === 'Audio/Video Door Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalAudioDoorStations = items
    .filter((item) => item.category === 'Audio Only Door Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const totalNonMasterStations = totalSubMasterStations + totalVideoDoorStations + totalAudioDoorStations;
  const totalStations = totalMasterStations + totalNonMasterStations;

  // Per every 16 stations, additional speed dials are included
  if (totalStations > 16) {
    const AX16SWIndex = items.findIndex((item) => item.itemName === 'AX-16SW');
    items[AX16SWIndex].quantity = Math.ceil((totalStations - 16) / 16) * totalMasterStations;
  }

  // Central controller determination
  if (totalMasterStations <= 4 && totalNonMasterStations <= 8) {
    const AX084CIndex = items.findIndex((item) => item.itemName === 'AX-084C');
    items[AX084CIndex].quantity = 1;
  } else {
    const AX248CIndex = items.findIndex((item) => item.itemName === 'AX-248C');
    items[AX248CIndex].quantity = 1;
  }
  if (totalNonMasterStations > 24) {
    const AX320CIndex = items.findIndex((item) => item.itemName === 'AX-320C');
    items[AX320CIndex].quantity = Math.ceil((totalNonMasterStations - 24) / 32);
  }

  // Quantity of power supply is always 2
  const PS2420ULIndex = items.findIndex((item) => item.itemName === 'PS-2420UL');
  items[PS2420ULIndex].quantity = 2;
};

const updateWiresAX = (values: FormValues) => {
  values.wires.push(
    {
      system: 'Masters to CCU',
      wire: 'CAT-5e',
      description: 'UTP4 non-shielded Category 5e cable 24AWG (Not Available from Aiphone)',
      maxDistance: "980'",
      jacketNomOD: 'Per Spec',
      capacitanceLoopRes: 'Per Spec'
    },
    {
      system: 'Doors/subs to CCU',
      wire: 'CAT-5e',
      description: 'UTP4 non-shielded Category 5e cable 24AWG (Not Available from Aiphone)',
      maxDistance: "980'",
      jacketNomOD: 'Per Spec',
      capacitanceLoopRes: 'Per Spec'
    },
    {
      system: 'Power to CCU',
      wire: '822202',
      description: '2 Cond.,22AWG, Shielded, Available in: 500’ & 1000’',
      maxDistance: 'N/A',
      jacketNomOD: 'PVC .141"',
      capacitanceLoopRes: '48.2 pf/ft 16.6 Ohms'
    }
  );
};

const updateNotesAX = (values: FormValues) => {
  if (
    values.items
      .filter((item) => item.itemName === 'AX-DV-P')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'AX-DVF-P')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'HID® card reader embedded door stations contain a ProxPoint® Plus reader. Access control system is not supplied by Aiphone, and must be purchased, installed and connected separately. Separate wiring to card reader is also required.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'AX-16SW')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    const totalMasterStations = values.items
      .filter((item) => item.category === 'Master Stations')
      ?.reduce((total: number, item) => total + item.quantity, 0);
    const numberOfSelectorPerMaster =
      values.items
        .filter((item) => item.itemName === 'AX-16SW')
        ?.reduce((total: number, item) => total + item.quantity, 0) / totalMasterStations;
    values.notes.push(
      'Add-on selector quantity is ' +
        numberOfSelectorPerMaster +
        ' AX-16SW for each of ' +
        totalMasterStations +
        ' total master stations. This will allow all masters to communicate to all subs/doors.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-1824L')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The RY-1824L "Form C" relay is specified for selective door release, and has a contact rating of 3A or higher. The CEU is equipped with programmable dry contacts with a contact rating of 24VDC, 500mA. The RY-1824L will prevent the CEU from sustaining power surge damage in the event of excessive current draw from your door release hardware.'
    );
    values.notes.push(
      'Door strikes and power supplies are not included in this quote and must be purchased separately. Not available from Aiphone.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IER-2')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The IER-2 will emit the same tone as the master during an incoming call (on and off ringing). There is a 3-position switch to adjust the call tone volume.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-ES')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The RY-ES will provide a dry contact closure when the call tone sounds at the master station (on and off as the master rings). External device and its power is not provided in this equipment list and is not available from Aiphone.'
    );
  }

  //values.notes.push("CCTV Camera call-up is achieved using RS-232 protocol. Contact Technical Support for additional information on RS-232. Software programming is required, and is not provided by Aiphone.");
  //values.notes.push("Call transfer to CO line requires the use of Viking K-1900-5 adaptor.");
  //values.notes.push("Current RS-232 protocol information must be obtained directly from the Technical Support.");
  //values.notes.push("Aiphone recommends the use of a computer-style headset for communication. The headset plugs directly into the AX series master station. (Headset not available from Aiphone.)");
  //values.notes.push("Aiphone recommends the use of a footswitch with a normally open contact for TALK switch activation. See the AX installation manual for footswitch connection points. (Footswitch not available from Aiphone. Visit www.linemaster.com as a possible source for a footswitch.)");
};
