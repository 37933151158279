import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateGwDeviceDetails } from 'features/SimBilling/Stores/aclSitesSlice';
import { GwDetailsPayloadType } from '../Components/Entrypoints/Gwdevices/GWDevices.types';

const useUpdateSiteDetails = () => {
  const dispatch = useAppDispatch();

  const updateSiteDetailsCallback = useCallback(
    async (details: GwDetailsPayloadType, aclSiteId: number) => {
      await dispatch(updateGwDeviceDetails({ details, aclSiteId }));
    },

    [dispatch]
  );

  return { updateSiteDetailsCallback };
};

export default useUpdateSiteDetails;
