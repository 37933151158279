import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import 'styles/frontshine.css';

interface SpinnerProps {
  onMount?: () => void; // Optional callback prop when the component mounts
  aboveSpinner?: React.ReactNode; // Optional prop to render content above the spinner
  belowSpinner?: React.ReactNode; // Optional prop to render content below the spinner
}

const Spinner = ({ onMount, aboveSpinner, belowSpinner }: SpinnerProps) => {
  useEffect(() => {
    if (onMount) {
      onMount(); // Call the onMount callback if it exists
    }
    // Add any cleanup actions to be performed when the component unmounts here
    // return () => {
    //   // Cleanup actions
    // };
  }, [onMount]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '64px',
        left: 0,
        width: '100vw',
        height: `calc(100vh - 64px)`,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        zIndex: 1500
      }}
    >
      {/* Render the aboveSpinner prop if it exists */}
      {aboveSpinner && <Box className={'mb-2'}>{aboveSpinner}</Box>}
      <CircularProgress size={70} /> {/* Increase the size of the spinner */}
      {/* Render the belowSpinner prop if it exists */}
      {belowSpinner && <Box className={'mt-2'}>{belowSpinner}</Box>}
    </Box>
  );
};

export default Spinner;
