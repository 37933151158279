import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IContactOutput, IContactOutputControl, IDeviceContactInput } from 'store/slices/devicesSlice';

interface IStatusOutputSettingProps {
  selectedRelay: IContactOutput;
  setNeedsSave: (needsSave: boolean) => void;
  newRelaySettings: Array<IContactOutput | IDeviceContactInput>;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
  outputFunction: number;
}

interface IOption {
  title: string;
  options: Array<{ name: string; label: string }>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type DeviceTypeKey = 'doorStationDevices' | 'submasterDevices' | 'masterStationDevices' | 'tenantStationDevices';

const strings = {
  title: 'Status Output',
  description: 'Select one or more conditions during which the output will be activated.'
};

const StatusOutputSetting = ({
  selectedRelay,
  setNeedsSave,
  setNewRelaySettings,
  outputFunction
}: IStatusOutputSettingProps) => {
  const [outputOptions, setOutputOptions] = React.useState<IContactOutputControl>(selectedRelay.control);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const deviceType = deviceList[selectedRelay.devicePublicId].basicInfo.deviceType;

  useEffect(() => {
    setOutputOptions(selectedRelay.control);
  }, [selectedRelay]);

  useEffect(() => {
    setNewRelaySettings((prev) => {
      const newSettingsArray = prev;
      const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);

      const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
      if (index !== -1) {
        if (currentRelaySettings) newSettingsArray[index] = { ...currentRelaySettings, control: outputOptions };
      } else {
        newSettingsArray.push({ ...selectedRelay, control: outputOptions });
      }

      if (JSON.stringify(selectedRelay.control) !== JSON.stringify(outputOptions)) {
        setNeedsSave(true);
      } else {
        setNeedsSave(false);
      }

      return newSettingsArray;
    });
  }, [outputOptions]);

  // Define a reusable component for option groups
  const OptionGroup = ({ title, options }: IOption) => {
    return (
      <Grid item xs={2}>
        <Box sx={styles.optionBox}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">{title}</FormLabel>
            <FormGroup>
              {options.map((option) => (
                <FormControlLabel
                  key={option.name}
                  control={
                    <Checkbox
                      onChange={(event) => handleChange(event)}
                      name={option.name}
                      checked={outputOptions[option.name] || false}
                    />
                  }
                  label={option.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Grid>
    );
  };

  // Define the options for each device type
  const deviceTypeOptions = {
    doorStationDevices: [
      {
        title: 'Outgoing Call',
        options: [
          { name: 'outgoingCallNormal', label: 'Normal' },
          { name: 'outgoingCallPriority', label: 'Priority' },
          { name: 'outgoingCallUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Incoming Call',
        options: [
          { name: 'incomingCallNormal', label: 'Normal' },
          { name: 'incomingCallPriority', label: 'Priority' },
          { name: 'incomingCallUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Communication',
        options: [
          { name: 'duringCallNormal', label: 'Normal' },
          { name: 'duringCallPriority', label: 'Priority' },
          { name: 'duringCallUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Incoming Page',
        options: [
          { name: 'incomingPageNormal', label: 'Normal' },
          { name: 'incomingPageUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Monitored',
        options: [{ name: 'monitoredNormal', label: 'Normal' }]
      }
    ],
    submasterDevices: [
      {
        title: 'Outgoing Call',
        options: [
          { name: 'outgoingCallNormal', label: 'Normal' },
          { name: 'outgoingCallPriority', label: 'Priority' },
          { name: 'outgoingCallUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Incoming Call',
        options: [
          { name: 'incomingCallNormal', label: 'Normal' },
          { name: 'incomingCallPriority', label: 'Priority' },
          { name: 'incomingCallUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Communication',
        options: [
          { name: 'duringCallNormal', label: 'Normal' },
          { name: 'duringCallPriority', label: 'Priority' },
          { name: 'duringCallUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Incoming Page',
        options: [
          { name: 'incomingPageNormal', label: 'Normal' },
          { name: 'incomingPageUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Monitored',
        options: [{ name: 'monitoredNormal', label: 'Normal' }]
      }
    ],
    masterStationDevices: [
      {
        title: 'Outgoing Call',
        options: [
          { name: 'outgoingCallNormal', label: 'Normal' },
          { name: 'outgoingCallPriority', label: 'Priority' },
          { name: 'outgoingCallUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Incoming Call',
        options: [
          { name: 'incomingCallNormal', label: 'Normal' },
          { name: 'incomingCallPriority', label: 'Priority' },
          { name: 'incomingCallUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Communication',
        options: [
          { name: 'duringCallNormal', label: 'Normal' },
          { name: 'duringCallPriority', label: 'Priority' },
          { name: 'duringCallUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Outgoing Page',
        options: [
          { name: 'outgoingPageNormal', label: 'Normal' },
          { name: 'outgoingPageUrgent', label: 'Urgent' }
        ]
      },
      {
        title: 'Incoming Page',
        options: [
          { name: 'incomingPageNormal', label: 'Normal' },
          { name: 'incomingPageUrgent', label: 'Urgent' }
        ]
      }
    ],
    tenantStationDevices: [
      {
        title: 'Incoming Call',
        options: [
          { name: 'incomingCallNormal', label: 'Normal' },
          { name: 'incomingCallPriority', label: 'Priority' },
          { name: 'incomingCallUrgent', label: 'Urgent' }
        ]
      }
    ]
  };

  const generateStatusOutputOptions = (deviceType: number) => {
    const doorStationDevices = [8, 9, 11, 12, 20];
    const submasterDevices = [5];
    const masterStationDevices = [4, 16];
    const tenantStationDevices = [14];

    let optionsKey: DeviceTypeKey = 'doorStationDevices';
    if (doorStationDevices.includes(deviceType)) optionsKey = 'doorStationDevices';
    else if (submasterDevices.includes(deviceType)) optionsKey = 'submasterDevices';
    else if (masterStationDevices.includes(deviceType)) optionsKey = 'masterStationDevices';
    else if (tenantStationDevices.includes(deviceType)) optionsKey = 'tenantStationDevices';

    const optionsToRender = deviceTypeOptions[optionsKey];

    return (
      <>
        {optionsToRender.map((group, index: number) => (
          <OptionGroup
            key={index}
            title={group.title}
            options={group.options}
            onChange={(event) => handleChange(event)}
          />
        ))}
      </>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setOutputOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked
    }));
  };

  if (outputFunction != 2) {
    return null;
  }

  return (
    <>
      <Box sx={styles.settingsWrapper}>
        <Box sx={styles.descriptionWrapper}>
          <Box sx={styles.title}>{strings.title}</Box>
          <Box sx={styles.description}> {strings.description}</Box>
        </Box>
      </Box>
      <Box sx={styles.optionsWrapper}>
        <Grid container spacing={2}>
          {generateStatusOutputOptions(deviceType)}
        </Grid>
      </Box>
    </>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '100%',
    float: 'right'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  optionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  optionBox: {
    display: 'flex'
  }
};

export default StatusOutputSetting;
