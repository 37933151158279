import { Box, CircularProgress, Grid } from '@mui/material';
import SelectedDeviceSettings from './components/selectedDevice/SelectedDeviceSettings';
import ControlPanel from './components/ControlPanel';
import AddNewDevice from './components/addNewDevice/AddNewDevice';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import {
  DevicesState,
  getSelectedDevice,
  IAddressBookEntry,
  IDevice,
  updateSelectedDevice
} from '../../../../store/slices/devicesSlice';
import DialogWindow from './components/DialogWindow';
import { getAllSoundsBySiteId } from '../../../../shared/api/Aws/RemoteManagementApi';
import { ISound } from './types';
import MultiselectControlPanel from './components/selectedDevice/MultiselectControlPanel';
import { useLazyGetDeviceListWithSitePublicIdQuery, useUpdateDeviceMutation } from '../../../../services/aiphoneCloud';
import { IUnit, IUnitList, IUnitState } from 'store/slices/unitsSlice';
import AddedAppUnits from '../callSettings/callDestination/addedAppUnits/AddedAppUnits';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FilterByAddedDevices from '../Components/FilterByAddedDevices';
import AddedDevices from '../Components/AddedDevices';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { IContactGroupList } from 'store/slices/devicesSlice';

/**
 * Naming conventions inside this component:
 * *** targetDevice - The main device into which we will add devices in the address book.
 * *** selectedDevice - The device we want to add to the address book of the main device.
 * *** addressBookDevices - The devices that already exist in the main device's address book.
 * *** selectedDeviceIds - When you click on an added device to make changes (delete/update), this device's ID appears in the selectedDeviceIds array.
 * */
const DoorRelease = () => {
  const devices = useSelector((state: RootState) => state.devices);
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);
  const site = useSelector((state: RootState) => state.site);
  const users = useSelector((state: RootState) => state.users);
  const appGroup = useSelector((state: RootState) => state.apps).AppGroup;
  const units = useSelector((state: RootState) => state.units);
  const selectedDevice = useSelector(getSelectedDevice);
  const [currentDeviceSelected, setCurrentDeviceSelected] = useState<IDevice | null>(null);
  const [_currentAppSelected, setCurrentAppSelected] = useState<IUnit | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [addressBookDevices, setAddressBookDevices] = useState<IDevice[]>([]);
  const [newDeviceModalCondition, setNewDeviceModalCondition] = useState(false);
  const [soundsList, setSoundsList] = useState<ISound[]>([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
  const [selectedAppUnitIds, setSelectedAppUnitIds] = useState<string[]>([]);
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [filteredDevices, setFilteredDevices] = useState<IDevice[]>([]);
  const [addressBookAppUnits, setAddressBookAppUnits] = useState<IUnit[]>([]);
  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();

  const sitePublicId = useParams().id;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');
  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');

  const fetchAppUnits = () => {
    const callGroupKeys = Object.keys(appGroup);
    const unitList = units?.UnitList;
    return callGroupKeys.map((key) => unitList[key]);
  };

  const [updateDevice] = useUpdateDeviceMutation();
  const CONTACT_GROUP_LIMIT = 20;

  const getAllSoundsPayload = {
    siteId: site.siteInfo.publicId,
    userId: users.currentUser?.publicId || ''
  };

  const appUnits = useMemo(() => fetchAppUnits(), [appGroup, units]);

  // Extract the address book devices and app units using the helper function
  useEffect(() => {
    if (selectedDevice) {
      if (selectedDevice.basicInfo.deviceType === 5) {
        const { contactGroupDevices } = extractContactGroupEntries(selectedDevice, devices);
        if (contactGroupDevices) {
          setAddressBookDevices(contactGroupDevices);
          setFilteredDevices(contactGroupDevices);
        }
      } else {
        const addressBookEntries = extractAddressBookEntries(selectedDevice, devices, units);
        if (addressBookEntries) {
          const { addressBookDevicesList, addressBookAppUnitsList } = addressBookEntries;
          setAddressBookDevices(addressBookDevicesList);
          setAddressBookAppUnits(addressBookAppUnitsList);
          setFilteredDevices(addressBookDevicesList); // Initialize filteredDevices to the full list
        }
      }
    }
  }, [selectedDevice, devices, units]);

  useEffect(() => {
    /**
     * TODO: Move the API call to get all sounds outside this component.
     * We use sounds in several areas of advanced settings. We need to get all sounds when opening advanced settings and save them into Redux.
     * */
    //TODO: Once setup uncomment soundList in SelectedDeviceSettings below as that component is using soundsList.
    setupSounds();
  }, []);

  const listOfAdaptors = useMemo(() => {
    return [...deviceListByType.IOAdaptor, ...deviceListByType.GatewayAdaptor];
  }, [deviceListByType]);

  const allowedDevicesForAddressBook = useMemo(() => {
    return Object.values(devices.DeviceList).filter((device) => !listOfAdaptors.includes(device.publicId));
  }, [devices.DeviceList, listOfAdaptors]);

  useEffect(() => {
    if (isSelectAll) {
      setSelectedDeviceIds(addressBookDevices.map((device) => device.publicId));
    } else {
      setSelectedDeviceIds([]);
      setSelectedAppUnitIds([]);
      setCurrentAppSelected(null);
    }
  }, [isSelectAll]);

  useEffect(() => {
    if (selectedDeviceIds?.length === 1) {
      setCurrentDeviceSelected(getDevice(selectedDeviceIds[0]) || null);
    }

    if (selectedAppUnitIds.length === 1) {
      setCurrentAppSelected(getApp(selectedAppUnitIds[0]) || null);
    } else {
      setCurrentAppSelected(null);
    }
  }, [selectedDeviceIds, selectedAppUnitIds]);

  useEffect(() => {
    if (selectedDeviceIds.length !== addressBookDevices.length) {
      setIsSelectAll(false);
    }
  }, [selectedDeviceIds, addressBookDevices.length]);

  /**Helper functions*/
  const getDevice = (deviceId: string | null) => {
    return Object.values(devices?.DeviceList || []).find((device: IDevice) => device?.publicId === deviceId);
  };

  const getApp = (appId: string | null) => {
    return Object.values(appUnits).find((app: IUnit) => app?.publicId === appId);
  };

  const setupSounds = async () => {
    if (!site.siteInfo.publicId || !users.currentUser?.publicId) return;
    const response = await getAllSoundsBySiteId(getAllSoundsPayload);
    const allSounds: ISound[] = response || [];
    setSoundsList(allSounds);
  };

  /** Load the contact group list if the chosen device is subMaster station */
  const extractContactGroupEntries = (selectedDevice: IDevice, devices: DevicesState) => {
    const contactGroupList: IContactGroupList = selectedDevice?.callSettings?.contactGroupList || {};
    const contactGroupKeys = ['6', '7', '8', '9', '10'];
    const contactGroupDevices: IDevice[] = [];
    const addressBookAppUnitsList: IUnit[] = [];

    contactGroupKeys.forEach((key) => {
      const targetList = contactGroupList[key]?.targetList;

      if (targetList.length === 0) return;

      const deviceResult = contactGroupList[key]?.targetList.map((target) => {
        return Object.values(devices.DeviceList).find((device) => device.publicId === target.targetDevicePublicId);
      });
      if (deviceResult.length > 0) {
        contactGroupDevices.push(...deviceResult.filter((device): device is IDevice => device !== undefined));
      }
    });
    setAddressBookDevices(contactGroupDevices);
    setFilteredDevices(contactGroupDevices);

    return { contactGroupDevices, addressBookAppUnitsList };
  };

  /** Load the Address Book List from previously saved data. */
  const extractAddressBookEntries = (selectedDevice: IDevice, devices: DevicesState, units: IUnitState) => {
    if (!selectedDevice) return { addressBookDevicesList: [], addressBookAppUnitsList: [] };

    if (selectedDevice?.basicInfo?.deviceType === 5) {
      extractContactGroupEntries(selectedDevice, devices);
    } else {
      const addressBookDevicesList: IDevice[] = [];
      const addressBookAppUnitsList: IUnit[] = [];

      const addressBookList: IAddressBookEntry[] = selectedDevice?.systemInfo?.addressBook || [];
      addressBookList.forEach((entry) => {
        if (entry.targetDevicePublicId) {
          const deviceResult = Object.values(devices.DeviceList).find(
            (device) => device.publicId === entry.targetDevicePublicId
          );
          if (deviceResult) addressBookDevicesList.push(deviceResult);
        }
        if (entry.targetUnitPublicId) {
          const appUnitResult = Object.values(units.UnitList).find(
            (unit) => unit.publicId === entry.targetUnitPublicId
          );
          if (appUnitResult) addressBookAppUnitsList.push(appUnitResult);
        }
      });
      return { addressBookDevicesList, addressBookAppUnitsList };
    }
  };

  /**Handlers*/
  const handleSelectDevice = useCallback(
    (id: string) => {
      if (isMultiSelect) {
        setSelectedDeviceIds((prev) =>
          prev.includes(id) ? prev.filter((deviceId) => deviceId !== id) : [...prev, id]
        );
      } else {
        setSelectedDeviceIds([id]);
      }
    },
    [isMultiSelect]
  );

  const toggleMultiSelect = () => {
    setIsMultiSelect((prev) => !prev);
    setSelectedDeviceIds([]);
    setIsSelectAll(false);
  };
  const toggleSelectAll = () => {
    setIsSelectAll((prev) => !prev);
  };
  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };
  const closeDeleteDialog = () => {
    selectedDeviceIds.pop();
    setIsDeleteDialogOpen(false);
  };

  /** API request for subMaster stations */
  const saveDevicesToContactGroupUnlockOperationGroup = async (newDevices: IDevice[]) => {
    const newDevice = JSON.parse(JSON.stringify(selectedDevice));
    const currentUnlockOperationGroupList = newDevice?.deviceSettings?.unlockOperationGroupList;
    const currentContactGroupList = newDevice?.callSettings?.contactGroupList || {};

    // filter out the gateway devices
    const FilteredNewDevices = newDevices.filter((device) => device.basicInfo.deviceType !== 18);

    // set the initial contact group key for sub Mater station
    let currentGroupKey = '6';

    for (let i = 0; i < FilteredNewDevices.length; i++) {
      let currentContactGroup = currentContactGroupList?.[currentGroupKey] || {
        targetList: [] as { targetDevicePublicId: string }[]
      };
      const currentTargetList = currentContactGroup?.targetList || [];
      const currentDevicePublicId = FilteredNewDevices[i].publicId;
      const targetListObj = {
        protocol: 1,
        targetDevicePublicId: currentDevicePublicId
      };

      let currentUnlockedOperationGroup = currentUnlockOperationGroupList?.[currentGroupKey];
      const unlockOperationObj = {
        devicePublicId: currentDevicePublicId,
        deviceTarget: 2
      };

      // calculate the available space in the current contact group
      const availableSpace = CONTACT_GROUP_LIMIT - currentTargetList.length;
      if (availableSpace > 0) {
        // add the device to the current contact group
        currentContactGroup.targetList.push(targetListObj);
        currentUnlockedOperationGroup.deviceList.push(unlockOperationObj);
      } else {
        // move to the next contact group
        currentGroupKey = (parseInt(currentGroupKey) + 1).toString();
        currentContactGroup = currentContactGroupList?.[currentGroupKey] || {
          targetList: [] as { targetDevicePublicId: string }[]
        };
        currentContactGroup.targetList.push(targetListObj);

        // move to next unlock operation group
        currentUnlockedOperationGroup = currentUnlockOperationGroupList?.[currentGroupKey];
        currentUnlockedOperationGroup.deviceList.push(unlockOperationObj);
      }
    }
    const updatedCallSettings = {
      ...newDevice?.callSettings,
      contactGroupList: currentContactGroupList
    };

    const updatedUnlockOperationGroupList = {
      ...newDevice?.deviceSettings,
      unlockOperationGroupList: currentUnlockOperationGroupList
    };

    newDevice.callSettings = updatedCallSettings;
    try {
      const updatedDevice = {
        device: {
          publicId: selectedDevice?.publicId,
          callSettings: updatedCallSettings,
          deviceSettings: updatedUnlockOperationGroupList
        }
      };

      const response = await updateDevice(updatedDevice).unwrap();
      if ('error' in response) {
        throw response.error;
      }

      // Update the selected device in Redux
      dispatch(updateSelectedDevice({ device: newDevice }));

      setAddressBookDevices((prevDevices) => [...prevDevices, ...FilteredNewDevices]);
      setFilteredDevices((prevDevices) => [...prevDevices, ...FilteredNewDevices]);

      setSuccessMessage(t('Device_Added_To_Contact_Group'));
      setShowSuccessMessage(true);
    } catch (error: any) {
      const err = JSON.parse(error.data);
      if (err.errorDetails.includes('Unauthorized user Id')) {
        setErrorMessage(unauthorizedUser);
      } else {
        setErrorMessage(t('Device_Added_To_Contact_Group_Error'));
      }

      setShowErrorMessage(true);
    }
  };

  /**API requests*/
  const saveDevicesAndAppsToAddressBook = async (newDevices: IDevice[], newAppUnits: IUnit[]) => {
    const currentAddressBook = selectedDevice?.systemInfo?.addressBook || [];

    //only the door de vices and answering stations are added to the contact group
    if (selectedDevice?.basicInfo?.deviceType === 5) {
      saveDevicesToContactGroupUnlockOperationGroup(newDevices);
    } else {
      // Default ringtone settings
      const defaultRingtoneSetting = {
        callButtonNormalSound: soundsList[1].public_id,
        callButtonPrioritySound: soundsList[2].public_id,
        callButtonUrgentSound: soundsList[3].public_id,
        optionInputNormalSound: soundsList[1].public_id,
        optionInputPrioritySound: soundsList[2].public_id,
        optionInputUrgentSound: soundsList[3].public_id
      };

      const updatedSystemInfo = {
        ...selectedDevice?.systemInfo,
        addressBook: [
          ...currentAddressBook,
          ...newDevices.map((device) => ({
            targetDevicePublicId: device.publicId,
            ringtoneSetting: defaultRingtoneSetting,
            unlockSetting: { deviceTarget: 2 }
          })),
          ...newAppUnits.map((unit) => ({
            targetUnitPublicId: unit.publicId
          }))
        ]
      };

      const newDevice = JSON.parse(JSON.stringify(selectedDevice));
      newDevice.systemInfo = updatedSystemInfo;

      try {
        const updatedDevice = {
          device: {
            publicId: selectedDevice?.publicId,
            systemInfo: updatedSystemInfo
          }
        };

        const response = await updateDevice(updatedDevice).unwrap();
        if ('error' in response) {
          throw response.error;
        }

        // Update the selected device in Redux
        dispatch(updateSelectedDevice({ device: newDevice }));

        setAddressBookDevices((prevDevices) => [...prevDevices, ...newDevices]);
        setAddressBookAppUnits((prevUnits) => [...prevUnits, ...newAppUnits]);
        setFilteredDevices((prevDevices) => [...prevDevices, ...newDevices]);

        setSuccessMessage(t('AddressBook_Success_Add_App_And_Device'));
        setShowSuccessMessage(true);
      } catch (error) {
        setSuccessMessage(t('AddressBook_Error_Add_App_And_Device'));
        setShowErrorMessage(true);
      }
    }
  };

  /** Saving the address book entries */
  const handleDeviceUpdate = async (readyToSaveDevice: IDevice) => {
    setIsSaving(true);
    let params;
    if (readyToSaveDevice?.basicInfo?.deviceType === 5) {
      params = {
        device: {
          publicId: readyToSaveDevice.publicId,
          deviceSettings: {
            unlockOperationGroupList: readyToSaveDevice.deviceSettings?.unlockOperationGroupList
          }
        }
      };
    } else {
      params = {
        device: {
          publicId: readyToSaveDevice.publicId,
          systemInfo: { addressBook: readyToSaveDevice.systemInfo?.addressBook }
        }
      };
    }

    try {
      await updateDevice(params).unwrap();
      dispatch(updateSelectedDevice({ device: readyToSaveDevice }));
      setSuccessMessage(successUpdateDevice);
      setShowSuccessMessage(true);
    } catch (error: any) {
      const err = JSON.parse(error.data);
      if (err.errorDetails.includes('Unauthorized user Id')) {
        setErrorMessage(unauthorizedUser);
      } else {
        setErrorMessage(errorUpdateDevice);
      }

      setShowErrorMessage(true);
    } finally {
      setIsSaving(false);
    }
  };

  const deleteDeviceFromContactGroup = async () => {
    const newDevice = JSON.parse(JSON.stringify(selectedDevice));
    const contactGroupList = newDevice?.callSettings?.contactGroupList || {};
    const contactGroupKeys = ['6', '7', '8', '9', '10'];
    const updatedContactGroupList = { ...contactGroupList };
    const unlockedOperationGroupList = newDevice?.deviceSettings?.unlockOperationGroupList || {};
    const updatedUnlockOperationGroupList = { ...unlockedOperationGroupList };

    contactGroupKeys.forEach((key) => {
      const targetList = updatedContactGroupList[key]?.targetList.filter(
        (target: { targetDevicePublicId: string }) => !selectedDeviceIds.includes(target.targetDevicePublicId || '')
      );
      updatedContactGroupList[key].targetList = targetList || [];
    });
    contactGroupKeys.forEach((key) => {
      const deviceList = updatedUnlockOperationGroupList[key]?.deviceList.filter(
        (device: { devicePublicId: string }) => !selectedDeviceIds.includes(device.devicePublicId || '')
      );
      updatedUnlockOperationGroupList[key].deviceList = deviceList || [];
    });

    const params = {
      device: {
        publicId: selectedDevice.publicId,
        callSettings: { contactGroupList: updatedContactGroupList },
        deviceSettings: { unlockOperationGroupList: updatedUnlockOperationGroupList }
      }
    };

    try {
      await updateDevice(params).unwrap();
      setSuccessMessage(t('Device_Success_Remove_Contact_Group'));
      setShowSuccessMessage(true);
      // Filter out deleted devices and update state
      const updatedAddressBookDevices = addressBookDevices.filter(
        (device) => !selectedDeviceIds.includes(device.publicId)
      );
      setFilteredDevices(updatedAddressBookDevices);

      // Reset `currentDeviceSelected` if the selected device is deleted
      if (selectedDeviceIds.includes(currentDeviceSelected?.publicId || '')) {
        setCurrentDeviceSelected(null);
      }

      setIsSelectAll(false);
      setIsDeleteDialogOpen(false);

      //Fetch devices to update the state
      fetchDevices({ sitePublicId, qty: -1, page: 0 });
    } catch (error: any) {
      const err = JSON.parse(error.data);
      if (err.errorDetails.includes('Unauthorized user Id')) {
        setErrorMessage(unauthorizedUser);
      } else {
        setErrorMessage(t('Device_Error_Remove_Contact_Group'));
      }

      setShowErrorMessage(true);
    }
  };

  /**
   * Take the list of selected IDS(devices) and filter this devices inside target device(in all places related to address book)
   * */
  const handleDeleteSelectedDevices = async () => {
    // If the selected device is a subMaster, we need to update the contact group list
    if (selectedDevice?.basicInfo?.deviceType === 5) {
      deleteDeviceFromContactGroup();
    }

    // If the selected device is a gateway, we need to update the address book
    // Create a new address book by filtering out all selected device IDs at once
    const updatedAddressBook =
      selectedDevice.systemInfo?.addressBook?.filter(
        (item) =>
          !item.targetDevicePublicId || // Keep the entry if there's no targetDevicePublicId (meaning it's likely a unit)
          !selectedDeviceIds.includes(item.targetDevicePublicId)
      ) ?? [];

    const params = {
      device: {
        publicId: selectedDevice.publicId,
        systemInfo: { addressBook: updatedAddressBook }
      }
    };

    try {
      await updateDevice(params).unwrap();
      setSuccessMessage(t('AddressBook_Success_Remove_Device'));
      setShowSuccessMessage(true);
      // Filter out deleted devices and update state
      const updatedAddressBookDevices = addressBookDevices.filter(
        (device) => !selectedDeviceIds.includes(device.publicId)
      );
      setFilteredDevices(updatedAddressBookDevices);

      // Reset `currentDeviceSelected` if the selected device is deleted
      if (selectedDeviceIds.includes(currentDeviceSelected?.publicId || '')) {
        setCurrentDeviceSelected(null);
      }

      setIsSelectAll(false);
      setIsDeleteDialogOpen(false);

      //Fetch devices to update the state
      fetchDevices({ sitePublicId, qty: -1, page: 0 });
    } catch (error: any) {
      const err = JSON.parse(error.data);
      if (err.errorDetails.includes('Unauthorized user Id')) {
        setErrorMessage(unauthorizedUser);
      } else {
        setErrorMessage(t('AddressBook_Error_Remove_Device'));
      }

      setShowErrorMessage(true);
    }
  };

  if (!selectedDevice || !addressBookDevices) {
    return (
      <Box sx={styles.selectedDeviceWrapper}>
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  const filterDevices = (fieldName: string, query: string) => {
    if (!query) {
      setFilteredDevices(addressBookDevices);
      return;
    }
    switch (fieldName) {
      case 'stationName':
        setFilteredDevices(
          addressBookDevices.filter((device) =>
            device.basicInfo.stationName.toLowerCase().includes(query.toLowerCase())
          )
        );
        break;
      case 'unitNumber': {
        const unitList: IUnitList = units.UnitList;
        const matchingUnits = Object.values(unitList).filter((unit) => unit.unitNumber.includes(query));
        const matchingDeviceIds = matchingUnits.flatMap((unit) => unit.devicePublicIds);
        setFilteredDevices(addressBookDevices.filter((device) => matchingDeviceIds.includes(device.publicId)));
        break;
      }
      case 'stationNumber':
        setFilteredDevices(
          addressBookDevices.filter((device) =>
            device.basicInfo.stationNumber.toLowerCase().includes(query.toLowerCase())
          )
        );
        break;
      default:
        console.error('Unknown filter field');
    }
  };

  const deviceList = Object.values(allowedDevicesForAddressBook).filter(
    (device) =>
      device.publicId !== selectedDevice.publicId &&
      !addressBookDevices.some((addressBookDevice) => addressBookDevice.publicId === device.publicId)
  );
  const appList = Object.values(appUnits).filter(
    (unit) => !addressBookAppUnits.some((addressBookAppUnit) => addressBookAppUnit.publicId === unit.publicId)
  );

  return (
    <Grid container style={styles.addressBookWrapper}>
      <SnackbarAlert
        type={'success'}
        time={3000}
        text={successMessage}
        isOpen={showSuccessMessage}
        onClose={() => setShowSuccessMessage(false)}
      />
      <SnackbarAlert
        type="error"
        time={10000}
        text={errorMessage}
        isOpen={showErrorMessage}
        onClose={() => setShowErrorMessage(false)}
      />
      <DialogWindow
        isOpen={isDeleteDialogOpen}
        handleClose={closeDeleteDialog}
        title="Delete Device"
        description="Are you sure that you want to delete the selected device?"
        onConfirm={handleDeleteSelectedDevices}
      />
      <AddNewDevice
        selectedDevice={selectedDevice}
        appUnits={appList}
        devices={deviceList}
        isOpen={newDeviceModalCondition}
        handleClose={() => setNewDeviceModalCondition(false)}
        saveNewDevicesAndApps={saveDevicesAndAppsToAddressBook}
      />
      <Grid container>
        <Grid item lg={12}>
          <Box sx={styles.controlPanelWrapper}>
            <Box sx={styles.descriptionWrapper}>
              <Box sx={styles.title}>{t('Door_Release')}</Box>
              <Box sx={styles.description}>{t('Door_Release_Desc')}</Box>
            </Box>
            <ControlPanel
              selectedDevice={selectedDevice}
              targetDevice={selectedDevice}
              handleUpdate={handleDeviceUpdate}
              addNewDeviceHandler={() => setNewDeviceModalCondition(true)}
              isSaving={isSaving}
            />
          </Box>
        </Grid>
        <Grid container sx={styles.deviceSettingsWrapper}>
          <Grid item xs={2} sm={2} md={2} lg={2} style={styles.filterWrapper}>
            <FilterByAddedDevices filterDevices={filterDevices} />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} sx={styles.addedDevicesWrapper}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AddedAppUnits
                  mobileUnits={addressBookAppUnits}
                  callDestinationAppUnit={[]}
                  onSelectAppUnit={() => console.log('onSelectAppUnit')}
                  handleRemoveAppUnit={() => console.log('handleRemoveAppUnit')}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AddedDevices
                  devices={filteredDevices}
                  isSelectAll={isSelectAll}
                  isMultiselect={isMultiSelect}
                  selectedDevicesIds={selectedDeviceIds}
                  onSelectDevice={handleSelectDevice}
                  toggleMultiselect={toggleMultiSelect}
                  toggleSelectAll={toggleSelectAll}
                  handleDelete={openDeleteDialog}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7} sm={7} md={7} lg={7} style={styles.deviceSettingsWrapper}>
            {isMultiSelect || selectedDeviceIds?.length > 1 ? (
              <MultiselectControlPanel handleDelete={openDeleteDialog} />
            ) : soundsList.length > 0 ? (
              <SelectedDeviceSettings
                selectedDeviceStationName={currentDeviceSelected?.basicInfo?.stationName || ''}
                selectedDevicePublicId={currentDeviceSelected?.publicId || ''}
                soundsList={soundsList}
              />
            ) : (
              <CircularProgress color="inherit" />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  addressBookWrapper: {
    width: '100%',
    minWidth: '1250px',
    height: '90%',
    zIndex: 0
  },
  devicesAndSettingsWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },
  descriptionWrapper: {
    width: '70%'
  },
  addedDevicesWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: '#EFEFEF'
  },
  deviceSettingsWrapper: {
    display: 'flex',
    width: '100%',
    minWidth: '500px',
    height: '100%',
    backgroundColor: '#FFFFFF'
  },
  controlPanelWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px'
  },
  selectedDeviceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {}
};

export default DoorRelease;
