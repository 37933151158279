import { Box, Card, FormControl, Grid, InputLabel, List, MenuItem, Select, Typography } from '@mui/material';
import AddedDevicesItem from 'features/RemoteManagement/DeviceDashboard/Components/AddedDevicesItem';
import DeviceCard from 'features/RemoteManagement/DeviceDashboard/transfer/components/DeviceCard';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';

interface IOuputNameSettingProps {
  selectedRelay: any;
  setNeedsSave: (needsSave: boolean) => void;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<any>>>;
}

const ContactChangeStationEventSetting = ({
  selectedRelay,
  setNeedsSave,
  setNewRelaySettings
}: IOuputNameSettingProps) => {
  const { t } = useTranslation();
  const title = t('Contact_Change_Station_Event_Title');
  const description = t('Contact_Change_Station_Event_Description');
  const [deviceType, setDeviceType] = useState<string>('');
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);
  const siteDevices = useSelector((state: RootState) => state.devices);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
  const handleSelectDevice = (id: string) => {
    setSelectedDeviceId(id);
    if (id != selectedRelay.sifEventSourceDevicePublicId) {
      setNeedsSave(true);
      const outputFunction = 7;
      setNewRelaySettings((prev) => {
        const newSettingsArray = [...prev];
        const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);
        const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
        if (index !== -1) {
          if (currentRelaySettings) {
            newSettingsArray[index] = {
              ...currentRelaySettings,
              outputFunction,
              sifEventSourceDevicePublicId: id,
              previousSelectedDeviceId: selectedRelay.sifEventSourceDevicePublicId
            };
          }
        } else {
          newSettingsArray.push({
            ...selectedRelay,
            outputFunction,
            sifEventSourceDevicePublicId: id,
            previousSelectedDeviceId: selectedRelay.sifEventSourceDevicePublicId
          });
        }
        return newSettingsArray;
      });
    }
  };

  const handleUnSelect = () => {
    setSelectedDeviceId('');
    const outputFunction = 1;
    setNewRelaySettings((prev) => {
      const newSettingsArray = prev;
      const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);
      const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
      if (index !== -1) {
        newSettingsArray[index] = { ...currentRelaySettings, outputFunction, sifEventSourceDevicePublicId: null };
      } else {
        newSettingsArray.push({ ...selectedRelay, outputFunction, sifEventSourceDevicePublicId: null });
      }
      return newSettingsArray;
    });
    if (selectedRelay.sifEventSourceDevicePublicId) {
      setNeedsSave(true);
    }
  };

  useEffect(() => {
    if (selectedRelay.sifEventSourceDevicePublicId) {
      setSelectedDeviceId(selectedRelay.sifEventSourceDevicePublicId);
    } else {
      setSelectedDeviceId('');
    }
  }, [selectedRelay]);

  const getDevicesList = (deviceIds: string[]) => {
    return deviceIds.map((deviceId) => {
      const deviceDetails = siteDevices.DeviceList[deviceId];
      return (
        <AddedDevicesItem
          key={deviceId}
          device={deviceDetails}
          isSelected={selectedDeviceId === deviceId}
          handleDelete={handleUnSelect}
          handleSelect={() => handleSelectDevice(deviceId)}
          isMultiselect={false}
        />
      );
    });
  };

  const devices = deviceListByType[deviceType] || [];

  return (
    <Box sx={styles.settingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Typography variant="h5" sx={styles.title}>
          {title}
        </Typography>
        <Typography variant="body1" sx={styles.description}>
          {description}
        </Typography>
      </Box>
      <Box sx={styles.inputWrapper}>
        {selectedRelay.sifEventSourceDevicePublicId && (
          <>
            <Typography variant="h6" sx={styles.selectedStationTitle}>
              {t('Current_Station')}
            </Typography>
            <Box sx={styles.currentStationWrapper}>
              <DeviceCard devicePublicId={selectedRelay.sifEventSourceDevicePublicId} />
            </Box>
          </>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="device-type-label">{t('Device_Type')}</InputLabel>
              <Select
                labelId="device-type-label"
                label="Device Type"
                name="deviceType"
                onChange={(e) => setDeviceType(e.target.value)}
              >
                <MenuItem value="VideoDoorStation">{t('Video_Door_Station')}</MenuItem>
                <MenuItem value="AudioDoorStation">{t('Audio_Door_Station')}</MenuItem>
                <MenuItem value="EntranceStation">{t('Entrance_Station')}</MenuItem>
              </Select>
            </FormControl>
            <Card sx={styles.devicesAndSettingsWrapper}>
              <Box sx={styles.addedDevicesWrapper}>
                <List sx={styles.newDevicesWrapper}>{getDevicesList(devices)}</List>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            {selectedDeviceId && (
              <Box sx={styles.selectedStationWrapper}>
                <Typography variant="h6" sx={styles.selectedStationTitle}>
                  {t('Selected_Station')}
                </Typography>
                <DeviceCard devicePublicId={selectedDeviceId} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px'
  },
  descriptionWrapper: {
    marginBottom: '15px'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {
    marginTop: '10px'
  },
  inputWrapper: {
    width: '100%'
  },
  newDevicesWrapper: {
    marginBottom: '25px'
  },
  devicesAndSettingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px'
  },
  addedDevicesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    padding: '16px'
  },
  currentStationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    marginBottom: '16px', // Added margin to separate from the next section
    width: 'fit-content' // Adjust the width to fit content
  },
  selectedStationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    marginTop: '16px' // Added margin to prevent overlap
  },
  selectedStationTitle: {
    marginBottom: '10px'
  }
};

export default ContactChangeStationEventSetting;
