import { QuikspecCategory, SystemConstraint } from './QuikSpecTypes';

/**
 * AC Series QuikSpec Categories and items
 *
 */
export const acControlKits: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Access Control Kit',
    description:
      'The ACS-2DR-C starter kit is a 2-8 door master door controller which includes a steel ventalated lockable enclosure containing a master door control board and a two-door relay control board.',
    items: []
  },
  {
    id: 2,
    title: 'Over the Door Controller',
    description:
      'The AC-2DM-B and AC-2DM-W are both PoE over-the-door controllers with a black case designed to be mounted above or near a door. The 2nd output is typically used for egress on the same door.',
    items: [{ deviceName: 'AC-2DM-B' }, { deviceName: 'AC-2DM-W' }, { deviceName: 'AC-2DMBOX' }]
  },
  {
    id: 3,
    title: 'Elevator Control Kit',
    description:
      'The ACS-ELV provides elevator access control to a single elevator cab for up to eight floors. It can be expanded to two cabs or to 64 floors (split in intervals of eight) with the addition of seven AC-IOE expansion boards.',
    items: []
  },
  {
    id: 4,
    title: 'I/O Kit',
    description:
      'The ACS-IO controls up to eight relays and eight inputs. It can be expanded to 64 inputs and outputs with the addition of seven AC-IOE expension boards. An additional AC-C enclosure is required when adding more than three AC-IOE expansion boards.',
    items: []
  }
];

export const acLicenses: QuikspecCategory[] = [
  {
    id: 1,
    title: 'AC NIO Management Software',
    description:
      'AC NIO is management software for the AC Series access control system. It is used to configure, program, and manage day-to-day functionality of the system. There are two license options available to accommodate different applications.',
    items: [
      {
        deviceName: 'AC-NIO-ESS1',
        multipleAllowed: false
      },
      {
        deviceName: 'AC-NIO-ESS5',
        multipleAllowed: false
      },
      {
        deviceName: 'AC-NIO-SB1',
        multipleAllowed: false
      },
      {
        deviceName: 'AC-NIO-SB5',
        multipleAllowed: false
      },
      {
        deviceName: 'AC-HOST'
      }
    ]
  }
];

export const acReaderKits: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Card Readers',
    description:
      'These card readers can connect to either the master controller or over-the-door controllers. Please select up to 80 card readers.',
    items: [
      { deviceName: 'AC-PROX-1G' },
      { deviceName: 'AC-BT-10K' },
      { deviceName: 'AC-10K-1G' },
      { deviceName: 'AC-BT-M' },
      { deviceName: 'AC-10K-M' }
    ],
    maxQuantityAllowedPerCategory: 80
  },
  {
    id: 2,
    title: 'Reader Credentials',
    description:
      'AC Series Proximity card, Proximity key fob, and Proximity disk tag is designed to work with the AC-PROX-1G, AC-10K-1G, and AC-10K-M proximity readers.',
    items: [
      {
        deviceName: 'AC-PC',
        accessories: [
          { accessoryName: 'Aiphone 40 Format', deviceName: 'AC-PC-40' },
          { accessoryName: 'HID 26 Format', deviceName: 'AC-PC-H26' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'AC-PF',
        accessories: [
          { accessoryName: 'Aiphone 40 Format', deviceName: 'AC-PF-40' },
          { accessoryName: 'HID 26 Format', deviceName: 'AC-PF-H26' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'AC-PT',
        accessories: [
          { accessoryName: 'Aiphone 40 Format', deviceName: 'AC-PT-40' },
          { accessoryName: 'HID 26 Format', deviceName: 'AC-PT-H26' }
        ],
        countWithAccessories: true
      }
    ]
  }
];

export const acHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      { deviceName: 'ACS-2DR-C' },
      { deviceName: 'ACS-2DR' },
      { deviceName: 'AC-2DE' },
      { deviceName: 'AC-PS1250' },
      { deviceName: 'AC-CA1' },
      { deviceName: 'ACS-ELV' },
      { deviceName: 'AC-IOE' },
      { deviceName: 'AC-C' },
      { deviceName: 'ACS-IO' },
      { deviceName: 'AC-PC-40' },
      { deviceName: 'AC-PC-H26' },
      { deviceName: 'AC-PF-40' },
      { deviceName: 'AC-PF-H26' },
      { deviceName: 'AC-PT-40' },
      { deviceName: 'AC-PT-H26' },
      { deviceName: 'NUMBER_OF_READERS_AC' },
      { deviceName: 'NUMBER_OF_READERS_AC_TROVE' },
      { deviceName: 'NUMBER_OF_FLOORS' },
      { deviceName: 'NUMBER_OF_INPUTS' },
      { deviceName: 'NUMBER_OF_OUTPUTS' }
    ]
  }
];

export const acSystemConstraints: SystemConstraint[] = [{ description: 'Max Readers allowed', max: 80 }];
