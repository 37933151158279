import React, { useState } from 'react';
import {
  Alert,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  TextField
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import 'styles/frontshine.css';
import Login from 'shared/api/Acl/Login';
import { areInputsEmpty } from '../../../../../../shared/utils/helperFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import { IXGPortalLoginFormStyles } from '../../../../../../styles/form-input';
import StringUtils from '../../../../../../shared/utils/StringUtils';
import { getString } from 'shared/utils/LocalizationUtils';

const ID_MAX_LENGTH = 22;

interface ActivationPanelProps {
  // This should hopefully be the easiest way to get past linting errors
  onClose: (() => void) | ((event: React.SyntheticEvent, reason: string | void) => void);
}

const ActivationPanel = ({ onClose }: ActivationPanelProps) => {
  const titles = {
    adminId: getString('AdvancedSettings_AdminId_Title')
  };

  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const fieldErrors = {
    maxLen: getString('Field_Error_MaxLen'),
    minLen: getString('Field_Error_MinLen'),
    required: getString('Field_Error_Required'),
    unique: getString('Field_Error_Unique')
  };

  const validationSchema = Yup.object({
    id: Yup.string()
      .required('Administrator ID is required.')
      .max(ID_MAX_LENGTH, StringUtils.format(fieldErrors.maxLen, titles.adminId, ID_MAX_LENGTH)),
    code: Yup.string().required('Verification Code is required.')
    //   To handle correct verification code, we must check on the backend
  });

  const [result, setResult] = useState('');
  const handleVerification = async (values: { id: string; code: string }) => {
    try {
      const result = await Login.confirm(values.id, values.code);
      setResult(result);
      // Convert result to all caps
      const upperCaseResult = result.toUpperCase();
      // If the result is 'SUCCESS', close the dialog after 3 seconds
      if (upperCaseResult === 'SUCCESS') {
        timeoutRef.current = setTimeout(() => {
          const blankEvent = new Event('synthetic') as unknown as React.SyntheticEvent;
          onClose(blankEvent, 'successful-close');
        }, 3000);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setResult(error.message);
      } else {
        setResult(String(error));
      }
    }
  };

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <DialogTitle className={'text-center text-size_28px bg-grey'}>Activation</DialogTitle>
      <Formik
        initialValues={{ id: '', code: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await handleVerification(values);
        }}
      >
        {({ handleChange, handleBlur, values, errors, isValid, isSubmitting, touched }) => (
          <Form className={'bg-grey'}>
            <DialogContent>
              <Grid container direction={'column'} spacing={1} className={'space-y-0.5'}>
                <Grid item>
                  <DialogContentText>Enter Administrator ID and Verification Code.</DialogContentText>
                </Grid>
                <Grid item>
                  <InputLabel>
                    <span className={'font-bolder text-black'}>Administrator ID</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={values.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Administrator ID"
                    id="id"
                    sx={IXGPortalLoginFormStyles.inputField}
                    error={touched.id && Boolean(errors.id)}
                    helperText={touched.id && errors.id}
                    required
                  />
                </Grid>
                <Grid item>
                  <InputLabel>
                    <span className={'font-bolder text-black'}>Verification Code</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={values.code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Verification Code"
                    id="code"
                    sx={IXGPortalLoginFormStyles.inputField}
                    error={touched.code && Boolean(errors.code)}
                    helperText={touched.code && errors.code}
                    required
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting || result.toLowerCase() === 'success' || areInputsEmpty(values)}
                className={'w-full mt-4'}
              >
                {isSubmitting ? <CircularProgress size="25px" sx={{ color: 'white' }} /> : 'Activate'}
              </Button>
              {result.length > 0 && (
                <Alert className={'mt-4'} severity={result.toLowerCase() === 'success' ? 'success' : 'error'}>
                  {result.toLowerCase() === 'success'
                    ? 'Activation successful. This window will close in 3' + ' seconds...'
                    : result}
                </Alert>
              )}
            </DialogContent>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ActivationPanel;
