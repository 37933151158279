import { getString } from 'shared/utils/LocalizationUtils';

/* CGI tab stub */
export const CGILabel = () => {
  const cgi = getString('CGI_Label');
  return <span>{cgi}</span>;
};

const CGI = () => {
  const cgi = getString('CGI_Label');
  return <span>{cgi}</span>;
};

export default CGI;
