import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { fetchAclSitesData } from 'features/SimBilling/Stores/aclSitesSlice';

const useFetchACLSites = () => {
  const dispatch = useAppDispatch();

  const fetchSites = useCallback(
    async (aclToken: string) => {
      await dispatch(fetchAclSitesData({ aclToken }));
    },
    [dispatch]
  );

  return { fetchSites };
};

export default useFetchACLSites;
