import { QuikspecCategory, SystemConstraint } from 'features/QuikSpec/Types/QuikSpecTypes';

/**
 * JO Series QuikSpec Categories and items
 *
 */
export const joMasterStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Master Stations',
    description:
      'Master stations answer incoming calls, monitor doors, and unlock doors. The JO-1MDW is required for every system and connects to Wi-Fi to support 8 JO Mobile Apps. One JO-1FD expansion master station may be added to the system.',
    items: [
      {
        deviceName: 'JO-1MDW',
        defaultQuantity: 1,
        readOnly: true,
        maxQuantityAllowedPerItem: 1,
        itemTitle: 'With Mobile App Integration',
        required: true
      },
      {
        deviceName: 'JO-1FD',
        maxQuantityAllowedPerItem: 1
      }
    ]
  }
];

export const joExpansionStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Expansion Stations',
    description:
      'An Expansion Station has similar basic features when compared to a master station, such as 2-way communication and the ability to activate door release, but is limited in other advanced features. When choosing the addition of a sub master station, ensure the station is appropriate for the application.',
    items: [
      {
        deviceName: 'JO-1FD',
        multipleAllowed: true
      }
    ]
  }
];

export const joDoorStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Door Stations',
    description: 'Video Door Stations provide two-way audio and video communication.',
    items: [
      {
        deviceName: 'JO-DA',
        multipleAllowed: false,
        maxQuantityAllowedPerItem: 1
      },
      {
        deviceName: 'JO-DV',
        multipleAllowed: false,
        maxQuantityAllowedPerItem: 1
      },
      {
        deviceName: 'JO-DVF',
        accessories: [
          { accessoryName: 'Flush Mount', maxQuantityAllowedPerItem: 1 },
          { accessoryName: 'Surface Mount', deviceName: 'SBX-DVF', maxQuantityAllowedPerItem: 1 }
        ],
        countWithAccessories: true,
        maxQuantityAllowedPerItem: 1
      }
    ],
    maxQuantityAllowedPerCategory: 2
  }
];

export const optionalFeatures: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Optional Features',
    description: 'Optional Features',
    items: [
      { deviceName: 'RY-ES', maxQuantityAllowedPerItem: 1 },
      { deviceName: 'MCW-S/B', maxQuantityAllowedPerItem: 2 },
      { deviceName: 'JOW-2D', maxQuantityAllowedPerItem: 1 }
    ]
  }
];

// Hidden products are designed not to be displayed.
export const joHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      { deviceName: 'JOS-1A' },
      { deviceName: 'JOS-1V' },
      { deviceName: 'JOS-1F' },
      { deviceName: 'JOS-1AW' },
      { deviceName: 'JOS-1VW' },
      { deviceName: 'JOS-1FW' },
      { deviceName: 'SBX-DVF' },
      { deviceName: 'RY-1824L' }
    ]
  }
];

export const joSystemConstraints: SystemConstraint[] = [
  { description: 'Master Stations allowed', max: 2 },
  { description: 'Door Stations allowed', max: 2 },
  { description: 'Mobile Apps allowed', max: 8 }
];
