import { QuikspecCategory, SystemConstraint } from 'features/QuikSpec/Types/QuikSpecTypes';

/**
 * JV Series QuikSpec Categories and items
 *
 */
export const jvMasterStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Master Stations',
    description:
      'Master stations are where incoming calls are received and door release can be activated. The JV-1MD is included in box sets. The JV-1FD can be added to the system.',
    items: [
      {
        deviceName: 'JV-1MD',
        defaultQuantity: 1,
        readOnly: true,
        maxQuantityAllowedPerItem: 1,
        required: true,
        imageExtension: 'png'
      },
      {
        deviceName: 'JV-1FD',
        maxQuantityAllowedPerItem: 1,
        imageExtension: 'png'
      }
    ]
  }
];

export const jvDoorStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Door Stations',
    description:
      'Video Door Stations provide two-way audio and video communication and can be monitored by Master Stations.',
    items: [
      {
        deviceName: 'JV-DV',
        multipleAllowed: false,
        maxQuantityAllowedPerItem: 1
      },
      {
        deviceName: 'JV-DVF',
        accessories: [
          { accessoryName: 'Flush Mount', maxQuantityAllowedPerItem: 1 },
          { accessoryName: 'Surface Mount', deviceName: 'SBX-DVF', maxQuantityAllowedPerItem: 1 }
        ],
        countWithAccessories: true,
        maxQuantityAllowedPerItem: 1
      }
    ],
    maxQuantityAllowedPerCategory: 2
  }
];

export const optionalFeatures: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Optional Features',
    description: 'Optional Features',
    items: [
      { deviceName: 'RY-ES', maxQuantityAllowedPerItem: 2 },
      { deviceName: 'MCW-S/B', maxQuantityAllowedPerItem: 2 },
      { deviceName: 'IER-2', maxQuantityAllowedPerItem: 2 }
    ]
  }
];

// Hidden products are designed not to be displayed.
export const jvHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [{ deviceName: 'JVS-1V' }, { deviceName: 'JVS-1F' }, { deviceName: 'SBX-DVF' }, { deviceName: 'RY-1824L' }]
  }
];

export const jvSystemConstraints: SystemConstraint[] = [
  { description: 'Master Stations allowed', max: 2 },
  { description: 'Door Stations allowed', max: 1 }
];
