import { Box, Tooltip, Card, Checkbox, Slider, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Grid } from '@mui/material';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { RegexIpV4OrDomain, RegexIpV6OrDomain } from 'features/RemoteManagement/Types';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

export const TimeLabel = () => {
  const { t } = useTranslation();
  const time = t('Time');
  return (
    <Tooltip title="Time, NTP">
      <span>{time}</span>
    </Tooltip>
  );
};

const Time = () => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const selectedDevice = useSelector(getSelectedDevice);
  const [formikSchema, setFormikSchema] = useState<Yup.ObjectSchema<any> | null>(null);
  const { t } = useTranslation();

  const ntpSettingsTitle = t('AdvancedSettings_NtpSettings_Title');
  const ntpSettingsDesc = t('AdvancedSettings_NtpSettings_Desc');
  const ntpSyncIntervalTitle = t('AdvancedSettings_NtpSyncInterval_Title');
  const ntpSyncIntervalDesc = t('AdvancedSettings_NtpSyncInterval_Desc');
  const ntpPrimaryServerIpV4AddressTitle = t('AdvancedSettings_NtpPrimaryServerIpV4Address_Title');
  const ntpPrimaryServerIpV4AddressDesc = t('AdvancedSettings_NtpPrimaryServerIpV4Address_Desc');
  const ntpSecondaryServerIpV4AddressTitle = t('AdvancedSettings_NtpSecondaryServerIpV4Address_Title');
  const ntpSecondaryServerIpV4AddressDesc = t('AdvancedSettings_NtpSecondaryServerIpV4Address_Desc');
  const ntpPrimaryServerIpV6AddressTitle = t('AdvancedSettings_NtpPrimaryServerIpV6Address_Title');
  const ntpPrimaryServerIpV6AddressDesc = t('AdvancedSettings_NtpPrimaryServerIpV6Address_Desc');
  const ntpSecondaryServerIpV6AddressTitle = t('AdvancedSettings_NtpSecondaryServerIpV6Address_Title');
  const ntpSecondaryServerIpV6AddressDesc = t('AdvancedSettings_NtpSecondaryServerIpV6Address_Desc');
  const ntpPrimaryServerPortTitle = t('AdvancedSettings_NtpPrimaryServerPort_Title');
  const ntpPrimaryServerPortDesc = t('AdvancedSettings_NtpPrimaryServerPort_Desc');
  const ntpSecondaryServerPortTitle = t('AdvancedSettings_NtpSecondaryServerPort_Title');
  const ntpSecondaryServerPortDesc = t('AdvancedSettings_NtpSecondaryServerPort_Desc');

  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');

  const ipV4AddressErrorInvalid = t('IpV4Address_Error_Invalid');
  const ipV6AddressErrorInvalid = t('IpV6Address_Error_Invalid');

  const buttonSaveChanges = t('Button_SaveChanges');
  const buttonReset = t('Button_Reset');

  const ntpSyncIntervalMin = 1;
  const ntpSyncIntervalMax = 24;
  const formDevice = {
    ntpSettings: selectedDevice.networkSettings?.ntpSettings,
    ntpSyncInterval: selectedDevice.networkSettings?.ntpSyncInterval,
    ntpPrimaryServerIpV4Address: selectedDevice.networkSettings?.ntpPrimaryServerIpV4Address,
    ntpSecondaryServerIpV4Address: selectedDevice.networkSettings?.ntpSecondaryServerIpV4Address,
    ntpPrimaryServerIpV6Address: selectedDevice.networkSettings?.ntpPrimaryServerIpV6Address,
    ntpSecondaryServerIpV6Address: selectedDevice.networkSettings?.ntpSecondaryServerIpV6Address,
    ntpPrimaryServerPort: selectedDevice.networkSettings?.ntpPrimaryServerPort,
    ntpSecondaryServerPort: selectedDevice.networkSettings?.ntpSecondaryServerPort
  };

  const getValidationSchema = (currValues: any) => {
    const networkSchema: any = {};

    if (currValues.ntpSyncInterval !== null && currValues.ntpSyncInterval !== undefined) {
      networkSchema.ntpSyncInterval = Yup.number().min(ntpSyncIntervalMin).max(ntpSyncIntervalMax).required();
    }

    if (currValues.ntpPrimaryServerIpV4Address !== null && currValues.ntpPrimaryServerIpV4Address !== undefined) {
      networkSchema.ntpPrimaryServerIpV4Address = Yup.string().matches(
        new RegExp(`^${RegexIpV4OrDomain}$`),
        ipV4AddressErrorInvalid
      );
    }

    if (currValues.ntpSecondaryServerIpV4Address !== null && currValues.ntpSecondaryServerIpV4Address !== undefined) {
      networkSchema.ntpSecondaryServerIpV4Address = Yup.string().matches(
        new RegExp(`^${RegexIpV4OrDomain}$`),
        ipV4AddressErrorInvalid
      );
    }

    if (currValues.ntpPrimaryServerIpV6Address !== null && currValues.ntpPrimaryServerIpV6Address !== undefined) {
      networkSchema.ntpPrimaryServerIpV6Address = Yup.string().matches(
        new RegExp(`^${RegexIpV6OrDomain}$`),
        ipV6AddressErrorInvalid
      );
    }

    if (currValues.ntpSecondaryServerIpV6Address !== null && currValues.ntpSecondaryServerIpV6Address !== undefined) {
      networkSchema.ntpSecondaryServerIpV6Address = Yup.string().matches(
        new RegExp(`^${RegexIpV6OrDomain}$`),
        ipV6AddressErrorInvalid
      );
    }

    if (currValues.ntpPrimaryServerPort !== null && currValues.ntpPrimaryServerPort !== undefined) {
      networkSchema.ntpPrimaryServerPort = Yup.number().required();
    }

    if (currValues.ntpSecondaryServerPort !== null && currValues.ntpSecondaryServerPort !== undefined) {
      networkSchema.ntpSecondaryServerPort = Yup.number().required();
    }

    return Yup.object(networkSchema);
  };

  const onSubmit = async (values: any, actions: any) => {
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        networkSettings: values
      }
    };

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...newDevice.networkSettings,
      ...values
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }

        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(unauthorizedUser);
        } else {
          setErrorMessage(errorUpdateDevice);
        }
      });
  };

  if (!formikSchema) {
    setFormikSchema(getValidationSchema(formDevice));
  }

  return (
    <>
      <Box sx={containerStyle.mainWrapper}>
        <SnackbarAlert
          type="error"
          time={10000}
          text={`${errorMessage}`}
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <SnackbarAlert
          type="success"
          time={3000}
          text={successUpdateDevice}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
        />
        <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={formikSchema}>
          {({ values, dirty, touched, errors, isSubmitting, handleChange }) => (
            <Form style={containerStyle.form}>
              <Box sx={containerStyle.controlPanelWrapper}>
                <LoadingButton variant="outlined" type="reset" disabled={!dirty || isSubmitting || isUpdating}>
                  {buttonReset}
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="submit"
                  disabled={!dirty || isSubmitting || isUpdating}
                >
                  {buttonSaveChanges}
                </LoadingButton>
              </Box>

              <Card sx={containerStyle.settingsWrapper}>
                <Box sx={containerStyle.gridContainer}>
                  {formDevice.ntpSettings !== null && formDevice.ntpSettings !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box style={containerStyle.itemTitle}>{ntpSettingsTitle}</Box>
                        <Box style={containerStyle.itemDesc}>{ntpSettingsDesc}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box style={containerStyle.fieldContainer}>
                          <Field
                            as={Checkbox}
                            name="ntpSettings"
                            style={containerStyle.toggleField}
                            checked={values.ntpSettings}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.ntpSyncInterval !== null && formDevice.ntpSyncInterval !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box style={containerStyle.itemTitle}>{ntpSyncIntervalTitle}</Box>
                        <Box style={containerStyle.itemDesc}>{ntpSyncIntervalDesc}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box style={containerStyle.fieldContainer}>
                          <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={7.5}>
                              <Field
                                as={Slider}
                                type="range"
                                label={ntpSyncIntervalTitle}
                                name="ntpSyncInterval"
                                min={ntpSyncIntervalMin}
                                max={ntpSyncIntervalMax}
                              />
                            </Grid>
                            <Grid item xs={4.5}>
                              <Field
                                as={TextField}
                                type="number"
                                name="ntpSyncInterval"
                                size="small"
                                onChange={(e: any) => {
                                  e.target.value = parseInt(e.target.value);
                                  if (e.target.value > ntpSyncIntervalMax) {
                                    e.target.value = ntpSyncIntervalMax;
                                  } else if (e.target.value < ntpSyncIntervalMin) {
                                    e.target.value = ntpSyncIntervalMin;
                                  }
                                  handleChange(e);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.ntpPrimaryServerIpV4Address !== null &&
                  formDevice.ntpPrimaryServerIpV4Address !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box style={containerStyle.itemTitle}>{ntpPrimaryServerIpV4AddressTitle}</Box>
                        <Box style={containerStyle.itemDesc}>{ntpPrimaryServerIpV4AddressDesc}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box style={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            type="text"
                            label={ntpPrimaryServerIpV4AddressTitle}
                            name="ntpPrimaryServerIpV4Address"
                            style={containerStyle.textField}
                            helperText={touched.ntpPrimaryServerIpV4Address && errors.ntpPrimaryServerIpV4Address}
                            error={touched.ntpPrimaryServerIpV4Address && errors.ntpPrimaryServerIpV4Address}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.ntpSecondaryServerIpV4Address !== null &&
                  formDevice.ntpSecondaryServerIpV4Address !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box style={containerStyle.itemTitle}>{ntpSecondaryServerIpV4AddressTitle}</Box>
                        <Box style={containerStyle.itemDesc}>{ntpSecondaryServerIpV4AddressDesc}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box style={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            type="text"
                            label={ntpSecondaryServerIpV4AddressTitle}
                            name="ntpSecondaryServerIpV4Address"
                            style={containerStyle.textField}
                            helperText={touched.ntpSecondaryServerIpV4Address && errors.ntpSecondaryServerIpV4Address}
                            error={touched.ntpSecondaryServerIpV4Address && errors.ntpSecondaryServerIpV4Address}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.ntpPrimaryServerIpV6Address !== null &&
                  formDevice.ntpPrimaryServerIpV6Address !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box style={containerStyle.itemTitle}>{ntpPrimaryServerIpV6AddressTitle}</Box>
                        <Box style={containerStyle.itemDesc}>{ntpPrimaryServerIpV6AddressDesc}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box style={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            type="text"
                            label={ntpPrimaryServerIpV6AddressTitle}
                            name="ntpPrimaryServerIpV6Address"
                            style={containerStyle.textField}
                            helperText={touched.ntpPrimaryServerIpV6Address && errors.ntpPrimaryServerIpV6Address}
                            error={touched.ntpPrimaryServerIpV6Address && errors.ntpPrimaryServerIpV6Address}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.ntpSecondaryServerIpV6Address !== null &&
                  formDevice.ntpSecondaryServerIpV6Address !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box style={containerStyle.itemTitle}>{ntpSecondaryServerIpV6AddressTitle}</Box>
                        <Box style={containerStyle.itemDesc}>{ntpSecondaryServerIpV6AddressDesc}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box style={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            type="text"
                            label={ntpSecondaryServerIpV6AddressTitle}
                            name="ntpSecondaryServerIpV6Address"
                            style={containerStyle.textField}
                            helperText={touched.ntpSecondaryServerIpV6Address && errors.ntpSecondaryServerIpV6Address}
                            error={touched.ntpSecondaryServerIpV6Address && errors.ntpSecondaryServerIpV6Address}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.ntpPrimaryServerPort !== null && formDevice.ntpPrimaryServerPort !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box style={containerStyle.itemTitle}>{ntpPrimaryServerPortTitle}</Box>
                        <Box style={containerStyle.itemDesc}>{ntpPrimaryServerPortDesc}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box style={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            type="text"
                            label={ntpPrimaryServerPortTitle}
                            name="ntpPrimaryServerPort"
                            style={containerStyle.textField}
                            helperText={touched.ntpPrimaryServerPort && errors.ntpPrimaryServerPort}
                            error={touched.ntpPrimaryServerPort && errors.ntpPrimaryServerPort}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.ntpSecondaryServerPort !== null && formDevice.ntpSecondaryServerPort !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box style={containerStyle.itemTitle}>{ntpSecondaryServerPortTitle}</Box>
                        <Box style={containerStyle.itemDesc}>{ntpSecondaryServerPortDesc}</Box>
                      </Grid>
                      <Grid item xs={5} lg={3}>
                        <Box style={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            type="text"
                            label={ntpSecondaryServerPortTitle}
                            name="ntpSecondaryServerPort"
                            style={containerStyle.textField}
                            helperText={touched.ntpSecondaryServerPort && errors.ntpSecondaryServerPort}
                            error={touched.ntpSecondaryServerPort && errors.ntpSecondaryServerPort}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Time;
