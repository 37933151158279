/**
 * Maintenance has 2 tabs (Firmware Update, Initialization)
 * user can navigate through each tab setting for each device
 */
import FirmwareUpdate from './FirmwareUpdate';

const Maintenance = () => {
  return (
    <div>
      <FirmwareUpdate />
    </div>
  );
};

export default Maintenance;
