import { createSlice } from '@reduxjs/toolkit';
import { aiphoneCloudApi } from 'services/aiphoneCloud';

export interface Tenant {
  publicId: string;
  unitPublicId: string;
  firstName: string;
  firstNameOtherLanguage: string;
  lastName: string;
  lastNameOtherLanguage: string;
  languageId: number;
  phoneNumber: string;
  email: string;
  isPrimaryTenant: boolean;
  createdBy: string;
  createdOn: string;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
}

interface ITenantList {
  [key: string]: Tenant;
}

interface TenantState {
  TenantList: ITenantList;
  SelectedTenant: Tenant | null | undefined;
  Loading: boolean;
}

const initialState: TenantState = {
  TenantList: {},
  SelectedTenant: null,
  Loading: false
};

const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(aiphoneCloudApi.endpoints.getTenantListWithSiteId.matchFulfilled, (state, { payload }) => {
      state.TenantList = {};
      state.TenantList = payload.data.tenantList;
    });
  }
});

export const tenantsReducer = tenantsSlice.reducer;
