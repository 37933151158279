import React from 'react';
import { Box, Button, TextField } from '@mui/material';

interface IFilterByAddedDevicesProps {
  filterDevices: (field: string, query: string) => void;
}
const FilterByAddedDevices = ({ filterDevices }: IFilterByAddedDevicesProps) => {
  const [stationName, setStationName] = React.useState('');
  const [unitNumber, setUnitNumber] = React.useState('');
  const [stationNumber, setStationNumber] = React.useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStationName(name === 'stationName' ? value : stationName);
    setUnitNumber(name === 'unitNumber' ? value : unitNumber);
    setStationNumber(name === 'stationNumber' ? value : stationNumber);
    filterDevices(name, value);
  };

  const handleClearFilters = () => {
    setStationName('');
    setUnitNumber('');
    setStationNumber('');
    filterDevices('clear', '');
  };

  return (
    <Box sx={styles.filterFieldsWrapper}>
      <TextField
        id="name-filter"
        name="stationName"
        label="Search by Station name"
        value={stationName}
        variant="standard"
        onChange={handleInputChange}
        helperText="Enter a station name to filter device list"
      />
      <TextField
        id="unit-filter"
        name="unitNumber"
        label="Search by Unit number"
        value={unitNumber}
        variant="standard"
        onChange={handleInputChange}
        helperText="Enter a unit number to filter device list"
      />
      <TextField
        id="number-filter"
        name="stationNumber"
        label="Search by Station number"
        value={stationNumber}
        variant="standard"
        onChange={handleInputChange}
        helperText="Enter a station number to filter device list"
      />
      <Box sx={styles.buttonWrapper}>
        <Button variant="outlined" onClick={handleClearFilters}>
          Clear Filters
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  buttonWrapper: {
    display: 'flex-start',
    justifyContent: 'flex-end',
    marginTop: '30px'
  },
  filterFieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginRight: '20px',
    minWidth: '200px',
    padding: 1
  }
};

export default FilterByAddedDevices;
