import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import CONFIG from 'config.ts';

const HTTP_API_URL = CONFIG.httpApiEndpoint;
const IXG_API_URL = CONFIG.ixgApiEndPoint;
const S3_ACL_URL = CONFIG.ixgs3EndPoint;

// Different types of calls that can be made to ACL
type FuncType =
  | 'GetUploadURL'
  | 'GetRoomAuthCode_v2'
  | 'GetRoomAuthCodeProgress'
  | 'GetNextPropertyID'
  | 'CheckEmailDuplication';

// Type of data that can be passed into call body
type CallData = object | number[] | { Email: string };

// Format of ACL call Body
interface AclApiCall {
  PropertyID?: number;
  Func: FuncType;
  Data?: CallData;
}

function createAclCall(dataStr: string, token: string, url = '') {
  const host = new URL(IXG_API_URL).host;
  return {
    url,
    method: 'POST',
    keepalive: true,
    headers: {
      Host: host,
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `${token}`,
      'Content-Length': dataStr.length.toString()
    },
    body: dataStr
  };
}

export const ixgAclApiSlice = createApi({
  reducerPath: 'ixgapi',
  baseQuery: fetchBaseQuery({ baseUrl: IXG_API_URL }),
  endpoints: (builder) => ({
    getUploadUrl: builder.mutation({
      query: (query) => {
        const data: AclApiCall = { PropertyID: query.propertyid, Func: 'GetUploadURL', Data: {} };
        const dataStr = JSON.stringify(data);

        return createAclCall(dataStr, query.token);
      }
    }),
    getRoomAuthCode: builder.mutation({
      query: (query) => {
        const data: AclApiCall = { PropertyID: query.propertyid, Func: 'GetRoomAuthCode_v2', Data: query.roomids };
        const dataStr = JSON.stringify(data);

        return createAclCall(dataStr, query.token);
      }
    }),
    getRoomAuthCodeProgress: builder.mutation({
      query: (query) => {
        const data: AclApiCall = { PropertyID: query.propertyid, Func: 'GetRoomAuthCodeProgress', Data: {} };
        const dataStr = JSON.stringify(data);

        return createAclCall(dataStr, query.token);
      }
    }),
    getNextPropertyId: builder.mutation({
      query: (query) => {
        const data: AclApiCall = { Func: 'GetNextPropertyID' };
        const dataStr = JSON.stringify(data);

        return createAclCall(dataStr, query.token);
      }
    }),
    checkEmail: builder.mutation({
      query: (query) => {
        const data: AclApiCall = { Func: 'CheckEmailDuplication', Data: { Email: query.email } };

        return {
          url: HTTP_API_URL + '/checkemail',
          method: 'POST',
          keepalive: true,
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(data)
        };
      },
      transformResponse: (responseData) => {
        return responseData.Data;
      }
    }),
    uploadData: builder.mutation({
      query: (query) => {
        const { url, systemData } = query;
        const modifedurl = url.replace(S3_ACL_URL, HTTP_API_URL + '/upload');
        const dataStr = JSON.stringify({
          ...systemData,
          s3AclUrl: S3_ACL_URL // adding S3_ACL_URL in the request body
        });

        return {
          url: modifedurl,
          method: 'PUT',
          keepalive: true,
          headers: {
            'Content-Type': ''
          },
          body: dataStr
        };
      }
    })
  })
});

export const {
  useGetUploadUrlMutation,
  useGetRoomAuthCodeMutation,
  useGetRoomAuthCodeProgressMutation,
  useGetNextPropertyIdMutation,
  useCheckEmailMutation,
  useUploadDataMutation
} = ixgAclApiSlice;
