import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ReDirectedPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRedirect = () => {
    navigate('/');
  };
  return (
    <Box sx={styles.mainContainer}>
      <p>{t('You_are_being_redirected')}</p>
      <p>{t('If_you_havent_been_redirected')}</p>

      <Button variant="outlined" onClick={handleRedirect}>
        {t('Continue')}
      </Button>
    </Box>
  );
};
/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainContainer: {
    margin: 'auto',
    textAlign: 'center'
  }
};
export default ReDirectedPage;
