import { Box, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface IDoorReleaseSettingsProps {
  onChange: (payload: boolean) => void;
  doorReleaseCondition: boolean;
}

const strings = {
  title: 'Door Release',
  description: 'Check the box to enable the door release on this device.'
};

const DoorReleaseSettings = ({ onChange, doorReleaseCondition }: IDoorReleaseSettingsProps) => {
  const [doorReleaseValue, setDoorReleaseValue] = useState<boolean>(doorReleaseCondition);
  useEffect(() => {
    setDoorReleaseValue(doorReleaseCondition);
  }, [doorReleaseCondition]);

  const handleUpdate = (event: any) => {
    const newValue = event.target.checked;
    setDoorReleaseValue(newValue);
    onChange(newValue);
  };

  return (
    <Box style={styles.doorReleaseWrapper}>
      <Box style={styles.descriptionWrapper}>
        <Box style={styles.title}>{strings.title}</Box>
        <Box style={styles.description}>{strings.description}</Box>
      </Box>
      <Box sx={styles.checkboxWrapper}>
        <Checkbox onChange={handleUpdate} checked={doorReleaseValue} inputProps={{ 'aria-label': 'controlled' }} />
      </Box>
    </Box>
  );
};

const styles = {
  doorReleaseWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
    // border: '1px solid #FF0000'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  checkboxWrapper: {
    width: '30%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  }
};

export default DoorReleaseSettings;
