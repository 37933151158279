import { Box, Grid } from '@mui/material';
import DeviceCard from 'features/RemoteManagement/Components/DeviceCard';
import { getString } from 'shared/utils/LocalizationUtils';

interface DeviceTabContentProps {
  deviceList: string[];
}

const DeviceTabContent = ({ deviceList }: DeviceTabContentProps) => {
  const noIntercoms = getString('No_Intercoms_Added');
  return (
    <Grid container spacing={2}>
      {deviceList?.length > 0 ? (
        deviceList.map((devicePublicId, index) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
              <DeviceCard key={index} devicePublicId={devicePublicId} />
            </Grid>
          );
        })
      ) : (
        <Grid item xs={12}>
          <Box sx={styles.noIntercomPanel}>{noIntercoms}</Box>
        </Grid>
      )}
    </Grid>
  );
};

const styles = {
  noIntercomPanel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '12rem'
  }
};

export default DeviceTabContent;
