import { useState } from 'react';
import { Box, Card, CardContent, Grid, Typography, CardHeader } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { IUnit } from 'store/slices/unitsSlice';

interface ISelectedAppUnitProps {
  appUnit: IUnit;
  handleRemoveAppUnit: (unitPublicId: string) => void;
}

const SelectedAppUnit = ({ appUnit, handleRemoveAppUnit }: ISelectedAppUnitProps) => {
  const { publicId, unitName, unitNumber } = appUnit || {};
  const [isDeleting, setIsDeleting] = useState(false);

  const handleRemoveAppGroup = (unitPublicId: string) => {
    setIsDeleting(true);
    handleRemoveAppUnit(unitPublicId);
    setIsDeleting(false);
  };

  return (
    <>
      <Box sx={styles.selectedDeviceWrapper}>
        <Card>
          <Grid sx={{ position: 'relative' }}>
            <Box sx={styles.loadingBox}>
              <LoadingButton
                loading={isDeleting}
                size="small"
                onClick={() => {
                  handleRemoveAppGroup(publicId);
                }}
                sx={styles.loadingButton}
              >
                <CancelOutlinedIcon />
              </LoadingButton>
            </Box>
          </Grid>
          <CardHeader title="Mobile App" sx={{ backgroundColor: '#ececec' }} />
          <CardContent>
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
              <Grid sx={styles.deviceWrapper}>
                <Typography variant="body2" color="text.secondary">
                  <strong>Unit Number: </strong>
                  {unitNumber}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <strong>Unit Name: </strong>
                  {unitName}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

const styles = {
  deviceInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  deviceWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  selectedDeviceWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  loadingBox: {
    position: 'absolute',
    top: 3,
    right: 0
  },
  loadingButton: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    minWidth: 0,
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  }
};

export default SelectedAppUnit;
