import React, { useEffect, useState } from 'react';
import { Box, Grid, Pagination, Tab, Tabs, Typography } from '@mui/material';
import theme from 'data/theme';
import ProductCard from 'features/QuikSpec/Components/UiParts/ProductCard';
import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';
import ExpandedProductCard from './ExpandedProductCard';
import useQuantityChange from '../../Hooks/useQuantityChange';
import { analyticsService } from 'services/googleAnalyticsService';
interface Props {
  categories: QuikspecCategory[];
}

const QuikspecCategoryTabs = ({ categories }: Props) => {
  const defaultItemsPerPage = 4;

  const [page, setPage] = React.useState(1);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedRadioItem, setSelectedRadioItem] = useState<string>('');
  const { handleQuantityChange, directGetQuantity } = useQuantityChange(selectedRadioItem, false, 1);

  /**
   * 1)If any item within a category has its multipleAllowed set to false, we will handle it using RadioButton.
   * We'll assume there are multiple items with multipleAllowed set to false.
   * In such cases, we'll take the first item as the default value.
   *
   * 2) The handleQuantityChange function should be executed within a useEffect hook because we need to wait until selectedRadioItem is updated.
   * This ensures that changes are handled correctly after the state has been updated.
   * */
  useEffect(() => {
    if (!selectedRadioItem) {
      categories[selectedTab].items.forEach((item) => {
        /**
         * If 'selectedRadioItem' is not empty, it means we have already found the default value.
         * Therefore, we just need to skip the loop.
         * */
        if (item?.multipleAllowed === false) {
          if (directGetQuantity(item.deviceName) > 0) {
            return setSelectedRadioItem(item.deviceName);
          }
        }
      });
    }
    handleQuantityChange('1');
  }, [selectedRadioItem]);

  /**
   * When the radio button component updates,
   * it implies that the previous value will always be false ('0'),
   * and the selected value will always be true ('1').
   * In this function, we set the previous value to false.
   * The current value is set in the useEffect component after the state has been updated.
   * */
  const handleRadioBtnChange = (itemName: string) => {
    selectedRadioItem && handleQuantityChange('0');
    setSelectedRadioItem(itemName);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    let label = `Page ${value}`;
    if (value === page + 1) {
      label = 'Next Page';
    } else if (value === page - 1) {
      label = 'Previous Page';
    }
    analyticsService.trackPaginationClick('QuikSpec', 'Pagination Click', label);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(1);
    setSelectedTab(newValue);
    // Track the tab change event with analytics
    const tabLabel = categories[newValue].title; // Assuming the title can serve as a unique identifier for the tab
    analyticsService.trackTabClick('QuikSpec', tabLabel);
  };

  return (
    <Box sx={styles.mainStationsBox}>
      <Grid container justifyContent="center" flexDirection={'column'} sx={styles.mainStationsTabGrid}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {categories.map((product) => (
            <Tab key={product.id} label={product.title} sx={styles.mainStationsTab} />
          ))}
        </Tabs>

        <Grid container justifyContent="center">
          <Grid item xs={12} sx={styles.categoryDescription}>
            <Typography variant="h6" component={'h6'} sx={styles.descriptionContainer}>
              {categories[selectedTab].description}
            </Typography>
          </Grid>
          {categories[selectedTab].items
            .slice(
              (page - 1) * (categories[selectedTab].itemsPerPage || defaultItemsPerPage),
              page * (categories[selectedTab].itemsPerPage || defaultItemsPerPage)
            )
            .map((item, index) =>
              item?.multipleAllowed === false ? (
                <ExpandedProductCard
                  item={item}
                  key={index}
                  index={index}
                  selectedItem={selectedRadioItem}
                  changeSelectedItem={handleRadioBtnChange}
                />
              ) : (
                <ProductCard item={item} key={index} index={index} />
              )
            )}
        </Grid>

        <Grid container justifyContent={'center'}>
          <Pagination
            count={Math.ceil(
              categories[selectedTab].items.length / (categories[selectedTab].itemsPerPage || defaultItemsPerPage)
            )}
            page={page}
            onChange={handleChange}
            color="secondary"
            sx={styles.pagination}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainStationsBox: {
    my: 4,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  mainStationsTabGrid: {
    my: 2,
    width: '95%',
    mx: 'auto'
  },
  mainStationsTab: {
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: '0',
    '&:first-of-type': {
      borderTopLeftRadius: '7px',
      borderBottomLeftRadius: '7px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '7px',
      borderBottomRightRadius: '7px'
    },
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f0f0f0',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#d0d0d0',
      opacity: 1
    }
  },
  categoryDescription: {
    my: 2,
    py: 2
  },
  descriptionContainer: {
    color: '#2c3e50',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 500
  },
  pagination: {
    justifyContent: 'center',
    position: 'relative'
  }
};

export default QuikspecCategoryTabs;
