import { useCallback } from 'react';
import { useLazyGetBranchWithPublicIdQuery } from 'services/aiphoneCloud';

const useFetchBranches = () => {
  const [getBranchWithPublicId] = useLazyGetBranchWithPublicIdQuery();

  const getBranchById = useCallback(
    async (branchPublicId: string) => {
      if (!branchPublicId) {
        return;
      }
      await getBranchWithPublicId(branchPublicId);
    },
    [getBranchWithPublicId]
  );

  return { getBranchById };
};

export default useFetchBranches;
