import React, { useEffect, useRef, useState } from 'react';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import 'react-h5-audio-player/lib/styles.css';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { IRingtoneSettings, ISound } from '../../../types';
import { useTranslation } from 'react-i18next';

interface IRingtoneSettingsProps {
  ringtoneSetting: IRingtoneSettings;
  onChange: (payload: IRingtoneSettings) => void;
  soundsList: ISound[];
}

const RingtoneSettings = ({ ringtoneSetting, soundsList, onChange }: IRingtoneSettingsProps) => {
  const defaultRingtone = soundsList[0];
  const audioRefNormal = useRef<HTMLAudioElement | null>(null);
  const audioRefPriority = useRef<HTMLAudioElement | null>(null);
  const audioRefUrgent = useRef<HTMLAudioElement | null>(null);

  const [playerConditionNormal, setPlayerConditionNormal] = useState<boolean>(false);
  const [playerConditionPriority, setPlayerConditionPriority] = useState<boolean>(false);
  const [playerConditionUrgent, setPlayerConditionUrgent] = useState<boolean>(false);

  const [currentRingtoneSetting, setCurrentRingtoneSetting] = useState<IRingtoneSettings>(ringtoneSetting);

  const { t } = useTranslation();

  useEffect(() => {
    setCurrentRingtoneSetting(ringtoneSetting);
  }, [ringtoneSetting]);

  // Update the ringtoneTypes state when the ringtoneSetting prop changes
  useEffect(() => {
    setRingtoneTypes({
      normal: getNormalRingtone(),
      priority: getPriorityRingtone(),
      urgent: getUrgentRingtone()
    });
  }, [currentRingtoneSetting]);

  const getNormalRingtone = () => {
    return (
      soundsList.find((item) => item.public_id === currentRingtoneSetting?.callButtonNormalSound) || defaultRingtone
    );
  };

  const getPriorityRingtone = () => {
    return (
      soundsList.find((item) => item.public_id === currentRingtoneSetting?.callButtonPrioritySound) || defaultRingtone
    );
  };

  const getUrgentRingtone = () => {
    return (
      soundsList.find((item) => item.public_id === currentRingtoneSetting?.callButtonUrgentSound) || defaultRingtone
    );
  };

  const initializeRingtoneTypes = () => ({
    normal: getNormalRingtone(),
    priority: getPriorityRingtone(),
    urgent: getUrgentRingtone()
  });

  const [ringtoneTypes, setRingtoneTypes] = useState<{
    [key: string]: ISound | undefined;
  }>(initializeRingtoneTypes());

  // useEffect(() => {
  //   // This will trigger a re-render when playerConditionNormal changes
  // }, [playerConditionNormal]);

  // useEffect(() => {
  //   // This will trigger a re-render when playerConditionPriority changes
  // }, [playerConditionPriority]);

  // useEffect(() => {
  //   // This will trigger a re-render when playerConditionUrgent changes
  // }, [playerConditionUrgent]);

  useEffect(() => {
    onChange(currentRingtoneSetting);
  }, [currentRingtoneSetting]);

  const handleAudioEnded = React.useCallback((type: string) => {
    switch (type) {
      case 'normal':
        setPlayerConditionNormal(false);
        break;
      case 'priority':
        setPlayerConditionPriority(false);
        break;
      case 'urgent':
        setPlayerConditionUrgent(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    // We need to create an event listener to handle the audio ended event
    audioRefNormal.current?.addEventListener('ended', () => handleAudioEnded('normal'));
    audioRefPriority.current?.addEventListener('ended', () => handleAudioEnded('priority'));
    audioRefUrgent.current?.addEventListener('ended', () => handleAudioEnded('urgent'));
    return () => {
      audioRefNormal.current?.removeEventListener('ended', () => handleAudioEnded('normal'));
      audioRefPriority.current?.removeEventListener('ended', () => handleAudioEnded('priority'));
      audioRefUrgent.current?.removeEventListener('ended', () => handleAudioEnded('urgent'));
    };
  }, [handleAudioEnded]);

  const handlePlaySound = React.useCallback(
    (
      type: 'normal' | 'priority' | 'urgent',
      playerCondition: boolean,
      setPlayerCondition: (playing: boolean) => void
    ) => {
      // If the player is currently playing, then stop it
      if (playerCondition) {
        setPlayerCondition(false);
      }

      switch (type) {
        case 'normal':
          if (!playerConditionNormal && audioRefNormal.current) {
            audioRefNormal.current.play().then(() => {
              setPlayerConditionNormal(true);
            });
          }
          break;
        case 'priority':
          if (!playerConditionPriority && audioRefPriority.current) {
            audioRefPriority.current.play().then(() => {
              setPlayerConditionPriority(true);
            });
          }
          break;
        case 'urgent':
          if (!playerConditionUrgent && audioRefUrgent.current) {
            audioRefUrgent.current.play().then(() => {
              setPlayerConditionUrgent(true);
            });
          }
          break;
        default:
          throw new Error(`Unknown type: ${type}`);
      }
    },
    [
      playerConditionNormal,
      playerConditionPriority,
      playerConditionUrgent,
      audioRefNormal,
      audioRefPriority,
      audioRefUrgent
    ]
  );

  const handleRingtoneChange = (type: string, event: SelectChangeEvent<string>) => {
    const selectedRingtonePublicId = event.target.value;
    const ringtoneDetails = soundsList.find((item) => item.public_id === selectedRingtonePublicId);

    setRingtoneTypes((prevRingtoneTypes) => {
      return {
        ...prevRingtoneTypes,
        [type]: ringtoneDetails
      };
    });

    // Reset all player conditions
    setPlayerConditionNormal(false);
    setPlayerConditionPriority(false);
    setPlayerConditionUrgent(false);

    // If any player is currently playing, then stop it
    if (playerConditionNormal) audioRefNormal.current?.pause();
    if (playerConditionPriority) audioRefPriority.current?.pause();
    if (playerConditionUrgent) audioRefUrgent.current?.pause();

    switch (type) {
      case 'normal':
        setCurrentRingtoneSetting((prevRingtoneSetting) => ({
          ...prevRingtoneSetting,
          callButtonNormalSound: selectedRingtonePublicId,
          optionInputNormalSound: selectedRingtonePublicId
        }));
        audioRefNormal.current = new Audio(ringtoneDetails?.s3_location); // Initialize audio element
        break;
      case 'priority':
        setCurrentRingtoneSetting((prevRingtoneSetting) => ({
          ...prevRingtoneSetting,
          callButtonPrioritySound: selectedRingtonePublicId,
          optionInputPrioritySound: selectedRingtonePublicId
        }));
        audioRefPriority.current = new Audio(ringtoneDetails?.s3_location); // Initialize audio element
        break;
      case 'urgent':
        setCurrentRingtoneSetting((prevRingtoneSetting) => ({
          ...prevRingtoneSetting,
          callButtonUrgentSound: selectedRingtonePublicId,
          optionInputUrgentSound: selectedRingtonePublicId
        }));
        audioRefUrgent.current = new Audio(ringtoneDetails?.s3_location);
        break;
    }
  };

  const renderAudioPlayer = React.useCallback(
    (type: string) => {
      let audioRef, playerCondition: boolean, setPlayerCondition: (arg0: boolean) => void;

      switch (type) {
        case 'normal':
          audioRef = audioRefNormal;
          playerCondition = playerConditionNormal;
          setPlayerCondition = setPlayerConditionNormal;
          break;
        case 'priority':
          audioRef = audioRefPriority;
          playerCondition = playerConditionPriority;
          setPlayerCondition = setPlayerConditionPriority;
          break;
        case 'urgent':
          audioRef = audioRefUrgent;
          playerCondition = playerConditionUrgent;
          setPlayerCondition = setPlayerConditionUrgent;
          break;
        default:
          return null;
      }
      return (
        <>
          <IconButton
            onClick={() => {
              handlePlaySound(type, playerCondition, setPlayerCondition);
            }}
            size="small"
          >
            {playerCondition ? (
              <PauseCircleIcon color="primary" sx={styles.playIcon} />
            ) : (
              <PlayCircleFilledIcon color="primary" sx={styles.playIcon} />
            )}
          </IconButton>
          <audio ref={audioRef} src={ringtoneTypes[type]?.s3_location} />
        </>
      );
    },
    [handlePlaySound, playerConditionNormal, playerConditionPriority, playerConditionUrgent, ringtoneTypes]
  );

  return (
    <Box sx={styles.ringtoneSettingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{t('Ringtones')}</Box>
        <Box sx={styles.description}>{t('Select_A_Tone_to_Be_Played')}</Box>
      </Box>
      <Box sx={styles.ringtoneWrapper}>
        <Box sx={{ width: '100%' }}>
          {['normal', 'priority', 'urgent'].map((type) => (
            <Box key={type} sx={{ display: 'flex', marginBottom: 1 }}>
              <Box>
                <FormControl key={type} sx={styles.formControl}>
                  <InputLabel id={`${type}-select-label`}>{`${
                    type.charAt(0).toUpperCase() + type.slice(1)
                  } Sound`}</InputLabel>
                  <Select
                    labelId={`${type}-select-label`}
                    label={`${type.charAt(0).toUpperCase() + type.slice(1)} Sound`}
                    id={`${type}-select`}
                    value={ringtoneTypes[type]?.public_id || ''}
                    onChange={(event) => handleRingtoneChange(type, event)}
                    fullWidth
                    size="small"
                  >
                    {soundsList.map(({ id, sound_name, public_id }) => (
                      <MenuItem key={id} value={public_id}>
                        {sound_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>{renderAudioPlayer(type)}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  descriptionWrapper: {
    width: '100%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  ringtoneWrapper: {
    display: 'flex',
    justifyContent: 'end'
    //border: '1px solid #FF0000'
  },
  ringtoneSettingsWrapper: {
    display: 'flex',
    padding: '15px',
    flexDirection: 'row',
    spacing: 2
    //border: '1px solid #0000FF'
  },
  formControl: {
    marginBottom: 1
  },
  playIcon: {
    fontSize: '30px'
  },
  controlIcons: {
    fontSize: '30px'
  },
  iconButtonsWrapper: {}
};

export default RingtoneSettings;
