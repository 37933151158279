import { Alert, AlertTitle, Box, Divider, Link, List, ListItem, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useEffect, useRef, useState, useCallback, ReactNode } from 'react';
import { ErrorType, ErrorTypeValues } from 'shared/api/ApiError';
import DataUsageCard from 'features/SimBilling/Components/Entrypoints/Site/Children/DataUsageCard';
import SubscriptionPlans from 'features/SimBilling/Components/Entrypoints/Site/Children/SubscriptionPlans';
import ConfirmDialog from 'features/SimBilling/Components/UiParts/ConfirmDialog';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { isAclUserThePayer } from 'features/SimBilling/Utils/SimBillingUtils';
import { ACLUser, SimBillingSite, SubscriptionPlan } from 'features/SimBilling/Types/SimBillingTypes';
import {
  useFetchSubscriptionDetail,
  useFetchSubscriptionPlans,
  useSubscriptionActions
} from 'features/SimBilling/Hooks';
import { useTranslation } from 'react-i18next';
import { SubscriptionStatus } from 'features/SimBilling/Hooks/useFetchSubscriptionDetail';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import SiteInformation from './SiteInformation';
import SubscriptionActions from './SubscriptionActions';
import { TERMS_URL } from 'features/Auth/constants';

interface SiteDetailProps {
  user: ACLUser;
  siteDetails: SimBillingSite;
}

export enum DialogActionTypes {
  NOT_SET = 'notSet',
  BUY_DATA = 'buyData',
  CANCEL_SUBSCRIPTION = 'cancelSubscription',
  SUBSCRIBE = 'subscribe'
}

const isValidErrorKey = (key: any): key is keyof typeof ErrorTypeValues => {
  return key in ErrorTypeValues;
};

const SiteDetail = ({ user, siteDetails }: SiteDetailProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [currentSubscription, setCurrentSubscription] = useState<SubscriptionPlan | undefined>(undefined);
  const timeoutRef = useRef<number | null>(null);
  const [paymentUpdateUrl, setPaymentUpdateUrl] = useState<string | undefined>(undefined);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [dialogContent, setDialogContent] = useState<ReactNode>('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogMessageType, setDialogMessageType] = useState<'success' | 'error' | 'warning' | 'info'>('success');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogLoadingMessage, setDialogLoadingMessage] = useState('');
  const [dialogActionType, setDialogActionType] = useState(DialogActionTypes.NOT_SET);
  const [newSubscription, setNewSubscription] = useState(false);

  const { t } = useTranslation('simbillings');
  const subscriptionId = siteDetails.SubscriptionId;

  const allSIMsDeactivated = siteDetails.GwAndSim.every((sim) => sim.SimStatus === 'DEACTIVATED');
  const hasNoAvailableData = (totalDataAvailable: number) => siteDetails.CurrentDataUsage >= totalDataAvailable;

  const countryCodeForSubscriptionPlans =
    siteDetails.Payer === 'Dealer' ? user?.CompanyCountryCode : siteDetails.CountryCode;
  const { subscriptionPlans, subscriptionPlansError } = useFetchSubscriptionPlans(
    countryCodeForSubscriptionPlans ?? null
  );
  const { currentPriceId, subscriptionDetail, subscriptionDetailError } = useFetchSubscriptionDetail(subscriptionId);

  const subscriptionStatus = subscriptionDetail?.status ?? null;
  const isPastDue = subscriptionStatus && subscriptionStatus === SubscriptionStatus.PAST_DUE;
  const subscriptionInactive = subscriptionStatus && subscriptionStatus !== SubscriptionStatus.ACTIVE;

  const isSubscriptionCanceledButActive = (): boolean => {
    if (siteDetails.SubscriptionId) {
      const subscriptionEndDate = new Date(siteDetails.SubscriptionEndDate);
      return subscriptionStatus === SubscriptionStatus.CANCELED && new Date() < subscriptionEndDate;
    } else return false;
  };

  const formattedSubscriptionEndDate = (): string => {
    if (!siteDetails.SubscriptionEndDate) {
      return t('Subscription.Not_Available');
    }
    const subscriptionEndDate = new Date(siteDetails.SubscriptionEndDate);
    return subscriptionEndDate.toLocaleDateString();
  };

  const isPaymentFailure = useCallback((): boolean => {
    if (subscriptionStatus) {
      return ['past_due', 'unpaid'].includes(subscriptionStatus);
    } else return false;
  }, [subscriptionStatus]);

  const handleResponse = useCallback((message: string, status: boolean, showPaymentUpdateLink?: boolean) => {
    if (showPaymentUpdateLink) {
      createPaymentUpdateSession();
    } else {
      setPaymentUpdateUrl(undefined);
    }
    setDialogLoading(false);
    setDialogMessage(message);
    setDialogMessageType(status ? 'success' : 'error');
    if (showPaymentUpdateLink || status) {
      timeoutRef.current = window.setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const { handleSubscribe, getPaymentUpdateSession } = useSubscriptionActions(
    user,
    siteDetails,
    currentSubscription,
    handleResponse,
    enumList.country
  );

  const createPaymentUpdateSession = async () => {
    const paymentUpdateUrl = await getPaymentUpdateSession(location.href);
    setPaymentUpdateUrl(paymentUpdateUrl);
  };

  useEffect(() => {
    if (user.StripeCustomerId && subscriptionStatus) {
      createPaymentUpdateSession();
    }
  }, [user.StripeCustomerId, isPaymentFailure]);

  useEffect(() => {
    if (subscriptionPlansError) {
      if (isValidErrorKey(subscriptionPlansError)) {
        setErrorMessage(ErrorTypeValues[subscriptionPlansError].message3);
      } else {
        setErrorMessage(ErrorTypeValues[ErrorType.UNEXPECTED].message3);
      }
    } else if (subscriptionDetailError) {
      if (isValidErrorKey(subscriptionDetailError)) {
        setErrorMessage(ErrorTypeValues[subscriptionDetailError].message3);
      } else {
        setErrorMessage(ErrorTypeValues[ErrorType.UNEXPECTED].message3);
      }
    }
  }, [subscriptionPlansError, subscriptionDetailError]);

  useEffect(() => {
    if (currentPriceId && subscriptionPlans && subscriptionPlans.length > 0) {
      setCurrentSubscription(subscriptionPlans.find((item) => item.priceID === currentPriceId));
    }
  }, [subscriptionPlans, currentPriceId]);

  const hasValidIccid = (): boolean => {
    return siteDetails.GwAndSim.some((item) => item.Iccid !== '-' && item.Iccid !== null);
  };

  const handleDialogConfirm = async () => {
    setDialogLoading(true);
    if (dialogActionType === DialogActionTypes.SUBSCRIBE) {
      await handleSubscribe(newSubscription);
    }
  };

  const formatSubscriptionDetails = (subscription: SubscriptionPlan) => {
    const placeholders = {
      '{planName}': `${subscription.dataAmount}GB`,
      '{price}': `${subscription.price}`,
      '{linkToAgreement}': TERMS_URL
    };

    // Ensure `details` is an array; if not, use a default empty array.
    const details = t('Site.Subscribe_Dialog_Content.Details', { returnObjects: true }) as string[];

    return details.map((detail) =>
      Object.keys(placeholders).reduce(
        (formattedDetail, key) => formattedDetail.replace(new RegExp(key, 'g'), placeholders[key]),
        detail
      )
    );
  };

  const openDialog = (
    actionType: DialogActionTypes,
    content: ReactNode,
    title: string,
    loadingMessage: string,
    newSubscription?: any
  ) => {
    setDialogContent(content);
    setDialogTitle(title);
    setDialogActionType(actionType);
    setIsDialogOpen(true);
    setDialogLoadingMessage(loadingMessage);
    if (newSubscription) setNewSubscription(newSubscription);
  };

  const openSubscribeDialog = (newSubscription: SubscriptionPlan) => {
    const formattedDetails = formatSubscriptionDetails(newSubscription);
    const content = (
      <Box>
        <List>
          {formattedDetails.map((detail, index) => (
            <ListItem key={index}>{detail}</ListItem>
          ))}
          <ListItem>
            <Link href={TERMS_URL} target="_blank" rel="noopener noreferrer">
              {t('Site.Subscribe_Dialog_Content.Terms')}
            </Link>
          </ListItem>
        </List>
        <Typography>{t('Site.Subscribe_Dialog_Content.Confirmation')}</Typography>
      </Box>
    );
    openDialog(
      DialogActionTypes.SUBSCRIBE,
      content,
      t('Site.Subscribe_Dialog_Title'),
      t('Site.Subscribe_Loading'),
      newSubscription
    );
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setDialogMessage('');
    setPaymentUpdateUrl(undefined);
  };

  const totalDataAvailableForSite: number =
    Number(currentSubscription?.dataAmount ?? 0) + Number(siteDetails.AdditionalData);
  const isSubscriptionCreatedByCurrentUser = siteDetails.SubscriptionCreatedByAclUserPublicId === user.PublicId;

  return (
    <>
      {(!subscriptionPlans || (subscriptionId && (!currentPriceId || !subscriptionDetail))) &&
        (errorMessage ? (
          <Box sx={styles.boxWithBottomMargin}>
            <Alert severity="error">
              <AlertTitle>{t('Site.Error')}</AlertTitle>
              {errorMessage}
            </Alert>
          </Box>
        ) : (
          <Spinner />
        ))}
      <Box>
        <SiteInformation siteDetails={siteDetails} />
        {siteDetails.SubscriptionId && currentSubscription && (
          <Box>
            <Divider sx={styles.topAndBottomMargin} />
            <Box>
              <DataUsageCard
                totalData={totalDataAvailableForSite}
                usedData={siteDetails.CurrentDataUsage}
                dataUnit={'GB'}
                additionalData={Number(siteDetails.AdditionalData ?? 0)}
                subscriptionData={Number(currentSubscription?.dataAmount ?? 0)}
                subscriptionStatus={subscriptionStatus}
              />
              {allSIMsDeactivated && hasNoAvailableData(totalDataAvailableForSite) && (
                <Alert severity="warning" sx={styles.boxWithBottomMargin}>
                  <AlertTitle>{t('Warning.No_Data_Title')}</AlertTitle>
                  {t('Warning.No_Data_Message')}
                </Alert>
              )}
              {isSubscriptionCanceledButActive() && (
                <Box sx={styles.subscriptionAlert}>
                  <ErrorOutlineIcon sx={styles.subscriptionAlertIcon} />
                  <Typography variant="body1" sx={styles.subscriptionAlertText}>
                    {t('Subscription.Canceled_But_Active', { endDate: formattedSubscriptionEndDate() })}
                  </Typography>
                </Box>
              )}
              {user && siteDetails && isAclUserThePayer(user, siteDetails) && (
                <Box>
                  {!isSubscriptionCreatedByCurrentUser &&
                    subscriptionDetail?.status !== SubscriptionStatus.CANCELED && (
                      <Alert severity="warning" sx={styles.boxWithBottomMargin}>
                        <AlertTitle>{t('Warning.Not_Created_By_You_Title')}</AlertTitle>
                        {t('Warning.Not_Created_By_You')}
                      </Alert>
                    )}
                  <Typography variant="body1" sx={styles.labelsWithMargin}>
                    {t('Payment.Update_Info')} &nbsp;
                    <Link href={paymentUpdateUrl} target="_blank" rel="noopener noreferrer">
                      {t('Payment.Update_Link')}
                    </Link>
                  </Typography>
                  <SubscriptionActions
                    user={user}
                    siteDetails={siteDetails}
                    subscriptionStatus={subscriptionStatus}
                    currentSubscription={currentSubscription}
                    enumList={enumList}
                    handleResponse={handleResponse}
                  />
                  {isPaymentFailure() && (
                    <Box sx={styles.subscriptionAlert}>
                      <ErrorOutlineIcon sx={styles.subscriptionAlertIcon} />
                      <Typography variant="body1" sx={styles.subscriptionAlertText}>
                        {t('Payment.Unpaid_Invoice')}
                        &nbsp;
                        <a href={paymentUpdateUrl} target="_blank" rel="noopener noreferrer">
                          {t('Payment.Update_Payment_Method_Link')}
                        </a>
                      </Typography>
                    </Box>
                  )}
                  <SubscriptionPlans
                    handleSubscribe={openSubscribeDialog}
                    currentPriceId={siteDetails.StripePriceId ?? null}
                    subscriptionPlans={subscriptionPlans}
                    isPastDue={isPastDue}
                    subscriptionInactive={subscriptionInactive === true}
                    isSubscriptionCreatedByUser={isSubscriptionCreatedByCurrentUser}
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
        {isAclUserThePayer(user, siteDetails) && !siteDetails.SubscriptionId && hasValidIccid() && (
          <Box>
            <Typography variant="h6" sx={styles.labelsWithMargin}>
              {t('Subscription.No_Plan')}
            </Typography>
            <SubscriptionPlans
              handleSubscribe={openSubscribeDialog}
              currentPriceId={siteDetails.StripePriceId ?? null}
              subscriptionPlans={subscriptionPlans}
              isPastDue={isPastDue}
              subscriptionInactive={subscriptionInactive === true}
            />
          </Box>
        )}
        <ConfirmDialog
          isOpen={isDialogOpen}
          title={dialogTitle}
          content={dialogContent}
          onClose={handleCloseDialog}
          onConfirm={handleDialogConfirm}
          isLoading={dialogLoading}
          loadingMessage={dialogLoadingMessage}
          message={dialogMessage}
          messageType={dialogMessageType}
        />
      </Box>
    </>
  );
};

const styles = {
  boxWithBottomMargin: { mb: 2 },
  button: { marginRight: 2 },
  labelsWithMargin: {
    mb: 2,
    mt: 2
  },
  topAndBottomMargin: {
    my: 2
  },
  subscriptionAlert: {
    backgroundColor: '#ffe3e3',
    border: '1px solid #ffcccc',
    padding: '16px',
    marginTop: '16px',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  subscriptionAlertIcon: {
    marginRight: '8px',
    color: '#d32f2f'
  },
  subscriptionAlertText: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold'
  }
};

export default SiteDetail;
