import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';

interface IOuputNameSettingProps {
  selectedRelay: IContactOutput;
  setNeedsSave: (needsSave: boolean) => void;
  newRelaySettings: Array<IContactOutput | IDeviceContactInput>;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
}

const strings = {
  title: 'Output Name',
  description: 'A user-friendly name to identify the output.',
  helperText: 'Name of the output.'
};

const OutputNameSetting = ({
  selectedRelay,
  setNeedsSave,
  newRelaySettings,
  setNewRelaySettings
}: IOuputNameSettingProps) => {
  const [outputName, setOutputName] = useState<string>(selectedRelay.outputName);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    // If the selectedRelay is changed from the parent component, update the outputName state
    setOutputName(selectedRelay.outputName);
  }, [selectedRelay]);

  useEffect(() => {
    // Find the relay in newRelaySettings that matches the selectedRelay's publicId
    const matchingRelay = newRelaySettings.find((relay) => relay.publicId === selectedRelay.publicId);
    if (matchingRelay && 'outputName' in matchingRelay && matchingRelay.outputName !== selectedRelay.outputName) {
      // If a matching relay is found and its outputName is different from the selectedRelay's outputName,
      // update the outputName state with the new outputName from newRelaySettings
      setOutputName(matchingRelay.outputName);
    } else {
      // If no matching relay is found or the names are the same, use the selectedRelay's outputName
      setOutputName(selectedRelay.outputName);
    }
  }, [newRelaySettings, selectedRelay]);

  const handleBlur = () => {

    setIsError(false);
    setErrorMessage('');
   
    if (outputName !== selectedRelay.outputName) {
      setNeedsSave(true);
      setNewRelaySettings((prev) => {
        const newSettingsArray = prev;
        const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);

        const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
        if (index !== -1) {
          if (currentRelaySettings) newSettingsArray[index] = { ...currentRelaySettings, outputName };
        } else {
          newSettingsArray.push({ ...selectedRelay, outputName });
        }
        return newSettingsArray;
      });
    } else {
      setNeedsSave(false);
      setNewRelaySettings((prev) => {
        return prev.filter((relay) => relay.publicId !== selectedRelay.publicId);
      });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Filter out any characters that are not alphanumeric
    const keyValue = event.key;
    if (!/[a-zA-Z0-9]/.test(keyValue))
      event.preventDefault();
  }

  const outputNameChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsError(false);
    setErrorMessage('');
    if (event.target.value.length > 24) {
      setIsError(true);
      setErrorMessage('Maximum length reached');
      return;
    }
    setOutputName(event.target.value);
  }

  return (
    <Box sx={styles.settingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{strings.title}</Box>
        <Box sx={styles.description}> {strings.description}</Box>
      </Box>
      <Box sx={styles.inputWrapper}>
        <TextField
          id="output-name"
          label="Output Name"
          value={outputName}
          onKeyDown={(event) => handleKeyPress(event)}
          onChange={(event) => outputNameChanged(event)}
          onBlur={handleBlur}
          error={isError}
          variant="outlined"
          sx={styles.textField}
          helperText={isError ? errorMessage : strings.helperText}
        />
      </Box>
    </Box>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  inputWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  textField: {
    width: '60%'
  }
};

export default OutputNameSetting;
