import { Box, Fab, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'store';
import { setSiteData, setSiteLoading, clearSiteData } from 'store/slices/siteSlice';
import RemoteManagementSideBar from 'layouts/RemoteManagementSideBar';
import DeviceSideBar from 'layouts/DeviceSideBar';
import UnitSideBar from 'layouts/UnitSideBar';
import {
  useGetAppWithSiteIdQuery,
  useGetBuildingsQuery,
  useGetDeviceListWithSitePublicIdQuery,
  useGetSiteWithPublicIdQuery,
  useGetTenantListWithSiteIdQuery,
  useLazyGetUnitListWithSitePublicIdQuery
} from 'services/aiphoneCloud';
import { setSelectedDevice } from 'store/slices/devicesSlice';
import { Building } from 'store/slices/buildingsSlice';

const selectSite = (state: RootState) => state.site;

/**
 * There has to be a better way of doing this. This component is loading the site data, device data, building data, and unit data.
 *
 * If anyone has a better way of doing this, please let me know.
 *
 */

const SiteLoader = () => {
  const [collapsed] = useState(false);
  const [toggled] = useState(false);
  const location = useLocation();
  const site = useSelector(selectSite);
  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [getUnits] = useLazyGetUnitListWithSitePublicIdQuery();
  const { data: siteData, error: siteDataError, isLoading } = useGetSiteWithPublicIdQuery(params.id ?? '');

  // Clear site data when switching between sites
  useEffect(() => {
    dispatch(clearSiteData()); // Reset site data to ensure we don't show the old site's state
  }, [params.id, dispatch]);

  useGetTenantListWithSiteIdQuery(
    {
      sitePublicId: params.id ?? '',
      qty: 500,
      page: 0
    },
    {
      skip: !params.id
    }
  );

  const { error: deviceDataError } = useGetDeviceListWithSitePublicIdQuery(
    {
      sitePublicId: params.id ?? '',
      qty: 500,
      page: 0
    },
    {
      skip: !params.id
    }
  );

  const { data: buildingData, isSuccess: buildingDataSuccess } = useGetBuildingsQuery(params.id ?? '', {
    skip: !params.id
  });

  // Removing unused variables
  useGetAppWithSiteIdQuery(
    {
      sitePublicId: params.id ?? '',
      page: 0,
      qty: 500
    },
    {
      skip: !params.id
    }
  );

  useEffect(() => {
    if (buildingDataSuccess) {
      const buildings = buildingData as Building[];
      const primaryBuildingId = Object.values(buildings).find(
        (building) => building.buildingNumber === '0001'
      )?.publicId;
      if (primaryBuildingId) {
        getUnits({
          sitePublicId: params.id ?? '',
          qty: 500,
          page: 0
        });
      }
    }
  }, [buildingDataSuccess, buildingData, params.id, getUnits]);

  useEffect(() => {
    dispatch(setSiteLoading(isLoading));
    if (deviceDataError) {
      console.error('Error fetching devicesBasicInfoList:', deviceDataError);
    }

    if (siteData && !siteDataError) {
      dispatch(setSiteData(siteData));
    }

    if (params.deviceid) {
      dispatch(setSelectedDevice(params.deviceid));
    }
  }, [siteData, siteDataError, isLoading, deviceDataError, dispatch, params.deviceid]);

  let SidebarToRender = RemoteManagementSideBar;

  if (/^\/site\/[a-zA-Z0-9-]+\/devices\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]+)?$/.test(location.pathname)) {
    SidebarToRender = DeviceSideBar;
  } else if (
    /^\/site\/[a-zA-Z0-9-]+\/units\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]+)?(&[a-zA-Z0-9-]+)?$/.test(location.pathname)
  ) {
    SidebarToRender = UnitSideBar;
  }

  return (
    <>
      <SidebarToRender collapsed={collapsed} toggled={toggled} />
      <Box component={'main'} sx={styles.mainSection}>
        {isLoading ? (
          <Box sx={styles.loaderContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {site.Error && site.StatusCode === 404 ? (
              <Box>404 Site Not Found</Box>
            ) : (
              <>
                <Outlet />
                {site.siteInfo && !site.siteInfo.isWizardCompleted && !location.pathname.endsWith('/wizard') && (
                  <Link to={`${params.id}/wizard`} style={styles.link}>
                    <Fab variant="extended" color="primary" aria-label="add" sx={styles.fab}>
                      Return to Setup Wizard
                    </Fab>
                  </Link>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    bgcolor: 'neutral.light',
    height: 'calc(100% - 64px)',
    overflow: 'auto'
  },
  mainSection: {
    p: 4,
    width: '100%',
    height: '100%'
  },
  fab: {
    display: 'relative',
    position: 'absolute',
    bottom: 16,
    left: 300
  },
  link: {
    textDecoration: 'none',
    color: 'white'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    flexGrow: 1
  }
};

export default SiteLoader;
