import { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import RingtoneSettings from './settings/RingtoneSettings';
import DoorReleaseSettings from './settings/DoorReleaseSettings';
import { IRingtoneSettings, ISound } from '../../types';
import { getSelectedDevice, IDeviceWithAddressBook, updateSelectedDevice } from 'store/slices/devicesSlice';
import { getString } from 'shared/utils/LocalizationUtils';
import { AddressBook } from 'data/Device/Variables/AddressBook';
import { useDispatch, useSelector } from 'react-redux';
import { IRingtoneSettingList } from 'data/Device/Variables/RingtoneSettingList';
import { RootState } from 'store';
import { useParams } from 'react-router-dom';

/**
 * Naming conventions inside this component:
 * *** selectedDevice - device which exist inside targetDevice's address book.
 * *** targetDevice - the main device inside which address book we are adding devices.
 *
 * */
interface ISelectedDeviceSettingsProps {
  selectedDeviceStationName: string;
  selectedDevicePublicId: string;
  soundsList: ISound[];
  onUpdateTargetDevice?: (updatedDevice: IDeviceWithAddressBook) => void;
}

const SelectedDeviceSettings = ({
  selectedDeviceStationName,
  selectedDevicePublicId,
  soundsList
}: ISelectedDeviceSettingsProps) => {
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const selectedDevice = useSelector(getSelectedDevice);
  const hostDevicePublicId = useParams().deviceid || '';
  const hostDevice = deviceList[hostDevicePublicId];
  const dispatch = useDispatch();
  const isSubMaster = selectedDevice?.basicInfo?.deviceType === 5;
  const defaultRingtoneSetting = {
    // default ringtone settings -> public_id's
    callButtonNormalSound: soundsList[1].public_id,
    callButtonPrioritySound: soundsList[2].public_id,
    callButtonUrgentSound: soundsList[3].public_id,
    optionInputNormalSound: soundsList[1].public_id,
    optionInputPrioritySound: soundsList[2].public_id,
    optionInputUrgentSound: soundsList[3].public_id
  };
  const [loading] = useState(false);
  const selectDevice = getString('Please_Select_Device');

  if (loading) {
    return (
      <Box sx={styles.centeredWrapper}>
        <Box>
          <CircularProgress color="inherit" />
        </Box>
      </Box>
    );
  }

  if (!selectedDevicePublicId) {
    return (
      <Box sx={styles.emptyStateWrapper}>
        <Typography sx={styles.emptyStateText}>{selectDevice}</Typography>
      </Box>
    );
  }

  /**Helpers*/
  const getDoorReleaseSettingForSubMaster = (): boolean => {
    const groupKey = searchContactGroupList();
    const unlockOperationGroupList = hostDevice?.deviceSettings?.unlockOperationGroupList;
    const unlockDeviceList = groupKey ? (unlockOperationGroupList ?? {})[groupKey]?.deviceList || [] : [];
    if (groupKey === null) {
      return false;
    }
    const selectedDeviceUnlockSetting = unlockDeviceList.find(
      (unlockDevice) => unlockDevice.devicePublicId === selectedDevicePublicId
    );
    return selectedDeviceUnlockSetting?.deviceTarget === 2;
  };

  const getDoorReleaseSetting = (): boolean => {
    /***
     * If the selected device is a sub master station, then the door release setting is in the unlockOperationGroupList
     */

    if (isSubMaster) {
      return getDoorReleaseSettingForSubMaster();
    }
    /**
     * Need to check exist this selectedDevice inside door release object of target device.
     * exist - that mean that this device can open the door.(checkbox true)
     * doesn't exist - that mean that this device can't open the door.(checkbox false)
     * */
    const addressBook = hostDevice.systemInfo?.addressBook || [];
    const selectedDeviceUnlockSetting = addressBook.find(
      (addressBook) => addressBook.targetDevicePublicId === selectedDevicePublicId
    );
    return selectedDeviceUnlockSetting?.unlockSetting?.deviceTarget === 2;
  };

  const getRingtoneSetting = (): IRingtoneSettings => {
    const addressBook = selectedDevice.systemInfo?.addressBook || [];
    const selectedAddressBookDevice = addressBook.find(
      (addressBook) => addressBook.targetDevicePublicId === selectedDevicePublicId
    );
    return selectedAddressBookDevice?.ringtoneSetting || defaultRingtoneSetting;
  };

  const searchContactGroupList = () => {
    const keys = ['6', '7', '8', '9', '10'];
    for (const key of keys) {
      const targetList = selectedDevice?.callSettings?.contactGroupList[key].targetList || [];
      if (targetList.length > 0) {
        const found = targetList.find((target) => target.targetDevicePublicId === selectedDevicePublicId);
        if (found) {
          return key;
        }
      }
    }
    return null;
  };

  /**
   * The redux value for Door Release for the sub Master station is in deviceSettings.unlockOperationGroupList
   * TheList Key needs to match with the contactGroupList key where the device is present.
   * @param doorReleaseCondition
   * @returns
   */
  const updateDoorReleaseForSubMaster = (doorReleaseCondition: boolean) => {
    const newDevice = JSON.parse(JSON.stringify(selectedDevice));
    // find the contactGroupList key where this device exist based on public_id
    const key = searchContactGroupList();
    if (!key) {
      return;
    }

    const unlockOperationGroupList = newDevice.deviceSettings?.unlockOperationGroupList || {};
    const deviceList = unlockOperationGroupList[key]?.deviceList ?? [];
    const updatedDeviceList = deviceList.map((target: any) => {
      if (target.devicePublicId === selectedDevicePublicId) {
        // update the deviceTarget value based on doorReleaseCondition
        // 1 - disable, 2 - enable'
        return {
          ...target,
          deviceTarget: doorReleaseCondition ? 2 : 1
        };
      }
      return target;
    });
    unlockOperationGroupList[key].deviceList = updatedDeviceList;

    const updatedTargetDeviceDoorRelease = {
      ...newDevice,
      deviceSettings: {
        ...newDevice.deviceSettings,
        unlockOperationGroupList: unlockOperationGroupList
      }
    };
    dispatch(updateSelectedDevice({ device: updatedTargetDeviceDoorRelease }));
  };

  const updateUnlockSettings = (doorReleaseCondition: boolean): AddressBook[] => {
    const addressBook = selectedDevice.systemInfo?.addressBook || [];
    if (doorReleaseCondition) {
      return addressBook.map((addressBookItem) => {
        if (addressBookItem.targetDevicePublicId === selectedDevicePublicId) {
          return {
            ...addressBookItem,
            ringtoneSetting: addressBookItem?.ringtoneSetting,
            unlockSetting: {
              deviceTarget: 2
            }
          };
        }
        return addressBookItem;
      });
    } else {
      //return address book without unlock setting for selected device
      return addressBook.map((addressBookItem) => {
        if (addressBookItem.targetDevicePublicId === selectedDevicePublicId) {
          return {
            ...addressBookItem,
            ringtoneSetting: addressBookItem?.ringtoneSetting,
            unlockSetting: { deviceTarget: 1 }
          };
        }
        return addressBookItem;
      });
    }
  };

  const updateRingtoneSettings = (ringtones: IRingtoneSettings): IRingtoneSettingList[] => {
    const addressBook = selectedDevice.systemInfo?.addressBook || [];
    return addressBook.map((addressBookItem) => {
      if (addressBookItem.targetDevicePublicId === selectedDevicePublicId) {
        return {
          ...addressBookItem,
          ringtoneSetting: ringtones,
          unlockSetting: addressBookItem?.unlockSetting
        };
      } else {
        return addressBookItem;
      }
    });
  };

  /**Handlers*/
  const handleDoorReleaseUpdate = (doorRelease: boolean) => {
    if (isSubMaster) {
      updateDoorReleaseForSubMaster(doorRelease);
    } else {
      const addressBookWithUnlockSetting = updateUnlockSettings(doorRelease);
      const updatedTargetDeviceDoorRelease = {
        ...selectedDevice,
        systemInfo: {
          ...selectedDevice.systemInfo,
          addressBook: addressBookWithUnlockSetting
        }
      };
      dispatch(updateSelectedDevice({ device: updatedTargetDeviceDoorRelease }));
    }
  };

  const handleRingtoneUpdate = (payload: IRingtoneSettings) => {
    const updatedRingtoneSettings = updateRingtoneSettings(payload);

    const updatedTargetDeviceRingtone = {
      ...selectedDevice,
      systemInfo: {
        ...selectedDevice.systemInfo,
        addressBook: updatedRingtoneSettings
      }
    };
    dispatch(updateSelectedDevice({ device: updatedTargetDeviceRingtone }));
  };

  return (
    <Box sx={styles.selectedDeviceWrapper}>
      <Box sx={styles.title}>Modifying settings for {selectedDeviceStationName}</Box>
      {hostDevice.basicInfo.deviceType !== 15 && (
        <DoorReleaseSettings onChange={handleDoorReleaseUpdate} doorReleaseCondition={getDoorReleaseSetting()} />
      )}
      {!isSubMaster && (
        <RingtoneSettings
          onChange={handleRingtoneUpdate}
          soundsList={soundsList}
          ringtoneSetting={getRingtoneSetting()}
        />
      )}
    </Box>
  );
};

const styles = {
  multipleActionsWrapper: {
    padding: '20px'
  },
  selectedDeviceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '10px',
    textAlign: 'center',
    backgroundColor: '#F0F0F0'
  },
  description: {
    fontSize: '17px',
    color: 'grey'
  },
  saveButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    display: 'flex'
  },
  emptyStateWrapper: {
    justifyContent: 'left',
    padding: '20px'
  },
  emptyStateText: {
    fontSize: '20px',
    color: 'grey'
  },
  centeredWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
};

export default SelectedDeviceSettings;
