import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { getString } from 'shared/utils/LocalizationUtils';
import { RootState } from 'store';
import { IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';

interface IOutputFunctionSettingProps {
  selectedRelay: IContactOutput;
  setNeedsSave: (needsSave: boolean) => void;
  newRelaySettings: Array<IContactOutput | IDeviceContactInput>;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
  outputFunction: number;
  setOutputFunction: (outputFunction: number) => void;
}

const strings = {
  title: 'Output Function',
  description: 'Choose a function.'
};

const generateOutputFunctionOptions = (enumList: EnumList, deviceType: number) => {
  const doorStationDevices = [5, 8, 9, 11, 12, 20];
  const doorStationOptions = [1, 2, 4];

  const masterStationDevices = [4, 16];
  const masterStationOptions = [1, 2, 4];

  const tenantStationDevices = [14];
  const tenantStationOptions = [1, 2];

  const entranceStationDevices = [15];
  const entranceStationOptions = [1, 4];

  if (doorStationDevices.includes(deviceType)) {
    return Object.entries(enumList.contactOutputFunction).map(([key, option]) => {
      if (doorStationOptions.includes(parseInt(key))) {
        return (
          <MenuItem key={key} value={parseInt(key)}>
            {option.value}
          </MenuItem>
        );
      }
    });
  } else if (masterStationDevices.includes(deviceType)) {
    return Object.entries(enumList.contactOutputFunction).map(([key, option]) => {
      if (masterStationOptions.includes(parseInt(key))) {
        return (
          <MenuItem key={key} value={parseInt(key)}>
            {option.value}
          </MenuItem>
        );
      }
    });
  } else if (tenantStationDevices.includes(deviceType)) {
    return Object.entries(enumList.contactOutputFunction).map(([key, option]) => {
      if (tenantStationOptions.includes(parseInt(key))) {
        return (
          <MenuItem key={key} value={parseInt(key)}>
            {option.value}
          </MenuItem>
        );
      }
    });
  } else if (entranceStationDevices.includes(deviceType)) {
    return Object.entries(enumList.contactOutputFunction).map(([key, option]) => {
      if (entranceStationOptions.includes(parseInt(key))) {
        return (
          <MenuItem key={key} value={parseInt(key)}>
            {option.value}
          </MenuItem>
        );
      }
    });
  }

  return Object.entries(enumList.contactOutputFunction).map(([key, option]) => {
    return (
      <MenuItem key={key} value={parseInt(key)}>
        {option.value}
      </MenuItem>
    );
  });
};

const OutputFunctionSetting = ({
  selectedRelay,
  setNeedsSave,
  newRelaySettings,
  setNewRelaySettings,
  outputFunction,
  setOutputFunction
}: IOutputFunctionSettingProps) => {
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const deviceType = deviceList[selectedRelay.devicePublicId].basicInfo.deviceType;

  useEffect(() => {
    setOutputFunction(selectedRelay.outputFunction);
  }, [selectedRelay]);
  const outputFunctionText = getString('Output_Function');

  useEffect(() => {
    // Find the relay in newRelaySettings that matches the selectedRelay's publicId
    const matchingRelay = newRelaySettings.find((relay) => relay.publicId === selectedRelay.publicId);
    if (
      matchingRelay &&
      'outputFunction' in matchingRelay &&
      matchingRelay.outputFunction !== selectedRelay.outputFunction
    ) {
      // If a matching relay is found and its outputFunction is different from the selectedRelay's outputFunction,
      // update the outputFunction state with the new outputFunction from newRelaySettings
      setOutputFunction(matchingRelay.outputFunction);
    } else {
      // If no matching relay is found or the functions are the same, use the selectedRelay's outputFunction
      setOutputFunction(selectedRelay.outputFunction);
    }
  }, [newRelaySettings, selectedRelay]);

  const handleChange = (event: SelectChangeEvent<number>) => {
    const outputFunction = event.target.value as number;
    if (selectedRelay.outputFunction !== outputFunction) {
      setNeedsSave(true);
      setNewRelaySettings((prev) => {
        const newSettingsArray = prev;
        const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);
        const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
        if (index !== -1) {
          if (currentRelaySettings) newSettingsArray[index] = { ...currentRelaySettings, outputFunction };
        } else {
          newSettingsArray.push({ ...selectedRelay, outputFunction });
        }
        return newSettingsArray;
      });
    } else {
      setNeedsSave(false);
      setNewRelaySettings((prev) => {
        return prev.filter((relay) => relay.publicId !== selectedRelay.publicId);
      });
    }
    setOutputFunction(event.target.value as number);
  };

  const enumList: EnumList = fetchLocalEnumList();

  return (
    <Box sx={styles.settingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{strings.title}</Box>
        <Box sx={styles.description}> {strings.description}</Box>
      </Box>
      <Box sx={styles.selectWrapper}>
        <FormControl sx={styles.selectField}>
          <InputLabel id="output-function-label">{outputFunctionText}</InputLabel>
          <Select
            labelId="output-function-label"
            id="output-function"
            value={outputFunction}
            label="Output Function"
            onChange={handleChange}
          >
            {generateOutputFunctionOptions(enumList, deviceType)}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  selectWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  selectField: {
    width: '60%'
  }
};

export default OutputFunctionSetting;
