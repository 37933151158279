import { CreateQuikSpecData, GetQuikspecsData, UpdateQuikSpecData } from '../../../shared/api/Aws/apiParamsTypes';
import { FormValues } from '../Types/QuikspecFormTypes';

/*
 * Function to create QuikSpec data for C2 API request body format
 * @param filteredValues - FormValues object
 * @returns data - object with QuikSpec data for C2 API request body
 */
export const createQuikSpecData = (filteredValues: FormValues, countryCode: string | null) => {
  //transform FormValueItems to lineItems for C2 API
  const lineItems = filteredValues.items.map((item) => ({
    quantity: item.quantity,
    title: item.itemName,
    category: item.category
  }));

  if (!countryCode) {
    throw new Error('Country code is undefined');
  }

  const data = {
    formValues: filteredValues,
    countryCode,
    quote: {
      companyid: filteredValues.companyid,
      contactid: filteredValues.contactid,
      description: filteredValues.systemforC2,
      specName: filteredValues.description,
      ownerid: filteredValues.contactid,
      specmarket: filteredValues.marketType,
      sourcetable: 'C2_OPPORTUNITY',
      spectype: 'Quik Spec',
      lineitems_json: {
        children: lineItems
      }
    }
  };

  return data as CreateQuikSpecData;
};

/*
 * Function to form QuikSpec data for C2 API request body format for GET request
 * @param values - FormValues object
 * @returns data - object with QuikSpec data for C2 API body
 */
export const getQuikSpecsData = ({ c2CompanyId, c2ContactId }: { c2CompanyId: string; c2ContactId: string }) => {
  const data = {
    quote: {
      companyid: c2CompanyId,
      contactid: c2ContactId
    }
  };

  return data as GetQuikspecsData;
};

/*
 * Function to update QuikSpec data for C2 API request body format
 * @param filteredValues - FormValues object
 * @returns data - object with QuikSpec data for C2 API request body
 */
export const updateQuikSpecData = (filteredValues: FormValues, countryCode: string | null) => {
  //transform FormValueItems to lineItems for C2 API
  const lineItems = filteredValues.items.map((item) => ({
    quantity: item.quantity,
    title: item.itemName,
    category: item.category
  }));

  if (!countryCode) {
    throw new Error('Country code is undefined');
  }

  const data = {
    formValues: filteredValues,
    countryCode,
    quote: {
      id: filteredValues.quikspecId,
      companyid: filteredValues.companyid,
      contactid: filteredValues.contactid,
      description: filteredValues.systemforC2,
      specName: filteredValues.description,
      ownerid: filteredValues.contactid,
      specmarket: filteredValues.marketType,
      sourcetable: 'C2_OPPORTUNITY',
      spectype: 'Quik Spec',
      //lineitems is what most likely will be updated
      lineitems_json: {
        children: lineItems
      }
    }
  };

  return data as UpdateQuikSpecData;
};
