import { getString } from 'shared/utils/LocalizationUtils';

/* CSR tab stub */
export const CSRLabel = () => {
  const csrLabel = getString('CSR_Label');
  return <span>{csrLabel}</span>;
};

const CSR = () => {
  const csrDialog = getString('CSR_Dialog');
  return <span>{csrDialog}</span>;
};

export default CSR;
