/**
 * Global FAB that appears above the bottom right of the screen.
 *
 * Based off of [FAB MUI](https://mui.com/material-ui/react-floating-action-button/)
 */

import React from 'react';
import { Fab, FabProps } from '@mui/material';
import 'styles/frontshine.css';

export interface BaseFABProps extends FabProps {
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  children: React.ReactNode;
  onClick: () => void;
}

/**
 * @function BaseFAB
 * @param props
 * @description A basic floating action button that can be used to create more complex FABs.
 */
export const BaseFAB = (props: BaseFABProps) => {
  // If there are props.sx, combine it with the styles.fab
  // If not, just use the styles.fab
  return (
    <Fab
      sx={(props.sx ? { ...styles.fab, ...props.sx } : styles.fab) as any}
      className={'flex justify-center items-center'}
      {...props}
      onClick={props.onClick}
    >
      {props.children}
    </Fab>
  );
};

const styles = {
  fab: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1
  }
};
