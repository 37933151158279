import { Box, Button, Typography } from '@mui/material';

interface IMultiselectControlPanelProps {
  handleDelete: () => void;
}

const strings = {
  title: 'You have selected several devices.',
  description: 'Please choose the action you want to perform with these devices.',
  commonSettingsBtn: ' Apply common settings'
};

const MultiselectControlPanel = ({ handleDelete }: IMultiselectControlPanelProps) => {
  return (
    <Box style={styles.multipleActionsWrapper}>
      <Typography style={styles.title}>{strings.title}</Typography>
      <Typography style={styles.description}>{strings.description}</Typography>
      <Box style={styles.btnsWrapper}>
        <Button variant="outlined" color="error" disabled={false} onClick={handleDelete}>
          Delete
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  multipleActionsWrapper: {
    padding: '20px'
  },
  title: {
    fontSize: '23px'
  },
  description: {
    fontSize: '17px',
    color: 'grey'
  },
  btnsWrapper: {
    display: 'flex',
    width: '350px',
    justifyContent: 'space-between',
    marginTop: '30px'
  }
};

export default MultiselectControlPanel;
