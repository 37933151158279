import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { IDevice } from 'store/slices/devicesSlice';
import 'styles/frontshine.css';

interface IControlPanelProps {
  selectedDevice: IDevice;
  targetDevice: IDevice;
  addNewDeviceHandler: () => void;
  handleUpdate: (payload: IDevice) => void;
  isSaving: boolean;
}

const ControlPanel = (props: IControlPanelProps) => {
  const { selectedDevice, addNewDeviceHandler, handleUpdate, targetDevice, isSaving } = props;
  const isSubMaster = selectedDevice.basicInfo.deviceType === 5;
  return (
    <Box style={styles.controlPanelWrapper}>
      <Box style={styles.buttonsWrapper}>
        <Box className="mx-2">
          <Button variant="contained" onClick={addNewDeviceHandler}>
            {isSubMaster ? 'Add to Contact Group' : 'Add to Address Book'}
          </Button>
        </Box>
        <Box>
          <LoadingButton
            variant="outlined"
            color="primary"
            loading={isSaving}
            onClick={() => {
              handleUpdate(targetDevice);
            }}
          >
            Save Changes
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  controlPanelWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  multiselectWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end'
  }
};

export default ControlPanel;
