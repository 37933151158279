/**
 * Security Settings has 4 tabs (ID & Password, CSR, SSL Certificate, IEEE 802.1X)
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from '../DeviceTabs';
import { useSelector } from 'react-redux';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { Box } from '@mui/material';

const SecuritySettings = () => {
  const selectedDevice = useSelector(getSelectedDevice)?.basicInfo;
  if (!selectedDevice) return <Box>Error Loading Device</Box>;
  const securitySettingsTabs: DeviceTabType[] = [
    DeviceTabType.SecuritySettings_IDPassword
    //Hiding CSR, non-mvp: DeviceTabType.SecuritySettings_CSR,
    //Hiding SSLCertificate, non-mvp: DeviceTabType.SecuritySettings_SSLCertificate,
    //Hiding IEEE8021X, non-mvp: DeviceTabType.SecuritySettings_IEEE8021X
  ];

  return <TabsContainer tabTypes={securitySettingsTabs} />;
};

export default SecuritySettings;
