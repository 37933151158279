import React, { useEffect, useRef, useState } from 'react';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import 'react-h5-audio-player/lib/styles.css';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { IRingtoneSettings, ISound } from '../../../types';

interface IRingtoneSettingsProps {
  ringtoneSetting: IRingtoneSettings;
  onChange: (payload: IRingtoneSettings) => void;
  soundsList: ISound[];
}

const strings = {
  title: 'Ringtones',
  description: 'Select a tone to be played when receiving a call from this station.'
};

const RingtoneSettings = ({ ringtoneSetting, soundsList, onChange }: IRingtoneSettingsProps) => {
  const defaultRingtone = soundsList[0];
  const audioRefNormal = useRef<HTMLAudioElement | null>(null);
  const audioRefPriority = useRef<HTMLAudioElement | null>(null);
  const audioRefUrgent = useRef<HTMLAudioElement | null>(null);

  const [playerConditionNormal, setPlayerConditionNormal] = useState<boolean>(false);
  const [playerConditionPriority, setPlayerConditionPriority] = useState<boolean>(false);
  const [playerConditionUrgent, setPlayerConditionUrgent] = useState<boolean>(false);

  const [currentRingtoneSetting, setCurrentRingtoneSetting] = useState<IRingtoneSettings>(ringtoneSetting);

  const getNormalRingtone = () => {
    return (
      soundsList.find((item) => item.public_id === currentRingtoneSetting?.callButtonNormalSound) || defaultRingtone
    );
  };

  const getPriorityRingtone = () => {
    if (ringtoneSetting?.callButtonPrioritySound) {
      return soundsList.find((item) => item.public_id === ringtoneSetting.callButtonPrioritySound);
    } else {
      return defaultRingtone;
    }
  };

  const getUrgentRingtone = () => {
    if (ringtoneSetting?.callButtonUrgentSound) {
      return soundsList.find((item) => item.public_id === ringtoneSetting.callButtonUrgentSound);
    } else {
      return defaultRingtone;
    }
  };

  const initializeRingtoneTypes = () => ({
    normal: getNormalRingtone(),
    priority: getPriorityRingtone(),
    urgent: getUrgentRingtone()
  });

  const [ringtoneTypes, setRingtoneTypes] = useState<{
    [key: string]: ISound | undefined;
  }>(initializeRingtoneTypes());

  useEffect(() => {
    // This will trigger a re-render when playerConditionNormal changes
  }, [playerConditionNormal]);

  useEffect(() => {
    // This will trigger a re-render when playerConditionPriority changes
  }, [playerConditionPriority]);

  useEffect(() => {
    // This will trigger a re-render when playerConditionUrgent changes
  }, [playerConditionUrgent]);

  useEffect(() => {
    onChange(currentRingtoneSetting);
  }, [currentRingtoneSetting]);

  const handlePlaySound = (type: any) => {
    switch (type) {
      case 'normal':
        if (playerConditionNormal) {
          audioRefNormal?.current?.pause();
          setPlayerConditionNormal(false);
        } else if (audioRefNormal.current) {
          audioRefNormal.current
            .play()
            .then(() => {
              setPlayerConditionNormal(true);
            })
            .catch((error) => {
              console.error('Error playing audio:', error);
            });
        }
        break;
      case 'priority':
        if (playerConditionPriority) {
          audioRefPriority?.current?.pause();
          setPlayerConditionPriority(false);
        } else if (audioRefPriority.current) {
          audioRefPriority.current
            .play()
            .then(() => {
              setPlayerConditionPriority(true);
            })
            .catch((error) => {
              console.error('Error playing audio:', error);
            });
        }
        break;
      case 'urgent':
        if (playerConditionUrgent) {
          audioRefUrgent?.current?.pause();
          setPlayerConditionUrgent(false);
        } else if (audioRefUrgent.current) {
          audioRefUrgent.current
            .play()
            .then(() => {
              setPlayerConditionUrgent(true);
            })
            .catch((error) => {
              console.error('Error playing audio:', error);
            });
        }
        break;
      default:
        return;
    }
  };

  const handleRingtoneChange = (type: string, event: SelectChangeEvent<string>) => {
    const selectedRingtonePublicId = event.target.value;
    const ringtoneDetails = soundsList.find((item) => item.public_id === selectedRingtonePublicId);

    setRingtoneTypes((prevRingtoneTypes) => {
      const updatedRingtoneTypes = {
        ...prevRingtoneTypes,
        [type]: ringtoneDetails
      };
      return updatedRingtoneTypes;
    });

    switch (type) {
      case 'normal':
        setCurrentRingtoneSetting((prevRingtoneSetting) => ({
          ...prevRingtoneSetting,
          callButtonNormalSound: selectedRingtonePublicId,
          optionInputNormalSound: selectedRingtonePublicId
        }));
        audioRefNormal.current = new Audio(ringtoneDetails?.s3_location); // Initialize audio element
        break;
      case 'priority':
        setCurrentRingtoneSetting((prevRingtoneSetting) => ({
          ...prevRingtoneSetting,
          callButtonPrioritySound: selectedRingtonePublicId,
          optionInputPrioritySound: selectedRingtonePublicId
        }));
        audioRefPriority.current = new Audio(ringtoneDetails?.s3_location); // Initialize audio element
        break;
      case 'urgent':
        setCurrentRingtoneSetting((prevRingtoneSetting) => ({
          ...prevRingtoneSetting,
          callButtonUrgentSound: selectedRingtonePublicId,
          optionInputUrgentSound: selectedRingtonePublicId
        }));
        audioRefUrgent.current = new Audio(ringtoneDetails?.s3_location);
        break;
    }
  };

  const renderAudioPlayer = (type: string) => {
    let audioRef, playerCondition, setPlayerCondition;

    switch (type) {
      case 'normal':
        audioRef = audioRefNormal;
        playerCondition = playerConditionNormal;
        setPlayerCondition = setPlayerConditionNormal;
        break;
      case 'priority':
        audioRef = audioRefPriority;
        playerCondition = playerConditionPriority;
        setPlayerCondition = setPlayerConditionPriority;
        break;
      case 'urgent':
        audioRef = audioRefUrgent;
        playerCondition = playerConditionUrgent;
        setPlayerCondition = setPlayerConditionUrgent;
        break;
      default:
        return null;
    }
    return (
      <>
        <IconButton onClick={() => handlePlaySound(type)} size="small">
          {playerCondition ? (
            <PauseCircleIcon color="primary" sx={styles.playIcon} />
          ) : (
            <PlayCircleFilledIcon color="primary" sx={styles.playIcon} />
          )}
        </IconButton>
        <audio ref={audioRef} src={ringtoneTypes[type]?.s3_location} onEnded={() => setPlayerCondition(false)} />
      </>
    );
  };

  return (
    <Box sx={styles.ringtoneSettingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{strings.title}</Box>
        <Box sx={styles.description}>{strings.description}</Box>
      </Box>
      <Box sx={styles.ringtoneWrapper}>
        <Box sx={{ width: '100%' }}>
          {['normal', 'priority', 'urgent'].map((type) => (
            <Box sx={{ display: 'flex', marginBottom: 1 }}>
              <Box>
                <FormControl key={type} sx={styles.formControl}>
                  <InputLabel id={`${type}-select-label`}>{`${
                    type.charAt(0).toUpperCase() + type.slice(1)
                  } Sound`}</InputLabel>
                  <Select
                    labelId={`${type}-select-label`}
                    label={`${type.charAt(0).toUpperCase() + type.slice(1)} Sound`}
                    id={`${type}-select`}
                    value={ringtoneTypes[type]?.public_id || ''}
                    onChange={(event) => handleRingtoneChange(type, event)}
                    fullWidth
                    size="small"
                  >
                    {soundsList.map(({ id, sound_name, public_id }) => (
                      <MenuItem key={id} value={public_id}>
                        {sound_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>{renderAudioPlayer(type)}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  descriptionWrapper: {
    width: '100%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  ringtoneWrapper: {
    display: 'flex',
    justifyContent: 'end'
    //border: '1px solid #FF0000'
  },
  ringtoneSettingsWrapper: {
    display: 'flex',
    padding: '15px',
    flexDirection: 'row',
    spacing: 2
    //border: '1px solid #0000FF'
  },
  formControl: {
    marginBottom: 1
  },
  playIcon: {
    fontSize: '30px'
  },
  controlIcons: {
    fontSize: '30px'
  },
  iconButtonsWrapper: {}
};

export default RingtoneSettings;
