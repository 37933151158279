import { Autocomplete, TextField } from '@mui/material';
import { IUnit } from 'store/slices/unitsSlice';

interface ISearchableDropdownProps {
  dropdownOptions: IUnit[];
  selectDeviceHandler: (event: any, value: any) => void; // todo change types
}

const SearchableDropdown = (props: ISearchableDropdownProps) => {
  const defaultValue = '-';
  const { dropdownOptions, selectDeviceHandler } = props;
  return (
    <Autocomplete
      getOptionLabel={(option: IUnit) => `${option?.unitName || defaultValue} / ${option?.unitNumber || defaultValue}`}
      getOptionKey={(option: IUnit) => option.publicId}
      isOptionEqualToValue={(option: IUnit, value: IUnit) => option.publicId === value.publicId}
      disablePortal
      id="devices-for-address-book"
      options={dropdownOptions}
      sx={{ width: '100%' }}
      onChange={selectDeviceHandler}
      blurOnSelect
      clearOnBlur
      renderInput={(params) => <TextField {...params} label="Search App" />}
    />
  );
};

export default SearchableDropdown;
