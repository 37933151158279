import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';

interface IDoorReleaseCodeSettingProps {
  selectedRelay: IContactOutput;
  setNeedsSave: (needsSave: boolean) => void;
  newRelaySettings: Array<IContactOutput | IDeviceContactInput>;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
  outputFunction: number;
}

const strings = {
  title: 'Door Release Code',
  description: 'Security code for door release. Shorten this code if the door will be unlocked via DTMF.'
};

const DoorReleaseCodeSetting = ({
  selectedRelay,
  setNeedsSave,
  newRelaySettings,
  setNewRelaySettings,
  outputFunction
}: IDoorReleaseCodeSettingProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(outputFunction != 4);
  const [unlockKey, setUnlockKey] = useState<string>(selectedRelay.unlockKey);

  useEffect(() => {
    setIsDisabled(outputFunction != 4);
  }, [outputFunction]);

  useEffect(() => {
    setUnlockKey(selectedRelay.unlockKey);
  }, [selectedRelay]);

  useEffect(() => {
    // Find the relay in newRelaySettings that matches the selectedRelay's publicId
    const matchingRelay = newRelaySettings.find((relay) => relay.publicId === selectedRelay.publicId);
    if (matchingRelay && 'outputName' in matchingRelay && matchingRelay.unlockKey !== selectedRelay.unlockKey) {
      // If a matching relay is found and its unlockKey is different from the selectedRelay's unlockKey,
      // update the unlockKey state with the new unlockKey from newRelaySettings
      setUnlockKey(matchingRelay.unlockKey);
    } else {
      // If no matching relay is found or the names are the same, use the selectedRelay's unlockKey
      setUnlockKey(selectedRelay.unlockKey);
    }
  }, [newRelaySettings, selectedRelay]);

  const handleBlur = () => {

    if (unlockKey !== selectedRelay.unlockKey) {
      setNeedsSave(true);
      setNewRelaySettings((prev) => {
        const newSettingsArray = prev;
        const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);

        const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
        if (index !== -1) {
          if (currentRelaySettings) newSettingsArray[index] = { ...currentRelaySettings, unlockKey };
        } else {
          newSettingsArray.push({ ...selectedRelay, unlockKey });
        }
        return newSettingsArray;
      });
    } else {
      setNeedsSave(false);
      setNewRelaySettings((prev) => {
        return prev.filter((relay) => relay.publicId !== selectedRelay.publicId);
      });
    }
  };

  if (outputFunction != 4) {
    return null;
  }

  // TextField number types do not stop events for character keys of '.' or '+' or '-'. We must intercept them manually.
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const keyValue = event.key;
    if (/[.+-]/.test(keyValue))
      event.preventDefault();
  }

  const releaseCodeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input: string = event.target.value;
    if (input.length > 20) { return }
    setUnlockKey(input);
  }

  return (
    <Box sx={styles.settingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{strings.title}</Box>
        <Box sx={styles.description}> {strings.description}</Box>
      </Box>
      <Box sx={styles.inputWrapper}>
        <TextField
          id="door-release-code"
          label="Door Release Code"
          type='number'
          value={unlockKey}
          onKeyDown={handleKeyPress}
          onChange={releaseCodeChanged}
          onBlur={handleBlur}
          variant="outlined"
          sx={styles.textField}
          disabled={isDisabled}
        />
      </Box>
    </Box>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%',
    float: 'right'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  inputWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  textField: {
    width: '60%'
  }
};

export default DoorReleaseCodeSetting;
