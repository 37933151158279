import { useCallback } from 'react';
import { initAclUser } from 'store';
import { useAppDispatch } from 'store/hooks';
import { processAclUserData } from 'features/SimBilling/Stores/aclUserSlice';

const useProcessAclUser = () => {
  const dispatch = useAppDispatch();

  const initialize = () => {
    dispatch(initAclUser());
  };

  const processUserData = useCallback(
    async (userName: string, alcToken: string) => {
      await dispatch(processAclUserData({ userName: userName, aclToken: alcToken }));
    },
    [dispatch]
  );

  return { initialize, processUserData };
};

export default useProcessAclUser;
