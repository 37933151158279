import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import { useTranslation } from 'react-i18next';

// Stylesheet for App Registration PDF
const styles = StyleSheet.create({
  section: {
    margin: 25,
    padding: 25
  },
  title: {
    fontSize: 24,
    textAlign: 'center'
  },
  text: {
    textAlign: 'center',
    fontSize: 14
  },
  imageContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: 100,
    height: 100
  }
});

// Properites passed into the AppResigstionDoc react component
interface AppRegistrationProps {
  buildingNumber: string;
  unitNumber: string;
  qrCodeData: string[];
}

// Component to create Application Registration PDF
const AppRegistrationDoc: React.FC<AppRegistrationProps> = ({ buildingNumber, unitNumber, qrCodeData }) => {
  const { t } = useTranslation();

  if (qrCodeData.length === 0) {
    return (
      <Document>
        <Page size="A4">
          <View style={styles.section}>
            <Text style={styles.title}>{t('AppRegistrationDoc.noCodes')}</Text>
            <Text style={styles.title}>{t('AppRegistrationDoc.noCodesSync')}</Text>
          </View>
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      {qrCodeData.map((qrcode, index) => (
        <Page key={qrcode} size="A4">
          <View style={styles.section}>
            <Text style={styles.title}>
              {t('AppRegistrationDoc.title')} ({index + 1}/{qrCodeData.length})
            </Text>
            <Text> </Text>
            <Text style={styles.text}>
              {t('AppRegistrationDoc.buildingNumber')} {buildingNumber} {t('AppRegistrationDoc.unitNumber')}{' '}
              {unitNumber}
            </Text>
            <Text style={styles.text}>
              {t('AppRegistrationDoc.availableApps')}
              {qrCodeData.length}
            </Text>
            <Text> </Text>
            <Text style={styles.text}>{t('AppRegistrationDoc.scanMessage')}</Text>
            <Text style={styles.text}>{t('AppRegistrationDoc.usage')}</Text>
          </View>
          <View style={styles.imageContainer}>
            <Image src={QRCode.toDataURL(qrcode, { errorCorrectionLevel: 'H' })} style={styles.image} />
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default AppRegistrationDoc;
