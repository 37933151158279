import { useState } from 'react';
import { Container, Paper, Tab } from '@mui/material';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CompaniesDataGrid from '../Datagrids/CompaniesDataGrid';
import BranchesDataGrid from '../Datagrids/BranchesDataGrid';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useTranslation } from 'react-i18next';

const DealerCompanies = () => {
  const [currentTab, setCurrentTab] = useState('1');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isAllowedTo } = usePermission();
  const canViewCompany = isAllowedTo('company:view', null, PermissionsContextType.GLOBAL);
  const canViewBranch = isAllowedTo('branch:view', null, PermissionsContextType.GLOBAL);
  const { t } = useTranslation();
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Paper>
          <TabContext value={currentTab}>
            <TabList onChange={handleTabChange}>
              {canViewCompany && <Tab label={t('Companies')} value="1" />}
              {canViewBranch && <Tab label={t('Branches')} value="2" />}
            </TabList>
            {canViewCompany && (
              <TabPanel value="1">
                <CompaniesDataGrid />
              </TabPanel>
            )}
            {canViewBranch && (
              <TabPanel value="2">
                <BranchesDataGrid />
              </TabPanel>
            )}
          </TabContext>
        </Paper>
      </Container>
      <SnackbarAlert
        type={isError ? 'error' : 'success'}
        time={3000}
        text={errorMessage}
        onClose={() => {
          setErrorMessage('');
          setIsError(false);
        }}
        isOpen={isError}
      />
    </>
  );
};

export default DealerCompanies;
