import { useCallback } from 'react';
import { resetAclSiteError } from 'store';
import { useAppDispatch } from 'store/hooks';
import { processAndFetchSitesDetails } from 'features/SimBilling/Stores/aclSitesSlice';
import { ACLSite } from 'features/SimBilling/Types/SimBillingTypes';

const useProcessSite = () => {
  const dispatch = useAppDispatch();

  const resetError = () => {
    dispatch(resetAclSiteError());
  };

  const processSiteData = useCallback(
    async (aclToken: string, siteId: number, site: ACLSite) => {
      await dispatch(processAndFetchSitesDetails({ aclToken: aclToken, siteId: siteId, site: site }));
    },
    [dispatch]
  );

  return { resetError, processSiteData };
};

export default useProcessSite;
