import {
  DialogProps,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { getLatestFirmwareDownloadUrl, LatestFirmwareDownload } from 'shared/utils/firmwareFunctions';
import React from 'react';
import { useTranslation } from 'react-i18next';

// We are making a dialog to prompt the user to download the latest firmware for IXGW-GW

export interface PromptDownloadLatestFirmwareDialogProps extends DialogProps {
  deviceType: string;
  onClose: () => void;
}

export const PromptDownloadLatestFirmwareDialog = (props: PromptDownloadLatestFirmwareDialogProps) => {
  const [downloadUrl, setDownloadUrl] = React.useState<string | null>(null);
  const [downloadVersionNumber, setDownloadVersionNumber] = React.useState<string | null>(null);
  const [versionName, setVersionName] = React.useState<string | null>(null);

  const { t } = useTranslation();

  const handleDownloadPressed = React.useCallback(() => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    }
    props.onClose();
  }, [downloadUrl, props.onClose]);

  React.useEffect(() => {
    getLatestFirmwareDownloadUrl(props.deviceType).then((data: LatestFirmwareDownload | null) => {
      if (data) {
        setDownloadUrl(data.downloadUrl);
        setDownloadVersionNumber(data.versionNumber.toString());
        setVersionName(data.name);
      }
    });
  }, [props.deviceType]);

  // If there is no download URL, we don't need to show the dialog
  if (!downloadUrl) {
    return null;
  } else {
    return (
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>{t('Download_Latest_Firmware_Button')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>{t('Use_IXG_Support_Tool')}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            {t('My_IXGW-GW_Firmware_Is_Up_To_Date')}
          </Button>
          <Button onClick={handleDownloadPressed} color="primary">
            <b>Download {versionName?.replace('.bin', '')}</b> (version {downloadVersionNumber})
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
};
