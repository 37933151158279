import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';

export const updateFormValuesJV = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesJV(values);
  updateWiresJV(values);
  updateNotesJV(values);
  return values;
};

const updateItemQuantityWithAccessoriesJV = (values: FormValues) => {
  const items = Object.values(values.items);

  // Add RY-1824L depending on the number of doors
  const totalDoorStations = items
    .filter((item) => item.category === 'Door Stations')
    .reduce((total: number, item) => total + item.quantity, 0);
  const RY1824LIndex = items.findIndex((item) => item.itemName === 'RY-1824L');
  items[RY1824LIndex].quantity = totalDoorStations;

  // Kit determination
  const JV1MDWIndex = items.findIndex((item) => item.itemName === 'JV-1MD');
  const JVDVIndex = items.findIndex((item) => item.itemName === 'JV-DV');
  const JVDVFIndex = items.findIndex((item) => item.itemName === 'JV-DVF');
  const JVS1VIndex = items.findIndex((item) => item.itemName === 'JVS-1V');
  const JVS1FIndex = items.findIndex((item) => item.itemName === 'JVS-1F');
  items[JVS1VIndex].quantity = 0;
  items[JVS1FIndex].quantity = 0;

  if (items[JV1MDWIndex].quantity > 0) {
    if (items[JVDVIndex].quantity > 0) {
      items[JVS1VIndex].quantity = 1;
      items[JV1MDWIndex].quantity = 0;
      items[JVDVIndex].quantity = 0;
    } else if (items[JVDVFIndex].quantity > 0) {
      items[JVS1FIndex].quantity = 1;
      items[JV1MDWIndex].quantity = 0;
      items[JVDVFIndex].quantity = 0;
    }
  }
};

const updateWiresJV = (values: FormValues) => {
  values.wires.push(
    {
      system: 'Door to Master Station',
      wire: '871802',
      description: "2 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: "330'",
      jacketNomOD: 'PVC .190"',
      capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
    },
    {
      system: 'Power to Master Station',
      wire: '871802',
      description: "2 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: "33'",
      jacketNomOD: 'PVC .190"',
      capacitanceLoopRes: '11.05 pf/ft, 6.385 Ohms'
    }
  );

  if (
    values.items
      .filter((item) => item.itemName === 'JV-1FD')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'Master Station to Expansion Station',
      wire: '871804',
      description: "4 Cond., 18AWG, Solid, Non-shielded, Low-cap, Available in: 500' & 1000'",
      maxDistance: "330'",
      jacketNomOD: 'PVC .290"',
      capacitanceLoopRes: '13.6 pf/ft, 6.385 Ohms'
    });
  }
};

const updateNotesJV = (values: FormValues) => {
  if (
    values.items
      .filter((item) => item.itemName === 'RY-ES')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The RY-ES provides a momentary contact upon activation. Any external device with its own power supply may be connected to this contact. (Not supplied by Aiphone.)'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-1824L')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'An RY-1824L has been added to the parts list for door release. The JV Series Mobile-Ready master station has a N/O dry contact, rated at 24V AC/DC, 1A. If this specification meets your system requirements, then a separate relay is not required. If the door release hardware is rated at a higher voltage or current draw, or if you are using a magnetic lock, include the door release relay specified above.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IER-2')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The IER-2 will emit the same tone as the master during an incoming call (on and off ringing). There is a 3-position switch to adjust the call tone volume.'
    );
  }
};
