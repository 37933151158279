import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Field, Form, Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import { useState, useMemo, useCallback, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useCreateUnitMutation,
  useLazyGetBuildingsQuery,
  useLazyGetUnitListWithSitePublicIdQuery
} from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { RootState } from 'store';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { VALID_STATION_NAME_REGEX } from 'shared/constants/regex';

interface IAddUnitDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
interface IUnitOption {
  unit: {
    name: string;
    type: number;
    description: string;
  };
}

interface IUnitDetailsProps {
  formikProps: FormikProps<any>;
}

const UnitOption = ({ unit }: IUnitOption) => {
  const { name, description } = unit;

  return (
    <Box>
      <Typography variant="body1">{name}</Typography>
      <Box sx={styles.unitDescriptionWrapper}>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Box>
  );
};

const AddUnitDialog = ({ isOpen, setIsOpen }: IAddUnitDialogProps) => {
  const [selectedUnit, setSelectedUnit] = useState(2);
  const [currentStep, setCurrentStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
  const [isBatchAddUnit, setIsBatchAddUnit] = useState(false);
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const buildingOptions = Object.entries(buildingList)
    .map(([key, building]) => {
      return { key, value: building.buildingNumber };
    })
    .sort((a, b) => a.value.localeCompare(b.value));
  const sitePublicId = useParams().id;
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const [createUnit, { isLoading }] = useCreateUnitMutation();
  const [fetchUnits] = useLazyGetUnitListWithSitePublicIdQuery();
  const [fetchBuildings] = useLazyGetBuildingsQuery();

  const { t } = useTranslation();

  const initialValues = {
    buildingNumber: buildingOptions.length > 0 ? buildingOptions[0].key : '',
    unitNumber: '',
    unitName: '',
    unitStartingNumber: '',
    unitEndingNumber: ''
  };

  const unitNumberValidation = useMemo(
    () =>
      yup
        .string()
        .matches(/^\d+$/, t('Unit_Number_must_be_only_digits'))
        .required(t('Unit_Number_is_required'))
        .test('max', t('Unit_Number_must_be_at_most_10_digits'), (value) => value.length <= 10)
        .test('min', t('Unit_Number_must_be_at_least_3_digits'), (value) => value.length >= 3),
    [t]
  );

  const generateValidation = useCallback(
    (isBatchAddUnit: boolean) => {
      if (isBatchAddUnit) {
        return yup.object().shape({
          buildingNumber: yup.string().required(t('Building_Number_is_required')),
          unitStartingNumber: unitNumberValidation
            .required(t('Starting_Unit_number_is_required'))
            .test('range-unique', t('One_or_more_unit_numbers_in_the_range_already_exist'), (value, context) => {
              const startNum = parseInt(value.toString(), 10);
              const endNum = parseInt(context.parent.unitEndingNumber, 10);
              const buildingNumber = context.parent.buildingNumber;
              const buildingUnits = Object.values(unitList).filter((unit) => unit.buildingPublicId === buildingNumber);
              const reservedUnitNumbers = buildingUnits.map((unit) => unit.unitNumber);
              for (let num = startNum; num <= endNum; num++) {
                if (reservedUnitNumbers.includes(num.toString())) {
                  return false;
                }
              }
              return true;
            })
            .test('range', t('Starting_Unit_number_must_be_less_than_ending_unit_number'), (value, context) => {
              const startNum = parseInt(value.toString(), 10);
              const endNum = parseInt(context.parent.unitEndingNumber, 10);
              return startNum < endNum;
            }),
          unitEndingNumber: unitNumberValidation.required(t('Ending_Unit_number_is_required'))
        });
      } else {
        return yup.object().shape({
          unitName: yup
            .string()
            .matches(VALID_STATION_NAME_REGEX)
            .min(1, t('Unit_Name_must_be_at_least_1_character'))
            .max(24, t('Unit_Name_must_be_at_most_24_characters'))
            .required(t('Unit_Name_is_required')),
          buildingNumber: yup.string().required(t('Building_Number_is_required')),
          unitNumber: unitNumberValidation.test('unique', t('Unit_Number_already_exists'), (value, context) => {
            const buildingNumber = context.parent.buildingNumber;
            const buildingUnits = Object.values(unitList).filter((unit) => unit.buildingPublicId === buildingNumber);
            const reservedUnitNumbers = buildingUnits.map((unit) => unit.unitNumber);
            return !reservedUnitNumbers.includes(value.toString());
          })
        });
      }
    },
    [unitList, unitNumberValidation, t]
  );

  const validationSchema = generateValidation(isBatchAddUnit);

  const handleCloseDialog = () => {
    setIsOpen(false);
    resetValues();
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedUnit(Number(event.target.value));
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (values: any) => {
    if (isBatchAddUnit) {
      for (let i = Number(values.unitStartingNumber); i <= Number(values.unitEndingNumber); i++) {
        await createUnit({
          unitData: {
            unitNumber: i.toString(),
            unitName: t('Unit', { number: i }),
            unitType: selectedUnit,
            buildingPublicId: values.buildingNumber
          }
        })
          .unwrap()
          .then(() => {
            setIsSuccessAlertOpen(true);
            fetchUnits({
              sitePublicId: sitePublicId ?? '',
              qty: 500,
              page: 0
            });
            fetchBuildings(sitePublicId ?? '');
            handleCloseDialog();
          })
          .catch(() => {
            setErrorMessage(t('Failed_to_add_unit'));
            setIsErrorAlertOpen(true);
          });
      }
    } else {
      await createUnit({
        unitData: {
          unitNumber: values.unitNumber,
          unitName: values.unitName,
          unitType: selectedUnit,
          buildingPublicId: values.buildingNumber
        }
      })
        .unwrap()
        .then(() => {
          setIsSuccessAlertOpen(true);
          fetchUnits({
            sitePublicId: sitePublicId ?? '',
            qty: 500,
            page: 0
          });
          fetchBuildings(sitePublicId ?? '');
          handleCloseDialog();
        })
        .catch(() => {
          setErrorMessage(t('Failed_to_add_unit'));
          setIsErrorAlertOpen(true);
        });
      setIsOpen(false);
    }
  };

  const handleSwitchAddType = () => {
    setIsBatchAddUnit((prev) => !prev);
  };

  const resetValues = () => {
    setSelectedUnit(2);
    setCurrentStep(1);
    setIsBatchAddUnit(false);
  };

  interface IAddSingleUnit {
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
  }

  const AddSingleUnit = ({ errors, touched }: IAddSingleUnit) => {
    return (
      <Box
        sx={{
          float: 'right',
          width: '100%'
        }}
      >
        <Field
          as={TextField}
          label={t('Unit_Number')}
          name="unitNumber"
          variant="outlined"
          sx={styles.unitNameField}
          error={errors.unitNumber && touched.unitNumber}
          helperText={errors.unitNumber && touched.unitNumber ? errors.unitNumber : null}
        />
      </Box>
    );
  };

  const BatchAddUnits = ({ errors, touched }: IAddSingleUnit) => {
    return (
      <Box
        sx={{
          float: 'right',
          width: '100%'
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label={t('Starting_Unit_Number')}
              name="unitStartingNumber"
              variant="outlined"
              sx={styles.rangeTextField}
              error={errors.unitStartingNumber && touched.unitStartingNumber}
              helperText={errors.unitStartingNumber && touched.unitStartingNumber ? errors.unitStartingNumber : null}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label={t('Ending_Unit_Number')}
              name="unitEndingNumber"
              variant="outlined"
              sx={styles.rangeTextField}
              error={errors.unitEndingNumber && touched.unitEndingNumber}
              helperText={errors.unitEndingNumber && touched.unitEndingNumber ? errors.unitEndingNumber : null}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const SelectUnitType = () => {
    return (
      <Box>
        <RadioGroup name="unitType" defaultValue={2} value={selectedUnit} onChange={handleRadioChange}>
          <Box sx={styles.unitCategory}>
            <Card>
              <Typography variant="h5" sx={{ padding: '15px' }}>
                {t('Common_Areas')}
              </Typography>
              <List>
                {[
                  {
                    name: t('Entrance'),
                    type: 2,
                    description: t('Entrance_unit_explanation')
                  },
                  {
                    name: t('Guard'),
                    type: 1,
                    description: t('Guard_units_description')
                  },
                  {
                    name: t('Outside_Area'),
                    type: 6,
                    description: t('Outside_Area_unit_description')
                  }
                ].map((unit, index) => (
                  // Fixing EsLint error about using index as key
                  <ListItem key={unit.name + index.toString()}>
                    <Card sx={selectedUnit === unit.type ? styles.selectedUnitType : { width: '100%' }}>
                      <CardActionArea
                        sx={styles.unitOptionCard}
                        disableRipple
                        onClick={() => setSelectedUnit(unit.type)}
                      >
                        <UnitOption unit={unit} />
                        <Radio
                          checked={selectedUnit === unit.type}
                          value={unit.type}
                          color="primary"
                          style={{ padding: 0 }}
                          disableTouchRipple
                        />
                      </CardActionArea>
                    </Card>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Box>
          <Box sx={styles.unitCategory}>
            <Card>
              <Typography variant="h5" sx={{ padding: '15px' }}>
                {t('Private_Areas')}
              </Typography>
              <List>
                {[
                  {
                    name: t('Residential'),
                    type: 4,
                    description: t('Residential_units_description')
                  },
                  {
                    name: t('Title_Commercial'),
                    type: 5,
                    description: t('Commercial_units_description')
                  }
                ].map((unit, index) => (
                  // Fixing EsLint error about using index as key
                  <ListItem key={unit.name + index.toString()}>
                    <Card sx={selectedUnit === unit.type ? styles.selectedUnitType : { width: '100%' }}>
                      <CardActionArea
                        sx={styles.unitOptionCard}
                        disableRipple
                        onClick={() => setSelectedUnit(unit.type)}
                      >
                        <UnitOption unit={unit} />
                        <Radio
                          checked={selectedUnit === unit.type}
                          value={unit.type}
                          color="primary"
                          style={{ padding: 0 }}
                          disableTouchRipple
                        />
                      </CardActionArea>
                    </Card>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Box>
        </RadioGroup>
      </Box>
    );
  };

  const UnitDetails = ({ formikProps }: IUnitDetailsProps) => {
    const { handleChange, errors, touched } = formikProps;
    return (
      <>
        <Box sx={styles.settingsWrapper}>
          <Box sx={styles.descriptionWrapper}>
            <Box sx={styles.title}>{t('Select_Building')}</Box>
            <Box sx={styles.description}></Box>
          </Box>
          <Box sx={styles.selectWrapper}>
            <FormControl sx={styles.selectField}>
              <InputLabel id="building-number-label">{t('Select_Building_Number')}</InputLabel>
              <Field as={Select} name="buildingNumber" label={t('Select_Building_Number')} onChange={handleChange}>
                {buildingOptions.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Box>
        </Box>
        <Box sx={styles.settingsWrapper}>
          <Box sx={styles.descriptionWrapper}>
            <Box sx={styles.title}>{t('Unit_Number')}</Box>
            <Box sx={styles.description}>{t('Unit_Numbers_must_be_unique_in_a_given_building')}</Box>
          </Box>
          <Box sx={styles.inputWrapper}>
            <Grid container>
              <Grid item xs={12}>
                {isBatchAddUnit ? (
                  <BatchAddUnits errors={errors} touched={touched} />
                ) : (
                  <AddSingleUnit errors={errors} touched={touched} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end'
                  }}
                >
                  <Button onClick={handleSwitchAddType}>
                    {isBatchAddUnit ? t('Switch_to_single_add') : t('Switch_to_batch_add')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={styles.settingsWrapper}>
          <Box sx={styles.descriptionWrapper}>
            <Box sx={styles.title}>{t('Unit_Name')}</Box>
            <Box sx={styles.description}>{t('The_Unit_name_will_appear')}</Box>
          </Box>
          <Box sx={styles.inputWrapper}>
            <Grid container>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  label={t('Unit_Name')}
                  name="unitName"
                  variant="outlined"
                  sx={styles.unitNameField}
                  disabled={isBatchAddUnit}
                  error={errors.unitName && touched.unitName}
                  helperText={errors.unitName ? errors.unitName : null}
                />
              </Grid>
              <Grid item xs={12}>
                {isBatchAddUnit && (
                  <FormHelperText sx={{ float: 'right' }}>
                    {t('Note_unit_name_will_be_automatically_generated_based_on_the_unit_number')}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  };

  const renderStepContent = (step: number, formikProps: FormikProps<any>) => {
    switch (step) {
      case 1:
        return <SelectUnitType />;
      case 2:
        return <UnitDetails formikProps={formikProps} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ ...props }) => (
            <Form>
              <DialogTitle>{t('Add_a_Unit')}</DialogTitle>
              <DialogContent>{renderStepContent(currentStep, props)}</DialogContent>
              <DialogActions>
                {currentStep === 2 && (
                  <Button variant="contained" onClick={handlePreviousStep}>
                    {t('Button_Back')}
                  </Button>
                )}
                {currentStep === 1 && (
                  <Button variant="contained" onClick={handleNextStep}>
                    {t('Button_Next')}
                  </Button>
                )}
                {currentStep === 2 && (
                  <LoadingButton variant="contained" type="submit" loading={isLoading}>
                    {t('Add_Unit')}
                  </LoadingButton>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <SnackbarAlert
        type="success"
        time={3000}
        text={t('Unit_Added_Successfully')}
        isOpen={isSuccessAlertOpen}
        onClose={() => setIsSuccessAlertOpen(false)}
      />
      <SnackbarAlert
        type="error"
        time={3000}
        text={errorMessage}
        isOpen={isErrorAlertOpen}
        onClose={() => setIsErrorAlertOpen(false)}
      />
    </>
  );
};

const styles = {
  unitDescriptionWrapper: {
    padding: '15px'
  },
  unitOptionCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    border: '1px solid #e0e0e0',
    width: '100%'
  },
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  selectWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  selectField: {
    width: '60%'
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  textField: {
    width: '60%'
  },
  unitNameField: {
    width: '60%',
    float: 'right'
  },
  rangeTextField: {
    width: '100%'
  },
  unitCategory: {
    marginBottom: '20px'
  },
  selectedUnitType: {
    width: '100%',
    border: '1px solid #0071ce',
    backgroundColor: '#f0f8ff'
  }
};

export default AddUnitDialog;
