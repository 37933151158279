import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './data/theme';
import ReactGA from 'react-ga4';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppRoutes from './router/AppRoutes';
import { useEffect } from 'react';
import { DialogProvider } from 'features/RemoteManagement/Components/Dialog/DialogContext';

function App() {
  const getMeasurementId = () => {
    const url = new URL(window.location.href);
    if (url.hostname === 'beta.aiphone.cloud') {
      return 'G-SRCF0YZC2F';
    } else if (url.hostname === 'aiphone.cloud') {
      return 'G-350PJT2TQN';
    }
    return null;
  };

  const measurementId = getMeasurementId();
  if (measurementId) {
    ReactGA.initialize(measurementId);
  }

  useEffect(() => {
    if (measurementId) {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }
  }, [measurementId]);

  useEffect(() => {
    const setWindowHeight = () => {
      document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);
    };

    // Set the initial height
    setWindowHeight();

    // Update height on resize
    window.addEventListener('resize', setWindowHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', setWindowHeight);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {' '}
        {/* Add PersistGate */}
        <ThemeProvider theme={theme}>
          <DialogProvider>
            <CssBaseline />
            <AppRoutes />
          </DialogProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
