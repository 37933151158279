/**
 * This component is used to display each unit in the address book.
 */
import { Box, ListItemIcon, Typography, ListItem, Checkbox } from '@mui/material';
import { IUnit } from 'store/slices/unitsSlice';
import ListItemButton from '@mui/material/ListItemButton';
import { UnitsIcon } from 'shared/icons/icons';
import theme from 'data/theme';
import { UUID } from 'crypto';

interface IUnitAddressBookItemProps {
  unit: IUnit;
  isSelected: boolean;
  handleSelect: (id: string) => void;
  removeUnitFromCallDirectory: (id: UUID) => void;
}

const UnitAddressBook = ({
  unit,
  isSelected,
  handleSelect,
  removeUnitFromCallDirectory
}: IUnitAddressBookItemProps) => {
  const defaultValue = '-';
  const publicId = unit?.publicId || '';

  const handleUnitItemSelection = () => {
    if (isSelected) {
      removeUnitFromCallDirectory(publicId);
      return;
    }
    handleSelect(publicId);
  };

  return (
    <ListItem key={publicId} disablePadding sx={{ padding: '5px' }}>
      <ListItemButton sx={styles.menuItem} onClick={handleUnitItemSelection} selected={true}>
        <Box style={styles.iconWrapper}>
          <ListItemIcon>
            {isSelected ? (
              <Checkbox
                edge="start"
                checked={isSelected}
                onChange={handleUnitItemSelection}
                tabIndex={-1}
                disableRipple
              />
            ) : (
              <UnitsIcon sx={styles.unitIcon} />
            )}
          </ListItemIcon>
        </Box>
        <Box>
          <Typography variant="body2">
            <Typography variant="h7" sx={styles.itemTitle}>
              Unit Name:
            </Typography>
            {unit?.unitName || defaultValue}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

const styles = {
  menuItem: {
    backgroundColor: theme.palette.neutral.light
  },
  iconWrapper: {
    width: '15%',
    cursor: 'pointer'
  },
  itemTitle: {
    marginRight: '5px',
    fontWeight: 'bold'
  },
  unitIcon: {
    width: '20px',
    height: '25px'
  }
};

export default UnitAddressBook;
