type CognitoErrorCode = string | undefined;

export const getCognitoErrorMessage = (errorCode: CognitoErrorCode): string => {
  switch (errorCode) {
    case 'UserNotFoundException':
      return 'User not found.';
    case 'UsernameExistsException':
      return 'An account with this email already exists.';
    case 'NotAuthorizedException':
      return 'Incorrect username or password.';
    case 'InvalidParameterException':
      return 'Invalid input parameters.';
    case 'CodeMismatchException':
      return 'Invalid verification code.';
    case 'ExpiredCodeException':
      return 'The verification code has expired.';
    case 'TooManyRequestsException':
      return 'Too many requests. Please try again later.';
    case 'LimitExceededException':
      return 'A limit has been exceeded. Please try again later.';
    case 'PasswordResetRequiredException':
      return 'Password reset is required.';
    case 'UserNotConfirmedException':
      return 'The user account is not confirmed. Please confirm your account.';
    case 'InvalidPasswordException':
      return 'The password does not meet the required criteria.';
    default:
      return 'An unexpected error occurred.';
  }
};
