import { Alert, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SubscriptionDetailsInfo = () => {
  const { t } = useTranslation('simbillings');

  const isEasternDaylightTime = () => {
    const currentDate = new Date();
    const januaryDate = new Date(currentDate.getFullYear(), 0, 1);
    const julyDate = new Date(currentDate.getFullYear(), 6, 1);
    const standardOffset = Math.max(januaryDate.getTimezoneOffset(), julyDate.getTimezoneOffset());
    const currentOffset = currentDate.getTimezoneOffset();
    return currentOffset < standardOffset;
  };

  return (
    <Alert severity="info" sx={styles.subscriptionDetail}>
      <Typography variant="h6" color="primary" gutterBottom>
        {t('Subscription_Details.Title')}
      </Typography>
      <Box component="ul" sx={styles.subscriptionListItem}>
        <li>
          <Typography variant="body2" component="span">
            {t('Subscription_Details.Restriction', {
              startHour: isEasternDaylightTime() ? '8:00' : '7:00',
              timezone: isEasternDaylightTime() ? 'EDT' : 'EST',
              endHour: isEasternDaylightTime() ? '7:59' : '6:59'
            })}
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="span">
            {t('Subscription_Details.Additional_Data_Cost')}
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="span">
            {t('Subscription_Details.Billing_Cycle')}
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="span">
            {t('Subscription_Details.Data_Refresh')}
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="span">
            {t('Subscription_Details.Data_Reset')}
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="span">
            {t('Subscription_Details.Charges')}
          </Typography>
        </li>
      </Box>
    </Alert>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  subscriptionDetail: {
    my: 2,
    border: '1px solid #007BFF',
    background: '#f0f8ff'
  },
  subscriptionListItem: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  }
};

export default SubscriptionDetailsInfo;
