import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, CardMedia, Grid, Tab, Tabs, Typography } from '@mui/material';
import theme from 'data/theme';
import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';
import useQuantityChange from '../../Hooks/useQuantityChange';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import ProductPopover from 'features/QuikSpec/Components/UiParts/ProductPopover';
import RadioButton from './QuikspecRadioButton';
import QuantityInputForm from 'features/QuikSpec/Components/UiParts/QuantityInputForm';
import strings from 'features/QuikSpec/Components/LanguageFiles/ViewDetail/en.json';

interface Props {
  categories: QuikspecCategory[];
}

const { description } = strings;

const QuikspecCategoryIxgMobile = ({ categories }: Props) => {
  const [licensePayment, setLicensePayment] = useState<string>('');
  const { directChangeQuantity, directGetQuantity } = useQuantityChange(licensePayment, false, 1);
  useEffect(() => {
    if (!licensePayment) {
      if (directGetQuantity('LIFETIME_LICENSE') > 0) {
        setLicensePayment('Lifetime');
      } else {
        setLicensePayment('RMR');
      }
    } else {
      if (licensePayment === 'Lifetime') {
        directChangeQuantity('LIFETIME_LICENSE', 1);
      } else {
        directChangeQuantity('LIFETIME_LICENSE', 0);
      }
    }
  }, [licensePayment]);

  return (
    <Box sx={styles.mainStationsBox}>
      <Grid container justifyContent="center" flexDirection={'column'} sx={styles.mainStationsTabGrid}>
        <Tabs value={0} variant="fullWidth" TabIndicatorProps={{ style: { display: 'none' } }}>
          {categories.map((product) => (
            <Tab key={product.id} label={product.title} sx={styles.mainStationsTab} />
          ))}
        </Tabs>

        <Grid container justifyContent="center">
          <Grid item xs={12} sx={styles.categoryDescription}>
            <Typography variant="h6" component={'h6'} sx={styles.descriptionContainer}>
              {categories[0].description}
            </Typography>
          </Grid>
          <Grid item sm={12} md={3} align="center">
            <Card sx={styles.licenseImage}>
              <CardHeader
                title="IXG Mobile App"
                sx={styles.header}
                titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
              />
              <CardContent sx={styles.cardContent}>
                <CardMedia
                  component="img"
                  image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/IXG-APP.jpg`}
                  sx={styles.deviceImage}
                />
              </CardContent>
              <CardContent sx={styles.cardComp}>
                <ProductPopover description={description.item['IXGAPP']} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={9} sx={styles.form}>
            <Grid item xs={12} sx={styles.numberOfAppsHeader}>
              Number of Apps
            </Grid>
            <Grid item xs={12}>
              Enter the number of Units/Tenants that will be using the IXG Mobile App.
            </Grid>
            <Grid item xs={12} sx={styles.secondQuestion}>
              <QuantityInputForm
                itemName={'IXG-APP'}
                multipleAllowed={undefined}
                isRequired={false}
                maxQuantityPerItem={9999}
                accessories={undefined}
                countWithAccessories={false}
              />
            </Grid>
            <Grid item xs={12} sx={styles.lifetimeLicenseHeader}>
              App Licensing Options
            </Grid>
            <Grid item xs={12}>
              An IXG Mobile App license accommodates up to 8 apps per Unit/Tenant. Monthly or yearly subscriptions or
              lifetime licenses can be purchased through the IXG Portal at the time of installation. Installing an
              IX-MV7 or IXG-2C7 station in the Unit covers the license fee. Alternatively, IXG-APP Lifetime Licenses can
              be purchased through normal distribution channels.
            </Grid>
            <Grid item xs={12} sx={styles.secondQuestion}>
              <RadioButton
                value="Lifetime"
                label="Add IXG-APP Lifetime Licenses to my design"
                checked={'Lifetime' === licensePayment}
                onChange={() => setLicensePayment('Lifetime')}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioButton
                value="RMR"
                label="Purchase licenses later through the IXG Portal or install a station in each Unit"
                checked={'RMR' === licensePayment}
                onChange={() => setLicensePayment('RMR')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainStationsBox: {
    my: 4,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  mainStationsTabGrid: {
    my: 2,
    width: '95%',
    mx: 'auto'
  },
  mainStationsTab: {
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: '0',
    '&:first-of-type': {
      borderTopLeftRadius: '7px',
      borderBottomLeftRadius: '7px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '7px',
      borderBottomRightRadius: '7px'
    },
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f0f0f0',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#d0d0d0',
      opacity: 1
    }
  },
  categoryDescription: {
    my: 2,
    py: 2
  },
  descriptionContainer: {
    color: '#2c3e50',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 500
  },
  licenseImage: {
    width: 'auto',
    height: '340px',
    boxShadow: '3',
    marginBottom: 3,
    position: 'relative'
  },
  header: {
    textAlign: 'center',
    background: 'linear-gradient(to right, #1e3c72, #2a5298)',
    color: '#fff',
    padding: '4px',
    boxShadow: 'inset 0 -1px 0 0 #e0e0e0',
    fontSize: '1.1rem',
    fontWeight: '200'
  },
  cardContent: {
    position: 'relative',
    textAlign: 'center',
    paddingBottom: 0,
    display: 'flex'
  },
  cardComp: {
    textAlign: 'center'
  },
  deviceImage: {
    objectFit: 'contain',
    maxWidth: '200px',
    maxHeight: '200px',
    margin: 'auto',
    display: 'block'
  },
  numberOfAppsHeader: {
    color: 'white.main',
    background: theme.palette.secondary.main,
    textAlign: 'center'
  },
  lifetimeLicenseHeader: {
    color: 'white.main',
    background: theme.palette.secondary.main,
    textAlign: 'center',
    marginTop: '2rem'
  },
  secondQuestion: {
    marginTop: '1rem'
  },
  form: {
    paddingLeft: 3
  }
};

export default QuikspecCategoryIxgMobile;
