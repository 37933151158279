import React from 'react';
import { AppBar, Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import NewSystem from './NewSystem';
import theme from 'data/theme';
import { analyticsService } from 'services/googleAnalyticsService';
import SavedSpecs from 'features/QuikSpec/Components/UiParts/SavedSpecs';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = ({ children, index, value, ...other }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  };
};

interface LinkTabProps {
  label?: string;
  href?: string;
  onTabClick?: (label?: string) => void;
}

const LinkTab = (props: LinkTabProps) => {
  const divProps = Object.assign({}, props);
  delete divProps.onTabClick;

  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        if (props.onTabClick) props.onTabClick(props.label);
      }}
      {...divProps}
    />
  );
};

const QuikspecHome = () => {
  const [value, setValue] = React.useState(0);
  // Hide the By Application option until it is ready
  //const tabLabels = ['New System', 'By Application', 'Saved Specs'];
  const tabLabels = ['New System', 'Saved Specs'];

  const handleTabClick = (label: string) => {
    analyticsService.trackTabClick('Quikspec', label);
  };

  const handleChange = (event: React.ChangeEvent<object>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Paper sx={styles.qsheader}>
        <img
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', height: 120 }}
          src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/quikspec_logo.jpg`}
          alt="QuikSpec"
        />
        <Grid container>
          <Typography align="center" variant="h5" color="inherit" paragraph gutterBottom>
            Aiphone's QuikSpec Program is provided as a tool to help you design an Aiphone system. Product model
            numbers, descriptions, notes, and wire will be given based on your input. The most popular Aiphone
            applications can be designed with QuikSpec.
          </Typography>
        </Grid>
      </Paper>

      <AppBar position="static" sx={styles.appBar}>
        <Tabs
          textColor="inherit"
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          {tabLabels.map((label, index) => (
            <LinkTab
              key={index}
              label={label}
              href={'#' + label.replace(/\s+/g, '').toLowerCase()}
              onTabClick={() => handleTabClick(label)}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} sx={styles.tabPanel}>
        <NewSystem />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SavedSpecs />
      </TabPanel>
      <TabPanel value={value} index={2}>
        We will update the brand new QuikSpec for By Application soon!
      </TabPanel>
    </div>
  );
};

const styles = {
  appBar: {
    bgcolor: theme.palette.primary.dark
  },
  tabPanel: {
    py: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  qsheader: {
    px: 2
  }
};

export default QuikspecHome;
