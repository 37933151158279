import React from 'react';
import { Typography } from '@mui/material';
import BasicFrame from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/other/BasicFrame';
import TabSwitcherContainer, {
  TabView
} from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/tabs/TabSwitcherContainer';
import { useTranslation } from 'react-i18next';
import ArrivalFloorDataGrid from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/datagrids/ArrivalFloorDataGrid';
import PickupFloorDataGrid from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/datagrids/PickupFloorDataGrid';
import BuildingExitDataGrid from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/datagrids/BuildingExitDataGrid';
import 'styles/frontshine.css';

const LiftControlDataGridPage: React.FC = () => {
  const { t } = useTranslation();

  const MemoizedArrivalFloorDataGrid = React.memo(ArrivalFloorDataGrid);
  const MemoizedPickupFloorDataGrid = React.memo(PickupFloorDataGrid);
  const MemoizedBuildingExitDataGrid = React.memo(BuildingExitDataGrid);

  const tabs: TabView[] = React.useMemo(
    () => [
      {
        label: t('Arrival_Floor'),
        ChildTabView: <MemoizedArrivalFloorDataGrid />
      },
      {
        label: t('Pick_Up_Floor'),
        ChildTabView: <MemoizedPickupFloorDataGrid />
      },
      {
        label: t('Building_Exit'),
        ChildTabView: <MemoizedBuildingExitDataGrid />
      }
    ],
    [t]
  );

  return (
    <div>
      <BasicFrame>
        <Typography variant={'h6'} sx={{ textAlign: 'left', fontWeight: 600, mt: '20px' }}>
          {t('Relay_Output_Management')}
        </Typography>
        <TabSwitcherContainer tabs={tabs} />
      </BasicFrame>
    </div>
  );
};

export default LiftControlDataGridPage;
