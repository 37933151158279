import { Container, Paper, Tab } from '@mui/material';
import { useState } from 'react';
import UsersDataGrid from './UsersDataGrid';
import RegisteredUsersDataGrid from './RegisteredUsersDataGrid';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AiphoneUsersDataGrid from './AiphoneUsersDataGrid';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';

const Users = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const currentUser = useSelector(getCurrentUser);
  const currentBranch = useSelector((state: RootState) => state.branches.currentBranch);
  const { isAllowedTo } = usePermission();

  const { t } = useTranslation();
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const canAccessAdminTools =
    isAllowedTo('usersTab:view', null, PermissionsContextType.GLOBAL) &&
    isAllowedTo('organizationsTab:view', null, PermissionsContextType.GLOBAL);

  let canAccessBranchUsersTab = false;

  if (!canAccessAdminTools) {
    const branchPermissions = currentUser?.permissions.branch;

    if (branchPermissions) {
      const firstBranchKey = Object.keys(branchPermissions)[0];
      const firstBranchPublicId = branchPermissions[firstBranchKey]?.roleList?.[0]?.publicId || null;

      canAccessBranchUsersTab = isAllowedTo(
        'usersTab:view',
        currentBranch?.publicId || firstBranchPublicId,
        PermissionsContextType.BRANCH
      );
    }
  }

  return (
    <Container maxWidth="xl">
      <Paper sx={styles.usersPanel}>
        {canAccessAdminTools && (
          <TabContext value={currentTab.toString()}>
            <TabList onChange={handleTabChange}>
              <Tab label="Branch Users" value="0" />
              <Tab label="Registered Users" value="1" />
              <Tab label="Aiphone Users" value="2" />
            </TabList>
            <TabPanel value="0">
              <UsersDataGrid />
            </TabPanel>
            <TabPanel value="1">
              <RegisteredUsersDataGrid />
            </TabPanel>
            <TabPanel value="2">
              <AiphoneUsersDataGrid />
            </TabPanel>
          </TabContext>
        )}
        {!canAccessAdminTools && canAccessBranchUsersTab && (
          <TabContext value={currentTab.toString()}>
            <TabList onChange={handleTabChange}>
              <Tab label="Branch Users" value="0" />
            </TabList>
            <TabPanel value="0">
              <UsersDataGrid />
            </TabPanel>
          </TabContext>
        )}
        {!canAccessAdminTools && !canAccessBranchUsersTab && <div>{t('Dont_Have_Access_To_View_This_Page')}</div>}
      </Paper>
    </Container>
  );
};

const styles = {
  devButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px'
  },
  usersPanel: {
    mt: 2
  },
  usersButtonContainer: {
    display: 'flex',
    float: 'right',
    padding: '20px'
  }
};

export default Users;
