import { Box, Typography } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';
const QuikspecIE = () => {
  const updateIe = getString('Update_New_Quikspec_For_Ie-Series');
  return (
    <Box>
      <Typography align="center" variant="h5">
        {updateIe}
      </Typography>
      {/*<GeneralInfo></GeneralInfo>*/}
    </Box>
  );
};

export default QuikspecIE;
