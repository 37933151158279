import { Box, Grid } from '@mui/material';
import { UUID } from 'crypto';
import SelectedDeviceCard from './SelectedDeviceCard';

interface ISelectedDevicesGridProps {
  selectedDevices: (UUID | string)[];
  handleRemoveFromSelectedDevices: (devicePublicId: string) => void;
}

const SelectedDevicesGrid = ({ selectedDevices, handleRemoveFromSelectedDevices }: ISelectedDevicesGridProps) => {
  // Map through the devices and display the device card
  const displayDeviceCard = () => {
    return selectedDevices.map((device, index) => {
      if (!device) return null;
      return (
        <Grid item key={index}>
          <SelectedDeviceCard
            key={index}
            devicePublicId={device}
            handleRemoveCallDestinationDevice={handleRemoveFromSelectedDevices}
          />
        </Grid>
      );
    });
  };

  return (
    <Box sx={styles.selectedDeviceWrapper}>
      <Grid container spacing={2} justifyContent="center">
        {displayDeviceCard()}
      </Grid>
    </Box>
  );
};
const styles = {
  selectedDeviceWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  descriptionWrapper: {
    marginY: '15px'
  },
  controlPanelWrapper: {
    display: 'flex',
    marginY: '20px'
  },
  devicesAndSettingsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '530px',
    overflow: 'auto'
  },
  filterDevicesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '220px',
    padding: '20px'
  },
  answeringStationsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
    maxHeight: 'var(--window-height)',
    overflow: 'auto',
    backgroundColor: '#EFEFEF'
  },
  addedStationsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto'
  }
};
export default SelectedDevicesGrid;
