/**
 * Entrance Settings _ has only 2 tabs for now.
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';
import { useSelector } from 'react-redux';
import { DeviceCategory } from 'features/RemoteManagement/Types';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { getDeviceCategoryFromDeviceTypeModel } from 'shared/utils/helperFunctions';

const EntranceSettings = () => {
  const selectedDevice = useSelector(getSelectedDevice)?.basicInfo;
  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(selectedDevice?.deviceType, selectedDevice?.deviceModel);
  // Tabs available on all Pages.
  let entranceSettingsTabs: DeviceTabType[] = [];

  switch (deviceCategory) {
    case DeviceCategory.EntranceStation:
      entranceSettingsTabs = [DeviceTabType.Entrance_Display];
      break;
  }

  return <TabsContainer tabTypes={entranceSettingsTabs} />;
};

export default EntranceSettings;
