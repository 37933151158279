import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { IContactOutput, IDeviceContactInput } from 'store/slices/devicesSlice';
import 'react-h5-audio-player/lib/styles.css';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getAllSoundsBySiteId } from 'shared/api/Aws/RemoteManagementApi';

interface IDoorReleaseSoundSettingProps {
  selectedRelay: IContactOutput;
  setNeedsSave: (needsSave: boolean) => void;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
  outputFunction: number;
}

interface IRingtone {
  id: number;
  public_id: string;
  s3_location: string;
  sound_file_name: string;
  sound_name: string;
}

const strings = {
  title: 'Door Release Sound',
  description: 'The sound played by the door station when door release is triggered.'
};

const ringtoneIdFilterConditions = [0, 10, 32, 33, 34, 50, 51, 52];

const DoorReleaseSoundSetting = ({
  selectedRelay,
  setNeedsSave,
  outputFunction,
  setNewRelaySettings
}: IDoorReleaseSoundSettingProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(outputFunction != 4);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [playerCondition, setPlayerCondition] = useState<boolean>(false);
  const [ringtoneList, setRingtoneList] = useState<IRingtone[]>([]);
  const [isAudioLoaded, setIsAudioLoaded] = useState<boolean>(true);
  const doorReleaseRingtonePublicId = selectedRelay.sound;
  const [selectedRingtone, setSelectedRingtone] = useState<IRingtone>();
  const site = useSelector((state: RootState) => state.site);
  const users = useSelector((state: RootState) => state.users);

  const getAllSoundsPayload = {
    siteId: site.siteInfo.publicId,
    userId: users.currentUser?.publicId || ''
  };

  useEffect(() => {
    setIsDisabled(outputFunction != 4);
  }, [outputFunction]);

  useEffect(() => {
    if (site.siteInfo.publicId && users.currentUser?.publicId) {
      const fetchSounds = async () => {
        const allSounds = (await getAllSoundsBySiteId(getAllSoundsPayload)) as IRingtone[];
        const filteredSounds = allSounds.filter((item) => ringtoneIdFilterConditions.includes(item.id));
        setRingtoneList(filteredSounds.length > 0 ? filteredSounds : []);
        const defaultRingtone = allSounds?.find((item) => item.public_id === doorReleaseRingtonePublicId);
        setSelectedRingtone(defaultRingtone);
      };
      fetchSounds();
    }
  }, [getAllSoundsPayload.siteId, getAllSoundsPayload.userId, ringtoneIdFilterConditions]);

  const handlePlaySound = () => {
    // pause the audio if it is playing
    if (playerCondition) {
      audioRef.current?.pause();
      setPlayerCondition(false);
      return;
    }

    // TODO: if the audio has error, set error message
    if (audioRef.current && audioRef.current.error) return;

    if (audioRef.current) {
      setPlayerCondition(true);
      audioRef.current.play();
    }
  };

  const handleRingtoneChange = (event: SelectChangeEvent<string>) => {
    setIsAudioLoaded(false);
    const { value } = event.target;
    const selectedRingtone = ringtoneList.find((item) => item.public_id === value);

    if (selectedRingtone?.public_id !== doorReleaseRingtonePublicId && selectedRingtone) {
      setNeedsSave(true);
      setNewRelaySettings((prev) => {
        const newSettingsArray = prev;
        const currentRelaySettings = newSettingsArray.find((relay) => relay.publicId === selectedRelay.publicId);
        const index = newSettingsArray.findIndex((relay) => relay.publicId === selectedRelay.publicId);
        if (index !== -1) {
          if (currentRelaySettings)
            newSettingsArray[index] = { ...currentRelaySettings, sound: selectedRingtone.public_id };
        } else {
          newSettingsArray.push({ ...selectedRelay, sound: selectedRingtone.public_id });
        }
        return newSettingsArray;
      });
    }

    //There is a delay to play the audio after selecting the ringtone
    setTimeout(() => {
      setIsAudioLoaded(true);
    }, 3000);
    setSelectedRingtone(selectedRingtone);
  };

  if (outputFunction != 4) {
    return null;
  }

  return (
    <Box sx={styles.settingsWrapper}>
      <Box sx={styles.descriptionWrapper}>
        <Box sx={styles.title}>{strings.title}</Box>
        <Box sx={styles.description}> {strings.description}</Box>
      </Box>
      <Box sx={styles.ringtoneWrapper}>
        <FormControl style={styles.formControl}>
          <InputLabel id="demo-simple-select-label">Select sound</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedRingtone?.public_id || ''}
            label="Select ringtone"
            onChange={(event) => handleRingtoneChange(event)}
            disabled={isDisabled}
          >
            {ringtoneList.length === 0 ? (
              <MenuItem value={''}>No ringtone available</MenuItem>
            ) : (
              ringtoneList.map(({ public_id, sound_name }) => (
                <MenuItem key={public_id} value={public_id}>
                  {sound_name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
        <audio
          ref={audioRef}
          preload="auto"
          src={selectedRingtone?.s3_location}
          onEnded={() => setPlayerCondition(false)}
        />
        <IconButton
          onClick={() => {
            handlePlaySound();
          }}
          disabled={isDisabled || !isAudioLoaded}
        >
          {playerCondition ? (
            <PauseCircleIcon color={isDisabled || !isAudioLoaded ? 'disabled' : 'primary'} sx={styles.playIcon} />
          ) : (
            <PlayCircleFilledIcon color={isDisabled || !isAudioLoaded ? 'disabled' : 'primary'} sx={styles.playIcon} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%',
    float: 'right'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  ringtoneWrapper: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  ringtoneSettingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  formControl: {
    width: '275px'
  },
  playIcon: {
    fontSize: '50px'
  },
  controlIcons: {
    fontSize: '30px'
  },
  iconButtonsWrapper: {}
};

export default DoorReleaseSoundSetting;
