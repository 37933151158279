import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  onNavigate: () => void;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
    logErrorToMyService(error, errorInfo);

    setTimeout(() => {
      this.props.onNavigate();
    }, 3000);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <p>We apologize for the inconvenience. Redirecting you to the previous page...</p>
        </div>
      );
    }

    return this.props.children;
  }
}

function logErrorToMyService(error: Error, errorInfo: ErrorInfo) {
  console.error('Logged to external service:', error, errorInfo);
}

export default ErrorBoundary;
