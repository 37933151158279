import { Box, Typography, Slider, Switch } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { IDevice } from 'store/slices/devicesSlice';

interface SoundSettingItemProps {
  setting: any;
  device: IDevice;
}

const styles = {
  h6: {
    alignItems: 'left',
    maxWidth: '500px'
  },

  boxcontainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    padding: '10px'
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid grey',
    marginTop: '5px'
  }
};

export const SoundSettingItem: React.FC<SoundSettingItemProps> = ({ setting }) => {
  const { setFieldValue } = useFormikContext();
  const [switchValue, setSwitchValue] = useState(setting.defaultValue || false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchValue(event.target.checked);
    setFieldValue(setting.name, event.target.checked);
  };

  const renderSetting = (setting: any) => {
    switch (setting.type) {
      case 'switch':
        return (
          <Field
            as={Switch}
            name={setting.name}
            checked={switchValue}
            onChange={handleSwitchChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
      case 'slider':
        return (
          <Field
            as={Slider}
            name={setting.name}
            size="medium"
            aria-labelledby="continuous-slider"
            min={1}
            max={10}
            sx={{ width: '300px' }}
            valueLabelDisplay="auto"
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Typography variant="h6" sx={styles.h6}>
        {setting.settingItemTitle}
      </Typography>
      <Box sx={styles.boxcontainer}>
        <Box sx={styles.box}>
          <Typography variant="body1">{setting.settingItemDescription}</Typography>
          <Box>{renderSetting(setting)}</Box>
        </Box>
      </Box>
    </>
  );
};
