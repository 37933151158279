/**
 * SnackbarAlert component displays a snackbar alert with a specified type, duration, text, and close button.
 */
import React from 'react';
import { Alert as MUIAlert, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Props for the SnackbarAlert component.
 */
interface AlertProps {
  /**
   * The type of the alert. Can be 'success', 'error', 'warning', or 'info'.
   */
  type: 'success' | 'error' | 'warning' | 'info';
  /**
   * The duration in milliseconds for which the snackbar alert should be displayed.
   */
  time: number;
  /**
   * The text content of the snackbar alert.
   */
  text: string;
  /**
   * Determines whether the snackbar alert is open or closed.
   */
  isOpen: boolean;
  /**
   * Callback function to be called when the snackbar alert is closed.
   */
  onClose: () => void;
}

/**
 * SnackbarAlert component displays a snackbar alert with a specified type, duration, text, and close button.
 * @param type - The type of the alert. Can be 'success', 'error', 'warning', or 'info'.
 * @param time - The duration in milliseconds for which the snackbar alert should be displayed.
 * @param text - The text content of the snackbar alert.
 * @param isOpen - Determines whether the snackbar alert is open or closed.
 * @param onClose - Callback function to be called when the snackbar alert is closed.
 * @returns The SnackbarAlert component.
 */
const SnackbarAlert: React.FC<AlertProps> = ({ type, time, text, isOpen, onClose }) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={time}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MUIAlert
        severity={type}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {text}
      </MUIAlert>
    </Snackbar>
  );
};

export default SnackbarAlert;
