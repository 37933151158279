import React from 'react';
import { Box, Container, ContainerProps } from '@mui/material';

export interface BasicFrameProps {
  containerProps?: ContainerProps;
  children: React.ReactNode;
}

const BasicFrame: React.FC<BasicFrameProps> = ({ children, containerProps }: BasicFrameProps) => {
  return (
    <Container
      maxWidth={'lg'}
      {...containerProps}
      sx={{ marginRight: 'auto', marginLeft: 'auto', height: '100%', ...containerProps?.sx }}
    >
      <Box className={'flex flex-col'}>{children}</Box>
    </Container>
  );
};

export default BasicFrame;
