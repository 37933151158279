import { Card, CardActions, CardContent, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IUnit } from 'store/slices/unitsSlice';

interface IAppCard {
  appUnit: IUnit | undefined;
  showRemoveIcon?: boolean;
  onRemove?: (unitPublicId: string) => void;
}

const AppCard = ({ appUnit, showRemoveIcon = false, onRemove }: IAppCard) => {
  if (!appUnit) {
    return (
      <Typography gutterBottom variant="h6">
        Unit Not Found
      </Typography>
    );
  }

  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography gutterBottom variant="h5" component="div" sx={styles.unitTitle}>
              {appUnit.unitName || 'No Name'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Unit Number: </strong>
              {appUnit.unitNumber || 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={styles.cardActions}>
        {showRemoveIcon && (
          <IconButton onClick={() => onRemove && onRemove(appUnit.publicId)} aria-label="remove">
            <DeleteIcon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

const styles = {
  card: {
    width: '390',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '16px',
    boxSizing: 'border-box'
  },
  cardContent: {
    flexGrow: 1
  },
  unitTitle: {
    fontWeight: 400
  },
  cardActions: {
    justifyContent: 'flex-end'
  }
};

export default AppCard;
