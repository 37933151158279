import React from 'react';
import {
  IContactOutput,
  IDeviceContactInput,
  getDeviceTypeModelMapping,
  getSelectedDevice
} from 'store/slices/devicesSlice';
import InputNameSetting from './settings/InputNameSetting';
import InputFunctionSetting from './settings/InputFunctionSetting';
import InputDetectionSetting from './settings/InputDetectionSetting';
import InputPolaritySetting from './settings/InputPolaritySetting';
import InputCallSetting from './settings/InputCallSetting';
import { useSelector } from 'react-redux';

interface IRelayInputSettingsProps {
  selectedRelay: IDeviceContactInput;
  setNeedsSave: (needsSave: boolean) => void;
  newRelaySettings: Array<IContactOutput | IDeviceContactInput>;
  setNewRelaySettings: React.Dispatch<React.SetStateAction<Array<IContactOutput | IDeviceContactInput>>>;
}
const RelayInputSettings = ({
  selectedRelay,
  setNeedsSave,
  newRelaySettings,
  setNewRelaySettings
}: IRelayInputSettingsProps) => {
  const [inputFunction, setInputFunction] = React.useState<number>(selectedRelay.inputFunction); // [1]
  const selectedDevice = useSelector(getSelectedDevice);

  const isSelectedDeviceIOAdaptorType = () => {
    return (
      getDeviceTypeModelMapping(selectedDevice.basicInfo.deviceType, selectedDevice.basicInfo.deviceModel) ===
      'IOAdaptor'
    );
  };

  if (!selectedRelay) {
    return <div>{noRelaySelected}</div>;
  }

  return (
    <>
      {!isSelectedDeviceIOAdaptorType() ? (
        <>
          <InputNameSetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            newRelaySettings={newRelaySettings}
            setNewRelaySettings={setNewRelaySettings}
          />
          <InputFunctionSetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            newRelaySettings={newRelaySettings}
            setNewRelaySettings={setNewRelaySettings}
            inputFunction={inputFunction}
            setInputFunction={setInputFunction}
          />
          <InputDetectionSetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            newRelaySettings={newRelaySettings}
            setNewRelaySettings={setNewRelaySettings}
            inputFunction={inputFunction}
          />
          <InputPolaritySetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            newRelaySettings={newRelaySettings}
            setNewRelaySettings={setNewRelaySettings}
            inputFunction={inputFunction}
          />
          <InputCallSetting
            selectedRelay={selectedRelay}
            setNeedsSave={setNeedsSave}
            setNewRelaySettings={setNewRelaySettings}
            inputFunction={inputFunction}
          />
        </>
      ) : (
        <InputFunctionSetting
          selectedRelay={selectedRelay}
          setNeedsSave={setNeedsSave}
          newRelaySettings={newRelaySettings}
          setNewRelaySettings={setNewRelaySettings}
          inputFunction={inputFunction}
          setInputFunction={setInputFunction}
        />
      )}
    </>
  );
};

export default RelayInputSettings;
