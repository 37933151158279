import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';
import { updateItemQuantityWithAccessoriesAC } from './QuikspecUpdateFormValuesAC';

export const updateFormValuesIX = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesIX(values);
  updateItemQuantityWithAccessoriesAC(values);
  updateWiresIX(values);
  updateNotesIX(values);
  return values;
};

export const updateItemQuantityWithAccessoriesIX = (values: FormValues) => {
  const items = Object.values(values.items);

  // IX-DVF-L requires power for the T-coil
  const IXDVFLIndex = items.findIndex((item) => item.itemName === 'IX-DVF-L');
  const PS2420ULIndex = items.findIndex((item) => item.itemName === 'PS-2420UL');
  items[PS2420ULIndex].quantity += items[IXDVFLIndex].quantity;

  // Towers require the mounting bolts
  const TWMKLIndex = items.findIndex((item) => item.itemName === 'TW-MKL');
  items[TWMKLIndex].quantity += items
    .filter((item) => item.category === 'Emergency Towers')
    .reduce((total: number, item) => total + item.quantity, 0);
};

const updateNotesIX = (values: FormValues) => {
  values.notes.push(
    'Network devices required to connect an IX system are not included in this specification and must be purchased separately. Not available from Aiphone.'
  );
  values.notes.push(
    'The IX stations are PoE powered only. Please ensure all devices are connected to PoE switches or injectors. For additional information contact your Network Administrator or Aiphone Technical Support at (800) 692-0200.'
  );
  values.notes.push(
    'The Simple Support Tool is recommended for a system of 20 or fewer devices. The product page for the Simple Support Tool can be found at aiphone.com.'
  );
  values.notes.push(
    'Door strikes and their power sources are not included in this quote and must be purchased separately. Not available from Aiphone.'
  );
  values.notes.push(
    "An amplifier or self amplified speaker can be connected to the 600 ohm output on the back of an IX-EA, IX-DV, IX-DVF, IX-DVF-4, IX-DVF-P, IX-DVF-HW, IX-DVF-RA, IX-DVF-2RA, IX-SSA-RA, IX-SSA-2RA, IX-SSA to increase outgoing volume. Communication from the door station to the master will still be transmitted from the door station's microphone. The IX-DVM and IX-SS-2G do not have the hardware to support this feature."
  );

  if (
    values.items
      .filter((item) => item.itemName === 'IX-SOFT')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'The IX-SOFT is delivered via a USB dongle that must remain plugged into the computer using the software. No replacements will be made available if lost.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IX-SOFT')
      ?.reduce((total: number, item) => total + item.quantity, 0) >= 2
  ) {
    values.notes.push(
      'The IX-SOFT is also available in bundles with 3, 5, or 10 licensed USB dongles at a discounted price. Please choose the appropriate sized bundle(s) based on your requirements. Model numbers are IX-SOFT-3, IX-SOFT-5, and IX-SOFT-10.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IX-RS-B')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0 ||
    values.items
      .filter((item) => item.itemName === 'IX-RS-W')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      "Ceiling speaker's 8 ohm direct input connected to the IX-RS provides approximately 500mW of output. For large rooms and/or noisy environments, a self amplified speaker may be preferred. Contact Technical Support for additional assistance."
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IX-DVF-L')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'A PS-2420UL has been added to the specification for each IX-DVF-L. The IX-DVF-L requires 24V DC power from a separate PS-2420UL power supply for T-coil functionality.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IXW-MA')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'An IXW-MA has been added to the parts list. This adaptor is a requirement when utilizing remote door release relays.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'IXW-MAA')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      'An IXW-MAA has been added to the parts list. This adaptor is a requirement when utilizing remote door release relays.'
    );
  }
};

const updateWiresIX = (values: FormValues) => {
  values.wires.push(
    {
      system: 'IX Units Network Drop',
      wire: 'CAT-5e/6',
      description: 'UTP4 non-shielded Category 5e/6 cable (Not Available from Aiphone)',
      maxDistance: 'Per Spec',
      jacketNomOD: 'Per Spec',
      capacitanceLoopRes: 'Per Spec'
    },
    {
      system: 'Contact to Strike',
      wire: '821802',
      description: '2 Cond.,18AWG, Shielded, Available in: 1000’',
      maxDistance: 'N/A',
      jacketNomOD: 'PVC .185"',
      capacitanceLoopRes: '55 pf/ft 6.45 Ohms'
    }
  );
};
