export const FeaturedSystems: QuikSpecSystem[] = [
  /*
  {
    id: 1,
    siteName: 'newIXSystem',
    seriesName: 'IX Series',
    seriesImageName: 'IX-Series.jpg',
    seriesDescription: 'Peer-to-Peer IP Intercom',
    seriesSummary: {
      title: 'Why IX is right for you',
      descriptions: [
        'IP intercoms that are Peer to Peer and PoE Powered',
        'System Partitioning and Call Transfers',
        'SIP Compliant'
      ]
    }
  },
  {
    id: 2,
    siteName: 'newIXGSystem',
    seriesName: 'IXG Series',
    seriesImageName: 'IXG-Series.jpg',
    seriesDescription: 'IP Multi-Tenant Video Intercom',
    seriesSummary: {
      title: 'Why IXG is right for you',
      descriptions: [
        'A complete network based communication system',
        'Unlimited possibilities',
        'For any multi-tenant security application'
      ]
    }
  },
*/
  {
    id: 1,
    siteName: 'newIXIXGSystem',
    seriesName: 'IX | IXG Series',
    systemforC2: 'IXG',
    seriesImageName: 'IXIXG-Series.jpg',
    seriesDescription: 'IP Video Intercom',
    seriesSummary: {
      title: 'Why IX | IXG is right for you',
      descriptions: [
        'IP Video Intercom solution from commercial to multi-tenant',
        'Feature rich and secure',
        'Answering stations include Mobile Apps, PC Software, and Physical Stations',
        'Unlimited station capacity',
        'SIP and ONVIF complaint',
        'Critical integrations with industry leaders'
      ]
    }
  },
  {
    id: 2,
    siteName: 'newACSystem',
    seriesName: 'AC Series',
    systemforC2: 'AC',
    seriesImageName: 'AC-Series.jpg',
    seriesDescription: 'Access Control System',
    seriesSummary: {
      title: 'Why AC is right for you',
      descriptions: [
        'Made in North America (NDAA and TAA compliant)',
        'Scalable from one door to enterprise-level systems',
        'Can be paired with any Aiphone system',
        'Customizable using a powerful scripting engine',
        'Open and documented RESTful API',
        'Multiple hosting options, including the AC-HOST'
      ]
    }
  },
  {
    id: 3,
    siteName: 'newJPSystem',
    seriesName: 'JP Series',
    systemforC2: 'JP',
    seriesImageName: 'JP-Series.jpg',
    seriesDescription: '7" Video Hardwired Intercom',
    seriesSummary: {
      title: 'Why JP is right for you',
      descriptions: [
        'Hands-free (VOX) or handset (full-duplex) communication',
        'Digital PTZ camera eliminates blind spots with 170° wide angle lens',
        'Capable of single or selective door release'
      ]
    }
  },
  {
    id: 4,
    siteName: 'newGTSystem',
    seriesName: 'GT Series',
    systemforC2: 'GT',
    seriesImageName: 'GT-Series.jpg',
    seriesDescription: 'Multi-Tenant Hardwired Intercom',
    seriesSummary: {
      title: 'Why GT is right for you',
      descriptions: [
        'Entrance panel fits buildings from 2 to 5000 tenants',
        'Update tenant directory onsite with NFC or remotely using a network adaptor',
        'Video guard stations can receive and forward calls to tenants'
      ]
    }
  },
  {
    id: 5,
    siteName: 'newJOSystem',
    seriesName: 'JO Series',
    systemforC2: 'JO',
    seriesImageName: 'JO-Series.jpg',
    seriesDescription: 'Mobile App Capable Hardwired Intercom',
    seriesSummary: {
      title: 'Why JO is right for you',
      descriptions: [
        'Answer calls and unlock your door from your smartphone or other mobile devices',
        'Identify visitors on the large 7″ screen',
        'Store calls with no added fees using onboard memory',
        'Easy installation using just 2 wires'
      ]
    }
  },
  {
    id: 6,
    siteName: 'newJVSystem',
    seriesName: 'JV Series',
    systemforC2: 'JV',
    seriesImageName: 'JV-Series.jpg',
    seriesDescription: '7" Video Hardwired Intercom',
    seriesSummary: {
      title: 'Why JV is right for you',
      descriptions: [
        'Communicate with visitors and unlock your door from up to two interior stations',
        'Identify visitors on the large 7″ screen',
        'Store calls with no added fees using onboard memory',
        'Easy installation using just 2 wires'
      ]
    }
  }
];

export const StandardSystems: QuikSpecSystem[] = [
  {
    id: 7,
    siteName: 'newAXSystem',
    seriesName: 'AX Series',
    systemforC2: 'AX',
    seriesImageName: 'AX-Series.jpg',
    seriesDescription: '3.5"  Analog Video Intercom'
  },
  {
    id: 8,
    siteName: 'newKBSystem',
    seriesName: 'KB Series',
    systemforC2: 'KB',
    seriesImageName: 'KB-Series.jpg',
    seriesDescription: '3.5" Video Intercom'
  },
  {
    id: 9,
    siteName: 'newNHXSystem',
    seriesName: 'NHX Series',
    systemforC2: 'NHX',
    seriesImageName: 'NHX-Series.jpg',
    seriesDescription: 'Nurse Call Intercom System'
  },
  {
    id: 10,
    siteName: 'newTwoWireSystem',
    seriesName: 'Two Wire System',
    systemforC2: 'CCS',
    seriesImageName: 'TWO-WIRE-Series.jpg',
    seriesDescription: 'Door Answering Intercoms'
  },
  {
    id: 11,
    siteName: 'newLEFSystem',
    seriesName: 'LEF Series',
    systemforC2: 'LEF Audio Only',
    seriesImageName: 'LEF-Series.jpg',
    seriesDescription: 'Hands-free Selective Call Intercom'
  },
  {
    id: 12,
    siteName: 'newIESystem',
    seriesName: 'IE Series',
    systemforC2: 'IE',
    seriesImageName: 'IE-Series.jpg',
    seriesDescription: 'Handset Intercom'
  }
];

export interface QuikSpecSystem {
  id: number;
  siteName: string;
  seriesName: string;
  systemforC2: string;
  seriesImageName: string;
  seriesDescription: string;
  seriesSummary?: QuikSpecSeriesSummary;
}

export interface QuikSpecSeriesSummary {
  title: string;
  descriptions: string[];
}

export interface QuikspecCategory {
  id: number;
  title: string;
  description: string;
  items: Item[];
  maxQuantityAllowedPerCategory?: number;
  itemsPerPage?: number;
}

export interface Item {
  id?: number;
  deviceName: string;
  itemTitle?: string;
  licenseDescription?: string;
  required?: boolean;
  multipleAllowed?: boolean;
  maxQuantityAllowedPerItem?: number;
  accessories?: AccessoryItem[]; //items that are accessories to this item
  countWithAccessories?: boolean;
  advantages?: string[]; //It shows as a list of characteristics.
  defaultQuantity?: number;
  readOnly?: boolean;
  imageExtension?: string;
}

export interface AccessoryItem {
  quantity?: number;
  accessoryName: string;
  deviceName?: string;
  required?: boolean;
  multipleAllowed?: boolean;
  maxQuantityAllowedPerItem?: number;
}

export interface SystemConstraint {
  description: string;
  max: number;
}

export const quikspecDisclaimer = `
DISCLAIMER: This program is designed to provide systems available in the North American market only.
Any products listed here are available from authorized Distributors and Dealers in the US and Canada.
Aiphone Corporation is not responsible for any variances in the system design between the intent of the user
of the QuikSpec program and the information given. This equipment list is a valid system at time of design.
This design configuration is valid for 90 days from the time of creation. If your project is delayed beyond
90 days, please generate a new QuikSpec design to assure product model accuracy and availability. For
further assistance, contact Technical Support by email or call (800) 692-0200 from 5:30 AM to 4:30 Pacific
Time, Monday through Friday.
`;
