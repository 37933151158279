import { Box, List, Typography } from '@mui/material';
import React from 'react';
import { SoundSettingItem } from './SoundSettingItem';
import { IDevice } from 'store/slices/devicesSlice';

// Assuming DeviceVolumeComponentType is defined in a file named 'types.ts' in the same directory

interface SoundsSectionProps {
  title: string;
  settings: any;
  device: IDevice;
}

export const SoundsSection: React.FC<SoundsSectionProps> = ({ title, settings, device }) => {
  return (
    <>
      <Box sx={{ justifyContent: 'left', maxWidth: '1053px' }}>
        <Typography variant="h4" sx={{ m: '16px' }}>
          {title}
        </Typography>
        <Box sx={styles.containerStyle}>
          <List>
            {settings.map((setting: any, index: number) => (
              <SoundSettingItem key={index} setting={setting} device={device} />
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    justifyContent: 'space-between',
    border: '1px solid grey',
    height: '100%',
    padding: '10px',
    alignItems: 'left'
  }
};
