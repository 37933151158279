/* Translate the device code to the actual device Name
 * Note #4, #8 have (* wild card) - means the cutsomer have to
 * make adjustment later to choose the correct type
 */
export const getDeviceType = (deviceCode: string) => {
  const deviceList: { [key: string]: string } = {
    '1': 'IX-DA',
    '2': 'IX-BA',
    '3': 'IX-MV',
    '4': 'IX-MV7-*',
    '5': 'IX-RS-*',
    '6': 'IXW-MA',
    '7': 'IX-SPMIC',
    '8': 'IX-DV*',
    '9': 'IX-SSA',
    '10': 'IX-SS-2G',
    '11': 'IX-EA',
    '12': 'IX-FA',
    '13': 'Voip Phone',
    '14': 'IXG-2C7',
    '15': 'IXG-DM7',
    '16': 'IXG-MK',
    '17': 'IXGW-LC',
    '18': 'IXGW-GW',
    '19': 'IXG-ASP',
    '20': 'IX-DVM',
    '30': 'IX-SOFT',
    '31': 'IX-DB',
    '32': 'IX-BB'
  };
  return deviceList[deviceCode];
};

export default getDeviceType;
