/**
 * Call Timeout - callSettings.outgoingCallTimeout (Number) default: 60
 * Call Priority - callSettings.?????
 *
 * Ring Back Tone - callSettings.incomingCallRingtone (String) default: ''
 * Ring Count - callSettings.incomingCallRingtoneCount (Number) default: 0
 */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Typography } from '@mui/material';
import CallTimeout from './settings/CallTimeout';
import RingbackTone from './settings/RingBackTone';
import RingCount from './settings/RingCount';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import CallPriority from './controlPanel/CallPriority';
import ContactInput from './controlPanel/ContactInput';
import { getString } from 'shared/utils/LocalizationUtils';
import { getDeviceModelNumberFromModelType, getCallDestinationConfig } from 'shared/utils/helperFunctions';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type TFormData = {
  contactInput: string;
  callPriority: number;
  callTimeout: number;
  tenantCallTimeout?: number;
  ringbackTone: string;
  ringCount: number;
};

const DEFAULT_CALL_TIMEOUT_VALUE = 60;

// ringbackTone: '0907bf08-dd48-47b5-9359-ba6ce04a212a', is the Call Pattern 1

const OutboundCallSetting = () => {
  const { t } = useTranslation();
  const outBoundCallDescription = t('Outbound_Call_Description');
  const successUpdateDevice = t('Success_Update_Device');
  const errorUpdateDevice = t('Error_Update_Device');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');

  const dispatch = useDispatch();
  const buttonSaveChanges = getString('Button_Save_Changes');
  const selectedDevice = useSelector(getSelectedDevice);

  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [needSave, setNeedSave] = useState(false);

  /** get the device type (example: IX-EA), fetch the drop down list for Call Priority, Call Group, Contact Group */
  const selectedDeviceModelNumber = getDeviceModelNumberFromModelType(
    selectedDevice?.basicInfo.deviceModel,
    selectedDevice?.basicInfo.deviceType
  );
  const callDropDownList = getCallDestinationConfig(selectedDeviceModelNumber);

  /** search parameter in the route */
  const [searchParams] = useSearchParams();
  const inputNumberDefaultValue: string = searchParams.get('inputNumber') ?? callDropDownList.contactGroupList[0].value;
  const callPriorityDefaultValue: number = callDropDownList.callPriorityList[0].value;

  const [formData, setFormData] = useState<TFormData>({
    contactInput: inputNumberDefaultValue,
    callPriority: callPriorityDefaultValue,
    callTimeout: selectedDevice?.callSettings?.outgoingCallTimeout ?? DEFAULT_CALL_TIMEOUT_VALUE,
    ringbackTone:
      (inputNumberDefaultValue === '0'
        ? selectedDevice?.callSettings?.ringtoneSetting
        : selectedDevice?.callSettings?.incomingCallRingtone) || '',
    ringCount: selectedDevice?.callSettings?.incomingCallRingtoneCount || 1
  });

  const updateSettingsForCallButton = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      callPriority: selectedDevice?.callSettings?.ipCallPriority || callPriorityDefaultValue,
      callTimeout: selectedDevice?.callSettings?.outgoingCallTimeout || DEFAULT_CALL_TIMEOUT_VALUE,
      ringbackTone: selectedDevice?.callSettings?.ringtoneSetting || '',
      ringCount: selectedDevice?.callSettings?.incomingCallRingtoneCount || 1
    }));
  };

  const updateSettingsForContactInput = () => {
    if (formData.contactInput === '0') return;

    const selectedContactInput = selectedDevice?.contactInputList?.find(
      (input) => input.inputNumber === Number(formData.contactInput)
    );
    if (!selectedContactInput) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      callPriority: selectedContactInput.callPriority,
      callTimeout: selectedContactInput?.outgoingCallTimeout ?? DEFAULT_CALL_TIMEOUT_VALUE,
      ringbackTone: selectedContactInput?.ringtone || ''
    }));
  };

  useEffect(() => {
    if (formData.contactInput === '0') {
      updateSettingsForCallButton();
    } else {
      updateSettingsForContactInput();
    }
  }, [formData.contactInput]);

  // handle out bound call setting option change
  const handleFieldChange = (fieldName: string, value: string | number) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value
    }));
  };

  const handleUpdateOutboundCallSettings = async () => {
    // check the call trigger setting, if 'Call Button' selected. save it to the device call Settings
    // else, save to the contactInputList
    let formattedDevice;
    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    if (formData.contactInput === '0') {
      const updatedCallSettings = {
        outgoingCallTimeout: formData.callTimeout,
        ringtoneSetting: formData.ringbackTone,
        incomingCallTimeout: formData.ringCount,
        ipCallPriority: formData.callPriority
      };
      newDevice.callSettings = {
        ...selectedDevice?.callSettings,
        ...updatedCallSettings
      };
      formattedDevice = {
        device: {
          publicId: selectedDevice?.publicId,
          callSettings: updatedCallSettings
        }
      };
    } else {
      // find object in contactInputList that matches the call Trigger value to inputNumber
      const copyContactInputList = selectedDevice?.contactInputList;
      const updatedContactInputSettings = copyContactInputList?.map((contactInput) => {
        if (contactInput.inputNumber === Number(formData.contactInput)) {
          return {
            ...contactInput,
            callPriority: formData.callPriority,
            outgoingCallTimeout: formData.callTimeout,
            ringtone: formData.ringbackTone
          };
        }
        return contactInput;
      });
      newDevice.contactInputList = updatedContactInputSettings;
      formattedDevice = {
        device: {
          publicId: selectedDevice?.publicId,
          contactInputList: updatedContactInputSettings
        }
      };
    }
    try {
      const updatedDevice = formattedDevice;
      const response = await updateDevice(updatedDevice).unwrap();
      if ('error' in response) {
        throw response.error;
      }

      dispatch(updateSelectedDevice({ device: newDevice }));
      setShowAlert(true);
      setNeedSave(false);
    } catch (error: any) {
      const err = JSON.parse(error.data);
      if (err.errorDetails.includes('Unauthorized user Id')) {
        setErrorMessage(unauthorizedUser);
      } else {
        setErrorMessage(errorUpdateDevice);
      }
    }
  };

  return (
    <>
      <Box sx={styles.outBoundCallWrapper}>
        <SnackbarAlert
          type="error"
          time={10000}
          text={`${errorMessage}`}
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <SnackbarAlert
          type="success"
          time={3000}
          text={successUpdateDevice}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
        />

        <Box sx={styles.descriptionCard}>
          <Typography>{outBoundCallDescription}</Typography>
        </Box>

        <Box sx={styles.controlPanelWrapper}>
          <Card sx={styles.callSettingWrapper}>
            <ContactInput
              fieldName="contactInput"
              value={formData.contactInput}
              setNeedSave={setNeedSave}
              contactGroupList={callDropDownList.contactGroupList}
              onChange={handleFieldChange}
            />
            <CallPriority
              fieldName="callPriority"
              value={formData.callPriority}
              setNeedSave={setNeedSave}
              callPriorityList={callDropDownList.callPriorityList}
              onChange={handleFieldChange}
            />
          </Card>
          <Box sx={styles.loadingBox}>
            <LoadingButton
              loading={isUpdating}
              variant="outlined"
              disabled={isUpdating || !needSave}
              onClick={handleUpdateOutboundCallSettings}
            >
              {buttonSaveChanges}
            </LoadingButton>
          </Box>
        </Box>
        <Card sx={styles.settingsWrapper}>
          <CallTimeout
            fieldName="callTimeout"
            value={formData.callTimeout}
            onChange={handleFieldChange}
            setNeedSave={setNeedSave}
            type="number"
          />
          <RingbackTone
            fieldName="ringbackTone"
            deviceModel={selectedDeviceModelNumber}
            value={formData.ringbackTone}
            onChange={handleFieldChange}
            setNeedSave={setNeedSave}
            type="string"
          />
          {formData.contactInput === '0' && (
            <RingCount
              fieldName="ringCount"
              value={formData.ringCount}
              onChange={handleFieldChange}
              setNeedSave={setNeedSave}
              type="number"
            />
          )}
        </Card>
      </Box>
    </>
  );
};

/* Call Origination tab stub */
export const OutboundCallSettingLabel = () => {
  const outBoundCallSettings = getString('Outbound_Call_Settings');
  return <span>{outBoundCallSettings}</span>;
};

const styles = {
  outBoundCallWrapper: {
    width: '100%',
    minWidth: '1250px',
    height: '100%'
  },
  controlPanelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  callSettingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '600px',
    width: '30%',
    padding: '10px',
    gap: '20px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#e9e9e9'
    }
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginY: '20px'
  },
  descriptionCard: {
    paddingY: '10px'
  },
  settingsWrapper: {
    marginY: '30px',
    padding: '10px'
  }
};

export default OutboundCallSetting;
