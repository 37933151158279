import { Box, Card, Typography } from '@mui/material';
import TenantsDatagrid from './datagrids/TenantsDatagrid';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useParams } from 'react-router-dom';
import AddTenantDialog from '../../../Tenants/components/AddTenantDialog';

const TenantsCard = () => {
  const [isAddTenantDialogOpen, setIsAddTenantDialogOpen] = useState(false);

  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const unitPublicId = useParams().unitid || '';
  const selectedUnit = unitList[unitPublicId];
  const selectedUnitType = selectedUnit?.unitType || '';

  return (
    <>
      <Card sx={styles.tenantsCard}>
        {selectedUnitType === 4 ? (
          <Typography variant="sectionHeader">Tenants</Typography>
        ) : (
          <Typography variant="sectionHeader">App Users</Typography>
        )}
        <Typography variant="body1">
          The primary tenant designation determines the phone number used for telephone entry and the name displayed in
          the IXG-DM7 directory.
        </Typography>

        <Box sx={styles.tenantsDatagridWrapper}>
          <TenantsDatagrid setIsAddTenantDialogOpen={setIsAddTenantDialogOpen} />
        </Box>
      </Card>
      <AddTenantDialog
        isOpen={isAddTenantDialogOpen}
        setIsOpen={setIsAddTenantDialogOpen}
        unitPublicId={unitPublicId}
      />
    </>
  );
};

const styles = {
  tenantsCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '20px'
  },
  tenantsDatagridWrapper: {
    display: 'flex',
    width: '100%',
    marginTop: '10px'
  },
  loadingButton: {
    float: 'right'
  }
};

export default TenantsCard;
