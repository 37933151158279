import { getString } from 'shared/utils/LocalizationUtils';
import LiftControlDataGridPage from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/LiftControlDataGridPage';

/* Relay Output Lift tab stub */
export const RelayOutputLiftLabel = () => {
  const relayOutput = getString('Relay_Output');
  return <span>{relayOutput}</span>;
};

// Adding this here for now so it isn't rendered in AppRoutes
const RelayOutputLift = LiftControlDataGridPage;

export default RelayOutputLift;
