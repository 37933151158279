import { Card, Button, Typography } from '@mui/material';

type CookieConsentBannerProps = {
  setShowCookieConsentBanner: (value: boolean) => void;
};

const CookieConsentBanner = ({ setShowCookieConsentBanner }: CookieConsentBannerProps) => {
  const handleAccept = () => {
    document.cookie = 'cookieConsent=accepted; max-age=31536000; path=/'; //cookie consent lasts for 1 year
    localStorage.setItem('cookieConsent', 'accepted');
    setShowCookieConsentBanner(false);
    return;
  };

  return (
    <>
      <Card sx={styles.cookieContainer}>
        <Typography variant="h6" sx={{ color: 'black' }}>
          We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to
          improve user experience and analyze website traffic. By clicking “Accept,” you agree to our website's cookie
          use as described in our{' '}
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=04586692-e696-4b13-b8cb-c0e4198af033"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </a>
          .
        </Typography>
        <Button variant="contained" sx={styles.acceptButton} onClick={handleAccept}>
          Accept
        </Button>
      </Card>
    </>
  );
};

export default CookieConsentBanner;

const styles = {
  cookieContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px', padding: '20px' },
  acceptButton: {
    margin: '20px',
    color: 'white',
    fontFamily: "'Roboto Condensed', sans-serif"
  }
};
