import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useCreateAppMutation,
  useCreateUnitMutation,
  useGetAppWithSiteIdQuery,
  useLazyGetAppWithSiteIdQuery
} from 'services/aiphoneCloud';
import { RootState } from 'store';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import 'styles/frontshine.css';
import { MAX_APPS, UnitTypesEnum } from 'features/RemoteManagement/Types';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { useTranslation } from 'react-i18next';

interface IAddAppsProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const AddApps: React.FC<IAddAppsProps> = ({ handlePreviousStep, handleNextStep }: IAddAppsProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const sitePublicId = useParams().id;
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const unitListByType = useSelector((state: RootState) => state.units.UnitListByType);
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const [numberOfApps, setNumberOfApps] = React.useState<number>(0);
  const [siteHasApps, setSiteHasApps] = React.useState<boolean>(false);
  const [createApps] = useCreateAppMutation();
  const [createUnit] = useCreateUnitMutation();
  const { data } = useGetAppWithSiteIdQuery({ sitePublicId: sitePublicId, qty: 500, page: 0 });
  const [fetchApps] = useLazyGetAppWithSiteIdQuery();
  const APP_UNIT_NUMBER = '9999';
  const APP_UNIT_NAME = 'MobileAppUnit';

  const { t } = useTranslation();

  useEffect(() => {
    if (data && Object.keys(data.appList).length > 0) {
      setSiteHasApps(true);
    }
  }, [data]);

  const handleAddApps = async () => {
    let targetUnitPublicId;
    setIsLoading(true);
    if (unitListByType.Residential.length > 0) {
      targetUnitPublicId = unitList[unitListByType.Residential[0]].publicId;
    } else if (unitListByType.Commercial.length > 0) {
      targetUnitPublicId = unitList[unitListByType.Commercial[0]].publicId;
    } else {
      const params = {
        unitData: {
          unitNumber: APP_UNIT_NUMBER,
          unitName: APP_UNIT_NAME,
          unitType: UnitTypesEnum.Commercial,
          buildingPublicId: Object.keys(buildingList)[0]
        }
      };
      try {
        const res = await createUnit(params).unwrap();
        targetUnitPublicId = res;
      } catch (error) {
        setIsLoading(false);
        setSnackbarMessage(t('Error_Create_Unit_For_Apps'));
        setShowAlert(true);
        return;
      }
    }

    if (!targetUnitPublicId) {
      setIsLoading(false);
      setSnackbarMessage(t('Target_Unit_Unavailable_For_Apps'));
      setShowAlert(true);
      return;
    }

    if (siteHasApps && numberOfApps > MAX_APPS - Object.keys(data?.appList).length) {
      setNumberOfApps(8 - Object.keys(data?.appList).length);
    }

    for (let i = 0; i < numberOfApps; i++) {
      let params;
      if (siteHasApps) {
        params = {
          appData: {
            stationNumber: `999${i + 1 + Object.keys(data?.appList).length}`,
            stationName: `MobileApp${i + 1 + Object.keys(data?.appList).length}`,
            unitPublicId: targetUnitPublicId
          }
        };
      } else {
        params = {
          appData: {
            stationNumber: `999${i + 1}`,
            stationName: `MobileApp${i + 1}`,
            unitPublicId: targetUnitPublicId
          }
        };
      }
      try {
        await createApps(params).unwrap();
        await fetchApps({
          sitePublicId: sitePublicId,
          qty: 500,
          page: 0
        }).unwrap();
      } catch (error) {
        setIsLoading(false);
        setSnackbarMessage(t('Error_Create_Apps'));
        setShowAlert(true);
        return;
      }
    }
    setIsLoading(false);
    handleNextStep();
  };

  const handleNumberOfAppsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredValue = parseInt(event.target.value);

    if (isNaN(enteredValue)) {
      setNumberOfApps(0);
      setErrorMessage('');
      return;
    }

    // If the entered value is greater than the maximum allowed apps
    if (siteHasApps && enteredValue > MAX_APPS - Object.keys(data?.appList).length) {
      setErrorMessage(`The number of apps cannot exceed ${MAX_APPS - Object.keys(data?.appList).length}.`);
    } else if (enteredValue > MAX_APPS) {
      setErrorMessage(`The number of apps cannot exceed ${MAX_APPS}.`);
    } else {
      setErrorMessage('');
    }
    setNumberOfApps(enteredValue);
  };

  return (
    <Container maxWidth="md">
      <Box className="my-4">
        <Box sx={styles.centerContent}>
          <Typography variant="h4" className="mb-2" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
            {t('Wizard_Mobile_Apps_Title')}
          </Typography>
        </Box>
      </Box>
      <Paper elevation={1} sx={styles.paper}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12} sx={styles.centerContent}>
            <Box
              component="img"
              src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/mobileApp.png`}
              alt="apps"
              height="300px"
            />
          </Grid>
          <Grid item sm={6} xs={12} sx={styles.centerContent}>
            <Box width={'100%'}>
              <Box className="mb-4">
                <Typography variant="body1">{t('Wizard_Mobile_Apps_Desc')}</Typography>
              </Box>
              <TextField
                label="Number of Apps"
                type="number"
                value={numberOfApps}
                onChange={handleNumberOfAppsChange}
                fullWidth
                error={!!errorMessage}
                helperText={errorMessage}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: siteHasApps ? MAX_APPS - Object.keys(data?.appList).length : MAX_APPS
                  }
                }}
              />
              {siteHasApps && (
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  This site currently has {Object.keys(data?.appList).length} apps. An additional{' '}
                  {numberOfApps ? numberOfApps : 0} apps will be added.
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          Back
        </Button>

        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleAddApps}
          disabled={!!errorMessage || isLoading}
        >
          Continue
        </LoadingButton>
      </Box>

      <SnackbarAlert
        type="error"
        time={7000}
        text={snackbarMessage}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </Container>
  );
};

const styles = {
  imageContainer: {
    width: '100%',
    borderRadius: 1
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flexDirection: 'row',
    marginTop: '1rem'
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    p: 3,
    px: 5,
    mb: 4,
    width: '100%',
    height: 'auto'
  }
};

export default AddApps;
