import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

interface CountdownProps {
  onComplete: () => void;
}

const Countdown: React.FC<CountdownProps> = ({ onComplete }) => {
  const [count, setCount] = useState(3);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (count > 1) {
        setCount(count - 1);
      } else {
        clearInterval(countdownInterval);
        onComplete();
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [count, onComplete]);

  return (
    <div>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Navigating to next screen in {count} seconds
      </Typography>
    </div>
  );
};

export default Countdown;
